import { Box, Typography, useMediaQuery } from "@mui/material";

import DeviceItem from "./DeviceItem";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getPackData } from "Services/Http/http.service";
import Skelton from "components/Skelton/Skelton";
import { MOBILE_WIDTH } from "constants/constant";
import AirPre from "../../assets/Sensors/Air/pre.png";
import AirTemp from "../../assets/Sensors/Air/TH.png";
import AirHumidity from "../../assets/Sensors/Air/TH.png";
import AirRain from "../../assets/Sensors/Air/baran.png";
import AirWindDir from "../../assets/Sensors/Air/winddirection.png";
import AirWindSpeed from "../../assets/Sensors/Air/windspeed.png";
import AirLux from "../../assets/Sensors/Air/lux.png";
import SoilHumidity from "../../assets/Sensors/Soil/TH.png";
import StemDiameterSensor from "../../assets/hardwares/stemDiameterSensor.svg";
import SensorDevice from "../../assets/hardwares/sensorDevice.svg";
import WeatherStation from "../../assets/hardwares/weatherStation.svg";
import PlantRoshd from "../../assets/Sensors/Plant/roshd.png";
import PlantHumidity from "../../assets/Sensors/Plant/H.png";
import Hesgarino from "../../assets/Sensors/Air/hesgarino.png";
import { setCart } from "States/shopping";
import DeviceItemMob from "./DeviceItemMob";
const details = [
  {
    title: "سنسور فشار هوا",
    type: "sensor",
    description:
      "سنسور فشار هوا با دقت بالا و پاسخ سریع برای اندازه‌گیری دقیق فشار محیط",
    image: AirPre,
  },
  {
    title: "سنسور دمای هوا",
    type: "sensor",
    description:
      "سنسور دمای هوا با دقت بالا و قابلیت پاسخ‌دهی سریع، مناسب برای نظارت و کنترل دمای محیط در کاربردهای مختلف.",
    image: AirTemp,
  },
  {
    title: "سنسور رطوبت هوا",
    type: "sensor",
    description:
      "سنسور رطوبت هوا با دقت بالا و پایداری طولانی‌مدت، ایده‌آل برای اندازه‌گیری و کنترل رطوبت در محیط‌های مختلف",
    image: AirHumidity,
  },
  {
    title: "سنسور باران سنج",
    type: "sensor",
    description:
      "سنسور باران‌سنج با دقت و حساسیت بالا، ایده‌آل برای اندازه‌گیری میزان بارش و پایش شرایط آب و هوایی در کاربردهای کشاورزی و محیط‌زیستی",
    image: AirRain,
  },
  {
    title: "سنسور جهت باد",
    type: "sensor",
    description:
      "سنسور جهت باد با دقت فوق‌العاده و طراحی مقاوم در برابر شرایط جوی، ایده‌آل برای تحلیل دقیق الگوهای باد",
    image: AirWindDir,
  },
  {
    title: "سنسور سرعت باد",
    type: "sensor",
    description:
      "سنسور سرعت باد با دقت بالا و واکنش سریع، طراحی شده برای اندازه‌گیری دقیق و قابل اعتماد سرعت باد در شرایط جوی مختلف و کاربردهای متنوع",
    image: AirWindSpeed,
  },
  {
    title: "سنسور رطوبت خاک",
    type: "sensor",
    description:
      "سنسور رطوبت خاک با دقت بالا و قابلیت اطمینان، ایده‌آل برای نظارت بر رطوبت خاک و بهینه‌سازی آبیاری در کشاورزی و باغبانی",
    image: SoilHumidity,
  },
  {
    title: "سنسور دمای خاک",
    type: "sensor",
    description:
      "سنسور دمای خاک با دقت بالا و قابلیت پایداری، مناسب برای اندازه‌گیری دقیق دمای خاک در سیستم‌های کشاورزی و محیط‌زیستی",
    image: SoilHumidity,
  },
  {
    title: "سنسور بارندگی",
    type: "sensor",
    description:
      "سنسور بارش با دقت بالا و حساسیت قابل تنظیم، طراحی شده برای اندازه‌گیری دقیق میزان بارش در مناطق مختلف",
    image: AirRain,
  },
  {
    title: "سنسور تابش",
    type: "sensor",
    description:
      "سنسور تابش با دقت بالا و قابلیت اندازه‌گیری دقیق در محدوده‌های مختلف، برای ارزیابی دقیق تابش نوری در کاربردهای کشاورزی و بهینه‌سازی رشد گیاهان",
    image: AirLux,
  },
  {
    title: "سنسور نمودار رشد گیاه",
    type: "sensor",
    description:
      "سنسور نمودار رشد گیاه، با قابلیت ثبت و نمایش دقیق فرآیند رشد گیاهان در طول زمان، مناسب برای مطالعات علمی و کاربردهای کشاورزی",
    image: PlantRoshd,
  },
  {
    title: "سنسور قطر میوه",
    type: "sensor",
    description:
      "سنسور قطر میوه با دقت بالا و قابلیت اندازه‌گیری دقیق، مناسب برای ارزیابی ابعاد و کیفیت میوه‌ها در صنایع کشاورزی و فرآوری غذایی",
    image: PlantRoshd,
  },
  {
    title: "سنسور قطر ساقه",
    type: "sensor",
    description:
      "سنسور قطر ساقه با دقت بالا و قابلیت اندازه‌گیری دقیق، مناسب برای مطالعه و اندازه‌گیری قطر و رشد ساقه‌های گیاهان",
    image: StemDiameterSensor,
  },
  {
    title: "سنسور رطوبت برگ",
    type: "sensor",
    description:
      "سنسور رطوبت برگ با دقت بالا و قابلیت اندازه‌گیری دقیق، مناسب برای نظارت و کنترل رطوبت برگ‌ها در کشت‌های گلخانه‌ای و کاربردهای تحقیقاتی در زمینه کشاورزی و باغبانی",
    image: PlantHumidity,
  },
  {
    title: "دستگاه سنسور هوا",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: SensorDevice,
  },
  {
    title: "دستگاه سنسور خاک",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: SensorDevice,
  },
  {
    title: "دستگاه سنسور گیاه",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: SensorDevice,
  },
  {
    title: "ایستگاه هواشناسی",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: WeatherStation,
  },
  {
    title: "حسگرینو",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: Hesgarino,
  },
];
const HardwareStore = ({ setDeviceType }) => {
  const isMobileScreens = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);
  const token = useSelector((state) => state.global.token);
  const hardwareDataQuery = useQuery(
    ["hardwareDataQuery", token, true, "SOFTWARE"],
    () => {
      return getPackData(token, false, "HARDWARE");
    }
  );

  const { isLoading, isIdle, isSuccess, data } = hardwareDataQuery;
  const carts = useSelector((state) => state.shopping.cart);
  const dispatch = useDispatch();
  // const exists = carts.some((i) => i.id === item.id);
  const getDetail = (item) => {
    const hardware = details.filter((i) => i.title === item.title);
    return hardware[0];
  };
  const handleAddToCart = (item) => {
    if (!carts.some((i) => i.id === item.id))
      dispatch(setCart({ cart: [...carts, item] }));
  };
  return isMobileScreens ? (
    <Box
      sx={{
        height: "80vh",
        overflowY: "auto",

        WebkitOverflowScrolling: "touch",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Typography mb="1rem" variant="h3">
        سنسور ها
      </Typography>
      {isIdle && isLoading ? (
        <Skelton />
      ) : (
        isSuccess &&
        data?.data?.foundPlan.map((item, index) => (
          <DeviceItemMob
            index={index}
            getDetail={getDetail}
            item={item}
            carts={carts}
            handleAddToCart={handleAddToCart}
          />
        ))
      )}
    </Box>
  ) : (
    <>
      <Typography mb="1rem" variant="h3">
        سنسور ها
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          // gap: "1rem",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {isIdle && isLoading ? (
          <Skelton />
        ) : (
          isSuccess &&
          data?.data?.foundPlan.map((item, index) => (
            <Box
              key={index}
              sx={{
                "@media (min-width: 1650px)": {
                  width: "16.66%",
                },
                "@media (min-width: 1350px) and (max-width: 1649px)": {
                  width: "20%",
                },
                "@media (min-width: 1150px) and (max-width: 1349px)": {
                  width: "25%",
                },
                "@media (max-width: 1149px)": {
                  width: "33.33%",
                },
              }}
            >
              <DeviceItem setDeviceType={setDeviceType} item={item} />
            </Box>
          ))
        )}
      </Box>
    </>
  );
};

export default HardwareStore;
