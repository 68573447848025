import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  reportData: [],
};
export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReportData: (state, action) => {
      state.reportData = action.payload.reportData;
    },
  },
});
export const { setReportData } = reportSlice.actions;
export default reportSlice.reducer;
