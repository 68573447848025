import { Box, useTheme } from "@mui/material";
import Checked from "../../assets/icon/primary_checked.svg";
const PrimaryCheck = ({ checked, setFactor }) => {
  const theme = useTheme();
  return (
    <>
      {checked ? (
        <Box
          component={"img"}
          sx={{ width: "16px", height: "16px" }}
          src={Checked}
          onClick={() => setFactor((prev) => !prev)}
        />
      ) : (
        <Box
          sx={{
            width: "16px",
            height: "16px",
            borderRadius: "4px",
            border: `2px solid ${theme.palette.primary.main}`,
          }}
          onClick={() => setFactor((prev) => !prev)}
        />
      )}
    </>
  );
};

export default PrimaryCheck;
