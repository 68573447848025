import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
  allServices: [],
  cart: [],
  shoppingCartOpen: false,
  finalize: false,
};

export const shoppingSlice = createSlice({
  name: "shopping",
  initialState,
  reducers: {
    setShoppingServices: (state, action) => {
      state.services = action.payload.services;
    },
    setAllServies: (state, action) => {
      state.allServices = action.payload.allServices;
    },
    setCart: (state, action) => {
      state.cart = action.payload.cart;
    },
    setShoppingCartOpen: (state, action) => {
      state.shoppingCartOpen = action.payload.shoppingCartOpen;
    },
    setFinalize: (state, action) => {
      state.finalize = action.payload.finalize;
    },
  },
});

export const {
  setShoppingServices,
  setAllServies,
  setCart,
  setShoppingCartOpen,
  setFinalize,
} = shoppingSlice.actions;
export default shoppingSlice.reducer;
