import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Drawer,
  Fade,
  IconButton,
  List,
  ListItemText,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import {
  QueryStats,
  QueryStatsOutlined,
  DashboardOutlined,
  HeadsetMicOutlined,
  ShoppingCartOutlined,
  CalculateOutlined,
  AirplaneTicketOutlined,
  CloudOutlined,
  Add,
  RecommendOutlined,
  ReportProblemOutlined,
  Dashboard,
  Recommend,
  ReportProblem,
  Cloud,
  AirplaneTicket,
  HeadsetMic,
  ShoppingCart,
  SatelliteAlt,
  SatelliteAltOutlined,
  ForumOutlined,
  Forum,
  LeaderboardOutlined,
  Leaderboard,
  ToggleOffOutlined,
  ToggleOff,
  PeopleAltOutlined,
  PeopleAlt,
  AccountBalanceOutlined,
  AccountBalance,
  Inventory2Outlined,
  Inventory2,
  Calculate,
  SettingsRemoteOutlined,
  SettingsRemote,
  SchoolOutlined,
  School,
  MessageOutlined,
  Message,
  GrassOutlined,
  Grass,
  SettingsOutlined,
  Settings,
  PriorityHighOutlined,
  PriorityHigh,
  MonetizationOnOutlined,
  MonetizationOn,
  PlaylistAddCheckOutlined,
  PlaylistAdd,
  AutoAwesomeMosaicOutlined,
  AutoAwesomeMosaic,
  PaidOutlined,
  Paid,
  AccountBalanceWalletOutlined,
  AccountBalanceWallet,
  ManageAccounts,
  ManageAccountsOutlined,
  RuleOutlined,
  Rule,
  CreateOutlined,
  Create,
  SecurityOutlined,
  Security,
  ForestOutlined,
  Forest,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import FlexBetween from "components/FlexBetween/FlexBetween";
import EosDrone from "assets/eos-icons_drone.svg";
import EosDrone1 from "assets/eos-icons_drone1.png";
import { useTranslation } from "react-i18next";

const RecordButton = styled(Button)(({ theme, mode, secondary = false }) => ({
  height: "40px",
  color:
    mode === "dark" || !secondary ? "#F7F7F7" : theme.palette.fontColor.alt,
  overflow: "hidden",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  gap: "0.5rem",
  background: secondary
    ? theme.palette.background.third
    : `${theme.palette.background.second} linear-gradient(180deg, #2A80F4 -11%, rgba(42, 128, 244, 0.4) 117%)`,
  "&:hover": {
    backgroundColor: secondary
      ? theme.palette.background.third
      : theme.palette.background.second,
    opacity: 0.9,
  },
}));
const farmerItems = [
  {
    text: "مدیریت جامع",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "سنجش از دور",
    t: "remotesensing",
    icon: <SatelliteAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SatelliteAlt sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مدیریت سنسور ها",
    t: "sensormanagement",
    icon: <SettingsRemoteOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SettingsRemote sx={{ width: "24px", height: "24px" }} />,
  },
];
const farmerItemsI = [
  {
    text: "هشدارها",
    t: "alarms",
    iconOpenDrawer: (
      <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: (
      <ReportProblem sx={{ width: "24px", height: "24px" }} />
    ),
    icon: <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ReportProblem sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مدیریت پرسنل",
    t: "staffmgmt",
    icon: <PeopleAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PeopleAlt sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "کنترل تجهیزات",
  //   t: "actuator",
  //   icon: <ToggleOffOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <ToggleOff sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "توصیه ها",
    t: "recommends",
    iconOpenDrawer: (
      <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: <RecommendOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Recommend sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "شاخص هوشمند",
  //   t: "smartindexes",
  //   icon: <QueryStatsOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <QueryStats sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "هواشناسی",
    t: "weather",
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مرحله رشد",
    t: "growthstage",
    icon: <GrassOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Grass sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "پیام رسان",
    t: "messenger",
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "کارشناس ها",
    t: "experts",
    icon: <ManageAccountsOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ManageAccounts sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "همسایگی",
  //   t: "neighborhood",
  //   icon: <AutoAwesomeMosaicOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <AutoAwesomeMosaic sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "کیف پول",
  //   t: "wallet",
  //   icon: (
  //     <AccountBalanceWalletOutlined sx={{ width: "24px", height: "24px" }} />
  //   ),
  //   iconFilled: <AccountBalanceWallet sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "گزارشات",
  //   t: "report",
  //   icon: <LeaderboardOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Leaderboard sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "تیکت",
    t: "ticket",
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "تنظیمات",
    t: "setting",
    icon: <SettingsOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Settings sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "راهنما",
    t: "help",
    icon: <PriorityHighOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <PriorityHigh sx={{ width: "24px", height: "24px" }} />,
  },
];
const farmerItemsEq = [
  {
    text: "آموزش",
    t: "education",
    icon: <SchoolOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <School sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "ردیابی و شناسایی",
  //   t: "tracking",
  //   icon: <MonetizationOnOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <MonetizationOn sx={{ width: "24px", height: "24px" }} />,
  // },

  {
    text: "بازارگاه",
    t: "shopping",
    icon: <ShoppingCartOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ShoppingCart sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "حسابداری",
  //   t: "shoppingg",
  //   icon: <CalculateOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Calculate sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "پهپاد",
  //   t: "drone",

  //   icon: (
  //     <Box
  //       component={"img"}
  //       src={EosDrone}
  //       sx={{ width: "24px", height: "24px" }}
  //     />
  //   ),
  //   iconFilled: (
  //     <Box
  //       component={"img"}
  //       src={EosDrone1}
  //       sx={{ width: "24px", height: "24px" }}
  //     />
  //   ),
  // },
  // {
  //   text: "انبارداری",
  //   t: "shoppinggg",
  //   icon: <Inventory2Outlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Inventory2 sx={{ width: "24px", height: "24px" }} />,
  // },
];
const staffItems = [
  {
    text: "وظایف",
    t: "",
    id: 1,
    icon: <PeopleAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PeopleAlt sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "پیام رسان",
    t: "messenger",
    id: 4,
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "هواشناسی",
    t: "weather",
    id: 5,
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "درخواست ها",
    t: "requestsFarmer",
    id: 5,
    icon: <RuleOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Rule sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "تیکت",
    t: "ticket",
    id: 6,
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "راهنما",
  //   t: "help",
  //   id: "help",
  //   icon: <PriorityHighOutlined style={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <PriorityHigh sx={{ width: "24px", height: "24px" }} />,
  // },
];
const expertItems = [
  {
    text: "مدیریت جامع",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "سنجش از دور",
    t: "remotesensing",
    icon: <SatelliteAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SatelliteAlt sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مدیریت سنسور ها",
    t: "sensormanagement",
    icon: <SettingsRemoteOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SettingsRemote sx={{ width: "24px", height: "24px" }} />,
  },
];

const expertItemsI = [
  {
    text: "پیام رسان",
    t: "messenger",
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مرحله رشد",
    t: "growthstage",
    icon: <GrassOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Grass sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "شاخص هوشمند",
  //   t: "smartindexes",
  //   icon: <QueryStatsOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <QueryStats sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "کنترل تجهیزات",
  //   t: "actuator",
  //   icon: <ToggleOffOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <ToggleOff sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "توصیه ها",
    t: "recommends",
    iconOpenDrawer: (
      <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: <RecommendOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Recommend sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "تیکت",
    t: "ticket",
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "گزارشات",
  //   t: "report",
  //   icon: <LeaderboardOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Leaderboard sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "درخواست ها",
    t: "requests",
    icon: <PlaylistAddCheckOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PlaylistAdd sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "زمین ها",
    t: "farmsList",
    icon: <AutoAwesomeMosaicOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <AutoAwesomeMosaic sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "هشدارها",
    t: "alarms",
    iconOpenDrawer: (
      <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: (
      <ReportProblem sx={{ width: "24px", height: "24px" }} />
    ),
    icon: <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ReportProblem sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "هواشناسی",
    t: "weather",
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "قیمت گذاری",
    t: "pricing",
    icon: <PaidOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Paid sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "تنظیمات",
    t: "setting",
    icon: <SettingsOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Settings sx={{ width: "24px", height: "24px" }} />,
  },

  // {
  //   text: "کیف پول",
  //   t: "wallet",
  //   icon: <AccountBalanceOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <AccountBalance sx={{ width: "24px", height: "24px" }} />,
  // },
];

const farmerItemsEn = [
  {
    text: "Dashboard",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "Remote Sensing",
    t: "remotesensing",
    icon: <SatelliteAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SatelliteAlt sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Sensor Management",
    t: "sensormanagement",
    icon: <SettingsRemoteOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SettingsRemote sx={{ width: "24px", height: "24px" }} />,
  },
];
const farmerItemsIEn = [
  {
    text: "Alarms",
    t: "alarms",
    iconOpenDrawer: (
      <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: (
      <ReportProblem sx={{ width: "24px", height: "24px" }} />
    ),
    icon: <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ReportProblem sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Staff Management",
    t: "staffmgmt",
    icon: <PeopleAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PeopleAlt sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "کنترل تجهیزات",
  //   t: "actuator",
  //   icon: <ToggleOffOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <ToggleOff sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "Recommends",
    t: "recommends",
    iconOpenDrawer: (
      <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: <RecommendOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Recommend sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "شاخص هوشمند",
  //   t: "smartindexes",
  //   icon: <QueryStatsOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <QueryStats sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "Weather",
    t: "weather",
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Growth Stages",
    t: "growthstage",
    icon: <GrassOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Grass sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Messenger",
    t: "messenger",
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Experts",
    t: "experts",
    icon: <ManageAccountsOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ManageAccounts sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "همسایگی",
  //   t: "neighborhood",
  //   icon: <AutoAwesomeMosaicOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <AutoAwesomeMosaic sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "کیف پول",
  //   t: "wallet",
  //   icon: (
  //     <AccountBalanceWalletOutlined sx={{ width: "24px", height: "24px" }} />
  //   ),
  //   iconFilled: <AccountBalanceWallet sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "گزارشات",
  //   t: "report",
  //   icon: <LeaderboardOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Leaderboard sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "Ticket",
    t: "ticket",
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Settings",
    t: "setting",
    icon: <SettingsOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Settings sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Help",
    t: "help",
    icon: <PriorityHighOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <PriorityHigh sx={{ width: "24px", height: "24px" }} />,
  },
];
const farmerItemsEqEn = [
  {
    text: "Education",
    t: "education",
    icon: <SchoolOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <School sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "ردیابی و شناسایی",
  //   t: "tracking",
  //   icon: <MonetizationOnOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <MonetizationOn sx={{ width: "24px", height: "24px" }} />,
  // },

  {
    text: "Shopping",
    t: "shopping",
    icon: <ShoppingCartOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ShoppingCart sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "حسابداری",
  //   t: "shoppingg",
  //   icon: <CalculateOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Calculate sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "پهپاد",
  //   t: "drone",

  //   icon: (
  //     <Box
  //       component={"img"}
  //       src={EosDrone}
  //       sx={{ width: "24px", height: "24px" }}
  //     />
  //   ),
  //   iconFilled: (
  //     <Box
  //       component={"img"}
  //       src={EosDrone1}
  //       sx={{ width: "24px", height: "24px" }}
  //     />
  //   ),
  // },
  // {
  //   text: "انبارداری",
  //   t: "shoppinggg",
  //   icon: <Inventory2Outlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Inventory2 sx={{ width: "24px", height: "24px" }} />,
  // },
];
const staffItemsEn = [
  {
    text: "Tasks",
    t: "",
    id: 1,
    icon: <PeopleAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PeopleAlt sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "Messenger",
    t: "messenger",
    id: 4,
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Weather",
    t: "weather",
    id: 5,
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Requests",
    t: "requestsFarmer",
    id: 5,
    icon: <RuleOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Rule sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Ticket",
    t: "ticket",
    id: 6,
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "راهنما",
  //   t: "help",
  //   id: "help",
  //   icon: <PriorityHighOutlined style={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <PriorityHigh sx={{ width: "24px", height: "24px" }} />,
  // },
];
const expertItemsEn = [
  {
    text: "Dashboard",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Remote Sensing",
    t: "remotesensing",
    icon: <SatelliteAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SatelliteAlt sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Sensor Management",
    t: "sensormanagement",
    icon: <SettingsRemoteOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SettingsRemote sx={{ width: "24px", height: "24px" }} />,
  },
];

const expertItemsIEn = [
  {
    text: "Messenger",
    t: "messenger",
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Growth Stages",
    t: "growthstage",
    icon: <GrassOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Grass sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "شاخص هوشمند",
  //   t: "smartindexes",
  //   icon: <QueryStatsOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <QueryStats sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "کنترل تجهیزات",
  //   t: "actuator",
  //   icon: <ToggleOffOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <ToggleOff sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "Recommends",
    t: "recommends",
    iconOpenDrawer: (
      <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: <RecommendOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Recommend sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Ticket",
    t: "ticket",
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "گزارشات",
  //   t: "report",
  //   icon: <LeaderboardOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Leaderboard sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "Requests",
    t: "requests",
    icon: <PlaylistAddCheckOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PlaylistAdd sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Farms",
    t: "farmsList",
    icon: <AutoAwesomeMosaicOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <AutoAwesomeMosaic sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Alarms",
    t: "alarms",
    iconOpenDrawer: (
      <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: (
      <ReportProblem sx={{ width: "24px", height: "24px" }} />
    ),
    icon: <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ReportProblem sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Weather",
    t: "weather",
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Pricing",
    t: "pricing",
    icon: <PaidOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Paid sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Settings",
    t: "setting",
    icon: <SettingsOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Settings sx={{ width: "24px", height: "24px" }} />,
  },

  // {
  //   text: "کیف پول",
  //   t: "wallet",
  //   icon: <AccountBalanceOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <AccountBalance sx={{ width: "24px", height: "24px" }} />,
  // },
];

const adminItems = [
  {
    text: "مدیریت جامع",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "ساخت پلن",
    t: "plan",
    icon: <CreateOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Create sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "سطح دسترسی",
    t: "permission",
    icon: <SecurityOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Security sx={{ width: "24px", height: "24px" }} />,
  },
];
const AdminItems2 = [
  {
    text: "لیست زمین ها",
    t: "farmlist",
    icon: <ForestOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forest sx={{ width: "24px", height: "24px" }} />,
  },
];
const Sidebar = ({ isNonMobile }) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const mode = useSelector((state) => state.global.mode);
  const alarmCount = useSelector((state) => state.global.alarmCount);
  const [value, setValue] = React.useState(0);
  const [becomeHover, setBecomeHover] = useState();
  const [record, setRecord] = useState(false);
  const user = useSelector((state) => state.global.userType);
  const farmerListA = language === "Fa" ? farmerItems : farmerItemsEn;
  const expertListA = language === "Fa" ? expertItems : expertItemsEn;
  const farmerListB = language === "Fa" ? farmerItemsI : farmerItemsIEn;
  const expertListB = language === "Fa" ? expertItemsI : expertItemsIEn;
  const staffList = language === "Fa" ? staffItems : staffItemsEn;

  const listItems1 =
    user === "FARMER"
      ? farmerListA
      : user === "EXPERT"
      ? expertListA
      : user === "ADMIN"
      ? adminItems
      : [];
  const listItems2 =
    user === "FARMER"
      ? farmerListB
      : user === "EXPERT"
      ? expertListB
      : user === "ADMIN"
      ? AdminItems2
      : staffList;
  const listItemsEq =
    user === "FARMER"
      ? language === "Fa"
        ? farmerItemsEq
        : farmerItemsEqEn
      : [];
  const handleMouseOut = (e) => {
    setRecord(false);
    if (true) {
      const menu = document.getElementById("openD");

      const menuBoundary = {
        left: menu.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right: menu.offsetLeft + menu.offsetHeight,
        bottom: menu.offsetTop + menu.offsetHeight,
      };

      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }

    setIsSidebarOpen(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Box component="nav">
      <div>
        <Drawer
          open={true}
          onClose={() => setIsSidebarOpen(true)}
          variant="persistent"
          anchor={language === "Fa" ? "right" : "left"}
          id="openD"
          sx={{
            width: "80px",
            display: "flex",
            flexDirection: "column",

            "& .MuiDrawer-paper": {
              backgroundColor: "background.second",
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: isSidebarOpen ? "80px" : "230px",
              transition: "all ease-in-out 0.3s",
              boxShadow: !isSidebarOpen
                ? "0 0 30px rgba(0, 0, 0, 0.4)"
                : "none",
            },
          }}
          onMouseEnter={() => setIsSidebarOpen(false)}
          onMouseLeave={handleMouseOut}
        >
          <Box
            width="90%"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mr: language === "Fa" ? "0.5rem" : "auto",
              ml: language === "En" ? "0.5rem" : "auto",
              height: "100%",

              justifyContent: "flex-start",
              overflowX: "hidden",
              marginTop: "1rem",
            }}
          >
            <List>
              {/* LOGO */}
              <Link to="/management">
                <Box
                  sx={{
                    mr: isSidebarOpen ? 0 : "35%",
                    transition: "all ease 0.3s",
                  }}
                >
                  {mode === "dark" ? (
                    <img
                      alt="img1"
                      src={require("../../assets/LogoColor.png")}
                      style={{
                        width: "52px",
                        height: "40px",
                        objectFit: "cover",
                        marginBottom: "1rem",
                      }}
                    ></img>
                  ) : (
                    <img
                      alt="img1"
                      src={require("../../assets/LogoColor.png")}
                      style={{
                        width: "52px",
                        height: "40px",
                        objectFit: "cover",
                      }}
                    ></img>
                  )}
                </Box>
              </Link>

              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="none"
                sx={{
                  mt: "1rem",
                  direction: language === "Fa" ? "rtl" : "ltr",
                  height: "162px",
                  width: isSidebarOpen ? "60px" : "209px",
                  transition: "all ease-in-out 0.3s",
                  display: "flex",
                  p: "0 0.6rem",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  backgroundColor: "background.third",
                  borderRadius: "12px",
                  "&	.MuiTabs-scrollButtons": {
                    height: "25px",
                  },
                  "& .MuiTabs-indicator": {
                    width: 0,
                  },
                }}
              >
                {listItems1.map(({ text, icon, t, iconFilled }) => {
                  const lcText = t.toLocaleLowerCase();

                  return (
                    <Box
                      width={isSidebarOpen ? "40px" : "190px"}
                      height="40px"
                      key={t}
                      mt="0.3rem"
                      mb="0.5rem"
                      sx={(theme) => ({
                        transition: "all ease-in-out 0.3s",
                        ...(active === lcText
                          ? {
                              "& .MuiIconButton-root:hover": {
                                backgroundColor: `${theme.palette.primary.main} !important`,
                              },
                            }
                          : {}),
                      })}
                    >
                      <IconButton
                        sx={{
                          width: isSidebarOpen ? "40px" : "190px",
                          transition: "all ease-in-out 0.3s",

                          height: "40px",
                          backgroundColor:
                            active === lcText ? "primary.main" : "transparent",
                          color:
                            mode === "dark"
                              ? active === lcText
                                ? "white"
                                : "white"
                              : active === lcText
                              ? "white"
                              : "fontColor.alt",

                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          borderRadius: "12px",
                        }}
                        onMouseEnter={() => {
                          setBecomeHover(lcText);
                        }}
                        onMouseLeave={() => setBecomeHover("")}
                        onClick={() => {
                          navigate(`/${lcText}`);
                          setActive(lcText);
                          // setIsSidebarOpen(false);
                        }}
                      >
                        <FlexBetween gap="1rem">
                          {active === lcText
                            ? iconFilled
                            : becomeHover === lcText
                            ? iconFilled
                            : icon}
                          {isSidebarOpen ? null : (
                            <ListItemText primary={text} />
                          )}
                        </FlexBetween>
                      </IconButton>
                    </Box>
                  );
                })}
              </Tabs>
            </List>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                mt: "0.5rem",
                direction: language === "Fa" ? "rtl" : "ltr",

                height: "180px",
                width: isSidebarOpen ? "60px" : "209px",
                transition: "all ease-in-out 0.3s",
                p: "0 0.6rem",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                backgroundColor: "background.third",
                borderRadius: "12px",
                "&	.MuiTabs-scrollButtons": {
                  height: "25px",
                },
                "& .MuiTabs-indicator": {
                  width: 0,
                },
              }}
            >
              {listItems2.map(({ text, icon, t, iconFilled }) => {
                const lcText = t.toLocaleLowerCase();

                return (
                  <Box
                    width={isSidebarOpen ? "40px" : "190px"}
                    transition="all ease-in-out 0.3s"
                    height="40px"
                    key={t}
                    mb="0.3rem"
                    sx={(theme) => ({
                      transition: "all ease-in-out 0.3s",
                      ...(active === lcText
                        ? {
                            "& .MuiIconButton-root:hover": {
                              backgroundColor: `${theme.palette.primary.main} !important`,
                            },
                          }
                        : {}),
                    })}
                  >
                    <IconButton
                      sx={{
                        width: isSidebarOpen ? "40px" : "190px",
                        transition: "all ease-in-out 0.3s",

                        height: "40px",
                        backgroundColor:
                          active === lcText ? "primary.main" : "transparent",
                        color:
                          mode === "dark"
                            ? active === lcText
                              ? "white"
                              : "white"
                            : active === lcText
                            ? "white"
                            : "fontColor.alt",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        borderRadius: "12px",
                      }}
                      onMouseEnter={() => {
                        setBecomeHover(lcText);
                      }}
                      onMouseLeave={() => setBecomeHover("")}
                      onClick={() => {
                        if (
                          lcText !== "actuator" &&
                          lcText !== "smartindexes" &&
                          lcText !== "neighborhood" &&
                          lcText !== "wallet" &&
                          lcText !== "report" &&
                          lcText !== "tracking" &&
                          lcText !== "shopping" &&
                          lcText !== "shoppingg" &&
                          lcText !== "shoppinggg"
                        ) {
                          navigate(`/${lcText}`);
                          setActive(lcText);
                        }
                        // setIsSidebarOpen(false);
                      }}
                    >
                      <FlexBetween gap="1rem">
                        {active === lcText
                          ? iconFilled
                          : becomeHover === lcText
                          ? iconFilled
                          : icon}
                        {isSidebarOpen ? null : <ListItemText primary={text} />}
                      </FlexBetween>
                    </IconButton>
                  </Box>
                );
              })}
            </Tabs>
            {user === "FARMER" && (
              <Box
                mt="1rem"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography mr="0.2rem" variant="body" color={"fontColor.alt"}>
                  {t("features")}
                </Typography>
                <Tabs
                  orientation="vertical"
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    direction: language === "Fa" ? "rtl" : "ltr",

                    height: "142px",
                    width: isSidebarOpen ? "60px" : "209px",
                    transition: "all ease-in-out 0.3s",
                    p: "0 0.6rem",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    backgroundColor: "background.third",
                    borderRadius: "12px",
                    "&	.MuiTabs-scrollButtons": {
                      height: "25px",
                    },
                    "& .MuiTabs-indicator": {
                      width: 0,
                    },
                  }}
                >
                  {listItemsEq.map(({ text, icon, t, iconFilled }) => {
                    const lcText = t.toLocaleLowerCase();

                    return (
                      <Box
                        width={isSidebarOpen ? "40px" : "190px"}
                        height="40px"
                        key={t}
                        mb="0.3rem"
                        sx={(theme) => ({
                          transition: "all ease-in-out 0.3s",
                          ...(active === lcText
                            ? {
                                "& .MuiIconButton-root:hover": {
                                  backgroundColor: `${theme.palette.primary.main} !important`,
                                },
                              }
                            : {}),
                        })}
                      >
                        <IconButton
                          sx={{
                            width: isSidebarOpen ? "40px" : "190px",
                            transition: "all ease-in-out 0.3s",
                            height: "40px",
                            backgroundColor:
                              active === lcText
                                ? "primary.main"
                                : "transparent",
                            color:
                              mode === "dark"
                                ? active === lcText
                                  ? "white"
                                  : "white"
                                : active === lcText
                                ? "white"
                                : "fontColor.alt",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            borderRadius: "12px",
                          }}
                          onMouseEnter={() => {
                            setBecomeHover(lcText);
                          }}
                          onMouseLeave={() => setBecomeHover("")}
                          onClick={() => {
                            if (lcText === "education") {
                              openInNewTab(
                                "https://partotaprayan.viannacloud.ir/"
                              );
                            }
                            if (
                              lcText !== "drone" &&
                              lcText !== "education" &&
                              lcText !== "tracking" &&
                              // lcText !== "shopping" &&
                              lcText !== "shoppingg" &&
                              lcText !== "shoppinggg"
                            ) {
                              navigate(`/${lcText}`);
                              setActive(lcText);
                            }
                            // setIsSidebarOpen(false);
                          }}
                        >
                          <FlexBetween gap="1rem">
                            {active === lcText
                              ? iconFilled
                              : becomeHover === lcText
                              ? iconFilled
                              : icon}
                            {isSidebarOpen ? null : (
                              <ListItemText primary={text} />
                            )}
                          </FlexBetween>
                        </IconButton>
                      </Box>
                    );
                  })}
                </Tabs>
              </Box>
            )}
            <Box
              height="72px"
              sx={{
                width: isSidebarOpen ? "60px" : "100%",
                mt: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                transition: "all ease 0.3s",
                borderRadius: "12px",
                position: "relative",
              }}
            >
              {!isSidebarOpen ? (
                <>
                  <RecordButton
                    onClick={() => setRecord(!record)}
                    secondary={record}
                  >
                    <Add sx={{ fontSize: "30px" }} />

                    <Typography variant="body" textTransform="none">
                      {t("record")}
                    </Typography>
                  </RecordButton>
                  {record && user === "FARMER" && (
                    <Box
                      sx={{
                        width: "100%",
                        // height: "220px",
                        position: "absolute",
                        bottom: "100%",
                        right: 0,
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "stretch",
                      }}
                    >
                      {/* <Fade in={record} timeout={800}>
                        <RecordButton
                          onClick={() => navigate("/recordRecommend")}
                        >
                          <Typography variant="body">ثبت توصیه</Typography>
                        </RecordButton>
                      </Fade> */}
                      <Fade in={record} timeout={800}>
                        <RecordButton onClick={() => navigate("/recordUser")}>
                          <Typography variant="body">
                            {t("recordStaff")}
                          </Typography>
                        </RecordButton>
                      </Fade>
                      <Fade in={record} timeout={600}>
                        <RecordButton
                          onClick={() => navigate("/recordSensors")}
                        >
                          <Typography variant="body">
                            {t("recordSensor")}
                          </Typography>
                        </RecordButton>
                      </Fade>
                      <Fade in={record} timeout={400}>
                        <RecordButton onClick={() => navigate("/recordEvents")}>
                          <Typography variant="body">
                            {t("recordEvent")}
                          </Typography>
                        </RecordButton>
                      </Fade>
                      <Fade in={record} timeout={200}>
                        <RecordButton onClick={() => navigate("/recordFarm")}>
                          <Typography variant="body">
                            {t("recordFarm")}
                          </Typography>
                        </RecordButton>
                      </Fade>
                    </Box>
                  )}
                  {record && user !== "FARMER" && (
                    <Box
                      sx={{
                        width: "100%",
                        // height: "220px",
                        position: "absolute",
                        bottom: "100%",
                        right: 0,
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "stretch",
                      }}
                    >
                      <Fade in={record} timeout={500}>
                        <RecordButton
                          onClick={() => navigate("/recordRecommend")}
                        >
                          <Typography variant="body">ثبت توصیه</Typography>
                        </RecordButton>
                      </Fade>
                    </Box>
                  )}
                </>
              ) : (
                <Add sx={{ fontSize: "40px" }} />
              )}
            </Box>
          </Box>
        </Drawer>
      </div>
    </Box>
  );
};

export default Sidebar;
