import { Box, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { CustomButton } from "components/styledComponents/common";
import { useDispatch, useSelector } from "react-redux";
import { translateToPersian } from "Utils/Utils";
import { setShoppingServices } from "States/shopping";
import { useTranslation } from "react-i18next";

const ServiceItem = ({ data, setServiceType, details }) => {
  console.log("🚀 ~ ServiceItem ~ details:", details);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const mode = useSelector((state) => state.global.mode);
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (inView) {
      if (!show) {
        animation.start({
          scale: 1,
          transition: {
            type: "spring",
            duration: 2000,
            bounce: 0.7,
          },
        });

        setShow(true);
      }
    }
    if (!inView) {
      if (!show) {
        animation.start({
          scale: 0.1,
        });
      }
    }
  }, [inView]);

  const permissions = useSelector((state) => state.global.userPermissions);
  const permissionItem = () => {
    switch (details.type) {
      case "sensorManagement":
        return permissions?.SENSOR;
      case "staffManagement":
        return permissions?.STAFF;
      case "smartIndex":
        return permissions?.SMART_INDEX;
      case "plantGrowthStages":
        return permissions?.GROWTH_STAGE;
      case "recommendations":
        return permissions?.RECOMMEND;
      case "weather":
        return permissions?.WEATHER;
      case "oprators":
        return permissions?.OPERATOR;
      case "remoteSensing":
        return permissions?.REMOTE_SENSING;

      default:
        break;
    }
  };
  const lang = useSelector((state) => state.global.language);
  return (
    <Box ref={ref} width="19%" height="191px" sx={{ p: "16px", mb: "1rem" }}>
      <FlexBetween>
        <motion.div animate={animation}>
          {mode === "dark" ? data?.icon?.dark : data?.icon?.light}
        </motion.div>
        {permissionItem() && (
          <Box
            width="60px"
            height="23px"
            borderRadius="4px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: "others.third",
            }}
          >
            <Typography
              variant="smallestTextPrimary"
              color={"background.third"}
            >
              {t("active")}
            </Typography>
          </Box>
        )}
      </FlexBetween>
      <Typography variant="h4" color={"fontColor.main"} mt="17px">
        {translateToPersian(details.type, lang)}
      </Typography>
      <Typography variant="subHeading" color={"fontColor.alt"}>
        (
        {details.services
          .map(
            (service) =>
              `${service.timeValue} ${
                service.timeUnit === "MONTH"
                  ? t("monthly")
                  : service.timeUnit === "YEAR"
                  ? t("yearly")
                  : t("daily")
              }`
          )
          .join(", ")}
        )
      </Typography>
      <CustomButton
        onClick={() => {
          if (!permissionItem()) {
            setServiceType(details?.type);
            dispatch(setShoppingServices({ services: details }));
          }
        }}
        height="32px"
        sx={{ width: "100%", mt: "20px" }}
      >
        <Typography variant="subHeading">
          {permissionItem() ? t("hasService") : t("purchase")}
        </Typography>
      </CustomButton>
    </Box>
  );
};

export default ServiceItem;
