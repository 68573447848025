// store.js
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Reducers for your existing functionality (sensors, dashboard, etc.)
import sensorsReducer from "./sensors";
import dashboardReducer from "./dashboard";
import globalReducer from "./index";
import weatherReducer from "./weather";
import reportReducer from "./report";
import remoteSensing from "./remoteSensing";
import messenger from "./messenger";
import api from "api/api";
import shopping, { shoppingSlice } from "./shopping";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "global",
    "dashboard",
    "sensors",
    "weather",
    "shopping",
    "remoteSensing",
  ],
};

const notPersistedSensor = {
  key: "sensors",
  storage,
  blacklist: ["firstDate", "secondDate"],
};

const notPersistedGlobal = {
  key: "global",
  storage,
  blacklist: ["userType"],
};

const rootReducer = combineReducers({
  sensors: persistReducer(notPersistedSensor, sensorsReducer),
  dashboard: dashboardReducer,
  global: persistReducer(notPersistedGlobal, globalReducer),
  weather: weatherReducer,
  remoteSensing: remoteSensing,
  messenger: messenger,
  report: reportReducer,
  shopping: shopping,

  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export const persistor = persistStore(store);
