import React, { useEffect, useState } from "react";

import {
  LightModeOutlined,
  DarkModeOutlined,
  Search,
  SettingsOutlined,
  MessageOutlined,
  NotificationsNoneOutlined,
  EditOutlined,
  LogoutOutlined,
  RecommendOutlined,
  AccessTimeOutlined,
  UpdateOutlined,
  DisplaySettingsOutlined,
  PeopleOutlineOutlined,
  RoomPreferencesOutlined,
  ListOutlined,
  AddOutlined,
  DarkMode,
  LightMode,
  Settings,
  Notifications,
  CloudUploadOutlined,
  CloudUpload,
  PersonOutlineOutlined,
  PersonPinCircleOutlined,
  AccountCircleOutlined,
  Person2,
  AccountCircle,
  Person,
  ReportProblemOutlined,
  LanguageOutlined,
  Language,
  SystemUpdateOutlined,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlarmCount,
  setChangedLand,
  setFarmName,
  setLanguage,
  setLogin,
  setLogout,
  setMode,
  setPropertyId,
  setUserPermissions,
} from "States";
// import profileImage from "../../assets/StaffImg/9.jpg";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  useMediaQuery,
  Stack,
  Badge,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";

import { Link, useNavigate } from "react-router-dom";
import ArrowIcon from "../../assets/arrow.png";
import ShoppingCartDark from "../../assets/icon/shopping-cart-dark.svg";
import ShoppingCartLight from "../../assets/icon/shopping-cart-light.svg";
import { styled } from "@mui/material/styles";
import { store } from "States/rootReducer";
import { farmData } from "data/farms";
import NavbarPopover from "components/NavbarPopover/NavbarPopover";
import {
  CustomButton,
  CustomMenuItem,
} from "components/styledComponents/common";
import { useQuery } from "react-query";
import {
  activateLand,
  getLandActive,
  getNotifications,
  getPermissions,
  landData,
} from "Services/Http/http.service";
import { setCart, setShoppingCartOpen } from "States/shopping";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [alarm, setAlarm] = useState(false);
  const [download, setDownload] = useState(false);
  const [setting, setSetting] = useState(false);
  const [ld, setLD] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const language = useSelector((state) => state.global.language);
  // const { response: farms } = useFetchFarmData(getFarmData);
  // const farmData = farms?.data;

  const isNonMobile = useMediaQuery("(min-width:1000px");
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.global.mode);
  const [anchorEL, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const token = useSelector((state) => state.global.token);
  console.log("🚀 ~ file: Navbar.jsx:81 ~ Navbar ~ token:", token);
  const alarmCount = useSelector((state) => state.global.alarmCount);
  const [profile, setProfile] = useState(false);
  const emailUser = useSelector((state) => state.global.email);
  const fullNameUser = useSelector((state) => state.global.fullName);
  const profileImageUser = useSelector((state) => state.global.profileImage);
  console.log(
    "🚀 ~ file: Navbar.jsx:98 ~ Navbar ~ profileImageUser:",
    profileImageUser
  );

  useEffect(() => {
    setAnchorEl(null);
    setAnchorElAlarm(null);
  }, [alarmCount, token]);
  const handleClose = () => {
    setAnchorEl(null);
    // googleLogout();
  };

  const handleSignOut = () => {
    dispatch(setLogout());

    console.log("login", token);
    navigate("/login");
  };
  ///////////////////////
  const [anchorElAlarm, setAnchorElAlarm] = React.useState(null);
  const isOpenAlarm = Boolean(anchorElAlarm);
  const handleClickAlarm = (event) => {
    setAnchorElAlarm(event.currentTarget);
  };
  const handleCloseAlarm = () => {
    setAnchorElAlarm(null);
    // navigate("/alarms");
  };

  /////////////////////////

  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const isOpenUpdate = Boolean(anchorElUpdate);
  const handleClickUpdate = (event) => {
    setAnchorElUpdate(event.currentTarget);
  };
  const handleCloseUpdate = () => {
    setAnchorElUpdate(null);
  };
  ////////////////////////////////////

  const [anchorElSetting, setAnchorElSetting] = React.useState(null);
  const isOpenSetting = Boolean(anchorElSetting);
  const handleClickSetting = (event) => {
    setAnchorElSetting(event.currentTarget);
  };
  const handleCloseSetting = () => {
    setAnchorElSetting(null);
  };
  ///////////////////////////////////////
  const [anchorElDrop, setAnchorElDrop] = React.useState(null);
  // const [farmName, setFarmName] = useState();
  const isOpenDrop = Boolean(anchorElDrop);
  const handleClickDrop = (event) => {
    setAnchorElDrop(event.currentTarget);
  };
  const [landName, setLandName] = useState("");

  const changedLand = useSelector((state) => state.global.changedLand);

  const setFarm = async (id, title) => {
    const res = await activateLand(token, id);
    setLandName(title);

    setAnchorElDrop(null);
    dispatch(
      setChangedLand({
        changedLand: !changedLand,
      })
    );
    window.location.reload();
  };

  const landsQuery = useQuery(["landsQuery", token], () => {
    return landData(token);
  });

  const fillteredFarm =
    landsQuery.isSuccess && landsQuery.data?.data.length > 0
      ? landsQuery.data?.data.filter((item) =>
          searchValue !== "" ? item.land.name.includes(searchValue) : item
        )
      : null;
  const proId = useSelector((state) => state.global.propertyId);
  const fName = useSelector((state) => state.global.farmName);
  const selectedFarm = landsQuery.data?.data?.find(
    (item) => item.land.id === proId
  );

  const landActiveQuery = useQuery(["landActiveQuery", token], () => {
    return getLandActive(token);
  });

  const notificationQuery = useQuery(["notificationQuery", token], () => {
    return getNotifications(token);
  });
  console.log("🚀 ~ notificationQuery ~ notificationQuery:", notificationQuery);
  const {
    isLoading: notificationLoading,
    isIdle: notificationIdle,
    isSuccess: notificationSuccess,
    data: notificationData,
  } = notificationQuery;

  const shoppingIcon = mode === "dark" ? ShoppingCartDark : ShoppingCartLight;

  const shoppingCartOpen = useSelector(
    (state) => state.global.shoppingCartOpen
  );
  const carts = useSelector((state) => state.shopping.cart);

  useEffect(() => {}, [landName]);
  const handleChangeFarm = async (item) => {
    setFarm(item.land.id, item.land.name);
    const permissionsResult = await getPermissions(token);
    console.log(
      "🚀 ~ handleChangeFarm ~ permissionsResult:",
      permissionsResult
    );
    dispatch(
      setUserPermissions({
        userPermissions: permissionsResult.data.groupPermissions,
      })
    );
    dispatch(
      setCart({
        cart: [],
      })
    );
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <AppBar
      sx={{
        position: "static",
        backgroundColor: "background.second",
        backgroundImage: "none",
        boxShadow: "none",
      }}
    >
      <Box width="100%">
        <Toolbar
          sx={{
            justifyContent: "space-between",
            paddingRight: "16px !important",
            paddingLeft: "8px !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h3"
              ml={language === "Fa" ? 1 : 0}
              mr={language === "En" ? 1 : 0}
              display={isNonMobile ? undefined : "none"}
              color={"fontColor.main"}
            >
              {t("titleCorp")}
            </Typography>
            <div
              style={{
                cursor: "pointer",
                paddingTop: "12px",
                paddingBottom: "12px",
              }}
              onClick={handleClickDrop}
            >
              <Typography
                variant="body"
                color={"fontColor.alt"}
                display={isNonMobile ? undefined : "none"}
              >
                <Typography variant="body" color={"fontColor.alt"}>
                  {landActiveQuery.isSuccess && landActiveQuery.data?.data
                    ? landActiveQuery.data?.data.land.name
                    : ""}
                </Typography>
              </Typography>
              <IconButton>
                <Box
                  component={"img"}
                  src={ArrowIcon}
                  sx={{
                    width: "14px",
                    height: "10px",
                    rotate: isOpenDrop ? "0deg" : "180deg",
                    transition: "rotate 0.3s",
                  }}
                />
              </IconButton>
              <NavbarPopover
                anchorEl={anchorElDrop}
                setAnchorEl={setAnchorElDrop}
              >
                <Box
                  sx={{
                    width: "246px",
                    height: "284px",
                    p: "0.25rem 0.75rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <FlexBetween>
                    <FlexBetween
                      sx={{
                        transition: "all 0.2s ease",
                        width: "70%",

                        alignItems: "center",
                        border: `1px solid`,
                        borderColor: "fontColor.alt",
                        // border: "1px solid black",
                        borderRadius: "8px",
                        mt: "0.25rem",
                      }}
                    >
                      <InputBase
                        disableUnderline
                        placeholder={t("search")}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        id="inputSearchRemote"
                        sx={(theme) => ({
                          paddingRight: "5px",
                          backgroundColor: "inherit",
                          "& .MuiInputBase-input": {
                            "::placeholder": {
                              color: `${theme.palette.fontColor.main} !important`,
                            },
                          },
                          height: "27px",
                          width: "80px",
                          borderRadius: "8px",
                          color: "fontColor.alt",
                          outline: "none",
                          borderBottom: "none",
                          ml: "0.5rem",
                        })}
                      />
                      <Search sx={{ ml: "0.2rem" }} />
                    </FlexBetween>
                    <ListOutlined />
                  </FlexBetween>
                  <Box
                    sx={(theme) => ({
                      width: "100%",
                      height: "70%",
                      overflowY: "auto",
                      mt: "1rem",
                      "& ::-webkit-scrollbar": {
                        width: "5px",
                        height: "5px",
                      },
                      "& ::-webkit-scrollbar-track": {
                        background: "transparent",
                        borderRadius: "5px",
                      },
                      "& ::-webkit-scrollbar-thumb": {
                        background: theme.palette.others.lineChart,
                        borderRadius: "5px",
                      },
                    })}
                  >
                    {fillteredFarm?.map((item, index) => (
                      <FlexBetween
                        sx={{
                          justifyContent: "flex-start",
                          gap: "0.5rem",
                          mb: "0.5rem",
                          cursor: "pointer",
                          p: "0.25rem 0.5rem",
                          borderRadius: "8px",
                          "&:hover": {
                            backgroundColor: "background.second",
                          },
                        }}
                        onClick={() => handleChangeFarm(item)}
                      >
                        {/* <Box width="16px" height="16px">
                          <svg viewBox="0 0 200 200">
                            <StyledPolygon points={points} />
                          </svg>
                        </Box> */}

                        {/* <Box
                          component="img"
                          src={`https://api.rahbaan.ir/public/land-images/${item.defaultImg}`}
                          sx={{
                            width: "16px",
                            height: "16px",
                            objectFit: "cover",
                            // borderRadius: "8px",
                            //   ml: "0.5rem",
                            //   mr: open ? "0px" : "-0.35rem",
                          }}
                        /> */}
                        {/* <img
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                          src={`https://api.rahbaan.ir/public/land-images/${item.defaultImg}`}
                          alt=""
                        /> */}
                        <Typography
                          variant={"body"}
                          color={"fontColor.main"}
                        ></Typography>
                        <Typography
                          variant={"smallestTextSecondary"}
                          color={"fontColor.alt"}
                        >
                          {item.land.name}
                        </Typography>
                      </FlexBetween>
                    ))}
                  </Box>
                  <CustomButton
                    onClick={() => navigate("/recordFarm")}
                    height="27px"
                    sx={{
                      flexDirection: language === "Fa" ? "row" : "row-reverse",
                    }}
                  >
                    <AddOutlined sx={{ width: "30px", height: "30px" }} />
                    <Typography variant={"smallestTextPrimary"}>
                      {t("newFarm")}
                    </Typography>
                  </CustomButton>
                </Box>
              </NavbarPopover>
            </div>
          </Box>
          {/* // */}
          <Box sx={{ display: "flex" }}>
            <IconButton
              onClick={() => {
                if (language === "Fa") {
                  changeLanguage("En");
                  dispatch(setLanguage({ language: "En" }));
                } else {
                  changeLanguage("Fa");
                  dispatch(setLanguage({ language: "Fa" }));
                }
              }}
            >
              {language === "Fa" ? (
                <Typography variant="h3">Fa</Typography>
              ) : (
                <Typography variant="h3">En</Typography>
              )}
            </IconButton>
            <IconButton
              onClick={() =>
                dispatch(setShoppingCartOpen({ shoppingCartOpen: true }))
              }
            >
              <Badge
                overlap="circular"
                badgeContent={carts.length}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                invisible={false}
                sx={{
                  "& 	.MuiBadge-badge": {
                    backgroundColor: "others.second",
                    minWidth: "16px",
                    width: "16px",
                    height: "16px",
                    color: "white",
                  },
                }}
              >
                <Box component="img" width="24px" src={shoppingIcon} />
              </Badge>
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(setMode());
              }}
              onMouseEnter={() => setLD(true)}
              onMouseLeave={() => setLD(false)}
              sx={{
                "&:hover": {
                  bgcolor: "others.first",
                  color: "#F7F7F7",
                },
                width: "50px",
              }}
            >
              {ld ? (
                mode === "dark" ? (
                  <DarkMode sx={{ fontSize: "25px" }} />
                ) : (
                  <LightMode sx={{ fontSize: "25px" }} />
                )
              ) : mode === "dark" ? (
                <DarkModeOutlined sx={{ fontSize: "25px" }} />
              ) : (
                <LightModeOutlined sx={{ fontSize: "25px" }} />
              )}
            </IconButton>
            {/* <IconButton
              onClick={handleClickSetting}
              sx={{
                "&:hover": {
                  bgcolor: "others.first",
                  color: "#F7F7F7",
                },
                width: "50px",
              }}
              onMouseEnter={() => setSetting(true)}
              onMouseLeave={() => setSetting(false)}
            >
              {setting ? (
                <Settings sx={{ fontSize: "25px" }} />
              ) : (
                <SettingsOutlined sx={{ fontSize: "25px" }} />
              )}
            </IconButton>
            <NavbarPopover
              anchorEl={anchorElSetting}
              setAnchorEl={setAnchorElSetting}
            >
              <Box display="flex" flexDirection="column" p="10px 8px">
                <Link
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "unset",
                  }}
                  to="/setting"
                >
                  <CustomMenuItem>
                    <DisplaySettingsOutlined
                      sx={{ width: "24px", height: "24px" }}
                    />
                    <Typography variant="body">تنظیمات سامانه</Typography>
                  </CustomMenuItem>
                </Link>
                <Link
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "unset",
                  }}
                  to="/setting"
                >
                  <CustomMenuItem>
                    <PeopleOutlineOutlined
                      sx={{ width: "24px", height: "24px" }}
                    />
                    <Typography variant="body">تنظیمات کاربران</Typography>
                  </CustomMenuItem>
                </Link>
                <Link
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "unset",
                  }}
                  to="/setting"
                >
                  <CustomMenuItem>
                    <RoomPreferencesOutlined
                      sx={{ width: "24px", height: "24px" }}
                    />
                    <Typography variant="body">تنظیمات تجهیزات</Typography>
                  </CustomMenuItem>
                </Link>
              </Box>
            </NavbarPopover> */}
            {/* <IconButton
              onClick={handleClickUpdate}
              sx={{
                "&:hover": {
                  bgcolor: "others.first",
                  color: "#F7F7F7",
                },
                width: "50px",
              }}
              onMouseEnter={() => setDownload(true)}
              onMouseLeave={() => setDownload(false)}
            >
              {download ? (
                <CloudUpload sx={{ fontSize: "25px" }} />
              ) : (
                <CloudUploadOutlined sx={{ fontSize: "25px" }} />
              )}
            </IconButton>
            <NavbarPopover
              anchorEl={anchorElUpdate}
              setAnchorEl={setAnchorElUpdate}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="stretch"
                p="1rem"
                gap="1rem"
              >
                <Stack>
                  <Typography variant="body">بروز رسانی سنسورها</Typography>
                  <FlexBetween>
                    <AccessTimeOutlined
                      sx={{
                        width: "13px",
                        height: "13px",
                        color: "fontColor.alt",
                      }}
                    />
                    <Typography
                      variant="smallestTextPrimary"
                      color="fontColor.alt"
                    >
                      سه شنبه 1401/10/06 - 20:08
                    </Typography>
                  </FlexBetween>
                </Stack>
                <CustomButton height="27px">
                  <UpdateOutlined
                    sx={{ width: "13px", height: "13px", ml: "0.25rem" }}
                  />
                  <Typography variant="smallestTextPrimary">
                    شروع بروز رسانی
                  </Typography>
                </CustomButton>
              </Box>
            </NavbarPopover> */}
            <IconButton
              onClick={handleClickAlarm}
              sx={{
                "&:hover": {
                  bgcolor: "others.first",
                  color: "#F7F7F7",
                },
                width: "50px",
              }}
              onMouseEnter={() => setAlarm(true)}
              onMouseLeave={() => setAlarm(false)}
            >
              <Badge
                overlap="circular"
                badgeContent={
                  notificationSuccess ? notificationData?.data?.unseenAlarms : 0
                }
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                invisible={false}
                sx={{
                  "& 	.MuiBadge-badge": {
                    backgroundColor: "others.second",
                    minWidth: "16px",
                    width: "16px",
                    height: "16px",
                    color: "white",
                  },
                }}
              >
                {alarm ? (
                  <Notifications sx={{ fontSize: "25px" }} />
                ) : (
                  <NotificationsNoneOutlined sx={{ fontSize: "25px" }} />
                )}
              </Badge>
            </IconButton>

            <NavbarPopover
              anchorEl={anchorElAlarm}
              setAnchorEl={setAnchorElAlarm}
            >
              <Box display="flex" flexDirection="column" p="10px 8px">
                <Link
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "unset",
                  }}
                  to="/alarms"
                >
                  <CustomMenuItem>
                    <FlexBetween width="100%" gap="8px">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <ReportProblemOutlined
                          sx={{ width: "24px", height: "24px" }}
                        />
                        <Typography variant="body">هشدار ها</Typography>
                      </Box>
                      <Box
                        minWidth="20px"
                        height="20px"
                        sx={{
                          backgroundColor: "others.second",
                          borderRadius: "6px",
                          position: "relative",
                          color: "#F7F7F7",
                          "& span": {
                            position: "absolute",
                            right: "50%",
                            top: "50%",
                            transform: "translate(48%,-45%)",
                          },
                        }}
                      >
                        <span>{notificationData?.data?.unseenAlarms}</span>
                      </Box>
                    </FlexBetween>
                  </CustomMenuItem>
                </Link>

                {/* <CustomMenuItem>
                  <FlexBetween width="100%" gap="8px">
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      <MessageOutlined sx={{ width: "24px", height: "24px" }} />
                      <Typography variant="body">پیام ها</Typography>
                    </Box>
                    <Box
                      width="20px"
                      height="20px"
                      sx={{
                        backgroundColor: "others.second",
                        borderRadius: "6px",
                        position: "relative",
                        color: "#F7F7F7",
                        "& span": {
                          position: "absolute",
                          right: "50%",
                          top: "50%",
                          transform: "translate(48%,-45%)",
                        },
                      }}
                    >
                      <span>4</span>
                    </Box>
                  </FlexBetween>
                </CustomMenuItem>
                <CustomMenuItem>
                  <FlexBetween width="100%" gap="8px">
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      <RecommendOutlined
                        sx={{ width: "24px", height: "24px" }}
                      />
                      <Typography variant="body">توصیه ها</Typography>
                    </Box>
                    <Box
                      width="20px"
                      height="20px"
                      sx={{
                        backgroundColor: "others.second",
                        borderRadius: "6px",
                        position: "relative",
                        color: "#F7F7F7",
                        "& span": {
                          position: "absolute",
                          right: "50%",
                          top: "50%",
                          transform: "translate(48%,-45%)",
                        },
                      }}
                    >
                      <span>2</span>
                    </Box>
                  </FlexBetween>
                </CustomMenuItem> */}
              </Box>
            </NavbarPopover>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                onClick={handleClick}
                onMouseEnter={() => setProfile(true)}
                onMouseLeave={() => setProfile(false)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "1rem",
                  width: "50px",
                  "&:hover": {
                    bgcolor: "others.first",
                    color: "#F7F7F7",
                  },
                }}
              >
                {profileImageUser ? (
                  <Box
                    component="img"
                    alt="profile"
                    src={`https://api.rahbaan.ir/public/profile-images/${profileImageUser}`}
                    height="32px"
                    width="32px"
                    borderRadius="99px"
                    sx={{ objectFit: "cover" }}
                  />
                ) : (
                  !profile && (
                    <PersonOutlineOutlined
                      sx={{ width: "32px", height: "32px" }}
                    />
                  )
                )}
                {profile && !profileImageUser && (
                  <Person sx={{ width: "32px", height: "32px" }} />
                )}
              </IconButton>

              {/* <IconButton
                onClick={handleClickSetting}
                sx={{
                  "&:hover": {
                    bgcolor: "others.first",
                    color: "#F7F7F7",
                  },
                  width: "50px",
                }}
                onMouseEnter={() => setSetting(true)}
                onMouseLeave={() => setSetting(false)}
              >
                {setting ? (
                  <Settings sx={{ fontSize: "25px" }} />
                ) : (
                  <SettingsOutlined sx={{ fontSize: "25px" }} />
                )}
              </IconButton> */}
              <NavbarPopover anchorEl={anchorEL} setAnchorEl={setAnchorEl}>
                <Box p="10px 8px">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    p="10px 20px"
                    mb="15px"
                  >
                    {profileImageUser ? (
                      <Box
                        component="img"
                        src={
                          profileImageUser
                            ? `https://api.rahbaan.ir/public/profile-images/${profileImageUser}`
                            : null
                        }
                        width="48px"
                        height="48px"
                        borderRadius="50%"
                        mb="0.2rem"
                        sx={{ objectFit: "cover" }}
                      />
                    ) : (
                      <AccountCircleOutlined
                        sx={{ width: "32px", height: "32px" }}
                      />
                    )}
                    <Typography variant="subHeading" color={"fontColor.main"}>
                      {fullNameUser}
                    </Typography>
                    <Typography
                      variant="smallestTextSecondary"
                      color={"fontColor.alt"}
                    >
                      {emailUser}
                    </Typography>
                  </Box>

                  <CustomMenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      gap: "1rem",
                    }}
                    onClick={() => navigate("/setting")}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      <EditOutlined
                        sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                      />
                      <Typography>ویرایش پروفایل</Typography>
                    </Box>
                  </CustomMenuItem>

                  <CustomMenuItem
                    display="flex"
                    sx={{ width: "100%", gap: "1rem" }}
                    onClick={() => {
                      dispatch(setLogout());
                      navigate("/login");
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      <LogoutOutlined
                        sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                      />
                      <Typography>خروج</Typography>
                    </Box>
                  </CustomMenuItem>
                </Box>
              </NavbarPopover>
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Navbar;
