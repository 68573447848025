import { ClickAwayListener, Fade, Paper, Popover, Popper } from "@mui/material";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

const NavbarPopover = ({ anchorEl, setAnchorEl, children }) => {
  const open = Boolean(anchorEl);
  const mode = useSelector((state) => state.global.mode);
  const [arrowRef, setArrowRef] = useState(null);
  const handleClose = () => setAnchorEl(null);
  return (
    <Popper
      sx={{
        '&[data-popper-placement*="bottom"] $arrow': {
          top: 0,
          left: 0,
          marginTop: "-0.71rem",
          marginLeft: 4,
          marginRight: 4,
          "&::before": {
            transformOrigin: "0 100%",
          },
        },
        '&[data-popper-placement*="top"] $arrow': {
          bottom: 0,
          left: 0,
          marginBottom: "-0.71rem",
          marginLeft: 4,
          marginRight: 4,
          "&::before": {
            transformOrigin: "100% 0",
          },
        },
        '&[data-popper-placement*="right"] $arrow': {
          left: 0,
          marginLeft: "-0.71rem",
          height: "1rem",
          width: "0.71r em",
          marginTop: 4,
          marginBottom: 4,
          "&::before": {
            transformOrigin: "100% 100%",
          },
        },
        '&[data-popper-placement*="left"] $arrow': {
          right: 0,
          marginRight: "-0.71rem",
          height: "1rem",
          width: "0.71rem",
          marginTop: 4,
          marginBottom: 4,
          "&::before": {
            transformOrigin: "0 0",
          },
        },
        "& .arrow": {
          position: "absolute",
          width: "1rem",
          height:
            "0.71rem" /* = width / sqrt(2) = (length of the hypotenuse) */,
          boxSizing: "border-box",
          color: "popper.backgroundColor",
          top: "-4px",
          "&::before": {
            content: '""',
            margin: "auto",
            display: "block",
            width: "100%",
            height: "100%",
            backgroundColor: "currentcolor",
            transform: "rotate(45deg)",
          },
        },
        "& .MuiPaper-root": (theme) => ({
          ...theme.palette.popper,
          borderRadius: "8px",
          backgroundImage: "none",
        }),
        zIndex: 1002,
      }}
      open={open}
      onClick={handleClose}
      anchorEl={anchorEl}
      onClose={handleClose}
      transition
      placement="bottom-start"
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={handleClose}>
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <span className={"arrow"} ref={setArrowRef} />
              {children}
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

export default NavbarPopover;
