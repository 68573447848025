import {
  Badge,
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AccountBalance,
  AccountBalanceOutlined,
  AccountBalanceWallet,
  AccountBalanceWalletOutlined,
  AirplaneTicket,
  AirplaneTicketOutlined,
  AutoAwesomeMosaic,
  AutoAwesomeMosaicOutlined,
  Calculate,
  CalculateOutlined,
  Cloud,
  CloudOutlined,
  Create,
  CreateOutlined,
  Dashboard,
  DashboardOutlined,
  Forum,
  ForumOutlined,
  Grass,
  GrassOutlined,
  HeadsetMic,
  HeadsetMicOutlined,
  Inventory2,
  Inventory2Outlined,
  KeyboardArrowDownOutlined,
  Leaderboard,
  LeaderboardOutlined,
  ManageAccounts,
  ManageAccountsOutlined,
  Message,
  MessageOutlined,
  MonetizationOn,
  MonetizationOnOutlined,
  Paid,
  PaidOutlined,
  PeopleAlt,
  PeopleAltOutlined,
  PlaylistAdd,
  PlaylistAddCheckOutlined,
  PriorityHigh,
  PriorityHighOutlined,
  QueryStats,
  QueryStatsOutlined,
  Recommend,
  RecommendOutlined,
  ReportProblem,
  ReportProblemOutlined,
  Rule,
  RuleOutlined,
  SatelliteAlt,
  SatelliteAltOutlined,
  School,
  SchoolOutlined,
  Science,
  ScienceOutlined,
  Security,
  SecurityOutlined,
  Settings,
  SettingsOutlined,
  SettingsRemote,
  SettingsRemoteOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
  ToggleOff,
  ToggleOffOutlined,
} from "@mui/icons-material";
import {
  setChangedLand,
  setFarmName,
  setFarmNameMob,
  setLogin,
  setPropertyId,
  setSelectedItemMob,
  setUserPermissions,
} from "States";
import { store } from "States/rootReducer";
import axios from "axios";
import { setProperty } from "States/dashboard";
import { useFetchFarmData } from "hooks/useFetchData";
import { getFarmData } from "api/remoteSensing";
import { farmData } from "data/farms";
import { Accordion } from "components/styledComponents/common";
import FlexBetween from "components/FlexBetween/FlexBetween";
import EosDrone from "assets/eos-icons_drone.svg";
import EosDrone1 from "assets/eos-icons_drone1.png";

import {
  activateLand,
  getLandActive,
  getPermissions,
  landData,
} from "Services/Http/http.service";
import { useQuery } from "react-query";
import { setCart } from "States/shopping";

const farmerItems = [
  {
    text: "مدیریت جامع",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "سنجش از دور",
    t: "remotesensing",
    icon: <SatelliteAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SatelliteAlt sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مدیریت سنسور ها",
    t: "sensormanagement",
    icon: <SettingsRemoteOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SettingsRemote sx={{ width: "24px", height: "24px" }} />,
  },
];
const farmerItemsI = [
  {
    text: "هشدارها",
    t: "alarms",
    iconOpenDrawer: (
      <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: (
      <ReportProblem sx={{ width: "24px", height: "24px" }} />
    ),
    icon: <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ReportProblem sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مدیریت پرسنل",
    t: "staffmgmt",
    icon: <PeopleAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PeopleAlt sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "کنترل تجهیزات",
  //   t: "actuator",
  //   icon: <ToggleOffOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <ToggleOff sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "توصیه ها",
    t: "recommends",
    iconOpenDrawer: (
      <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: <RecommendOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Recommend sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "شاخص هوشمند",
  //   t: "smartindexes",
  //   icon: <QueryStatsOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <QueryStats sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "هواشناسی",
    t: "weather",
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مرحله رشد",
    t: "growthstage",
    icon: <GrassOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Grass sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "پیام رسان",
    t: "messenger",
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "کارشناس ها",
    t: "experts",
    icon: <ManageAccountsOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ManageAccounts sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "همسایگی",
  //   t: "neighborhood",
  //   icon: <AutoAwesomeMosaicOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <AutoAwesomeMosaic sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "کیف پول",
  //   t: "wallet",
  //   icon: (
  //     <AccountBalanceWalletOutlined sx={{ width: "24px", height: "24px" }} />
  //   ),
  //   iconFilled: <AccountBalanceWallet sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "گزارشات",
  //   t: "report",
  //   icon: <LeaderboardOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Leaderboard sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "تیکت",
    t: "ticket",
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "تنظیمات",
    t: "setting",
    icon: <SettingsOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Settings sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "راهنما",
    t: "help",
    icon: <PriorityHighOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <PriorityHigh sx={{ width: "24px", height: "24px" }} />,
  },
];
const farmerItemsEq = [
  {
    text: "آموزش",
    t: "education",
    icon: <SchoolOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <School sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "ردیابی و شناسایی",
  //   t: "tracking",
  //   icon: <MonetizationOnOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <MonetizationOn sx={{ width: "24px", height: "24px" }} />,
  // },

  {
    text: "بازارگاه",
    t: "shopping",
    icon: <ShoppingCartOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ShoppingCart sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "حسابداری",
  //   t: "shoppingg",
  //   icon: <CalculateOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Calculate sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "پهپاد",
  //   t: "drone",

  //   icon: (
  //     <Box
  //       component={"img"}
  //       src={EosDrone}
  //       sx={{ width: "24px", height: "24px" }}
  //     />
  //   ),
  //   iconFilled: (
  //     <Box
  //       component={"img"}
  //       src={EosDrone1}
  //       sx={{ width: "24px", height: "24px" }}
  //     />
  //   ),
  // },
  // {
  //   text: "انبارداری",
  //   t: "shoppinggg",
  //   icon: <Inventory2Outlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Inventory2 sx={{ width: "24px", height: "24px" }} />,
  // },
];
const staffItems = [
  {
    text: "وظایف",
    t: "",
    id: 1,
    icon: <PeopleAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PeopleAlt sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "پیام رسان",
    t: "messenger",
    id: 4,
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "هواشناسی",
    t: "weather",
    id: 5,
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "درخواست ها",
    t: "requestsFarmer",
    id: 5,
    icon: <RuleOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Rule sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "تیکت",
    t: "ticket",
    id: 6,
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "راهنما",
  //   t: "help",
  //   id: "help",
  //   icon: <PriorityHighOutlined style={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <PriorityHigh sx={{ width: "24px", height: "24px" }} />,
  // },
];
const expertItems = [
  {
    text: "مدیریت جامع",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "سنجش از دور",
    t: "remotesensing",
    icon: <SatelliteAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SatelliteAlt sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مدیریت سنسور ها",
    t: "sensormanagement",
    icon: <SettingsRemoteOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SettingsRemote sx={{ width: "24px", height: "24px" }} />,
  },
];

const expertItemsI = [
  {
    text: "پیام رسان",
    t: "messenger",
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مرحله رشد",
    t: "growthstage",
    icon: <GrassOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Grass sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "شاخص هوشمند",
  //   t: "smartindexes",
  //   icon: <QueryStatsOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <QueryStats sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "کنترل تجهیزات",
  //   t: "actuator",
  //   icon: <ToggleOffOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <ToggleOff sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "توصیه ها",
    t: "recommends",
    iconOpenDrawer: (
      <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: <RecommendOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Recommend sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "تیکت",
    t: "ticket",
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "گزارشات",
  //   t: "report",
  //   icon: <LeaderboardOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Leaderboard sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "درخواست ها",
    t: "requests",
    icon: <PlaylistAddCheckOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PlaylistAdd sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "زمین ها",
    t: "farmsList",
    icon: <AutoAwesomeMosaicOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <AutoAwesomeMosaic sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "هشدارها",
    t: "alarms",
    iconOpenDrawer: (
      <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: (
      <ReportProblem sx={{ width: "24px", height: "24px" }} />
    ),
    icon: <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ReportProblem sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "هواشناسی",
    t: "weather",
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "قیمت گذاری",
    t: "pricing",
    icon: <PaidOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Paid sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "تنظیمات",
    t: "setting",
    icon: <SettingsOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Settings sx={{ width: "24px", height: "24px" }} />,
  },

  // {
  //   text: "کیف پول",
  //   t: "wallet",
  //   icon: <AccountBalanceOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <AccountBalance sx={{ width: "24px", height: "24px" }} />,
  // },
];

const farmerItemsEn = [
  {
    text: "Dashboard",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "Remote Sensing",
    t: "remotesensing",
    icon: <SatelliteAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SatelliteAlt sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Sensor Management",
    t: "sensormanagement",
    icon: <SettingsRemoteOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SettingsRemote sx={{ width: "24px", height: "24px" }} />,
  },
];
const farmerItemsIEn = [
  {
    text: "Alarms",
    t: "alarms",
    iconOpenDrawer: (
      <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: (
      <ReportProblem sx={{ width: "24px", height: "24px" }} />
    ),
    icon: <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ReportProblem sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Staff Management",
    t: "staffmgmt",
    icon: <PeopleAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PeopleAlt sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "کنترل تجهیزات",
  //   t: "actuator",
  //   icon: <ToggleOffOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <ToggleOff sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "Recommends",
    t: "recommends",
    iconOpenDrawer: (
      <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: <RecommendOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Recommend sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "شاخص هوشمند",
  //   t: "smartindexes",
  //   icon: <QueryStatsOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <QueryStats sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "Weather",
    t: "weather",
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Growth Stages",
    t: "growthstage",
    icon: <GrassOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Grass sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Messenger",
    t: "messenger",
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Experts",
    t: "experts",
    icon: <ManageAccountsOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ManageAccounts sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "همسایگی",
  //   t: "neighborhood",
  //   icon: <AutoAwesomeMosaicOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <AutoAwesomeMosaic sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "کیف پول",
  //   t: "wallet",
  //   icon: (
  //     <AccountBalanceWalletOutlined sx={{ width: "24px", height: "24px" }} />
  //   ),
  //   iconFilled: <AccountBalanceWallet sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "گزارشات",
  //   t: "report",
  //   icon: <LeaderboardOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Leaderboard sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "Ticket",
    t: "ticket",
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Settings",
    t: "setting",
    icon: <SettingsOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Settings sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Help",
    t: "help",
    icon: <PriorityHighOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <PriorityHigh sx={{ width: "24px", height: "24px" }} />,
  },
];
const farmerItemsEqEn = [
  {
    text: "Education",
    t: "education",
    icon: <SchoolOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <School sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "ردیابی و شناسایی",
  //   t: "tracking",
  //   icon: <MonetizationOnOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <MonetizationOn sx={{ width: "24px", height: "24px" }} />,
  // },

  {
    text: "Shopping",
    t: "shopping",
    icon: <ShoppingCartOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ShoppingCart sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "حسابداری",
  //   t: "shoppingg",
  //   icon: <CalculateOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Calculate sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "پهپاد",
  //   t: "drone",

  //   icon: (
  //     <Box
  //       component={"img"}
  //       src={EosDrone}
  //       sx={{ width: "24px", height: "24px" }}
  //     />
  //   ),
  //   iconFilled: (
  //     <Box
  //       component={"img"}
  //       src={EosDrone1}
  //       sx={{ width: "24px", height: "24px" }}
  //     />
  //   ),
  // },
  // {
  //   text: "انبارداری",
  //   t: "shoppinggg",
  //   icon: <Inventory2Outlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Inventory2 sx={{ width: "24px", height: "24px" }} />,
  // },
];
const staffItemsEn = [
  {
    text: "Tasks",
    t: "",
    id: 1,
    icon: <PeopleAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PeopleAlt sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "Messenger",
    t: "messenger",
    id: 4,
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Weather",
    t: "weather",
    id: 5,
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Requests",
    t: "requestsFarmer",
    id: 5,
    icon: <RuleOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Rule sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Ticket",
    t: "ticket",
    id: 6,
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "راهنما",
  //   t: "help",
  //   id: "help",
  //   icon: <PriorityHighOutlined style={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <PriorityHigh sx={{ width: "24px", height: "24px" }} />,
  // },
];
const expertItemsEn = [
  {
    text: "Dashboard",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Remote Sensing",
    t: "remotesensing",
    icon: <SatelliteAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SatelliteAlt sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Sensor Management",
    t: "sensormanagement",
    icon: <SettingsRemoteOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <SettingsRemote sx={{ width: "24px", height: "24px" }} />,
  },
];

const expertItemsIEn = [
  {
    text: "Messenger",
    t: "messenger",
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Growth Stages",
    t: "growthstage",
    icon: <GrassOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Grass sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "شاخص هوشمند",
  //   t: "smartindexes",
  //   icon: <QueryStatsOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <QueryStats sx={{ width: "24px", height: "24px" }} />,
  // },
  // {
  //   text: "کنترل تجهیزات",
  //   t: "actuator",
  //   icon: <ToggleOffOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <ToggleOff sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "Recommends",
    t: "recommends",
    iconOpenDrawer: (
      <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: <RecommendOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Recommend sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Ticket",
    t: "ticket",
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  // {
  //   text: "گزارشات",
  //   t: "report",
  //   icon: <LeaderboardOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <Leaderboard sx={{ width: "24px", height: "24px" }} />,
  // },
  {
    text: "Requests",
    t: "requests",
    icon: <PlaylistAddCheckOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PlaylistAdd sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Farms",
    t: "farmsList",
    icon: <AutoAwesomeMosaicOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <AutoAwesomeMosaic sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Alarms",
    t: "alarms",
    iconOpenDrawer: (
      <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: (
      <ReportProblem sx={{ width: "24px", height: "24px" }} />
    ),
    icon: <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ReportProblem sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Weather",
    t: "weather",
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Pricing",
    t: "pricing",
    icon: <PaidOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Paid sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "Settings",
    t: "setting",
    icon: <SettingsOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Settings sx={{ width: "24px", height: "24px" }} />,
  },

  // {
  //   text: "کیف پول",
  //   t: "wallet",
  //   icon: <AccountBalanceOutlined sx={{ width: "24px", height: "24px" }} />,
  //   iconFilled: <AccountBalance sx={{ width: "24px", height: "24px" }} />,
  // },
];

const adminItems = [
  {
    text: "مدیریت جامع",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "ساخت پلن",
    t: "plan",
    icon: <CreateOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Create sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "سطح دسترسی",
    t: "permission",
    icon: <SecurityOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Security sx={{ width: "24px", height: "24px" }} />,
  },
];
const ListSidebarMob = ({ setOpen }) => {
  const location = useLocation();
  const user = useSelector((state) => state.global.userType);
  console.log("🚀 ~ ListSidebarMob ~ user:", user);
  const lang = useSelector((state) => state.global.language);
  const [landName, setLandName] = useState("");
  const changedLand = useSelector((state) => state.global.changedLand);
  const farmerListA = lang === "Fa" ? farmerItems : farmerItemsEn;
  const expertListA = lang === "Fa" ? expertItems : expertItemsEn;
  const farmerListB = lang === "Fa" ? farmerItemsI : farmerItemsIEn;
  const expertListB = lang === "Fa" ? expertItemsI : expertItemsIEn;
  const staffList = lang === "Fa" ? staffItems : staffItemsEn;

  const listItems1 =
    user === "FARMER"
      ? farmerListA
      : user === "EXPERT"
      ? expertListA
      : user === "ADMIN"
      ? adminItems
      : [];
  const listItems2 =
    user === "FARMER"
      ? farmerListB
      : user === "EXPERT"
      ? expertListB
      : staffList;
  const listItemsEq =
    user === "FARMER" ? (lang === "Fa" ? farmerItemsEq : farmerItemsEqEn) : [];
  const setFarm = async (id, title) => {
    const res = await activateLand(token, id);
    console.log("🚀 ~ file: Navbar.jsx:159 ~ setFarm ~ res:", res);
    setLandName(title);
    dispatch(
      setChangedLand({
        changedLand: !changedLand,
      })
    );
    window.location.reload();
  };
  const token = useSelector((state) => state.global.token);

  const landActiveQuery = useQuery(["landActiveQuery", token], () => {
    return getLandActive(token);
  });
  // const selectedItem = useSelector((state) => state.global.selectedItemMob);

  const mode = useSelector((state) => state.global.mode);
  // const { response: farms } = useFetchFarmData(getFarmData);
  const landsQuery = useQuery(["landsQuery", token], () => {
    return landData(token);
  });

  console.log(
    "🚀 ~ file: SidebarRemoteSensing.jsx:116 ~ landsQuery ~ landsQuery:",
    landsQuery
  );
  // const farmData = farms?.data;
  useEffect(() => {
    if (farmData) {
      const active = farmData.find((farm) => farm.active);
      dispatch(
        setProperty({
          property: farmData.data,
        })
      );
      if (active) {
        dispatch(
          setFarmNameMob({
            farmNameMob: active.title,
          })
        );
        dispatch(setPropertyId({ propertyId: active.id }));
      }
    }
  }, [farmData]);
  const farmNameMob = useSelector((state) => state.global.farmNameMob);

  const navigate = useNavigate();
  const theme = useTheme();
  // const boxRefs = useRef({});
  const dispatch = useDispatch();
  const [openAccordion, setOpenAccordion] = useState(false);
  const handleClick = () => {
    setOpenAccordion((prev) => !prev);
  };

  const handleClose = () => {
    setTimeout(() => {
      setOpenAccordion(false);
    }, 300);
  };
  // useEffect(() => {
  //   if (boxRefs?.current?.[location?.pathname.replace("/", "")]) {
  //     boxRefs.current[location?.pathname.replace("/", "")].scrollIntoView({
  //       behavior: "smooth",
  //     });
  //     console.log(
  //       "🚀 ~ file: ListSidebarMob.jsx:243 ~ useEffect ~     boxRefs.current[selectedItem - 1]:",
  //       boxRefs.current
  //     );
  //   }
  // }, [location]);
  // const [landName, setLandName] = useState("");
  useEffect(() => {}, [landName]);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        WebkitOverflowScrolling: "touch",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          width: 265,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        role="presentation"
      >
        <Link to="/management">
          <Box mt="0.75rem">
            {mode === "dark" ? (
              <img
                alt="img1"
                src={require("../../assets/LogoColor.png")}
                style={{
                  width: "52px",
                  height: "40px",
                  objectFit: "cover",
                  marginBottom: "1rem",
                }}
              ></img>
            ) : (
              <img
                alt="img1"
                src={require("../../assets/LogoColor.png")}
                style={{
                  width: "52px",
                  height: "40px",
                  objectFit: "cover",
                }}
              ></img>
            )}
          </Box>
        </Link>

        <Box
          sx={{
            width: "241px",
            // minHeight: "40px !important",
            borderRadius: "16px",
            backgroundColor: theme.palette.background.third,
            border: "none",
            borderColor: "transparent !important",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            // gap: "8px",
            alignItems: "stretch",
            p: "0.75rem 1rem",
            position: "relative",
            overflow: "hidden",
          }}
          onClick={handleClick}
        >
          <FlexBetween alignItems="center">
            <Typography
              color={theme.palette.fontColor.main}
              fontSize={theme.typography.smallestTextPrimary}
            >
              {landActiveQuery.isSuccess && landActiveQuery.data?.data
                ? landActiveQuery.data?.data.land.name
                : ""}
            </Typography>
            <KeyboardArrowDownOutlined
              sx={{
                rotate: openAccordion ? "180deg" : "0deg",
                transition: "rotate 0.3s",
              }}
            />
          </FlexBetween>
          <Accordion open={openAccordion}>
            {landsQuery.isSuccess &&
              landsQuery.data?.data.length > 0 &&
              landsQuery.data?.data.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    borderRadius: "8px",
                    backgroundColor:
                      farmNameMob === item.title
                        ? "background.forth"
                        : "initial",
                  }}
                >
                  <Button
                    variant="text"
                    onClick={async () => {
                      try {
                        setFarm(item.land.id, item.land.name);
                        const permissionsResult = await getPermissions(token);
                        console.log(
                          "🚀 ~ onClick={ ~ permissionsResult:",
                          permissionsResult
                        );

                        dispatch(
                          setUserPermissions({
                            userPermissions:
                              permissionsResult.data.groupPermissions,
                          })
                        );
                        dispatch(
                          setCart({
                            cart: [],
                          })
                        );
                        handleClose();
                      } catch (error) {
                        console.log("🚀 ~ onClick={ ~ error:", error);
                      }
                    }}
                    sx={{
                      color: "fontColor.main",
                      justifyContent: "flex-start",
                      width: "100%",
                      // backgroundColor: `${theme.palette.background.third} !important`,
                      // ":hover": {
                      //   backgroundColor: `${theme.palette.fontColor.third} !important`,
                      // },
                    }}
                  >
                    {item.land.name}
                  </Button>
                </Box>
              ))}
          </Accordion>
        </Box>
      </Box>

      {user !== "Staff" && (
        <>
          <WidgetWrapper
            width="241px"
            height="150px"
            sx={{
              backgroundColor: theme.palette.background.third,
              m: "1rem 0.75rem !important",
              p: "1rem 0.5rem",
            }}
          >
            {listItems1.map((item, index) => (
              <Box
                // ref={(ref) => (boxRefs.current[item.t] = ref)}
                width="225px"
                height="40px"
                borderRadius="12px"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="0.5rem"
                p="0 0.5rem"
                sx={{
                  backgroundColor:
                    `/${item.t}` === location?.pathname
                      ? theme.palette.others.first
                      : "none",
                  color:
                    `/${item.t}` === location?.pathname
                      ? "#F7F7F7"
                      : "fontColor.main",
                  "scroll-snap-align": "start",
                  "scroll-snap-stop": "normal",
                }}
                onClick={() => {
                  // dispatch(
                  //   setSelectedItemMob({
                  //     selectedItemMob: item.id,
                  //   })
                  // );
                  navigate(`/${item.t}`);
                  setOpen(false);
                }}
                tabIndex={`/${item.t}` === location?.pathname ? 0 : -1}
              >
                {`/${item.t}` === location?.pathname
                  ? item.iconFilled
                  : item.icon}
                <Typography>{item.text}</Typography>
              </Box>
            ))}
          </WidgetWrapper>
          <WidgetWrapper
            width="241px"
            sx={{
              backgroundColor: theme.palette.background.third,
              m: "1rem 0.75rem !important",
              p: "1rem 0.5rem",
            }}
          >
            {user !== "EXPERT" &&
              listItems2.map((item, index) => (
                <Box
                  // ref={(ref) => (boxRefs.current[item.t] = ref)}
                  width="225px"
                  height="40px"
                  mt="0.25rem"
                  borderRadius="12px"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap="0.5rem"
                  p="0 0.5rem"
                  sx={{
                    backgroundColor:
                      `/${item.t}` === location?.pathname
                        ? theme.palette.others.first
                        : "none",
                    color:
                      `/${item.t}` === location?.pathname
                        ? "#F7F7F7"
                        : "fontColor.main",
                  }}
                  onClick={() => {
                    // dispatch(
                    //   setSelectedItemMob({
                    //     selectedItemMob: item.id,
                    //   })
                    // );
                    if (
                      item.t !== "actuator" &&
                      item.t !== "smartindexes" &&
                      item.t !== "neighborhood" &&
                      item.t !== "wallet" &&
                      item.t !== "report" &&
                      item.t !== "tracking" &&
                      item.t !== "shoppingg" &&
                      item.t !== "shoppinggg"
                    ) {
                      navigate(`/${item.t}`);
                      setOpen(false);
                    }
                  }}
                  tabIndex={`/${item.t}` === location?.pathname ? 0 : -1}
                >
                  {`/${item.t}` === location?.pathname
                    ? item.iconFilled
                    : item.icon}
                  <Typography>{item.text}</Typography>
                </Box>
              ))}
            {user === "EXPERT" &&
              expertItemsI.map((item, index) => (
                <Box
                  // ref={(ref) => (boxRefs.current[item.t] = ref)}
                  width="225px"
                  height="40px"
                  mt="0.25rem"
                  borderRadius="12px"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap="0.5rem"
                  p="0 0.5rem"
                  sx={{
                    backgroundColor:
                      `/${item.t}` === location?.pathname
                        ? theme.palette.others.first
                        : "none",
                    color:
                      `/${item.t}` === location?.pathname
                        ? "#F7F7F7"
                        : "fontColor.main",
                  }}
                  onClick={() => {
                    // dispatch(
                    //   setSelectedItemMob({
                    //     selectedItemMob: item.id,
                    //   })
                    // );
                    if (
                      item.t !== "actuator" &&
                      item.t !== "smartindexes" &&
                      item.t !== "experts" &&
                      item.t !== "neighborhood" &&
                      item.t !== "wallet" &&
                      item.t !== "report" &&
                      item.t !== "tracking" &&
                      item.t !== "shoppingg" &&
                      item.t !== "shoppinggg"
                    ) {
                      navigate(`/${item.t}`);
                      setOpen(false);
                    }
                  }}
                  tabIndex={`/${item.t}` === location?.pathname ? 0 : -1}
                >
                  {`/${item.t}` === location?.pathname
                    ? item.iconFilled
                    : item.icon}
                  <Typography>{item.text}</Typography>
                </Box>
              ))}
          </WidgetWrapper>
          <WidgetWrapper
            width="241px"
            // height="189px"
            sx={{
              backgroundColor: theme.palette.background.third,
              m: "1rem 0.75rem !important",
              p: "1rem 0.5rem",
            }}
          >
            {listItemsEq.map((item, index) => (
              <Box
                // ref={(ref) => (boxRefs.current[item.t] = ref)}
                width="225px"
                height="40px"
                mt="0.25rem"
                borderRadius="12px"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="0.5rem"
                p="0 0.5rem"
                sx={{
                  backgroundColor:
                    `/${item.t}` === location?.pathname
                      ? theme.palette.others.first
                      : "none",
                  color:
                    `/${item.t}` === location?.pathname
                      ? "#F7F7F7"
                      : "fontColor.main",
                }}
                onClick={() => {
                  // dispatch(
                  //   setSelectedItemMob({
                  //     selectedItemMob: item.id,
                  //   })
                  // );
                  if (item.t === "education") {
                    openInNewTab("https://partotaprayan.viannacloud.ir/");
                  }
                  if (
                    item.t !== "drone" &&
                    item.t !== "education" &&
                    item.t !== "assetManagement" &&
                    item.t !== "tracking" &&
                    item.t !== "shoppingg" &&
                    item.t !== "consultant" &&
                    item.t !== "lab" &&
                    item.t !== "shoppinggg"
                  ) {
                    navigate(`/${item.t}`);
                    setOpen(false);
                  }
                }}
                tabIndex={`/${item.t}` === location?.pathname ? 0 : -1}
              >
                {`/${item.t}` === location?.pathname
                  ? item.iconFilled
                  : item.icon}
                <Typography>{item.text}</Typography>
              </Box>
            ))}
          </WidgetWrapper>
        </>
      )}
      {user === "Staff" && (
        <WidgetWrapper
          width="241px"
          // height="106px"
          sx={{
            backgroundColor: theme.palette.background.third,
            m: "1rem 0.75rem !important",
            p: "1rem 0.5rem",
          }}
        >
          {staffItems.map((item, index) => (
            <Box
              // ref={(ref) => (boxRefs.current[item.t] = ref)}
              width="225px"
              height="40px"
              borderRadius="12px"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap="0.5rem"
              p="0 0.5rem"
              sx={{
                backgroundColor:
                  `/${item.t}` === location?.pathname
                    ? theme.palette.others.first
                    : "none",
                color:
                  `/${item.t}` === location?.pathname
                    ? "#F7F7F7"
                    : "fontColor.main",
                "scroll-snap-align": "start",
                "scroll-snap-stop": "normal",
              }}
              onClick={() => {
                // dispatch(
                //   setSelectedItemMob({
                //     selectedItemMob: item.id,
                //   })
                // );
                navigate(`/${item.t}`);
                setOpen(false);
              }}
              tabIndex={`/${item.t}` === location?.pathname ? 0 : -1}
            >
              {`/${item.t}` === location?.pathname
                ? item.iconFilled
                : item.icon}
              <Typography>{item.text}</Typography>
            </Box>
          ))}
        </WidgetWrapper>
      )}
    </Box>
  );
};

export default ListSidebarMob;
