import { createSlice } from "@reduxjs/toolkit";
import { SENSOR_MANAGEMENT_DEFAULT_PERIOD, TODAY } from "constants/constant";

const currentDate = new Date();

// Get the date 7 days ago
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(currentDate.getDate() - 7);

// Generate an array of dates from 7 days ago to now
const timestampsArray = [];
let currentDateIterator = new Date(sevenDaysAgo); // Create a new Date object to avoid reference issues
while (currentDateIterator <= currentDate) {
  timestampsArray.push(new Date(currentDateIterator));
  currentDateIterator.setDate(currentDateIterator.getDate() + 1);
}
const initialState = {
  sensorsData: {
    WEATHER: [
      {
        id: 1,
        position: [35.646029, 52.223528],
        title: "آب و هوای یک",
        zone: "ناحیه یک",
        zoneId: "1",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "ACTIVE",
        battery: "HIGH",
        signal: "LOW",
        alarms: [
          {
            type: "CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "دمای بالا",
            area: "ناحیه 1",
            action: "محمد رضا علیخانی",
            description:
              "با توجه به افزایش دما در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "temperature",
            data: [
              { ts: timestampsArray[0], v: 10 },
              { ts: timestampsArray[1], v: 25 },
              { ts: timestampsArray[2], v: 37 },
              { ts: timestampsArray[3], v: 32 },
              { ts: timestampsArray[4], v: 38 },
              { ts: timestampsArray[5], v: 36 },
              { ts: timestampsArray[6], v: 36 },
            ],
          },

          {
            type: "solar_radiation",
            data: [
              { ts: timestampsArray[0], v: 43 },
              { ts: timestampsArray[1], v: 50 },
              { ts: timestampsArray[2], v: 55 },
              { ts: timestampsArray[3], v: 48 },
              { ts: timestampsArray[4], v: 75 },
              { ts: timestampsArray[5], v: 50 },
            ],
          },
          {
            type: "humidity",
            data: [
              { ts: timestampsArray[0], v: 30 },
              { ts: timestampsArray[1], v: 43 },
              { ts: timestampsArray[2], v: 35 },
              { ts: timestampsArray[3], v: 40 },
              { ts: timestampsArray[4], v: 28 },
              { ts: timestampsArray[5], v: 53 },
            ],
          },
          {
            type: "precipitation_rain",
            data: [
              { ts: timestampsArray[0], v: 6 },
              { ts: timestampsArray[1], v: 7 },
              { ts: timestampsArray[2], v: 7 },
              { ts: timestampsArray[3], v: 5 },
              { ts: timestampsArray[4], v: 6 },
              { ts: timestampsArray[5], v: 7 },
              { ts: timestampsArray[6], v: 7 },
            ],
          },
          {
            type: "atmospheric_pressure",
            data: [
              { ts: timestampsArray[0], v: 6 },
              { ts: timestampsArray[1], v: 7 },
              { ts: timestampsArray[2], v: 7 },
              { ts: timestampsArray[3], v: 5 },
              { ts: timestampsArray[4], v: 6 },
              { ts: timestampsArray[5], v: 7 },
              { ts: timestampsArray[6], v: 9 },
            ],
          },
          {
            type: "wind_speed",
            data: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 6 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 10 },
            ],
          },
          {
            type: "shabnam",
            data: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 6 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 10 },
            ],
          },
        ],
      },
      {
        id: 2,
        position: [35.645329, 52.226028],
        title: "آب و هوای دو",
        zone: "ناحیه دو",
        zoneId: "2",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "ACTIVE",
        battery: "HIGH",
        signal: "LOWEST",
        alarms: [
          {
            type: "CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "دمای بسیار بالا",
            area: "ناحیه 2",
            action: "علی عباسی",
            description:
              "با توجه به افزایش دما در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "temperature",
            data: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 20 },
              { ts: timestampsArray[2], v: 27 },
              { ts: timestampsArray[3], v: 22 },
              { ts: timestampsArray[4], v: 28 },
              { ts: timestampsArray[5], v: 26 },
              { ts: timestampsArray[6], v: 26 },
            ],
          },

          {
            type: "solar_radiation",
            data: [
              { ts: timestampsArray[0], v: 53 },
              { ts: timestampsArray[1], v: 60 },
              { ts: timestampsArray[2], v: 65 },
              { ts: timestampsArray[3], v: 58 },
              { ts: timestampsArray[4], v: 65 },
              { ts: timestampsArray[5], v: 60 },
            ],
          },
          {
            type: "humidity",
            data: [
              { ts: timestampsArray[0], v: 40 },
              { ts: timestampsArray[1], v: 53 },
              { ts: timestampsArray[2], v: 55 },
              { ts: timestampsArray[3], v: 50 },
              { ts: timestampsArray[4], v: 38 },
              { ts: timestampsArray[5], v: 63 },
            ],
          },
          {
            type: "precipitation_rain",
            data: [
              { ts: timestampsArray[0], v: 8 },
              { ts: timestampsArray[1], v: 9 },
              { ts: timestampsArray[2], v: 9 },
              { ts: timestampsArray[3], v: 5 },
              { ts: timestampsArray[4], v: 3 },
              { ts: timestampsArray[5], v: 8 },
              { ts: timestampsArray[6], v: 8 },
            ],
          },
          {
            type: "atmospheric_pressure",
            data: [
              { ts: timestampsArray[0], v: 6 },
              { ts: timestampsArray[1], v: 7 },
              { ts: timestampsArray[2], v: 7 },
              { ts: timestampsArray[3], v: 5 },
              { ts: timestampsArray[4], v: 6 },
              { ts: timestampsArray[5], v: 7 },
              { ts: timestampsArray[6], v: 9 },
            ],
          },
          {
            type: "wind_speed",
            data: [
              { ts: timestampsArray[0], v: 9 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 13 },
              { ts: timestampsArray[3], v: 15 },
              { ts: timestampsArray[4], v: 10 },
              { ts: timestampsArray[5], v: 10 },
            ],
          },
          {
            type: "shabnam",
            data: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 6 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 10 },
            ],
          },
        ],
      },
      {
        id: 3,
        position: [35.639329, 52.220028],
        title: "آب و هوای سه",
        zone: "ناحیه سه",
        zoneId: "3",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "NOTACTIVE",
        battery: "LOWEST",
        signal: "LOWEST",
        alarms: [
          {
            type: "NOT CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "دمای متوسط",
            area: "ناحیه 3",
            action: "حامد صمیمی",
            description:
              "با توجه به افزایش دما در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "temperature",
            data: [
              { ts: timestampsArray[0], v: 15 },
              { ts: timestampsArray[1], v: 20 },
              { ts: timestampsArray[2], v: 27 },
              { ts: timestampsArray[3], v: 32 },
              { ts: timestampsArray[4], v: 35 },
              { ts: timestampsArray[5], v: 25 },
              { ts: timestampsArray[6], v: 25 },
            ],
          },

          {
            type: "solar_radiation",
            data: [
              { ts: timestampsArray[0], v: 48 },
              { ts: timestampsArray[1], v: 52 },
              { ts: timestampsArray[2], v: 50 },
              { ts: timestampsArray[3], v: 42 },
              { ts: timestampsArray[4], v: 60 },
              { ts: timestampsArray[5], v: 50 },
            ],
          },
          {
            type: "humidity",
            data: [
              { ts: timestampsArray[0], v: 35 },
              { ts: timestampsArray[1], v: 45 },
              { ts: timestampsArray[2], v: 30 },
              { ts: timestampsArray[3], v: 32 },
              { ts: timestampsArray[4], v: 22 },
              { ts: timestampsArray[5], v: 43 },
            ],
          },
          {
            type: "precipitation_rain",
            data: [
              { ts: timestampsArray[0], v: 9 },
              { ts: timestampsArray[1], v: 9 },
              { ts: timestampsArray[2], v: 8 },
              { ts: timestampsArray[3], v: 8 },
              { ts: timestampsArray[4], v: 8 },
              { ts: timestampsArray[5], v: 9 },
              { ts: timestampsArray[6], v: 8 },
            ],
          },
          {
            type: "atmospheric_pressure",
            data: [
              { ts: timestampsArray[0], v: 5 },
              { ts: timestampsArray[1], v: 5 },
              { ts: timestampsArray[2], v: 8 },
              { ts: timestampsArray[3], v: 8 },
              { ts: timestampsArray[4], v: 6 },
              { ts: timestampsArray[5], v: 6 },
              { ts: timestampsArray[6], v: 7 },
            ],
          },
          {
            type: "wind_speed",
            data: [
              { ts: timestampsArray[0], v: 15 },
              { ts: timestampsArray[1], v: 14 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 13 },
              { ts: timestampsArray[4], v: 19 },
              { ts: timestampsArray[5], v: 20 },
            ],
          },
          {
            type: "shabnam",
            data: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 16 },
              { ts: timestampsArray[4], v: 19 },
              { ts: timestampsArray[5], v: 10 },
            ],
          },
        ],
      },
      {
        id: 4,
        position: [35.641729, 52.221528],
        title: "آب و هوای چهار",
        zone: "ناحیه سه",
        zoneId: "3",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "ACTIVE",
        battery: "HIGH",
        signal: "HIGH",
        alarms: [
          {
            type: "CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "رطوبت بالا",
            area: "ناحیه 3",
            action: "علی عباسی",
            description:
              "با توجه به افزایش رطوبت در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "temperature",
            data: [
              { ts: timestampsArray[0], v: 30 },
              { ts: timestampsArray[1], v: 35 },
              { ts: timestampsArray[2], v: 37 },
              { ts: timestampsArray[3], v: 33 },
              { ts: timestampsArray[4], v: 38 },
              { ts: timestampsArray[5], v: 35 },
              { ts: timestampsArray[6], v: 36 },
            ],
          },

          {
            type: "solar_radiation",
            data: [
              { ts: timestampsArray[0], v: 33 },
              { ts: timestampsArray[1], v: 40 },
              { ts: timestampsArray[2], v: 45 },
              { ts: timestampsArray[3], v: 38 },
              { ts: timestampsArray[4], v: 65 },
              { ts: timestampsArray[5], v: 60 },
            ],
          },
          {
            type: "humidity",
            data: [
              { ts: timestampsArray[0], v: 45 },
              { ts: timestampsArray[1], v: 40 },
              { ts: timestampsArray[2], v: 45 },
              { ts: timestampsArray[3], v: 50 },
              { ts: timestampsArray[4], v: 58 },
              { ts: timestampsArray[5], v: 53 },
            ],
          },
          {
            type: "precipitation_rain",
            data: [
              { ts: timestampsArray[0], v: 9 },
              { ts: timestampsArray[1], v: 9 },
              { ts: timestampsArray[2], v: 9 },
              { ts: timestampsArray[3], v: 8 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 6 },
              { ts: timestampsArray[6], v: 5 },
            ],
          },
          {
            type: "atmospheric_pressure",
            data: [
              { ts: timestampsArray[0], v: 10 },
              { ts: timestampsArray[1], v: 9 },
              { ts: timestampsArray[2], v: 5 },
              { ts: timestampsArray[3], v: 6 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 8 },
              { ts: timestampsArray[6], v: 10 },
            ],
          },
          {
            type: "wind_speed",
            data: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 15 },
              { ts: timestampsArray[4], v: 12 },
              { ts: timestampsArray[5], v: 15 },
            ],
          },
          {
            type: "shabnam",
            data: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 16 },
              { ts: timestampsArray[2], v: 15 },
              { ts: timestampsArray[3], v: 12 },
              { ts: timestampsArray[4], v: 11 },
              { ts: timestampsArray[5], v: 12 },
            ],
          },
        ],
      },
    ],
    SOIL: [
      {
        id: 5,
        position: [35.645329, 52.226028],
        title: "خاک یک",
        zone: "ناحیه یک",
        zoneId: "1",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "ACTIVE",
        battery: "HIGH",
        signal: "HIGH",
        alarms: [
          {
            type: "CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "دمای بسیار بالا",
            area: "ناحیه 1",
            action: "حامد صمیمی",
            description:
              "با توجه به افزایش دمای خاک در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "temperature",
            data30: [
              { ts: timestampsArray[0], v: 20 },
              { ts: timestampsArray[1], v: 25 },
              { ts: timestampsArray[2], v: 18 },
              { ts: timestampsArray[3], v: 22 },
              { ts: timestampsArray[4], v: 29 },
              { ts: timestampsArray[5], v: 32 },
            ],
            data60: [
              { ts: timestampsArray[0], v: 15 },
              { ts: timestampsArray[1], v: 11 },
              { ts: timestampsArray[2], v: 8 },
              { ts: timestampsArray[3], v: 12 },
              { ts: timestampsArray[4], v: 10 },
              { ts: timestampsArray[5], v: 8 },
            ],
            data90: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 6 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 7 },
            ],
          },
          {
            type: "humidity",
            data30: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 6 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 7 },
            ],
            data60: [
              { ts: timestampsArray[0], v: 16 },
              { ts: timestampsArray[1], v: 25 },
              { ts: timestampsArray[2], v: 23 },
              { ts: timestampsArray[3], v: 21 },
              { ts: timestampsArray[4], v: 35 },
              { ts: timestampsArray[5], v: 40 },
            ],
            data90: [
              { ts: timestampsArray[0], v: 7 },
              { ts: timestampsArray[1], v: 8 },
              { ts: timestampsArray[2], v: 11 },
              { ts: timestampsArray[3], v: 15 },
              { ts: timestampsArray[4], v: 8 },
              { ts: timestampsArray[5], v: 5 },
            ],
          },
        ],
      },
      {
        id: 6,
        position: [35.639329, 52.220028],
        title: "خاک دو",
        zone: "ناحیه دو",
        zoneId: "2",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "ACTIVE",
        battery: "LOW",
        signal: "LOW",
        alarms: [
          {
            type: "NOT CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "دمای متوسط",
            area: "ناحیه 2",
            action: "محمد رضا علیخانی",
            description:
              "با توجه به افزایش دما در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "temperature",
            data30: [
              { ts: timestampsArray[0], v: 30 },
              { ts: timestampsArray[1], v: 35 },
              { ts: timestampsArray[2], v: 37 },
              { ts: timestampsArray[3], v: 25 },
              { ts: timestampsArray[4], v: 19 },
              { ts: timestampsArray[5], v: 22 },
            ],
            data60: [
              { ts: timestampsArray[0], v: 18 },
              { ts: timestampsArray[1], v: 13 },
              { ts: timestampsArray[2], v: 11 },
              { ts: timestampsArray[3], v: 12 },
              { ts: timestampsArray[4], v: 10 },
              { ts: timestampsArray[5], v: 10 },
            ],
            data90: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 6 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 7 },
            ],
          },
          {
            type: "humidity",
            data30: [
              { ts: timestampsArray[0], v: 22 },
              { ts: timestampsArray[1], v: 30 },
              { ts: timestampsArray[2], v: 26 },
              { ts: timestampsArray[3], v: 16 },
              { ts: timestampsArray[4], v: 23 },
              { ts: timestampsArray[5], v: 17 },
            ],
            data60: [
              { ts: timestampsArray[0], v: 16 },
              { ts: timestampsArray[1], v: 25 },
              { ts: timestampsArray[2], v: 23 },
              { ts: timestampsArray[3], v: 21 },
              { ts: timestampsArray[4], v: 35 },
              { ts: timestampsArray[5], v: 40 },
            ],
            data90: [
              { ts: timestampsArray[0], v: 7 },
              { ts: timestampsArray[1], v: 8 },
              { ts: timestampsArray[2], v: 11 },
              { ts: timestampsArray[3], v: 15 },
              { ts: timestampsArray[4], v: 8 },
              { ts: timestampsArray[5], v: 5 },
            ],
          },
        ],
      },
      {
        id: 7,
        position: [35.643729, 52.222528],
        title: "خاک سه",
        zone: "ناحیه سه",
        zoneId: "3",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "NOTACTIVE",
        battery: "LOWEST",
        signal: "LOWEST",
        alarms: [
          {
            type: "NOT CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "بارندگی متوسط",
            area: "ناحیه 3",
            action: "حامد صمیمی",
            description:
              "با توجه به افزایش دما در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "temperature",
            data30: [
              { ts: timestampsArray[0], v: 30 },
              { ts: timestampsArray[1], v: 20 },
              { ts: timestampsArray[2], v: 18 },
              { ts: timestampsArray[3], v: 12 },
              { ts: timestampsArray[4], v: 24 },
              { ts: timestampsArray[5], v: 22 },
            ],
            data60: [
              { ts: timestampsArray[0], v: 16 },
              { ts: timestampsArray[1], v: 12 },
              { ts: timestampsArray[2], v: 11 },
              { ts: timestampsArray[3], v: 8 },
              { ts: timestampsArray[4], v: 18 },
              { ts: timestampsArray[5], v: 10 },
            ],
            data90: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 6 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 7 },
            ],
          },
          {
            type: "humidity",
            data30: [
              { ts: timestampsArray[0], v: 10 },
              { ts: timestampsArray[1], v: 8 },
              { ts: timestampsArray[2], v: 12 },
              { ts: timestampsArray[3], v: 13 },
              { ts: timestampsArray[4], v: 11 },
              { ts: timestampsArray[5], v: 8 },
            ],
            data60: [
              { ts: timestampsArray[0], v: 16 },
              { ts: timestampsArray[1], v: 15 },
              { ts: timestampsArray[2], v: 13 },
              { ts: timestampsArray[3], v: 15 },
              { ts: timestampsArray[4], v: 25 },
              { ts: timestampsArray[5], v: 20 },
            ],
            data90: [
              { ts: timestampsArray[0], v: 17 },
              { ts: timestampsArray[1], v: 18 },
              { ts: timestampsArray[2], v: 11 },
              { ts: timestampsArray[3], v: 15 },
              { ts: timestampsArray[4], v: 18 },
              { ts: timestampsArray[5], v: 15 },
            ],
          },
        ],
      },
    ],
    PLANT: [
      {
        id: 8,
        position: [35.644729, 52.222528],
        title: "گیاه یک",
        zone: "ناحیه یک",
        zoneId: "1",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "ACTIVE",
        battery: "HIGH",
        signal: "LOW",
        alarms: [
          {
            type: "CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "رطوبت بسیار بالا",
            area: "ناحیه 1",
            action: "حامد صمیمی",
            description:
              "با توجه به افزایش رطوبت خاک در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "fruit_diameter",
            data: [
              { ts: timestampsArray[0], v: 3 },
              { ts: timestampsArray[1], v: 3 },
              { ts: timestampsArray[2], v: 3.5 },
              { ts: timestampsArray[3], v: 3.5 },
              { ts: timestampsArray[4], v: 3.5 },
              { ts: timestampsArray[5], v: 3.5 },
            ],
          },
          {
            type: "stem_diameter",
            data: [
              { ts: timestampsArray[0], v: 3 },
              { ts: timestampsArray[1], v: 3 },
              { ts: timestampsArray[2], v: 4 },
              { ts: timestampsArray[3], v: 4 },
              { ts: timestampsArray[4], v: 5 },
              { ts: timestampsArray[5], v: 5 },
            ],
          },
          {
            type: "leaf_humidity",
            data: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 6 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 7 },
            ],
          },
        ],
      },
      {
        id: 9,
        position: [35.642729, 52.224528],
        title: "گیاه دو",
        zone: "ناحیه دو",
        zoneId: "2",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "ACTIVE",
        battery: "HIGH",
        signal: "HIGH",
        alarms: [
          {
            type: "CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "رطوبت 60متر بالا",
            area: "ناحیه 2",
            action: "محمد رضا علیخانی",
            description:
              "با توجه به افزایش دمای خاک در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "fruit_diameter",
            data: [
              { ts: timestampsArray[0], v: 10 },
              { ts: timestampsArray[1], v: 11 },
              { ts: timestampsArray[2], v: 11 },
              { ts: timestampsArray[3], v: 11 },
              { ts: timestampsArray[4], v: 12 },
              { ts: timestampsArray[5], v: 11.5 },
            ],
          },
          {
            type: "stem_diameter",
            data: [
              { ts: timestampsArray[0], v: 3.5 },
              { ts: timestampsArray[1], v: 3.5 },
              { ts: timestampsArray[2], v: 4.5 },
              { ts: timestampsArray[3], v: 4.5 },
              { ts: timestampsArray[4], v: 5.5 },
              { ts: timestampsArray[5], v: 5.5 },
            ],
          },
          {
            type: "leaf_humidity",
            data: [
              { ts: timestampsArray[0], v: 14 },
              { ts: timestampsArray[1], v: 12 },
              { ts: timestampsArray[2], v: 18 },
              { ts: timestampsArray[3], v: 16 },
              { ts: timestampsArray[4], v: 10 },
              { ts: timestampsArray[5], v: 15 },
            ],
          },
        ],
      },
      {
        id: 10,
        position: [35.641729, 52.221528],
        title: "گیاه سه",
        zone: "ناحیه دو",
        zoneId: "2",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "ACTIVE",
        battery: "HIGH",
        signal: "LOW",
        alarms: [
          {
            type: "CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "دمای  بالا",
            area: "ناحیه 2",
            action: "محمد رضا علیخانی",
            description:
              "با توجه به افزایش دمای خاک در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "fruit_diameter",
            data: [
              { ts: timestampsArray[0], v: 3 },
              { ts: timestampsArray[1], v: 3 },
              { ts: timestampsArray[2], v: 3.5 },
              { ts: timestampsArray[3], v: 3.5 },
              { ts: timestampsArray[4], v: 3.5 },
              { ts: timestampsArray[5], v: 3.5 },
            ],
          },
          {
            type: "stem_diameter",
            data: [
              { ts: timestampsArray[0], v: 3 },
              { ts: timestampsArray[1], v: 3 },
              { ts: timestampsArray[2], v: 4 },
              { ts: timestampsArray[3], v: 4 },
              { ts: timestampsArray[4], v: 5 },
              { ts: timestampsArray[5], v: 5 },
            ],
          },
          {
            type: "leaf_humidity",
            data: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 12 },
              { ts: timestampsArray[2], v: 13 },
              { ts: timestampsArray[3], v: 12 },
              { ts: timestampsArray[4], v: 11 },
              { ts: timestampsArray[5], v: 12 },
            ],
          },
        ],
      },
      {
        id: 11,
        position: [35.645729, 52.225528],
        title: "گیاه چهار",
        zone: "ناحیه سه",
        zoneId: "3",
        lastTimeUpdate: "1402/05/09 - 11:23",
        status: "ACTIVE",
        battery: "LOW",
        signal: "LOW",
        alarms: [
          {
            type: "CIRTICAL",
            sendDate: "1402/05/23",
            sendTime: "22:30",
            alarm: "دمای 90 متر بالا",
            area: "ناحیه 3",
            action: "حامد صمیمی",
            description:
              "با توجه به افزایش دمای خاک  90 متر در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
            notification: "پیامک",
            alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
          },
        ],
        data: [
          {
            type: "wind_speed",
            data: [
              { ts: timestampsArray[0], v: 12 },
              { ts: timestampsArray[1], v: 10 },
              { ts: timestampsArray[2], v: 16 },
              { ts: timestampsArray[3], v: 6 },
              { ts: timestampsArray[4], v: 9 },
              { ts: timestampsArray[5], v: 7 },
            ],
          },
        ],
      },
    ],
  },
  lastDataSensors: [],
  allSensors: null,
  id: null,
  firstDate: SENSOR_MANAGEMENT_DEFAULT_PERIOD,
  secondDate: TODAY,
  date1: { year: null, month: null, day: null },
  date2: { year: null, month: null, day: null },
  lastTimeUpdate: "",
  alarms: null,
  devicesAlarm: null,
  alarmHelp: true,
  alarmHelper: {
    welcome: true,
    lastSensor: false,
    alarms: false,
    sensors: false,
  },
};

export const sensorsSlice = createSlice({
  name: "sensors",
  initialState,
  reducers: {
    setSensorsData: (state, action) => {
      state.sensorsData = action.payload.sensorsData;
    },
    setId: (state, action) => {
      state.id = action.payload.id;
    },
    setFirstDate: (state, action) => {
      state.firstDate = action.payload.firstDate;
    },
    setSecondDate: (state, action) => {
      state.secondDate = action.payload.secondDate;
    },
    setDate1: (state, action) => {
      state.date1 = action.payload.date1;
    },
    setDate2: (state, action) => {
      state.date2 = action.payload.date2;
    },
    setLastTimeUpdate: (state, action) => {
      state.lastTimeUpdate = action.payload.lastTimeUpdate;
    },
    setAlarms: (state, action) => {
      state.alarms = action.payload.alarms;
    },
    setDevicesAlarm: (state, action) => {
      state.devicesAlarm = action.payload.devicesAlarm;
    },
    setAllSensors: (state, action) => {
      state.allSensors = action.payload.allSensors;
    },
    setLastDataSensors: (state, action) => {
      state.lastDataSensors = action.payload.lastDataSensors;
    },
    setAlarmHelper: (state, action) => {
      state.alarmHelper = action.payload.alarmHelper;
    },
    setAlarmHelp: (state, action) => {
      state.alarmHelp = action.payload.alarmHelp;
    },
  },
});

export const {
  setSensorsData,
  setId,
  setFirstDate,
  setSecondDate,
  setDate1,
  setDate2,
  setLastTimeUpdate,
  setAlarms,
  setDevicesAlarm,
  setAllSensors,
  setLastDataSensors,
  setAlarmHelp,
  setAlarmHelper,
} = sensorsSlice.actions;
export default sensorsSlice.reducer;
