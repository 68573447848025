import { Box, Stack, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { CustomButton } from "components/styledComponents/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "States/shopping";

const PackageItem = ({ mob, title, price, unit, service }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const carts = useSelector((state) => state.shopping.cart);
  console.log("🚀 ~ PackageItem ~ carts:", carts);
  const exists = carts.some((item) => item.id === service.id);

  const handleremoveFromCart = () => {
    const filteredCart = carts.filter((item) => item.id !== service.id);
    dispatch(setCart({ cart: filteredCart }));
  };
  const handleAddToCart = (currentCart) => {
    if (!exists) dispatch(setCart({ cart: [...carts, service] }));
  };
  const enable = carts.find((item) => item.id);
  return (
    <Box
      width={mob ? "45%" : "160px"}
      height="142px"
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      sx={{
        backgroundColor: "background.third",
        borderRadius: "8px",
        p: "0 0.5rem",
      }}
    >
      <FlexBetween>
        <Typography variant="h5" color="fontColor.main">
          {title}
        </Typography>
        {/* {enable && (
          <Box
            width="60px"
            height="23px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="4px"
            sx={{ backgroundColor: "others.third" }}
          >
            <Typography>فعال</Typography>
          </Box>
        )} */}
      </FlexBetween>
      <Stack direction="row" alignItems="center" gap="10px">
        <Typography variant="h2" color="fontColor.main">
          {/* {price} */}
        </Typography>
        <Typography variant="subHeading" color="fontColor.main">
          {/* {unit} */}
          لطفا تماس بگیرید
        </Typography>
      </Stack>
      {/* {mob ? (
        <Box display="flex" gap="10px">
          {exists ? (
            <CustomButton
              // secondary
              height="32px"
              sx={{
                width: "100%",
              }}
              onClick={() => handleremoveFromCart()}
            >
              {t("remove")}
            </CustomButton>
          ) : (
            <CustomButton
              height="32px"
              sx={{
                width: exists ? "80%" : "100%",
              }}
              onClick={() => handleAddToCart(carts)}
            >
              {exists ? t("added") : t("addToCart")}
            </CustomButton>
          )}
        </Box>
      ) : (
        <Box display="flex" gap="10px">
          <CustomButton
            height="32px"
            sx={{
              width: exists ? "80%" : "100%",
            }}
            onClick={() => handleAddToCart(carts)}
          >
            {exists ? t("added") : t("addToCart")}
          </CustomButton>
          {exists && (
            <CustomButton
              // secondary
              height="32px"
              sx={{
                width: "20px",
              }}
              onClick={() => handleremoveFromCart()}
            >
              {t("remove")}
            </CustomButton>
          )}
        </Box>
      )} */}
    </Box>
  );
};

export default PackageItem;
