import {
  Box,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Skelton from "components/Skelton/Skelton";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getListFarms } from "Services/Http/http.service";
import { getDateShamsi, getDateShamsiAirSensors } from "Utils/Utils";
import "leaflet/dist/leaflet.css";
import { Search } from "@mui/icons-material";

const AdminFarmList = () => {
  const [mobile, setMobile] = useState("");
  const lang = useSelector((state) => state.global.language);
  const token = useSelector((state) => state.global.token);
  const farmListQuery = useQuery(["", token], () => {
    return getListFarms(token);
  });
  const [selectedFarm, setSelectedFarm] = useState(null);
  const { isLoading, isIdle, isSuccess, data } = farmListQuery;
  console.log("🚀 ~ AdminFarmList ~ farmListQuery:", selectedFarm?.coordinates);
  const simplifiedA = selectedFarm
    ? selectedFarm.coordinates.map((item) => [item.latitude, item.longitude])
    : [];
  console.log("🚀 ~ AdminFarmList ~ simplifiedA:", simplifiedA);
  return selectedFarm ? (
    <Box display="flex" width="100%" height="85%">
      <WidgetWrapper width="60%" height="100%" overflow="auto" m="1rem">
        <FarmFullDataDisplay
          data={selectedFarm}
          setSelectedFarm={setSelectedFarm}
        />
      </WidgetWrapper>
      <Box width="35%" height="100%" overflow={"hidden"} mt="1rem">
        <MapContainer
          bounds={simplifiedA}
          zoom={15.5}
          style={{ borderRadius: "8px", width: "100%", height: "100%" }}
        >
          <Polygon
            pathOptions={{
              color: "yellow",
              stroke: true,
              fillOpacity: 0,
            }}
            positions={simplifiedA}
          />
          {/* <ChangeView center={position} zoom={15.5} /> */}
          <TileLayer
            attribution="PTR"
            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            maxZoom={22}
            maxNativeZoom={18}
          />
        </MapContainer>
      </Box>
    </Box>
  ) : (
    <WidgetWrapper
      width="98%"
      height="83vh"
      m={lang === "Fa" ? "1rem 1rem 0 0" : "1rem 0 0 1rem"}
    >
      <Typography variant="h3" color={"fontColor.main"}>
        لیست تمامی زمین ها
      </Typography>
      {isLoading ? (
        <Skelton />
      ) : (
        <>
          <FlexBetween>
            <Box display="flex" gap="5rem" mt="1rem">
              <FlexBetween gap="1rem">
                <Typography variant="subHeading" color={"fontColor.main"}>
                  تعداد کل زمین ها :
                </Typography>
                <Typography variant="subHeading" color={"fontColor.main"}>
                  {data?.data?.totalCount}
                </Typography>
              </FlexBetween>
              <FlexBetween gap="1rem">
                <Typography variant="subHeading" color={"fontColor.main"}>
                  مساحت کل :
                </Typography>
                <Typography variant="subHeading" color={"fontColor.main"}>
                  {data?.data?.totalLandAreas} هکتار
                </Typography>
              </FlexBetween>
            </Box>
            <FlexBetween
              sx={{
                transition: "all 0.2s ease",
                ml: lang === "Fa" ? "0.5rem" : "",
                mr: lang !== "Fa" ? "0.5rem" : "",
                alignItems: "center",
                border: `1px solid`,
                borderColor: "fontColor.main",
                borderRadius: "8px",
              }}
            >
              <InputBase
                autoComplete="off"
                disableUnderline
                placeholder={lang === "Fa" ? "شماره همراه" : "Search"}
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                id="inputSearchRemote"
                sx={(theme) => ({
                  paddingRight: lang === "Fa" ? "5px" : "",
                  paddingLeft: lang !== "Fa" ? "5px" : "",
                  backgroundColor: "inherit",
                  "& .MuiInputBase-input": {
                    "::placeholder": {
                      color: `${theme.palette.fontColor.main} !important`,
                    },
                  },
                  height: "30px",
                  width: "180px",
                  borderRadius: "8px",
                  color: theme.palette.fontColor.alt,
                  outline: "none",
                  borderBottom: "none",
                })}
              />
            </FlexBetween>
          </FlexBetween>

          <Box width="100%" height="90%" overflow="auto">
            {data?.data?.lands
              .filter((item) => item.isDeleted !== true)
              .filter((i) => i.user !== null)
              .filter((j) => j.user?.mobile.includes(mobile))
              .map((farm) => (
                <WidgetWrapper
                  sx={{
                    backgroundColor: "background.third",
                    m: "0.5rem",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "background.forth",
                    },
                  }}
                  onClick={() => {
                    setSelectedFarm(farm);
                  }}
                >
                  <FarmDataDisplay data={farm} />
                </WidgetWrapper>
              ))}
          </Box>
        </>
      )}
    </WidgetWrapper>
  );
};

const FarmDataDisplay = ({ data }) => {
  // Function to format the key names to be more readable
  const formatKeyName = (key) => {
    switch (key) {
      case "id":
        return "ID زمین ";
      case "createdAt":
        return "تاریخ ساخت";
      case "updatedAt":
        return "تاریخ بروز رسانی";
      case "name":
        return "نام زمین";
      case "productSubGroup":
        return "زیر گروه محصول";
      case "productType":
        return "گروه محصول";
      case "productName":
        return "رقم محصول";
      case "plantingDate":
        return "تاریخ کشت";
      case "specificTypeOfProduct":
        return "نوع محصول خاص";
      case "cultivationDensity":
        return "تراکم کشت";
      case "plantingRowSpacing":
        return "فاصله ردیف های کاشت";
      case "spacingPlanysRow":
        return "فاصله گیاهان یک ردیف";
      case "irrigationType":
        return "نوع آبیاری";
      case "irrigationMethod":
        return "روش آبیاری";
      case "irrigationSystem":
        return "سیستم آبیاری";
      case "saltWaterRequirement":
        return "نیاز آبشوری";
      case "ecIrrigationWater":
        return "EC  آب آبیاری";
      case "poisonName":
        return "نام سم";
      case "pestName":
        return "نام آفت یا بیماری";
      case "pesticideSprayingMethod":
        return "روش سم پاشی";
      case "fertilizerName":
        return "نام کود";
      case "fertilizerSpreadingMethod":
        return "روش کوددهی";
      case "fertilizerQuantity":
        return "مفدار کود";
      case "desc":
        return "توضیحات";
      case "defaultImg":
        return "Default Image";
      case "landArea":
        return "مساحت";
      case "growId":
        return "Grow ID";
      case "growStage":
        return "مرحله رشد";
      case "coordinates":
        return "مختصات";
      case "isDeleted":
        return "Is Deleted";
      case "fullName":
        return "نام صاحب اکانت";
      case "mobile":
        return "شماره همراه";
      default:
        return key;
    }
  };

  return (
    <Box width="100%" height="90%" overflow="auto" padding="1rem">
      <Grid container spacing={2}>
        {/* Display User Full Name and Mobile */}
        {data?.user && (
          <>
            <Grid item xs={12} sm={6}>
              <Box display="flex" gap="1rem">
                <Typography variant="subtitle1">
                  {formatKeyName("fullName")}:
                </Typography>
                <Typography variant="body1">{data.user.fullName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" gap="1rem">
                <Typography variant="subtitle1">
                  {formatKeyName("mobile")}:
                </Typography>
                <Typography variant="body1">{data.user.mobile}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" gap="1rem">
                <Typography variant="subtitle1">
                  {formatKeyName("name")}:
                </Typography>
                <Typography variant="body1">{data.name}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" gap="1rem">
                <Typography variant="subtitle1">
                  {formatKeyName("landArea")}:
                </Typography>
                <Typography variant="body1">{data.landArea}</Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

const FarmFullDataDisplay = ({ data, setSelectedFarm }) => {
  // Function to format the key names to be more readable
  const formatKeyName = (key) => {
    switch (key) {
      case "id":
        return "ID زمین ";
      case "createdAt":
        return "تاریخ ساخت";
      case "updatedAt":
        return "تاریخ بروز رسانی";
      case "name":
        return "نام زمین";
      case "productSubGroup":
        return "زیر گروه محصول";
      case "productType":
        return "گروه محصول";
      case "productName":
        return "رقم محصول";
      case "plantingDate":
        return "تاریخ کشت";
      case "specificTypeOfProduct":
        return "نوع محصول خاص";
      case "cultivationDensity":
        return "تراکم کشت";
      case "plantingRowSpacing":
        return "فاصله ردیف های کاشت";
      case "spacingPlanysRow":
        return "فاصله گیاهان یک ردیف";
      case "irrigationType":
        return "نوع آبیاری";
      case "irrigationMethod":
        return "روش آبیاری";
      case "irrigationSystem":
        return "سیستم آبیاری";
      case "saltWaterRequirement":
        return "نیاز آبشوری";
      case "ecIrrigationWater":
        return "EC  آب آبیاری";
      case "poisonName":
        return "نام سم";
      case "pestName":
        return "نام آفت یا بیماری";
      case "pesticideSprayingMethod":
        return "روش سم پاشی";
      case "fertilizerName":
        return "نام کود";
      case "fertilizerSpreadingMethod":
        return "روش کوددهی";
      case "fertilizerQuantity":
        return "مفدار کود";
      case "desc":
        return "توضیحات";
      case "defaultImg":
        return "Default Image";
      case "landArea":
        return "مساحت";
      case "growId":
        return "Grow ID";
      case "growStage":
        return "مرحله رشد";
      case "coordinates":
        return "مختصات";
      case "isDeleted":
        return "Is Deleted";
      case "fullName":
        return "نام صاحب اکانت";
      case "mobile":
        return "شماره همراه";
      default:
        return key;
    }
  };

  const {
    defaultImg,
    coordinates,
    id,
    isDeleted,
    growId,
    desc,
    user,
    name,
    ...newData
  } = data;

  // Order of keys to display first
  const orderedKeys = [
    "productType",
    "productSubGroup",
    "productName",
    "growStage",
    "cultivationDensity",
  ];

  // Separate ordered and unordered keys
  const remainingKeys = Object.keys(newData).filter(
    (key) => !orderedKeys.includes(key)
  );

  return (
    <Box width="100%" height="100%" overflow="auto" padding="1rem">
      <Box
        width="100%"
        display="flex"
        aignItems="center"
        justifyContent="space-between"
        mb="2rem"
      >
        <Typography variant="h3">{`زمین : ${name}`}</Typography>

        <Box
          width="70px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          height="42px"
          bgcolor="background.third"
          sx={{
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "background.forth",
            },
          }}
          onClick={() => setSelectedFarm(null)}
        >
          <Typography>بازگشت</Typography>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {/* Display ordered keys first */}
        {orderedKeys.map((key) => (
          <Grid item xs={12} sm={6} md={4} key={key}>
            <Typography variant="subtitle1">{formatKeyName(key)}:</Typography>
            <Typography variant="body1">
              {data[key] !== null && data[key] !== ""
                ? data[key].toString()
                : "N/A"}
            </Typography>
          </Grid>
        ))}

        {/* Display remaining keys */}
        {remainingKeys.map((key) => (
          <Grid item xs={12} sm={6} md={4} key={key}>
            <Typography variant="subtitle1">{formatKeyName(key)}:</Typography>
            {key === "coordinates" ? (
              <Box>
                {data[key].map((coord, index) => (
                  <Typography key={index} variant="body2">
                    Latitude: {coord.latitude}, Longitude: {coord.longitude}
                  </Typography>
                ))}
              </Box>
            ) : key === "plantingDate" ||
              key === "createdAt" ||
              key === "updatedAt" ? (
              <Typography variant="body1">
                {/* {new Date(data[key]).toLocaleDateString()} */}
                {getDateShamsi(data[key])}
              </Typography>
            ) : (
              <Typography variant="body1">
                {data[key] !== null && data[key] !== ""
                  ? data[key].toString()
                  : "N/A"}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AdminFarmList;
