import { Box, Divider, Stack, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import PrimaryCheck from "components/PrimaryCheck/PrimaryCheck";
import WidgetWrapper from "components/WidgetWrapper";
import AsanPardakht from "assets/asanPardakht.png";
import SamanBank from "assets/samanBank.png";
import PartoWallet from "assets/partoWallet.png";
import SensorDevice from "assets/hardwares/sensorDevice.svg";
import { KeyboardBackspaceOutlined } from "@mui/icons-material";
import { CustomButton } from "components/styledComponents/common";
import { useSelector } from "react-redux";
import { useState } from "react";

const mockData = [
  {
    name: "مدیریت سنسور ها",
    time: "۶ماهه",
    price: "10.000.000",
    image: SensorDevice,
  },
  {
    name: "سنجش از دور",
    time: "6 ماهه",
    price: "3.500.000",
    image: SensorDevice,
  },
  {
    name: "هواشناسی",
    time: "یک ساله",
    price: "2.200.000",
    image: SensorDevice,
  },
  { name: "سنسور رطوبت خاک", price: "10.000.000", image: SensorDevice },
];
const FinalizeShop = ({ setFinalize }) => {
  const carts = useSelector((state) => state.shopping.cart);
  const [factor, setFactor] = useState(false);
  let sum = 0;

  carts.forEach((item) => {
    sum += parseInt(item.price);
  });
  return (
    <Box width="100%" height="calc(100vh - 110px)" overflow="auto" p="1rem">
      <WidgetWrapper width="100%" minHeight="100%" position="relative">
        <CustomButton
          onClick={() => setFinalize(false)}
          height="32px"
          sx={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            width: "32px",
            minWidth: "32px",
          }}
          secondary
        >
          <KeyboardBackspaceOutlined />
        </CustomButton>
        <Box sx={{ width: "50%", maxWidth: "600px", margin: "1rem auto" }}>
          {/* <Typography variant="h5">روش پرداخت</Typography>
          <Box
            sx={{
              mt: "1.5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              gap: "1rem",
            }}
          >
            <FlexBetween alignItems="center">
              <Box display="flex" alignItems="center" gap="8px">
                <Box component="img" src={SamanBank} />
                <Typography variant="subHeading">درگاه بانک سامان </Typography>
              </Box>
              <PrimaryCheck checked={false} />
            </FlexBetween>
            <FlexBetween alignItems="center">
              <Box display="flex" alignItems="center" gap="8px">
                <Box component="img" src={AsanPardakht} />
                <Typography variant="subHeading">درگاه آسان پرداخت </Typography>
              </Box>
              <PrimaryCheck checked={true} />
            </FlexBetween>
            <FlexBetween alignItems="center">
              <Box display="flex" alignItems="center" gap="8px">
                <Box component="img" src={PartoWallet} />
                <Typography variant="subHeading">کیف پول رهبان </Typography>
              </Box>
              <PrimaryCheck checked={false} />
            </FlexBetween>
          </Box>
          <Divider width="100%" sx={{ my: "1.5rem" }} /> */}
          <Typography variant="h5">جزئیات پرداخت</Typography>
          <Box
            sx={{
              mt: "1.5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              gap: "1rem",
            }}
          >
            {carts.map((item) => (
              <FlexBetween alignItems="center">
                <Box display="flex" alignItems="center" gap="8px">
                  <Box
                    sx={{
                      borderRadius: "8px",
                      padding: "0.5rem",
                      bgcolor: "background.third",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={SensorDevice}
                      width="24px"
                      height="24px"
                    />
                  </Box>
                  <Typography variant="subHeading">{item.title}</Typography>
                  <Typography
                    variant="smallestTextPrimary"
                    color="fontColor.alt"
                  >
                    {item.timeValue !== null
                      ? `${item.timeValue} ${
                          item.timeUnit === "MONTH" ? "ماهه" : "ساله"
                        }`
                      : ""}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="baseline" gap="4px">
                  <Typography variant="h5">{item.price}</Typography>
                  <Typography
                    variant="smallestTextSecondary"
                    color="fontColor.alt"
                  >
                    تومان
                  </Typography>
                </Box>
              </FlexBetween>
            ))}
          </Box>
          <Box
            sx={{
              mt: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              gap: "1rem",
            }}
          >
            <FlexBetween alignItems="center">
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                جمع کل
              </Typography>
              <Box display="flex" alignItems="baseline" gap="4px">
                <Typography variant="h5">{sum}</Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
                  تومان
                </Typography>
              </Box>
            </FlexBetween>
            <FlexBetween alignItems="center">
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                ارزش افزوده
              </Typography>
              <Box display="flex" alignItems="baseline" gap="4px">
                <Typography variant="h5">10</Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
                  ٪
                </Typography>
              </Box>
            </FlexBetween>
            <FlexBetween alignItems="center">
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                سود شما
              </Typography>
              <Box display="flex" alignItems="baseline" gap="4px">
                <Typography variant="h5">-</Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
                  تومان
                </Typography>
              </Box>
            </FlexBetween>
            <FlexBetween alignItems="center">
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                مبلغ قابل پرداخت
              </Typography>
              <Box display="flex" alignItems="baseline" gap="4px">
                <Typography variant="h5">
                  {Math.floor(sum * 1.1).toLocaleString()}
                </Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
                  تومان
                </Typography>
              </Box>
            </FlexBetween>
          </Box>
          <Divider width="100%" sx={{ my: "1.5rem" }} />
          <FlexBetween alignItems="center" mb="30px">
            <Stack gap="4px">
              <Typography variant="smallestTextPrimary">
                دریافت فاکتور رسمی
              </Typography>
              <Typography variant="extraSmall" color="fontColor.alt">
                این فاکتور از طریق ایمیل برای شما ارسال خواهد شد.
              </Typography>
            </Stack>
            <PrimaryCheck checked={factor} setFactor={setFactor} />
          </FlexBetween>
          <CustomButton
            sx={{ width: "100%" }}
            height="42px"
            onClick={() => console.log("salam***")}
          >
            پرداخت
          </CustomButton>
        </Box>
      </WidgetWrapper>
    </Box>
  );
};

export default FinalizeShop;
