import { createSlice } from "@reduxjs/toolkit";

    const initialState ={
        growth: {},
        recommendations: {
            enabled: true,
            status: "ENABLED",
          },
        remote: {
            enabled: true,
            status: "ENABLED",
            property: {
              id: "54cdec90-0f61-11ee-8e46-bb7ceec80128",
              title: "باغ سیب",
              boundary: {
                type: "Polygon",
                coordinates: [
                  [
                    [35.63756533193985, 52.22486391383863],
                    [35.6368424283126, 52.22690776756859],
                    [35.63835010593118, 52.22765149132153],
                    [35.63893821377864, 52.2258436395649],
                    [35.6387569327276, 52.22568225217225],
                    [35.638470337439, 52.22551259364648],
                    [35.63808414629099, 52.2251848602429],
                    [35.63793108763544, 52.2250641429807],
                    [35.63756533193985, 52.22486391383863],
                  ],
                ],
              },
              imageUrl: null,
              zones: [
                {
                  id: "74d9e70f-42dc-4c65-a1e8-b325043f142a",
                  title: "ناحیه اصلی",
                  boundary: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [35.63756533193985, 52.22486391383863],
                        [35.6368424283126, 52.22690776756859],
                        [35.63835010593118, 52.22765149132153],
                        [35.63893821377864, 52.2258436395649],
                        [35.6387569327276, 52.22568225217225],
                        [35.638470337439, 52.22551259364648],
                        [35.63808414629099, 52.2251848602429],
                        [35.63793108763544, 52.2250641429807],
                        [35.63756533193985, 52.22486391383863],
                      ],
                    ],
                  },
                  productId: 3,
                  productTitle: "سیب",
                },
              ],
              active: false,
            },
            devices: [
              [
                {
                  id: 1,
                  position: [35.646029, 52.223528],
                  title: "آب و هوای یک",
                  zone: "ناحیه یک",
                  zoneId: "1",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "ACTIVE",
                  battery: "HIGH",
                  signal: "LOW",
                  alarms: [
                    {
                      type: "CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "دمای بالا",
                      area: "ناحیه 1",
                      action: "محمد رضا علیخانی",
                      description:
                        "با توجه به افزایش دما در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "temperature",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 25,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 37,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 32,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 38,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 36,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 36,
                        },
                      ],
                    },
                    {
                      type: "solar_radiation",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 43,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 50,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 55,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 48,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 75,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 50,
                        },
                      ],
                    },
                    {
                      type: "humidity",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 30,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 43,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 35,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 40,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 28,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 53,
                        },
                      ],
                    },
                    {
                      type: "precipitation_rain",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 5,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 7,
                        },
                      ],
                    },
                    {
                      type: "atmospheric_pressure",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 5,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 9,
                        },
                      ],
                    },
                    {
                      type: "wind_speed",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 10,
                        },
                      ],
                    },
                    {
                      type: "shabnam",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 10,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 2,
                  position: [35.645329, 52.226028],
                  title: "آب و هوای دو",
                  zone: "ناحیه دو",
                  zoneId: "2",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "ACTIVE",
                  battery: "HIGH",
                  signal: "LOWEST",
                  alarms: [
                    {
                      type: "CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "دمای بسیار بالا",
                      area: "ناحیه 2",
                      action: "علی عباسی",
                      description:
                        "با توجه به افزایش دما در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "temperature",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 20,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 27,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 22,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 28,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 26,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 26,
                        },
                      ],
                    },
                    {
                      type: "solar_radiation",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 53,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 60,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 65,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 58,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 65,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 60,
                        },
                      ],
                    },
                    {
                      type: "humidity",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 40,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 53,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 55,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 50,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 38,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 63,
                        },
                      ],
                    },
                    {
                      type: "precipitation_rain",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 5,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 3,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 8,
                        },
                      ],
                    },
                    {
                      type: "atmospheric_pressure",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 5,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 9,
                        },
                      ],
                    },
                    {
                      type: "wind_speed",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 13,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 10,
                        },
                      ],
                    },
                    {
                      type: "shabnam",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 10,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 3,
                  position: [35.639329, 52.220028],
                  title: "آب و هوای سه",
                  zone: "ناحیه سه",
                  zoneId: "3",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "NOTACTIVE",
                  battery: "LOWEST",
                  signal: "LOWEST",
                  alarms: [
                    {
                      type: "NOT CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "دمای متوسط",
                      area: "ناحیه 3",
                      action: "حامد صمیمی",
                      description:
                        "با توجه به افزایش دما در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "temperature",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 20,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 27,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 32,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 35,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 25,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 25,
                        },
                      ],
                    },
                    {
                      type: "solar_radiation",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 48,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 52,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 50,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 42,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 60,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 50,
                        },
                      ],
                    },
                    {
                      type: "humidity",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 35,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 45,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 30,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 32,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 22,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 43,
                        },
                      ],
                    },
                    {
                      type: "precipitation_rain",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 8,
                        },
                      ],
                    },
                    {
                      type: "atmospheric_pressure",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 5,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 5,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 7,
                        },
                      ],
                    },
                    {
                      type: "wind_speed",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 14,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 13,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 19,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 20,
                        },
                      ],
                    },
                    {
                      type: "shabnam",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 19,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 10,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 4,
                  position: [35.641729, 52.221528],
                  title: "آب و هوای چهار",
                  zone: "ناحیه سه",
                  zoneId: "3",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "ACTIVE",
                  battery: "HIGH",
                  signal: "HIGH",
                  alarms: [
                    {
                      type: "CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "رطوبت بالا",
                      area: "ناحیه 3",
                      action: "علی عباسی",
                      description:
                        "با توجه به افزایش رطوبت در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "temperature",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 30,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 35,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 37,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 33,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 38,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 35,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 36,
                        },
                      ],
                    },
                    {
                      type: "solar_radiation",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 33,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 40,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 45,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 38,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 65,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 60,
                        },
                      ],
                    },
                    {
                      type: "humidity",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 45,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 40,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 45,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 50,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 58,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 53,
                        },
                      ],
                    },
                    {
                      type: "precipitation_rain",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 5,
                        },
                      ],
                    },
                    {
                      type: "atmospheric_pressure",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 5,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-04T22:09:32.208Z",
                          v: 10,
                        },
                      ],
                    },
                    {
                      type: "wind_speed",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 15,
                        },
                      ],
                    },
                    {
                      type: "shabnam",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 12,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 5,
                  position: [35.645329, 52.226028],
                  title: "خاک یک",
                  zone: "ناحیه یک",
                  zoneId: "1",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "ACTIVE",
                  battery: "HIGH",
                  signal: "HIGH",
                  alarms: [
                    {
                      type: "CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "دمای بسیار بالا",
                      area: "ناحیه 1",
                      action: "حامد صمیمی",
                      description:
                        "با توجه به افزایش دمای خاک در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "temperature",
                      data30: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 20,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 25,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 18,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 22,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 29,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 32,
                        },
                      ],
                      data60: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 8,
                        },
                      ],
                      data90: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 7,
                        },
                      ],
                    },
                    {
                      type: "humidity",
                      data30: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 7,
                        },
                      ],
                      data60: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 25,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 23,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 21,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 35,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 40,
                        },
                      ],
                      data90: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 5,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 6,
                  position: [35.639329, 52.220028],
                  title: "خاک دو",
                  zone: "ناحیه دو",
                  zoneId: "2",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "ACTIVE",
                  battery: "LOW",
                  signal: "LOW",
                  alarms: [
                    {
                      type: "NOT CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "دمای متوسط",
                      area: "ناحیه 2",
                      action: "محمد رضا علیخانی",
                      description:
                        "با توجه به افزایش دما در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "temperature",
                      data30: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 30,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 35,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 37,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 25,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 19,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 22,
                        },
                      ],
                      data60: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 18,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 13,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 10,
                        },
                      ],
                      data90: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 7,
                        },
                      ],
                    },
                    {
                      type: "humidity",
                      data30: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 22,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 30,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 26,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 23,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 17,
                        },
                      ],
                      data60: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 25,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 23,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 21,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 35,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 40,
                        },
                      ],
                      data90: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 7,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 5,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 7,
                  position: [35.643729, 52.222528],
                  title: "خاک سه",
                  zone: "ناحیه سه",
                  zoneId: "3",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "NOTACTIVE",
                  battery: "LOWEST",
                  signal: "LOWEST",
                  alarms: [
                    {
                      type: "NOT CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "بارندگی متوسط",
                      area: "ناحیه 3",
                      action: "حامد صمیمی",
                      description:
                        "با توجه به افزایش دما در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "temperature",
                      data30: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 30,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 20,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 18,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 24,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 22,
                        },
                      ],
                      data60: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 18,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 10,
                        },
                      ],
                      data90: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 7,
                        },
                      ],
                    },
                    {
                      type: "humidity",
                      data30: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 8,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 13,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 8,
                        },
                      ],
                      data60: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 13,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 25,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 20,
                        },
                      ],
                      data90: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 17,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 18,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 15,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 18,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 15,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 8,
                  position: [35.644729, 52.222528],
                  title: "گیاه یک",
                  zone: "ناحیه یک",
                  zoneId: "1",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "ACTIVE",
                  battery: "HIGH",
                  signal: "LOW",
                  alarms: [
                    {
                      type: "CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "رطوبت بسیار بالا",
                      area: "ناحیه 1",
                      action: "حامد صمیمی",
                      description:
                        "با توجه به افزایش رطوبت خاک در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "fruit_diameter",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 3,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 3,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 3.5,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 3.5,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 3.5,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 3.5,
                        },
                      ],
                    },
                    {
                      type: "stem_diameter",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 3,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 3,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 4,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 4,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 5,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 5,
                        },
                      ],
                    },
                    {
                      type: "leaf_humidity",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 7,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 9,
                  position: [35.642729, 52.224528],
                  title: "گیاه دو",
                  zone: "ناحیه دو",
                  zoneId: "2",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "ACTIVE",
                  battery: "HIGH",
                  signal: "HIGH",
                  alarms: [
                    {
                      type: "CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "رطوبت 60متر بالا",
                      area: "ناحیه 2",
                      action: "محمد رضا علیخانی",
                      description:
                        "با توجه به افزایش دمای خاک در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "fruit_diameter",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 11.5,
                        },
                      ],
                    },
                    {
                      type: "stem_diameter",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 3.5,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 3.5,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 4.5,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 4.5,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 5.5,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 5.5,
                        },
                      ],
                    },
                    {
                      type: "leaf_humidity",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 14,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 18,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 15,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 10,
                  position: [35.641729, 52.221528],
                  title: "گیاه سه",
                  zone: "ناحیه دو",
                  zoneId: "2",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "ACTIVE",
                  battery: "HIGH",
                  signal: "LOW",
                  alarms: [
                    {
                      type: "CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "دمای  بالا",
                      area: "ناحیه 2",
                      action: "محمد رضا علیخانی",
                      description:
                        "با توجه به افزایش دمای خاک در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "fruit_diameter",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 3,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 3,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 3.5,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 3.5,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 3.5,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 3.5,
                        },
                      ],
                    },
                    {
                      type: "stem_diameter",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 3,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 3,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 4,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 4,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 5,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 5,
                        },
                      ],
                    },
                    {
                      type: "leaf_humidity",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 13,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 11,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 12,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 11,
                  position: [35.645729, 52.225528],
                  title: "گیاه چهار",
                  zone: "ناحیه سه",
                  zoneId: "3",
                  lastTimeUpdate: "1402/05/09 - 11:23",
                  status: "ACTIVE",
                  battery: "LOW",
                  signal: "LOW",
                  alarms: [
                    {
                      type: "CIRTICAL",
                      sendDate: "1402/05/23",
                      sendTime: "22:30",
                      alarm: "دمای 90 متر بالا",
                      area: "ناحیه 3",
                      action: "حامد صمیمی",
                      description:
                        "با توجه به افزایش دمای خاک  90 متر در روزهای آبنده خطر از بین رفتن میوه های تمام ناحیه ها وجود دارد.-",
                      notification: "پیامک",
                      alt: "اتوماتیک توسط ماشین مربوطه انجام شود.",
                    },
                  ],
                  data: [
                    {
                      type: "wind_speed",
                      data: [
                        {
                          ts: "2023-07-29T22:09:32.208Z",
                          v: 12,
                        },
                        {
                          ts: "2023-07-30T22:09:32.208Z",
                          v: 10,
                        },
                        {
                          ts: "2023-07-31T22:09:32.208Z",
                          v: 16,
                        },
                        {
                          ts: "2023-08-01T22:09:32.208Z",
                          v: 6,
                        },
                        {
                          ts: "2023-08-02T22:09:32.208Z",
                          v: 9,
                        },
                        {
                          ts: "2023-08-03T22:09:32.208Z",
                          v: 7,
                        },
                      ],
                    },
                  ],
                },
              ],
            ],
          },
        staffs:  {
            enabled: true,
            status: "ENABLED",
          },
        weather: {
            enabled: true,
            status: "ENABLED",
            days: [
              {
                dateTime: "2023-07-10T12:00:00",
                epochDateTime: 1688974200000,
                values: {
                  maxTemperature: "22.97",
                  condition: "CLEAR",
                  minTemperature: "22.97",
                  temperature: "22.97",
                },
                children: null,
              },
              {
                dateTime: "2023-07-11T12:00:00",
                epochDateTime: 1689060600000,
                values: {
                  maxTemperature: "24.05",
                  condition: "CLEAR",
                  minTemperature: "24.05",
                  temperature: "24.05",
                },
                children: null,
              },
              {
                dateTime: "2023-07-12T12:00:00",
                epochDateTime: 1689147000000,
                values: {
                  maxTemperature: "30.44",
                  condition: "CLEAR",
                  minTemperature: "20.59",
                  temperature: "26.1",
                },
                children: null,
              },
              {
                dateTime: "2023-07-13T12:00:00",
                epochDateTime: 1689233400000,
                values: {
                  maxTemperature: "29.72",
                  condition: "CLEAR",
                  minTemperature: "21.25",
                  temperature: "25.67125",
                },
                children: null,
              },
              {
                dateTime: "2023-07-14T12:00:00",
                epochDateTime: 1689319800000,
                values: {
                  maxTemperature: "29.88",
                  condition: "CLEAR",
                  minTemperature: "21.74",
                  temperature: "25.96125",
                },
                children: null,
              },
              {
                dateTime: "2023-07-15T12:00:00",
                epochDateTime: 1689406200000,
                values: {
                  maxTemperature: "31.46",
                  condition: "CLEAR",
                  minTemperature: "22.4",
                  temperature: "27.305",
                },
                children: null,
              },
              {
                dateTime: "2023-07-16T12:00:00",
                epochDateTime: 1689492600000,
                values: {
                  maxTemperature: "31.41",
                  condition: "CLOUDS",
                  minTemperature: "21.56",
                  temperature: "26.16",
                },
                children: null,
              },
            ],
          },
        index: {
            enabled: true,
            status: "ENABLED",
          },
        actuators:  {
            enabled: true,
            status: "ENABLED",
          },
        alerts: {
            enabled: true,
            status: "ENABLED",
            alarmId: null,
            typeName: "دمای متوسط",
            zoneId: null,
            zoneTitle: null,
            severity: null,
            alarmStatus: null,
            created: null,
          },
        sensors: {
            enabled: true,
            status: "ENABLED",
            types: {
              WEATHER: {
                temperature: {
                  ts: 1688180775998,
                  v: 14.8,
                },
                HUMIDITY: {
                  ts: 1688180775998,
                  v: 32,
                },
                condition: null,
              },
              PLANT: {
                HUMIDITY: {
                  ts: 1688180775998,
                  v: 32,
                },
              },
              SOIL: {
                temperature: {
                  ts: 1688187265342,
                  v: 21,
                },
                HUMIDITY: {
                  ts: 1688180775998,
                  v: 35,
                },
                temperature_6: {
                  ts: 1688187265342,
                  v: 24,
                },
                humidity_6: {
                  ts: 1688180693404,
                  v: 91,
                },
              },
              WATER: {
                temperature: {
                  ts: 1688187265342,
                  v: 33,
                },
                HUMIDITY: {
                  ts: 1688180775998,
                  v: 35,
                },
                temperature_6: {
                  ts: 1688187265342,
                  v: 24,
                },
                humidity_6: {
                  ts: 1688180693404,
                  v: 91,
                },
              },
            },
          },
        cultivation: {
            enabled: true,
            status: "ENABLED",
          },
        property:[
            {
              id: "54cdec90-0f61-11ee-8e46-bb7ceec80128",
              title: "باغ سیب",
              boundary: {
                type: "Polygon",
                coordinates: [
                  [
                    [35.63756533193985, 52.22486391383863],
                    [35.6368424283126, 52.22690776756859],
                    [35.63835010593118, 52.22765149132153],
                    [35.63893821377864, 52.2258436395649],
                    [35.6387569327276, 52.22568225217225],
                    [35.638470337439, 52.22551259364648],
                    [35.63808414629099, 52.2251848602429],
                    [35.63793108763544, 52.2250641429807],
                    [35.63756533193985, 52.22486391383863],
                  ],
                ],
              },
              imageUrl:
                "https://api.rahbaan.ir/v1/thumbnails/property/54cdec90-0f61-11ee-8e46-bb7ceec80128/boundary",
              zones: null,
              active: true,
            },
            {
              id: "60871060-0f62-11ee-8e46-bb7ceec80128",
              title: "باغ پسته جنوبی",
              boundary: {
                type: "Polygon",
                coordinates: [
                  [
                    [33.38012619908079, 51.9445211079983],
                    [33.37973993624166, 51.93912546493698],
                    [33.37966382681244, 51.93909218776705],
                    [33.37757551138356, 51.94199828778238],
                    [33.375580755210585, 51.94493071695955],
                    [33.375402804983224, 51.94514044199858],
                    [33.376085323583325, 51.95512183970139],
                    [33.38494539390462, 51.95420952025264],
                    [33.38483901033882, 51.95277658951402],
                    [33.38467761126389, 51.95279216898007],
                    [33.384567803110926, 51.95137040666074],
                    [33.3847110481773, 51.94990982382162],
                    [33.38497602730012, 51.94843966760979],
                    [33.38547932480949, 51.94696943466351],
                    [33.385540010448636, 51.94684730514158],
                    [33.38533798338954, 51.94399992428515],
                    [33.38012619908079, 51.9445211079983],
                  ],
                ],
              },
              imageUrl:
                "https://api.rahbaan.ir/v1/thumbnails/property/60871060-0f62-11ee-8e46-bb7ceec80128/boundary",
              zones: null,
              active: false,
            },
            {
              id: "01d1f5d0-132c-11ee-8e46-bb7ceec80128",
              title: "باغ پسته شمالی",
              boundary: {
                type: "Polygon",
                coordinates: [
                  [
                    [33.39851278859996, 51.94086206369703],
                    [33.39425232619227, 51.94297129255164],
                    [33.39511731998557, 51.94557024200655],
                    [33.394984936009045, 51.94563819604776],
                    [33.395457362567235, 51.94694597741783],
                    [33.3949544489712, 51.94719804904258],
                    [33.39537144187726, 51.94848780896328],
                    [33.395267688576475, 51.94858841216095],
                    [33.397499526919106, 51.955161622924216],
                    [33.40051421196743, 51.95366138866453],
                    [33.39960059927887, 51.95096255425727],
                    [33.40095774731242, 51.950323961616604],
                    [33.40103964224533, 51.94973052082227],
                    [33.4010471738607, 51.94873434475848],
                    [33.40063681044414, 51.94743555992458],
                    [33.40071339827254, 51.9473916369184],
                    [33.39850563649123, 51.94096630951151],
                    [33.39851278859996, 51.94086206369703],
                  ],
                ],
              },
              imageUrl:
                "https://api.rahbaan.ir/v1/thumbnails/property/01d1f5d0-132c-11ee-8e46-bb7ceec80128/boundary",
              zones: null,
              active: false,
            },
            {
              id: "35f83720-c174-11ed-bc17-6b0808db3eef",
              title: "زمین باوان",
              boundary: {
                type: "Polygon",
                coordinates: [
                  [
                    [35.647550594895634, 52.22331316029803],
                    [35.638096669225014, 52.21837714993323],
                    [35.6371599094184, 52.22010975909988],
                    [35.63762583574791, 52.22089136520344],
                    [35.638194959920924, 52.22134817072747],
                    [35.63848941280419, 52.22177061916994],
                    [35.63864353470959, 52.22254154568463],
                    [35.6388888008636, 52.22295780496065],
                    [35.63944348048854, 52.2235763919431],
                    [35.63961981778828, 52.22416346617884],
                    [35.64338813679935, 52.22637798168365],
                    [35.64613337696015, 52.22809061786306],
                    [35.647550594895634, 52.22331316029803],
                  ],
                ],
              },
              imageUrl:
                "https://api.rahbaan.ir/v1/thumbnails/property/35f83720-c174-11ed-bc17-6b0808db3eef/boundary",
              zones: null,
              active: false,
            },
            {
              id: "0303ee20-c4b4-11ed-8386-752a22809230",
              title: "زمین حصارک",
              boundary: {
                type: "Polygon",
                coordinates: [
                  [
                    [35.67846313927023, 52.09911682438562],
                    [35.67824935582007, 52.10771927377692],
                    [35.680402436253694, 52.10804685134977],
                    [35.68023898230909, 52.11134637651141],
                    [35.68750273734908, 52.11283533938411],
                    [35.6860347767507, 52.10241128584836],
                    [35.682306513591776, 52.10102773801005],
                    [35.67989904439513, 52.09995539427302],
                    [35.67846313927023, 52.09911682438562],
                  ],
                ],
              },
              imageUrl:
                "https://api.rahbaan.ir/v1/thumbnails/property/0303ee20-c4b4-11ed-8386-752a22809230/boundary",
              zones: null,
              active: false,
            },
            {
              id: "fc886260-c4b3-11ed-8386-752a22809230",
              title: "زمین فرخشهر",
              boundary: {
                type: "Polygon",
                coordinates: [
                  [
                    [52.22331316029803, 35.647550594895634],
                    [52.21837714993323, 35.638096669225014],
                    [52.22010975909988, 35.6371599094184],
                    [52.22089136520344, 35.63762583574791],
                    [52.22134817072747, 35.638194959920924],
                    [52.22177061916994, 35.63848941280419],
                    [52.22254154568463, 35.63864353470959],
                    [52.22295780496065, 35.6388888008636],
                    [52.2235763919431, 35.63944348048854],
                    [52.22416346617884, 35.63961981778828],
                    [52.22637798168365, 35.64338813679935],
                    [52.22809061786306, 35.64613337696015],
                    [52.22331316029803, 35.647550594895634],
                  ],
                ],
              },
              imageUrl:
                "https://api.rahbaan.ir/v1/thumbnails/property/fc886260-c4b3-11ed-8386-752a22809230/boundary",
              zones: null,
              active: false,
            },
            {
              id: "076ffad0-c4b4-11ed-8386-752a22809230",
              title: "زمین کرج",
              boundary: {
                type: "Polygon",
                coordinates: [
                  [
                    [35.79487404583823, 50.92053342622462],
                    [35.79567862897422, 50.92685880063602],
                    [35.7980682204572, 50.9263397907363],
                    [35.797239946098465, 50.9200382458553],
                    [35.79487404583823, 50.92053342622462],
                  ],
                ],
              },
              imageUrl:
                "https://api.rahbaan.ir/v1/thumbnails/property/076ffad0-c4b4-11ed-8386-752a22809230/boundary",
              zones: null,
              active: false,
            },
          ]
    };

    export const dashboardSlice = createSlice({
        name:"dashboard",
        initialState,
        reducers:{ 
            setData:(state,action)=>{
                state.growth = action.payload.growth;
                state.recommendations = action.payload.recommendations;
                state.remote = action.payload.remote;
                state.staffs = action.payload.staffs;
                state.weather = action.payload.weather;
                state.index = action.payload.index;
                state.actuators = action.payload.actuators;
                state.alerts = action.payload.alerts;
                state.sensors = action.payload.sensors;
                state.cultivation = action.payload.cultivation;
            },
            setProperty:(state,action)=>{
                state.property= action.payload.property
            }
           
        }
    });

    export const {setData,setProperty}= dashboardSlice.actions;
    export default dashboardSlice.reducer;