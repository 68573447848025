import moment from "jalali-moment";
import jalaali from "jalaali-js";
import momentjs from "moment";
import DisplayToast from "components/DisplayToast/DisplayToast";
import toast from "react-hot-toast";
import { indexLegend, MOBILE_WIDTH } from "constants/constant";
import { useMediaQuery } from "@mui/material";
export const getMonthDay = (dd) => {
  const date = momentjs(dd);
  const d = new Date(date);
  return new Intl.DateTimeFormat("fa-IR", {
    month: "2-digit",
    day: "2-digit",
  }).format(d);
};

export const getMonthDayP1 = (dd) => {
  const date = momentjs(dd);
  momentjs.locale("fa");
  const d = new Date(date);
  return new Intl.DateTimeFormat("fa-IR", {
    month: "2-digit",
    day: "2-digit",
  }).format(d);
};
export const getMonthDayEn = (dd) => {
  const date = momentjs(dd);
  const d = new Date(date);
  return new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
  }).format(d);
};
export const getYearMonthDayEn = (dd) => {
  const date = momentjs(dd);
  const d = new Date(date);
  return new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(d);
};
export const getDayName = (dd) => {
  const date1 = momentjs(dd);
  momentjs.locale("fa"); // set the locale to Persian (Farsi)
  return date1.format("dddd");
};

export const getDayNameEn = (dd) => {
  const date1 = momentjs(dd);
  momentjs.locale("en-US"); // set the locale to Persian (Farsi)
  return date1.format("dddd");
};
export const getMonthDayT = (dd) => {
  const myDate = new Date(dd);
  const myDateString = myDate.toISOString();
  const date = moment(myDateString);
  const year = date.jYear();
  const month = date.jMonth();
  const day = date.jDate();
  const d = new Date(year, month, day);
  return new Intl.DateTimeFormat("fa-IR", {
    month: "2-digit",
    day: "2-digit",
  }).format(d);
};

export const getTimeT = (dd) => {
  const date = moment(dd);

  return date.format("HH:mm");
};
export const getTimeTL = (dd) => {
  const date = moment(dd);
  date.subtract(3, "hours");
  date.subtract(30, "minutes");
  return date.format("HH:mm");
};
export const getDateShamsi = (date1) => {
  const date = momentjs(date1);
  const d = new Date(date);
  return new Intl.DateTimeFormat("fa-IR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(d);
};
export const getDateEn = (date1) => {
  const date = momentjs(date1);
  const d = new Date(date);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(d);
};
export const getTimeShamsi = (date1) => {
  const date = momentjs(date1);
  const d = new Date(date);
  return new Intl.DateTimeFormat("fa-IR", {
    hour: "2-digit",
    minute: "2-digit",
  }).format(d);
};
export const getTimeEn = (date1) => {
  const date = momentjs(date1);
  const d = new Date(date);
  return new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  }).format(d);
};

export const getDateShamsiAirSensors = (date1) => {
  const myDate = new Date(date1);
  const myDateString = myDate.toISOString();
  const date = moment(myDateString);
  return new Intl.DateTimeFormat("fa-IR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
};
export const getDateEnAirSensors = (date1) => {
  const myDate = new Date(date1);
  const myDateString = myDate.toISOString();
  const date = moment(myDateString);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
};

export const getTimeShamsiAirSensors = (date1) => {
  const myDate = new Date(date1);
  // const getCurrentPersianDate = () => {
  //   const now = moment(); // get the current date and time
  //   const persianDate = now.locale("fa"); // set the locale to Persian
  //   const formattedDate = persianDate.format("dddd jYYYY/jM/jD - HH:mm"); // format the date and time string
  //   return formattedDate;
  // };
  const myDateString = myDate.toISOString();
  const date = moment(myDateString);
  const year = date.jYear();
  const month = date.jMonth();
  const day = date.jDate();
  const d = new Date(year, month, day);
  return new Intl.DateTimeFormat("fa-IR", {
    hour: "2-digit",
    minute: "2-digit",
  }).format(d);
};

export const shamsiToGregorian = (shamsiDate) => {
  const [shamsiYear, shamsiMonth, shamsiDay] = shamsiDate
    .split("-")
    .map(Number);
  const { gy, gm, gd } = jalaali.toGregorian(
    shamsiYear,
    shamsiMonth,
    shamsiDay
  );
  return `${gy}-${gm.toString().padStart(2, "0")}-${gd
    .toString()
    .padStart(2, "0")}`;
};

export const debounce = (fn, time = 100) => {
  let timer = null;
  return (evt) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(evt), time);
  };
};

export const throttle = (cb, delay = 1000) => {
  let shouldWait = false;

  return (...args) => {
    if (shouldWait) return;

    cb(...args);
    shouldWait = true;
    setTimeout(() => {
      shouldWait = false;
    }, delay);
  };
};

export const getCurrentPersianDate = () => {
  const now = moment(); // get the current date and time
  const persianDate = now.locale("fa"); // set the locale to Persian
  const formattedDate = persianDate.format("jYYYY/jM/jD - HH:mm"); // format the date and time string
  return formattedDate;
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setTime(result.getTime() + days * 24 * 60 * 60 * 1000);
  return result;
};
export const subDays = (date, days) => {
  var result = new Date(date);
  result.setTime(result.getTime() - days * 24 * 60 * 60 * 1000);
  return result;
};
export const scrollClick = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollTo({ top: 0, behavior: "smooth" });
  }
};

export const showToast = (type, message) => {
  return toast.custom(<DisplayToast type={type} message={message} />);
};

export const addHoursAndMinutesToDateTime = (
  dateTimeString,
  hoursToAdd,
  minutesToAdd
) => {
  const originalDateTime = new Date(dateTimeString);
  const newDateTime = new Date(
    originalDateTime.getTime() +
      hoursToAdd * 60 * 60 * 1000 +
      minutesToAdd * 60 * 1000
  );
  return newDateTime.toISOString(); // Returns the updated date in ISO string format
};

export const subtractTime = (
  inputTime,
  hoursToSubtract = 3,
  minutesToSubtract = 30
) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = inputTime.split(":").map(Number);

  // Create a new Date object and set the time
  const currentTime = new Date();
  currentTime.setHours(hours);
  currentTime.setMinutes(minutes);

  // Subtract hours and minutes
  currentTime.setHours(currentTime.getHours() - hoursToSubtract);
  currentTime.setMinutes(currentTime.getMinutes() - minutesToSubtract);

  // Get the updated hours and minutes
  const updatedHours = currentTime.getHours();
  const updatedMinutes = currentTime.getMinutes();

  // Format the updated time
  const updatedTime = `${updatedHours
    .toString()
    .padStart(2, "0")}:${updatedMinutes.toString().padStart(2, "0")}`;

  return updatedTime;
};

export const subtractOneDay = (dateStr) => {
  // Create a Date object from the given date string
  const currentDate = new Date(dateStr);

  // Subtract one day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const oneDayInMillis = 24 * 60 * 60 * 1000;
  const newDate = new Date(currentDate.getTime() - oneDayInMillis);

  // Get the year, month, and day from the new date
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so add 1
  const day = String(newDate.getDate()).padStart(2, "0");

  // Form the new date string in YYYY-MM-DD format
  const newDateString = `${year}-${month}-${day}`;

  return newDateString;
};

export const compareTimes = (timeA, timeB) => {
  // Split time strings into hours and minutes
  const [hoursA, minutesA] = timeA.split(":").map(Number);
  const [hoursB, minutesB] = timeB.split(":").map(Number);

  // Create Date objects for comparison
  const dateA = new Date(0, 0, 0, hoursA, minutesA);
  const dateB = new Date(0, 0, 0, hoursB, minutesB);

  // Compare timeA with timeB
  return dateA < dateB;
};

export function translateToPersian(englishText, lang) {
  const translationDict = {
    weather: lang === "Fa" ? "هواشناسی" : "Weather",
    alarms: lang === "Fa" ? "هشدارها" : "Alarms",
    remoteSensing: lang === "Fa" ? "سنجش از راه دور" : "Remote Sensing",
    operators: lang === "Fa" ? "عملگرها" : "Actuators",
    staffManagement: lang === "Fa" ? "مدیریت کارکنان" : "Staff Management",
    sensorManagement: lang === "Fa" ? "مدیریت سنسور ها" : "Sensor Management",
    smartIndex: lang === "Fa" ? "شاخص هوشمند" : "Smart Index",
    plantGrowthStages: lang === "Fa" ? "مراحل رشد گیاه" : "Growth Stages",
    recommendations: lang === "Fa" ? "توصیه ها" : "Recommends",
    messenger: lang === "Fa" ? "پیام رسان" : "Messenger",
  };

  return translationDict[englishText] || "Translation not found";
}
export function convertToToman(number) {
  return number / 10;
}

export const expertFieldTitle = (field, lang) => {
  if (lang === "Fa") {
    switch (field) {
      case "IRRIGATION":
        return "کارشناس آبیاری";
      case "SOIL_FERTILITY":
        return "کارشناس خاک و حاصلخیزی";
      case "POISON_PEST":
        return "کارشناس سم و آفات";
      case "PLANT":
        return "کارشناس گیاه";
      default:
        return "کارشناس ها";
    }
  } else if (lang === "En") {
    switch (field) {
      case "IRRIGATION":
        return "Irrigation Expert";
      case "SOIL_FERTILITY":
        return "Soil Fertility Expert";
      case "POISON_PEST":
        return "Poison and Pest Expert";
      case "PLANT":
        return "Plant Expert";
      default:
        return "Experts";
    }
  }
};

export function getDataByType(data, type) {
  if (type === "soil") {
    return {
      TS: data.TS || [],
      HS30: data.HS30 || [],
      HS60: data.HS60 || [],
    };
  } else if (type === "air") {
    return {
      TA: data.TA || [],
      HA: data.HA || [],
    };
  }
  return {};
}

export function getEachImagePixelColor(imageUrl) {
  return new Promise((resolve, reject) => {
    if (imageUrl) {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = imageUrl;
      image.onload = () => {
        try {
          // Create a canvas element and draw the image
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);

          const colors = [];
          const imageData = context.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          ).data;

          for (let y = 0; y < canvas.height; y++) {
            for (let x = 0; x < canvas.width; x++) {
              const index = (y * canvas.width + x) * 4;
              const r = imageData[index];
              const g = imageData[index + 1];
              const b = imageData[index + 2];
              const a = imageData[index + 3];

              // Ignore transparent pixels
              if (a !== 0) {
                const hex = `#${((1 << 24) + (r << 16) + (g << 8) + b)
                  .toString(16)
                  .slice(1)
                  .toUpperCase()}`;
                colors.push({ x, y, hex });
              }
            }
          }

          resolve(colors);
        } catch (e) {
          reject(e);
        }
      };
      image.onerror = (e) => reject(e);
    }
  });
}

export function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

// Function to convert RGB back to hex color code
export function rgbToHex(r, g, b) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
}

// Function to calculate the mean color
export function meanColor(colors) {
  let totalR = 0;
  let totalG = 0;
  let totalB = 0;

  colors.forEach((hex) => {
    const { r, g, b } = hexToRgb(hex);
    totalR += r;
    totalG += g;
    totalB += b;
  });

  const count = colors.length;
  const meanR = Math.round(totalR / count);
  const meanG = Math.round(totalG / count);
  const meanB = Math.round(totalB / count);

  return rgbToHex(meanR, meanG, meanB);
}

export function rgbToXyz({ r, g, b }) {
  r = r / 255;
  g = g / 255;
  b = b / 255;

  r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
  g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
  b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

  r *= 100;
  g *= 100;
  b *= 100;

  return {
    x: r * 0.4124 + g * 0.3576 + b * 0.1805,
    y: r * 0.2126 + g * 0.7152 + b * 0.0722,
    z: r * 0.0193 + g * 0.1192 + b * 0.9505,
  };
}

// Convert XYZ to LAB
export function xyzToLab({ x, y, z }) {
  x /= 95.047;
  y /= 100.0;
  z /= 108.883;

  x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
  y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
  z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;

  return {
    l: 116 * y - 16,
    a: 500 * (x - y),
    b: 200 * (y - z),
  };
}

export function hexToLab(hex) {
  const rgb = hexToRgb(hex);
  const xyz = rgbToXyz(rgb);
  return xyzToLab(xyz);
}

// Calculate Delta-E 2000
export function deltaE2000(lab1, lab2) {
  const deltaL = lab1.l - lab2.l;
  const lAvg = (lab1.l + lab2.l) / 2;
  const c1 = Math.sqrt(lab1.a * lab1.a + lab1.b * lab1.b);
  const c2 = Math.sqrt(lab2.a * lab2.a + lab2.b * lab2.b);
  const cAvg = (c1 + c2) / 2;
  const g =
    0.5 *
    (1 - Math.sqrt(Math.pow(cAvg, 7) / (Math.pow(cAvg, 7) + Math.pow(25, 7))));
  const a1Prime = lab1.a * (1 + g);
  const a2Prime = lab2.a * (1 + g);
  const c1Prime = Math.sqrt(a1Prime * a1Prime + lab1.b * lab1.b);
  const c2Prime = Math.sqrt(a2Prime * a2Prime + lab2.b * lab2.b);
  const deltaCPrime = c1Prime - c2Prime;
  const h1Prime = Math.atan2(lab1.b, a1Prime);
  const h2Prime = Math.atan2(lab2.b, a2Prime);
  const deltaHPrime =
    2 * Math.sqrt(c1Prime * c2Prime) * Math.sin((h2Prime - h1Prime) / 2);
  const lAvgPrime = (lab1.l + lab2.l) / 2;
  const cAvgPrime = (c1Prime + c2Prime) / 2;
  const hAvgPrime = (h1Prime + h2Prime) / 2;

  const t =
    1 -
    0.17 * Math.cos(hAvgPrime - Math.PI / 6) +
    0.24 * Math.cos(2 * hAvgPrime) +
    0.32 * Math.cos(3 * hAvgPrime + Math.PI / 30) -
    0.2 * Math.cos(4 * hAvgPrime - (Math.PI * 63) / 180);

  const deltaTheta =
    ((Math.PI * 30) / 180) *
    Math.exp(
      -Math.pow((hAvgPrime - (Math.PI * 275) / 180) / ((Math.PI * 25) / 180), 2)
    );

  const rC =
    2 *
    Math.sqrt(
      Math.pow(cAvgPrime, 7) / (Math.pow(cAvgPrime, 7) + Math.pow(25, 7))
    );
  const sL =
    1 +
    (0.015 * Math.pow(lAvgPrime - 50, 2)) /
      Math.sqrt(20 + Math.pow(lAvgPrime - 50, 2));
  const sC = 1 + 0.045 * cAvgPrime;
  const sH = 1 + 0.015 * cAvgPrime * t;
  const rT = -Math.sin(2 * deltaTheta) * rC;

  return Math.sqrt(
    Math.pow(deltaL / sL, 2) +
      Math.pow(deltaCPrime / sC, 2) +
      Math.pow(deltaHPrime / sH, 2) +
      rT * (deltaCPrime / sC) * (deltaHPrime / sH)
  );
}

export const colorDistance = (color1, color2) => {
  return Math.sqrt(
    Math.pow(color1.r - color2.r, 2) +
      Math.pow(color1.g - color2.g, 2) +
      Math.pow(color1.b - color2.b, 2)
  );
};
export const mapColorsToLegend = (colors) => {
  const codesToRemove = [
    // "#eaf7ac",
    // "#fdfec2",
    // "#ffefab",
    "#ffe093",
    "#ffc67d",
    "#ffab69",
    "#ff8d5a",
    "#fe6c4a",
    "#ef4c3a",
    "#e02d2c",
    "#c5142a",
    "#ad0028",
    "#ffffff",
  ];

  return colors
    .map((color) => {
      const targetLab = hexToLab(color); // Convert current color to LAB
      let closestLegend = indexLegend[0];
      let minDistance = deltaE2000(targetLab, hexToLab(closestLegend.code)); // Start with the first legend

      indexLegend.forEach((legend) => {
        const legendLab = hexToLab(legend.code); // Convert each legend color to LAB
        const distance = deltaE2000(targetLab, legendLab); // Calculate the distance

        if (distance < minDistance) {
          closestLegend = legend;
          minDistance = distance;
        }
      });

      return {
        color,
        closestLegend,
      };
    })
    .filter(({ closestLegend }) => !codesToRemove.includes(closestLegend.code)); // Filter out unwanted legend codes
};

export const groupByLegend = (mappedColors) => {
  return mappedColors.reduce((acc, { color, closestLegend }) => {
    const key = closestLegend.code;
    if (!acc[key]) {
      acc[key] = {
        legend: closestLegend,
        colors: [],
      };
    }
    acc[key].colors.push(color);
    return acc;
  }, {});
};

export const getColorDiversity = (mappedColors) => {
  if (mappedColors.length < 2) return 0; // Not enough colors to measure diversity

  const distances = [];
  for (let i = 0; i < mappedColors.length; i++) {
    for (let j = i + 1; j < mappedColors.length; j++) {
      const color1 = hexToRgb(mappedColors[i].color);
      const color2 = hexToRgb(mappedColors[j].color);
      console.log(
        `Comparing ${mappedColors[i].color} and ${mappedColors[j].color}`
      );
      distances.push(colorDistance(color1, color2));
    }
  }

  // Calculate the mean of distances
  const meanDistance = distances.reduce((a, b) => a + b, 0) / distances.length;
  console.log(`Mean Distance: ${meanDistance}`);

  // Calculate the variance
  const variance =
    distances.reduce(
      (sum, distance) => sum + Math.pow(distance - meanDistance, 2),
      0
    ) / distances.length;
  console.log(`Variance: ${variance}`);

  // Calculate the standard deviation
  const stdDev = Math.sqrt(variance);

  return stdDev;
};

export const extractNDVI = (dataArray) => {
  return dataArray.map((item) => item.closestLegend.percent / 100); // Dividing by 100 if necessary
};

export const calculateUniformity = (ndviArray) => {
  const meanNDVI =
    ndviArray.reduce((sum, ndvi) => sum + ndvi, 0) / ndviArray.length;
  const variance =
    ndviArray.reduce((sum, ndvi) => sum + Math.pow(ndvi - meanNDVI, 2), 0) /
    ndviArray.length;
  const standardDeviation = Math.sqrt(variance);

  return standardDeviation.toFixed(3); // Lower value means more uniform
};
export const calculateVigor = (ndviArray) => {
  const totalNDVI = ndviArray.reduce((sum, ndvi) => sum + ndvi, 0);
  const meanNDVI = totalNDVI / ndviArray.length;

  return (meanNDVI * 100).toFixed(2);
};
