import {
  Backdrop,
  Badge,
  Box,
  IconButton,
  Modal,
  Slide,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import SoftWareStore from "./SoftwareStore";
import ShoppingCartDark from "assets/icon/shopping-cart-dark.svg";
import ShoppingCartLight from "assets/icon/shopping-cart-light.svg";
import { useDispatch, useSelector } from "react-redux";
import HardwareStore from "./HardwareStore";
import ServiceIndividual from "./ServiceIndividual";
import DeviceIndividual from "./DeviceIndividual";
import { CustomButton } from "components/styledComponents/common";
import Transactions from "./Transactions";
import ShoppingCard from "./ShoppingCard";
import FinalizeShop from "./FinalizeShop";
import CopyRight from "widgets/CopyRight";
import { setFinalize, setShoppingCartOpen } from "States/shopping";
import { getPermissions } from "Services/Http/http.service";
import { setUserPermissions } from "States";
import { useTranslation } from "react-i18next";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TabSmall = styled(Tab)(() => ({
  fontSize: "12px",
  backgroundColor: "inherit",
  textTransform: "none",
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "12px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const modalContext = React.createContext();
const { Provider } = modalContext;
export const useStatus = () => React.useContext(modalContext);
const Shopping = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [serviceType, setServiceType] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [transactions, setTransactions] = useState(false);
  // const [finalize, setFinalize] = useState(false);
  const finalize = useSelector((state) => state.shopping.finalize);
  const { mode } = useSelector((state) => state.global);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const shoppingCartOpen = useSelector(
    (state) => state.shopping.shoppingCartOpen
  );
  console.log("🚀 ~ Shopping ~ shoppingCartOpen:", shoppingCartOpen);

  const token = useSelector((state) => state.global.token);
  const gettingPermission = async () => {
    const permissionsResult = getPermissions(token);
    console.log(
      "🚀 ~ gettingPermission ~ permissionsResult:",
      permissionsResult
    );

    dispatch(
      setUserPermissions({
        userPermissions: permissionsResult?.data?.groupPermissions,
      })
    );
  };
  useEffect(() => {
    gettingPermission();
  }, []);

  return (
    <Provider value={{ shoppingCartOpen, setShoppingCartOpen }}>
      {/* <Modal
        open={shoppingCartOpen}
        onClose={() =>
          dispatch(setShoppingCartOpen({ shoppingCartOpen: false }))
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
          },
        }}
      >
        <Slide in={shoppingCartOpen} direction="right">
          <Box
            sx={{
              height: "100%",
              width: "370px",
              position: "absolute",
              left: 0,
              backgroundColor: "background.second",
              borderTopRightRadius: "24px",
              borderBottomRightRadius: "24px",
              // position: "relative",
            }}
          >
            <ShoppingCard setFinalize={setFinalize} />
          </Box>
        </Slide>
      </Modal> */}
      {serviceType ? (
        <ServiceIndividual setServiceType={setServiceType} type={serviceType} />
      ) : deviceType ? (
        <DeviceIndividual setDeviceType={setDeviceType} type={deviceType} />
      ) : transactions ? (
        <Transactions setTransactions={setTransactions} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "calc(100% - 110px)",
            display: "flex",
            alignItems: "stretch",
            flexDirection: "column",
            p: "16px",
            gap: "8px",
            overflow: "auto",
            position: "relative",
          }}
        >
          {/* <CustomButton
            sx={{
              position: "absolute",
              top: "1rem",
              left: "1rem",
              width: "122px",
            }}
            height="27px"
            onClick={() => setTransactions(true)}
          >
            تراکنش ها
          </CustomButton> */}
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            scrollButtons="auto"
            indicatorColor="#1c1e2a"
            sx={(theme) => ({
              borderBottom: "none",
              width: "50%",
              minHeight: "38px",
              // mt: "1rem",
              "& .MuiTab-textColorPrimary": {
                color: `${theme.palette.fontColor.main} !important`,
              },
              "& .MuiTabs-flexContainer": {
                gap: "20px",
              },
              "& .MuiButtonBase-root": {
                padding: "0px 0px !important",
                minHeight: "32px",
                minWidth: "unset",
                borderBottom: "2px solid transparent",
              },
              "& 	.MuiTabs-root": {
                // borderBottom: "red !important",
              },

              "& .Mui-selected": {
                //   backgroundColor: "inherit !important",
                borderBottom: `2px solid ${theme.palette.others.first}`,

                color: "#757578",
              },
            })}
          >
            <TabSmall label={t("software")} {...a11yProps(0)} />
            <TabSmall label={t("hardware")} {...a11yProps(1)} />
          </Tabs>

          <WidgetWrapper width="100%" position="relative">
            <TabPanel value={value} index={0}>
              <SoftWareStore setServiceType={setServiceType} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <HardwareStore setDeviceType={setDeviceType} />
            </TabPanel>
          </WidgetWrapper>
        </Box>
      )}
      <Box mx="1rem">
        <CopyRight />
      </Box>
    </Provider>
  );
};

export default Shopping;
