import { Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import moment from "jalali-moment";
import { versionPack } from "versionPack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const CopyRight = (type) => {
  const getCurrentFormattedDate = (type) => {
    const now = moment(); // get the current date and time
    const formattedDate =
      type === "fa"
        ? now.locale("fa").format("dddd jYYYY/jM/jD HH:mm")
        : now.locale("en").format("dddd M/D/YYYY HH:mm");
    return formattedDate;
  };
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);
  return (
    <WidgetWrapper
      sx={{
        p: "0.5rem 0rem 0.5rem 0rem",
        backgroundColor: "inherit",
      }}
    >
      <FlexBetween>
        {/* <FlexBetween gap="0.3rem">
          <Typography variant="subHeading" color={alt}>
            آخرین اتصال به ابر:
          </Typography>
          <Typography variant="subHeading" color={main}>
            دوشنبه 1401/10/05 - 20:08
          </Typography>
        </FlexBetween> */}
        <Typography variant="subHeading" color={main}>
          {`${t("copyRight")} ${versionPack}`}
        </Typography>

        <FlexBetween gap="0.3rem">
          <Typography variant="subHeading" color={alt}>
            {t("today")}:
          </Typography>
          <Typography variant="subHeading" color={main}>
            {language === "Fa"
              ? getCurrentFormattedDate("fa")
              : getCurrentFormattedDate("en")}
          </Typography>
        </FlexBetween>
      </FlexBetween>
    </WidgetWrapper>
  );
};

export default CopyRight;
