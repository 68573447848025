export const products = [
  {
    name: "غلات",
    firstSubDomain: [
      {
        name: "جو",
        secondSubDomain: [
          {
            name: "جو",
            productItem: [
              "4 شوری",
              "CB 79-10",
              "MB 83-3",
              "UH-12",
              "WB 81-15",
              "آبیدر",
              "آذران",
              "آرتان",
              "آردا",
              "آیدین",
              "ارمغان",
              "افصل",
              "اکسین",
              "انصار",
              "ایذه",
              "بهدان",
              "به رخ",
              "بهمن",
              "جلگه",
              "جنوب",
              "خرم",
              "دشت",
              "راشین",
              "رودشت",
              "ریحان 03",
              "زهک",
              "سرارود1",
              "سهند",
              "شهبد",
              "صحرا",
              "فجر 30",
              "فراز",
              "فردان",
              "قافلان",
              "کویر",
              "گریس",
              "گلچین",
              "گوهران",
              "لوت",
              "ماکوئی",
              "نوبهار",
              "نوروز",
              "واکسیما",
              "والفجر",
              "نادر",
              "نصرت",
              "ویلما",
              "یوسف",
              "سایر",
            ],
          },
        ],
      },
      {
        name: "ذرت دانه ای",
        secondSubDomain: [
          {
            name: "ذرت دانه ای آردی",
            productItem: [
              "AS 66",
              "AS 71",
              "Bc 582",
              "Bc 678",
              "BK 65",
              "DKC 6589",
              "DKC 6876",
              "KSC 301",
              "KSC 302",
              "KSC 500",
              "KSC 700",
              "KSC 703",
              "KSC 705",
              "KSC 706",
              "LG 367",
              "MV 500",
              "NK اگرانو",
              "NK گالاکتیک ",
              "NK ویتورینو",
              "NP 2128",
              "NS 3014",
              "NS 4015",
              "NS 444 Utra",
              "NS 540",
              "NS 610",
              "NS 640",
              "NS 770",
              "OSSK 62",
              "safalz1",
              "zp 434",
              "zp 548",
              "zp 677",
              "zp 684",
              "zp 758",
              "بولسون",
              "تونو",
              "جتا",
              "جوین 1",
              "چاووش",
              "چوکان",
              "دامینیت",
              "دراما",
              "دهقان",
              "زرین 1",
              "زرین 2",
              "زرین 3",
              "زولا",
              "ساگونتو",
              "سی اینو",
              "سیمون",
              "سینگل کراس 301",
              "سینگل کراس 604(زرین)",
              "سینگل کراس 704",
              "سینگل کراس 711",
              "فاکتور NK",
              "فجر",
              "کارون",
              "کنسور",
              "کوردونا",
              "کوشا",
              "گازدار",
              "لاویرا",
              "ماسیل",
              "ماکسیما",
              "مبین",
              "مه 7089",
              "هکتور",
              "والبوم",
            ],
          },
          {
            name: "ذرت دانه ای بودادنی (پاپ کورن)",
            productItem: ["ذرت دانه ای بودادنی (پاپ کورن)"],
          },
          {
            name: "ذرت دانه ای شیرین",
            productItem: [
              "1203 رویال اسلویس",
              "1208",
              "1263 سمینیس",
              "1280 فیتو",
              "1446 سمینیس",
              "SF 1280",
              "آپادانا",
              "آپادانا مونارک سید",
              "آرسن",
              "آرسن کی سی",
              "آلتین",
              "آمیلا",
              "آنجیل",
              "آنجیل رویال اسلویس",
              "آوا",
              "اروند آمریکن ژنتیک",
              "اریکا",
              "اسپانسر",
              "اسپانسر کی سی",
              "استیلی ابوت",
              "اکسشن 1073 ابوت",
              "اکسن چوئیت",
              "امیننت",
              "امیننت آنتاریس",
              "اوبسیشن",
              "اورلند",
              "بارون",
              "بارون ماری",
              "بازین آر",
              "بالاتون",
              "براتلا",
              "براتلا فلامینگو سید",
              "بونانزا",
              "بونانزا کلوز",
              "بیتا بیکر",
              "بیسین",
              "پاشن",
              "پرسوییت",
              "پرسوییت آریستو سید",
              "پرودیوسر کلوز",
              "پشن (پاشن)",
              "تارگت",
              "تایسون",
              "تسا",
              "تمپتیشن",
              "جسیکا",
              "جنسیس",
              "جولی آمریکن ژنتیک",
              "چلنجر",
              "چیس",
              "خان",
              "خان ماری",
              "دانیکا",
              "درایور کلوز",
              "دیانا",
              "راکوئل",
              "راکوئل کلوز",
              "رویال اسلویس",
              "رویال تی",
              "سحر",
              "سحر سی ان یوز",
              "سلیم اینوواسید",
              "سمیرا",
              "سنتینل",
              "سنتینل کلوز",
              "سوپر گلد",
              "شاداب",
              "شایان",
              "شایان کی سی ",
              "شمیم",
              "شمیم (cnus)",
              "فرگمن",
              "کاراملو",
              "کامبر لند کلوز",
              "کوپر",
              "گلوریا",
              "لیسکام",
              "لیسکام کلوز",
              "لیندا",
              "لیندا ماگرووت",
              "مانا",
              "مریت",
              "مسنجر سمینیس",
              "میرزا",
              "هاروست گلد",
              "هازار",
              "هانی",
              "وگا",
              "ونوس",
              "سایر",
            ],
          },
          {
            name: "سایر دانه ذرت ",
            productItem: ["سایر دانه ذرت "],
          },
        ],
      },
      {
        name: "سایر غلات",
        secondSubDomain: [
          {
            name: "ارزن دانه ای",
            productItem: [
              "باستان",
              "پیشاهنگ",
              "چماقی",
              "گاورس",
              "گاورس زرد",
              "سایر",
            ],
          },
          {
            name: "تریتیکاله",
            productItem: ["پاژ", "سناباد"],
          },
          {
            name: "چاودار",
            productItem: ["helltop"],
          },
          {
            name: "سایر غلات",
            productItem: ["سایر غلات"],
          },
          {
            name: "سورگوم دانه ای",
            productItem: ["پیام", "سپیده", "فومن", "کیمیا", "سایر"],
          },
          {
            name: "یولاف(جو دو سر)",
            productItem: ["Euro", "Quoll"],
          },
        ],
      },
      {
        name: "شالی",
        secondSubDomain: [
          {
            name: "برنج پرمحصول",
            productItem: [
              "آنام",
              "تیسا",
              "حاج حیدری",
              "خزر",
              "درفک",
              "دیلم",
              "رش",
              "روشن",
              "ساحل",
              "سپیدرود",
              "ستایش",
              "شفق",
              "شهریار",
              "شیرودی",
              "طلوع",
              "فجر",
              "کادوس",
              "کشوری",
              "کیان",
              "گوهر",
              "گیلار",
              "گیلانه ",
              "ندا",
              "نعمت",
              "هاشمی",
              "هلال",
              "سایر",
            ],
          },
          {
            name: "برنج چمپا",
            productItem: [
              "چرام 1",
              "چرام 2",
              "چرام 3",
              "یاسوج 1",
              "یاسوج 2",
              "یاسوج 3",
            ],
          },
          {
            name: "برنج عنبربو",
            productItem: ["برنج عنبربو"],
          },
          {
            name: "برنج کامفیروز",
            productItem: ["درودزن"],
          },
          {
            name: "برنج لنجان",
            productItem: ["زاینده رود", "سازندگی", "کوهسار"],
          },
          {
            name: "برنج مرغوب شمال",
            productItem: ["برنج مرغوب شمال"],
          },
          {
            name: "سایر انواع برنج",
            productItem: ["سایر انواع برنج"],
          },
        ],
      },
      {
        name: "گندم",
        secondSubDomain: [
          {
            name: "گندم دروم",
            productItem: [
              "آبان ",
              "آران",
              "آریا",
              "آوان",
              "بهرگ",
              "تابش",
              "ثنا",
              "دنا",
              "دهدشت",
              "ذهاب",
              "ساجی",
              "ساورز",
              "سپند",
              "سمیره",
              "شبرنگ",
              "کرخه",
              "ماهان",
              "هانا",
              "یاواروس",
              "سایر",
            ],
          },
          {
            name: "گندم معمولی",
            productItem: [
              "N-80-19",
              "Nai 60",
              "Shanghai.7",
              "WS-82-9",
              "آذر 2",
              "آذران",
              "آراز",
              "آرتا",
              "آرتیمان",
              "آرمان",
              "آزادگان",
              "آزادی",
              "آسمان",
              "آفتاب",
              "آناپورنا",
              "آنتونیوس",
              "آینه",
              "آپاچی",
              "اترک",
              "احسان",
              "آرامش",
              "ارس بار 94",
              "ارگ",
              "اروم",
              "استار",
              "افلاک",
              "البرز",
              "الوند",
              "امین",
              "اوج",
              "اوحدی",
              "ایوان",
              "باران",
              "باسیلو",
              "بامداد",
              "برات",
              "بک کراس روشن بهاره",
              "بک کراس روشن زمستانه",
              "بم",
              "بهار",
              "بی تاپ",
              "پارسی",
              "پانیذ",
              "پایا",
              "پرآو",
              "پیام",
              "پیشتاز",
              "پیشگام",
              "تجن",
              "تفتان",
              "تک آب",
              "تکتاز",
              "توسن",
              "تیرگان",
              "جام",
              "جلال",
              "چروبلی",
              "چمران",
              "چمران 2",
              "چناب",
              "حیدری",
              "حیران",
              "خلیل",
              "داراب 2",
              "داریون",
              "دانش",
              "دریا",
              "دز",
              "دستان",
              "دیابلون",
              "راج",
              "راد",
              "رادیا",
              "رحمت",
              "رخشان",
              "رسول",
              "رصد",
              "روشن",
              "ریژاو",
              "زارع",
              "زاگروس",
              "زرین",
              "زرینه",
              "سائین",
              "سارنگ",
              "سالار",
              "سبلان",
              "سپاهان",
              "سپهر",
              "ستاره",
              "سحر",
              "سرداری",
              "سلا",
              "سیروان",
              "سیستان",
              "سیمونیدا",
              "سیمین",
              "سیوند",
              "شاداب",
              "شالان",
              "شاوور",
              "شهریار",
              "شه گل",
              "شوش",
              "شیراز",
              "شیرودی",
              "صبا 1",
              "صبا 2",
              "صبا 3",
              "صدرا",
              "صدرا",
              "طلایی",
              "فرین",
              "فلات",
              "قابوس",
              "قدس",
              "کبیر",
              "کراس بولانی",
              "کریم",
              "کمان",
              "کوهدشت",
              "کویر",
              "گاسپارد",
              "گاسکوژن",
              "گنبد",
              "گهر",
              "لاین A",
              "لوکولوس",
              "مروارید",
              "مرودشت",
              "مغان1",
              "مغان 3",
              "مهتاب",
              "مهدوی",
              "مهرگان",
              "میلان",
              "میهن",
              "نارین",
              "نودل",
              "نوید",
              "نیشابور",
              "نیک نژاد",
              "هامون",
              "هشترود",
              "هما",
              "هور",
              "هومان",
              "هیرمند",
              "واران",
              "والبونا",
              "ویریناک",
              "وینر",
              "سایر",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "حبوبات",
    firstSubDomain: [
      {
        name: "باقلا(حبوبات)",
        secondSubDomain: [
          {
            name: "باقلا خشک",
            productItem: [
              "برکت",
              "شادان",
              "فیض",
              "لوزدی اتونو",
              "مهتا",
              "هیستال",
              "سایر",
            ],
          },
        ],
      },
      {
        name: "عدس",
        secondSubDomain: [
          {
            name: "عدس درشت",
            productItem: [
              "بیله سوار",
              "سپهر",
              "سنا",
              "کیمیا",
              "گچساران",
              "مژده",
              "نوژیان",
              "سایر",
            ],
          },
          {
            name: "عدس ریز",
            productItem: ["عدس ریز"],
          },
        ],
      },
      {
        name: "سایر حبوبات",
        secondSubDomain: [
          {
            name: "سایر حبوبات",
            productItem: ["سایر حبوبات"],
          },
        ],
      },
      {
        name: "لوبیا",
        secondSubDomain: [
          {
            name: "چشم بلبلی",
            productItem: ["کامران", "محلی جیرفت", "محلی مشهد", "سایر"],
          },
          {
            name: "لوبیا چیتی",
            productItem: [
              "سپهر",
              "سیمبل",
              "صالح",
              "صدری",
              "غفار",
              "کوشا",
              "هیدج در",
              "سایر",
            ],
          },
          {
            name: "لوبیا سفید",
            productItem: ["الماس", "پاک", "درسا", "شکوفا", "لوبیا", "سایر"],
          },
          {
            name: "لوبیا سیاه",
            productItem: ["لوبیا سیاه"],
          },
          {
            name: "لوبیا عروس",
            productItem: ["لوبیا عروس"],
          },
          {
            name: "لوبیا قرمز",
            productItem: [
              "اختر",
              "افق",
              "دادفر",
              "درخشان",
              "صیاد",
              "یاقوت",
              "سایر",
            ],
          },
          {
            name: "لوبیا کشاورزی",
            productItem: ["لوبیا کشاورزی"],
          },
        ],
      },
      {
        name: "ماش",
        secondSubDomain: [
          {
            name: "ماش درشت",
            productItem: ["پرتو", "گوهر", "مهر", "هندی", "سایر"],
          },
          {
            name: "ماش ریز",
            productItem: ["ماش ریز"],
          },
        ],
      },
      {
        name: "نخود",
        secondSubDomain: [
          {
            name: "نخود",
            productItem: [
              "آتا",
              "آرمان",
              "آزاد",
              "آنا",
              "اروم 1",
              "اروم 2",
              "برکت",
              "بینالود",
              "بیونیچ",
              "پیروز",
              "ثمین",
              "جم",
              "روشنا",
              "زرین",
              "سارال",
              "سعید",
              "صوفی",
              "عادل",
              "کاکا",
              "کانی",
              "کاویان",
              "کسری",
              "منصور",
              "نصرت",
              "هاشم",
              "یادگار",
              "سایر",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "محصولات صنعتی",
    firstSubDomain: [
      {
        name: "پنبه",
        secondSubDomain: [
          {
            name: "وش پنبه",
            productItem: [
              "AGN117",
              "AGN126",
              "BA440",
              "PG 2018",
              "اپتازیا",
              "ادسا",
              "ارمغان",
              "اوا",
              "بابیلون",
              "بختگان",
              "پرتو",
              "تابان",
              "حرداد",
              "حکمت",
              "خورشیدی",
              "ساجدی",
              "ساحل",
              "سپید",
              "سایر",
              "شایان",
              "فلاش",
              "کاریزما",
              "کاشمر",
              "کامپو",
              "گلستان",
              "لطیف",
              "لودوس",
              "لیدر",
              "لیدیا",
              "مهر",
              "می 344",
              "ورامین",
            ],
          },
        ],
      },
      {
        name: "توتون و تنباکو",
        secondSubDomain: [
          {
            name: "برگ توتون و تنباکو",
            productItem: [
              "ارومیه205",
              "بارلی",
              "تیپ شرقی (توتون باسما)",
              "ویرجینیا",
              "سایر",
            ],
          },
        ],
      },
      {
        name: "چغندرقند",
        secondSubDomain: [
          {
            name: "چغندرقند",
            productItem: [
              "آریا",
              "اسیا",
              "اکباتان",
              "پارس",
              "پایا",
              "تارا",
              "تربت",
              "جام",
              "جلگه",
              "رسول",
              "زرقان",
              "سایر",
              "سینا",
              "شریف",
              "شکوفا",
              "کیمیا",
              "گدوک",
              "مطهر",
              "نیکا",
              "هما",
            ],
          },
        ],
      },
      {
        name: "خشخاش",
        secondSubDomain: [
          {
            name: "تریاک",
            productItem: ["تریاک"],
          },
          {
            name: "دانه خشخاش",
            productItem: ["دانه خشخاش"],
          },
        ],
      },
      {
        name: "سایر صنعتی ها",
        secondSubDomain: [
          {
            name: "سایر صنعتی ها",
            productItem: ["سایر صنعتی ها"],
          },
        ],
      },
      {
        name: " شاهدانه (صنعتی)",
        secondSubDomain: [
          {
            name: "برگ و غنچه",
            productItem: ["برگ و غنچه"],
          },
        ],
      },
      {
        name: "کتان",
        secondSubDomain: [
          {
            name: "الیاف کتان",
            productItem: ["الیاف کتان"],
          },
        ],
      },
      {
        name: "کنف",
        secondSubDomain: [
          {
            name: "الیاف کنف",
            productItem: ["الیاف کنف"],
          },
        ],
      },
      {
        name: "نیشکر",
        secondSubDomain: [
          {
            name: "نیشکر",
            productItem: ["نیشکر"],
          },
        ],
      },
    ],
  },
  {
    name: "دانه های روغنی و آجیلی",
    firstSubDomain: [
      {
        name: "آفتابگردان آجیلی",
        secondSubDomain: [
          {
            name: "تخم آفتابگردان آجیلی",
            productItem: ["دور", "سایر", "سنقری", "قلمی", "کله قوچی", "میخی"],
          },
        ],
      },
      {
        name: "آفتابگردان روغنی",
        secondSubDomain: [
          {
            name: "دانه آفتابگردان روغنی",
            productItem: [
              "S300",
              "S400",
              "آذرگل",
              "احمدبی",
              "استریپل",
              "برزگر",
              "رکود",
              "زاریا",
              "زرین",
              "شمس",
              "قاسم",
              "زاریا",
              "گابور",
              "گلدیس",
              "گلسا",
              "گلشید",
              "سایر",
            ],
          },
        ],
      },
      {
        name: "بادام زمینی",
        secondSubDomain: [
          {
            name: "بادام زمینی",
            productItem: [
              "nc2",
              "nc7",
              "اسپنیش",
              "ایمپرود306",
              "ایمپرود 308",
              "سایر",
              "شولامیت",
              "والنسیا",
              "ویرجینیا",
            ],
          },
        ],
      },
      {
        name: "سایر دانه ها",
        secondSubDomain: [
          {
            name: "سایر دانه های روغنی",
            productItem: ["سایر دانه های روغنی"],
          },
        ],
      },
      {
        name: "سویا",
        secondSubDomain: [
          {
            name: "دانه سویا",
            productItem: [
              "M7",
              "M9",
              "آرین",
              "امیر",
              "پارسا",
              "تپور",
              "تلار",
              "رحمت",
              "زان",
              "ساری",
              "سالند",
              "سامان",
              "سحر",
              "سلنا",
              "صبا",
              "کاسپین",
              "کتول",
              "کوثر",
              "گرگان-3",
              "گلستان",
              "نکادر",
              "ویلیمز",
              "سایر",
            ],
          },
        ],
      },
      {
        name: "شاهدانه (آجیلی)",
        secondSubDomain: [
          {
            name: "شاهدانه",
            productItem: ["شاهدانه"],
          },
        ],
      },
      {
        name: "کتان روغنی",
        secondSubDomain: [
          {
            name: "دانه کتان",
            productItem: ["تکاپو", "گلچین", "سایر"],
          },
        ],
      },
      {
        name: "کدو آجیلی",
        secondSubDomain: [
          {
            name: "تخم کدو",
            productItem: ["لیندا"],
          },
        ],
      },
      {
        name: "کرچک",
        secondSubDomain: [
          {
            name: "دانه کرچک",
            productItem: ["دانه کرچک"],
          },
        ],
      },
      {
        name: "کلزا",
        secondSubDomain: [
          {
            name: "دانه کلزا",
            productItem: [
              "PGS003",
              "SLM046",
              "آرام",
              "آگاماکس",
              "آنجلیکو",
              "ابونیت",
              "اپرا",
              "احمدی",
              "ادیمکس",
              "ارشیتکت",
              "اسا",
              "الیت",
              "اوکاپی",
              "بروتوس",
              "بلیندا",
              "بهاران",
              "پیشرو",
              "پیلانی",
              "تابان",
              "تراپر",
              "تراویتا",
              "داریوت",
              "دراگو",
              "دلگان",
              "روشنا",
              "زرفام",
              "زلانتا",
              "زمان",
              "زوریکا",
              "ساری گل",
              "سایر",
              "سیمبا",
              "طلایه",
              "ظفر",
              "کودیاک",
              "لیکورد",
              "ماراتن",
              "مدیا",
              "مهتاب",
              "مودنا",
              "نفیس",
              "نیلوفر",
              "نیما",
              "هایولا308",
              "هایولا 401",
              "هایولا420",
              "هایولا 432",
              "هایولا 48115",
              "هایولا 50",
              "هایولا 60",
            ],
          },
        ],
      },
      {
        name: "کنجد",
        secondSubDomain: [
          {
            name: "دانه کنجد",
            productItem: [
              "اولتان",
              "پاکستان",
              "جیرفت 13",
              "داراب 14",
              "داراب 2",
              "داکوب 1",
              "دشتستان2",
              "سایر",
              "سردار",
              "شوین",
              "فخرا",
              "مینا",
              "ناز چند شاخه",
              "هلیل",
              "ورامین2822",
              "یکتا",
            ],
          },
        ],
      },
      {
        name: "گلرنگ",
        secondSubDomain: [
          {
            name: "دانه گلرنگ",
            productItem: [
              "امید",
              "امیر",
              "پدیده",
              "پرنیان",
              "سایر",
              "سینا",
              "صفه",
              "فرامان",
              "گلدشت",
              "گل مهر",
            ],
          },
        ],
      },
      {
        name: "منداب (شابانک)",
        secondSubDomain: [
          {
            name: "دانه منداب",
            productItem: ["دانه منداب"],
          },
        ],
      },
    ],
  },
  {
    name: "سبزی و صیفی",
    firstSubDomain: [
      {
        name: "بادنجان",
        secondSubDomain: [
          {
            name: "بادنجان دلمه",
            productItem: [
              "بلک اورینت",
              "بلک بیوتی",
              "بلک مجیک",
              "بلک مون",
              "بونیکا کلوز",
              "پولسار",
              "تتی پروفیت",
              "داپان",
              "سایر",
              "سپهر کلش",
              "شانتال",
              "گالینا",
              "لیما",
            ],
          },
          {
            name: "بادنجان قلمی",
            productItem: [
              "ارمغان 513 سافال بیو سید",
              "ارمغان پارس سید",
              "افسون ویلمورین",
              "الترا پروفیت",
              "ایران بذر",
              "ایی جی 302",
              "بلک دیاموند",
              "بلک وان اینوا سید",
              "پی اس بی 330",
              "جگوار",
              "جیهون جنتو",
              "دارکو",
              "دهقان",
              "راف",
              "روناک",
              "سایر",
              "سمبرا آنتاریس",
              "سورمه بروسید",
              "سیسیلیا فیتو",
              "شادو کلش",
              "فاسیلیس",
              "کلوز",
              "لانگ بلک پروسید",
              "لانگ پریل",
              "لیما فیتو",
              "مرجان جنتو",
              "نگرو گرین",
              "هادریان",
              "والنتیا",
              "یامی",
              "",
            ],
          },
          {
            name: "بادنجان لامپی",
            productItem: [
              "آنامیس ویلمورین",
              "ارال",
              "ارلن سمینیس",
              "اگاتا",
              "بلک بیوتی",
              "بلکی",
              "بلن سمینیس",
              "بیکر",
              "دنیز",
              "ربلیون آنتاریس",
              "ساکاتا 1882",
              "سایر",
              "فینیکس انتاریس",
              "گالین کلوز",
            ],
          },
        ],
      },
      {
        name: "باقلا سبز",
        secondSubDomain: [
          {
            name: "باقلا سبز",
            productItem: [
              "برکت",
              "سایر",
              "شادان",
              "فیض",
              "لوزدی فیتو",
              "مهتا",
              "هستال",
            ],
          },
        ],
      },
      {
        name: "بامیه",
        secondSubDomain: [
          {
            name: "بامیه",
            productItem: [
              "0001 سمینیس",
              "8999 سمینیس",
              "آرگتو",
              "اپی کلش",
              "الدور ادو",
              "بروسید",
              "بیکر",
              "پروسید",
              "پوپ",
              "تاپ هاروست",
              "سانمیک",
              "سایر",
              "کشتزار",
              "کلش",
              "کلمسون اسپینلس هد سید",
              "کلمسون اسپینلس وسترن سید",
              "کی سی ",
              "هد",
              "هیبرید کلش",
              "وسترن",
              "ویکیما",
            ],
          },
        ],
      },
      {
        name: "پیاز",
        secondSubDomain: [
          {
            name: "پیاز",
            productItem: [
              "اسمارد",
              "ایرانشهر",
              "برازجان",
              "پریماورا",
              "تگزاس",
              "خمین",
              "درچه اصفهان",
              "رداستار",
              "ریوبراوو",
              "زرین",
              "زنجان",
              "ساواناسوئیت",
              "سایر",
              "سروپرکس",
              "سوئیت اسپانیش",
              "طارم",
              "قرمز آذرشهر",
              "قم",
              "قولی قصه زنجان",
              "کازرون",
              "کاشان",
              "گرگان",
              "لینداویستا",
              "محلی  بهبهان",
              "محلی زابل",
              "مینروا",
            ],
          },
        ],
      },
      {
        name: "ترب",
        secondSubDomain: [
          {
            name: "ترب",
            productItem: [
              "پروفیت",
              "پی اس",
              "دلتاسید",
              "رد میت تاکی",
              "سایر",
              "کلش",
              "کارنتان پروسید",
              "مینو تاکی",
              "مینو کورگان",
            ],
          },
        ],
      },
      {
        name: "چغندر لویی",
        secondSubDomain: [
          {
            name: "چغندر لبویی",
            productItem: [
              "بیت دراک رد",
              "بیکورس",
              "دارک رد ببت",
              "دترویت",
              "دترویت دارک رد",
              "سایر",
            ],
          },
        ],
      },
      {
        name: "سایر سبزیجات",
        secondSubDomain: [
          {
            name: "سایر سبزیجات",
            productItem: ["سایر سبزیجات"],
          },
        ],
      },
      {
        name: " سبزیجات برگی",
        secondSubDomain: [
          {
            name: "آرتیشو (کنگر فرنگی)",
            productItem: ["آرتیشو (کنگر فرنگی)"],
          },
          {
            name: "اسفناج",
            productItem: [
              "7141 پوپ",
              "آتلانتیس",
              "اسپینو",
              "استرانگ روزن سید",
              "الیت",
              "اورینتال پراید روزن سید",
              "اورینتال گرین پروسید",
              "اوکامه",
              "اوکامه تاکی",
              "پارسیس",
              "پارسیس هلندزادن",
              "تاکارا تاکی",
              "تیمپانی",
              "تیمپانی پروسید",
              "جی اس ان",
              "دش تاکی",
              "دیکسون",
              "دیکسون پوپ",
              "رادکان اماسید",
              "روزن سید",
              "رویال میدوری پوپ",
              "سایر",
              "فوسلو سمنتی",
              "کالادونیا سمینیس",
              "کوتو ساکاتا",
              "کوراسید",
              "کینگ کی سی",
              "گریفاتون",
              "ماتادور",
              "ماتادور پوپ",
              "نرسو اولسوننکه",
              "هارپ پوپ",
              "هارپ ناریتا پروسید",
              "هارپ ناریتا پوپ",
              "هاشور بروسید",
              "ورامین 99",
              "ویروفلای آنسم",
              "ویروفلای الیت",
              "ویروفلای پروفشنال",
              "ویروفلای جی اس ان",
              "ویروفلای روزن سید",
              "ویروفلای گلدن سید",
              "ویکیما (نعل اسبی)",
            ],
          },
          {
            name: "اندیو (کاسنی فرنگی)",
            productItem: ["اندیو (کاسنی فرنگی)"],
          },
          {
            name: "پیازچه",
            productItem: ["پیازچه"],
          },
          {
            name: "تربچه",
            productItem: [
              "آنیل هلندزادن",
              "اسپارکلر پروسید (دو رنگ)",
              "اسپارکلر گریفاتون",
              "اسکالا انزا",
              "پلاریس",
              "تینتو ویلمورین",
              "چریبل ام ای سید",
              "چری بل بروسید",
              "چریبل پاندیا سید",
              "چریبل پروسید",
              "چریبل پروفشنال",
              "چریبل پوپ",
              "چریبل ساتبرگ",
              "چریبل کی سی",
              "چریبل گریفاتون",
              "چریبل گریفین",
              "چریبل گرین پرت",
              "چریبل ویکیما",
              "چریبل ویلمورین",
              "چریبل یو اس اگرسید",
              "دلفین بروسید",
              "دیسکاوری",
              "رد گل نیکرسون",
              "ری کی سی",
              "ساتبرگ",
              "ساکسا 2",
              "ساکسا سیمیلاس",
              "ساکسا وسترن سید",
              "ساکسا ویکیما ",
              "ساگزا روزن سید",
              "سایر",
              "سلستا انزا",
              "فرانسوی لاروسا",
              "کامیل اولسوننکه",
              "کانیون",
              "گاردسکو",
              "گریفاتون",
              "گلوریت ساکاتا",
              "هورسان",
              "ونیتا روزن سید",
              "ویکیما",
            ],
          },
          {
            name: "پیازچه",
            productItem: ["پیازچه"],
          },
          {
            name: "ترخون",
            productItem: ["ترخون"],
          },
          {
            name: "تره",
            productItem: ["تره"],
          },
          {
            name: "تره فرنگی",
            productItem: ["تره فرنگی"],
          },
          {
            name: "جعفری",
            productItem: ["جعفری"],
          },
          {
            name: "ریحان",
            productItem: [
              "ریحان سبز پاکان بذر",
              "ریحان قرمز پاکان بذر",
              "سایر",
            ],
          },
          {
            name: "سایر سبزیجات برگی",
            productItem: ["سایر سبزیجات برگی"],
          },
          {
            name: "سیر سبز",
            productItem: ["سیر سبز"],
          },
          {
            name: "شاهی",
            productItem: ["پاکان بذر", "دهقان بذر", "سایر"],
          },
          {
            name: "شنبلیله",
            productItem: ["شنبلیله"],
          },
          {
            name: "شوید",
            productItem: ["شوید"],
          },
          {
            name: "گشنیز",
            productItem: ["گشنیز"],
          },
          {
            name: "مرزه",
            productItem: ["مرزه"],
          },
          {
            name: "نعناع",
            productItem: [
              "جویباری",
              "دشتی",
              "رسمی",
              "ژاپنی",
              "سایر",
              "سیب",
              "فلفلی",
              "گربه ای",
              "مجارستان",
              "محلی",
              "موهیتو",
              "",
            ],
          },
        ],
      },
      {
        name: "سیب زمینی",
        secondSubDomain: [
          {
            name: "سیب زمینی",
            productItem: [
              "آتوسا",
              "آریزونا",
              "آگریا",
              "آلفا",
              "آلمرا",
              "آمانی",
              "اتاوا",
              "ادونی",
              "اروما",
              "اسپریت",
              "استانبولی",
              "انوشا",
              "اودسا",
              "باراکا",
              "بانبا",
              "برتر",
              "بورن",
              "پانامرا",
              "پشندی",
              "پیکاسو",
              "تکتا",
              "تورنتو",
              "ثور",
              "جاوید",
              "جلی",
              "جورجینا",
              "چلنجر",
              "خاوران",
              "دایفلا",
              "دراگا",
              "دزیره",
              "دوناتا",
              "راشیدا",
              "رانومی",
              "روزتا",
              "رومبا",
              "رونا",
              "رویال",
              "ساتینا",
              "ساگیتا",
              "ساوالان",
              "سایر",
              "سزار",
              "سیفرا",
              "سیلوانا",
              "سینورا",
              "شیرین",
              "فابولا",
              "فالوکا",
              "فونتانه",
              "قرمز",
              "کاررا",
              "کارلیا",
              "کایزر",
              "کلر",
              "کلمبا",
              "کلیماکس",
              "کنبک",
              "کنستانس",
              "کنکوردیا",
              "گوئن",
              "لئوناردو",
              "لیزتا",
              "مارفونا",
              "مالو",
              "مبتکر",
              "مونترال",
              "موندیال",
              "میلوا",
              "هانا",
              "والنسا",
              "وام",
            ],
          },
        ],
      },
      {
        name: "سیر",
        secondSubDomain: [
          {
            name: "سیر خشک",
            productItem: [
              "سایر",
              "سیر",
              "سیرآذر",
              "سیر اسپانیولی",
              "سیر صورتی",
              "سیر طارم",
              "سیر قرمز",
            ],
          },
        ],
      },
      {
        name: "شلغم",
        secondSubDomain: [
          {
            name: "شلغم",
            productItem: [
              "الدورادو",
              "الیت",
              "بونانزا",
              "پرپل تاپ بروسید",
              "پرپل تاپ وایت گلوب وسترن",
              "پروفیت",
              "تاپ وایت آفریگرو",
              "تاپ وایت پروفشنال",
              "تاپ وایت پوپ",
              "تاپ وایت جی اس ان",
              "تاپ وایت یو اس اگرسید",
              "روزن سید",
              "ساکاتا",
              "سایر",
              "سرویس پلاس",
              "سه حلقه",
              "کانیون",
              "کشتزار",
              "کی سی",
              "گریفاتون",
              "گریفین سید",
              "گلدن سید",
              "هلندز ادن",
              "هورتوس سمنتی",
              "وایت گلوب اولسوننکه",
              "وایت گلوب پاندیاسید",
              "وایت گلوب گرین پرل",
              "وسترن",
              "ویکیما",
              "ویلمورین",
              "",
              "",
              "",
              "",
            ],
          },
        ],
      },
      {
        name: "فلفل",
        secondSubDomain: [
          {
            name: "فلفل دلمه ای",
            productItem: [
              "سمینیس 301",
              "سمینیس 302",
              "سمینیس 7141",
              "سمینیس 9325",
              "آریستوتل",
              "آیبیس",
              "آیبیس(IBIS)کلوز",
              "ارشمیدس سمینیس",
              "اگروتیپ",
              "اماسید",
              "انتبلوم سمینیس",
              "بهارات ایندام",
              "بونانزا",
              "بیور ثمین",
              "پاترون کانیون",
              "پروفیت",
              "پلاتو سمینیس",
              "پلاریس",
              "جی اس کانیون",
              "دلمه آوالون",
              "دلمه هایتک",
              "راشا",
              "زارا جنتو",
              "زر بذر",
              "سایر",
              "ستاره رویال",
              "سرویس پلاس",
              "سه حلقه",
              "سوپر گرین",
              "سوپر گرین دونگیا",
              "فلاویو",
              "کاراکو",
              "کاراکو گرین دیاموند سید",
              "کاریزما فیتو",
              "کالیفرنیا واندر 310 کانیون",
              "کالیفرنیا واندر آپولو",
              "کالیفرنیا واندر (اپی) ایندام",
              "کالیفرنیا واندر الیت",
              "کالیفرنیا واندر بیکر",
              "کالیفرنیا واندر پروسید",
              "کالیفرنیا واندر پوپ",
              "کالیفرنیا واندر جی اس ان",
              "کالیفرنیا واندر روزن سید",
              "کالیفرنیا واندر ساکاتا",
              "کالیفرنیا واندر سام سید",
              "کالیفرنیا واندر سانمیک",
              "کالیفرنیا واندر هایتک",
              "کالیفرنیا واندر یو اس اگرسید",
              "کراسادر سینجنتا",
              "کروسادر (کراسادر) سینجنتا",
              "کواترو امرالد",
              "کوانتوم",
              "گاسپل رکسوان",
              "گریفاتون",
              "گریفین سید",
              "لاریسا آنتاریس",
              "مارگاریتا ناسکو",
              "مکان گیرنده",
              "موراین درویتر",
              "میترا",
              "میترا لیان سید",
              "هانرس",
              "هد",
              "ویزارد سمینیس",
              "یلو واندر گاردسکو",
              "یونیژن",
            ],
          },
          {
            name: "فلفل قلمی",
            productItem: [
              "آناتولین کانیون",
              "ارمغان اگروتیپ",
              "ایران بذر",
              "ایندام 67",
              "پفکی هاشمی پی اس",
              "پویا سید",
              "پیمان ",
              "پینوکیو کانیون",
              "تانگو یو اس",
              "راما ثمین",
              "رد و اندر کورگان",
              "سانمیک",
              "سایر",
              "سیگارتا کانیون",
              "سینگر",
              "عنبری",
              "کانزو یو اس",
              "کیزیل آرگتو",
              "گازر",
              "گازر سرس",
              "گرین چیلی",
              "مایا",
              "میناکشی",
              "نارین ثمین",
              "هالوپینو 1037 سمینیس",
              "هالوپینو سمینیس",
              "هانا ثمین",
              "وانیا سید",
              "ویکونا",
            ],
          },
        ],
      },
      {
        name: "کاهو",
        secondSubDomain: [
          {
            name: "کاهو پیچ",
            productItem: ["آیس برگ", "رکسوس آسیاسید", "سایر", "طاووسی", "وراش"],
          },
          {
            name: "کاهو چینی",
            productItem: ["یورکا کانیون", "سایر"],
          },
          {
            name: "کاهو رسمی",
            productItem: [
              "آرال ",
              "اگروتیپ",
              "اورست 1",
              "اورست 2",
              "اولگادا نانهمز",
              "بامبولا انزا",
              "بونانزا",
              "پاریس ایسلند هایتک",
              "پاریس ایسلند هد",
              "دوک سمنتی",
              "راوک",
              "رایدر",
              "رایدر پلاس سمینیس",
              "روبی",
              "سالادر ایران بذر",
              "ساهارا سمینیس",
              "سایر",
              "ستاره",
              "سیسکو تاکی",
              "فارو",
              "فارو سمنتی",
              "فردوس فلات",
              "فرنو",
              "کارتاگونوا سمینیس",
              "گاردسکو",
              "گرمسیر فلات",
              "گریزلی سمینیس",
              "لارسن فیم سید",
              "مهرگان 48 فلات",
              "موهاک سمینیس",
              "مینیجم",
              "مینیجم ریچموند",
              "هرمیون هد",
              "هیرو تاکی",
              "وارش",
              "وفلات",
              "یورکا کارنیون",
              "یوکی ساکاتا",
            ],
          },
          {
            name: "کاهو فرانسوی",
            productItem: [
              "اسیا سید",
              "سایر",
              "فرنو ریچموند",
              "کیمیا",
              "نیو رد فایر",
              "",
              "",
            ],
          },
        ],
      },
      {
        name: "کرفس",
        secondSubDomain: [
          {
            name: "کرفس",
            productItem: ["برگی", "سایر", "قمری ", "معمولی"],
          },
        ],
      },
      {
        name: "کلم",
        secondSubDomain: [
          {
            name: "کلم بروکلی",
            productItem: [
              "آتیس اماسید",
              "آراکسوس (آراکسوز) کانیون",
              "آکویلس ساکاتا",
              "آگاسی",
              "تفتان دیاموند",
              "جید کراس تاکی",
              "ساکورا توکیتا",
              "سامرکینگ آسیاسید",
              "سایر",
              "سنتارو تاکی",
              "سیگنو کلوز",
              "کستل دام سمینیس",
              "کووینا بیجو",
              "کوینا",
              "گرین استار",
              "گرین پاراسول تاکی",
              "گرین مجیک",
              "ماتسوری توکیتا",
              "ماسترو",
              "ماسترو (مایسترو) کانیون",
              "ناکسوس (ناکسوز)",
              "هراکلیون",
              "هستی آتلانتیک",
            ],
          },
          {
            name: "کلم پیچ",
            productItem: [
              "آی تی اف",
              "ارغوان اماسید",
              "بلو داینستی سمینیس",
              "بونوس",
              "پرسپولیس نیکرسون",
              "پروفیت",
              "پوپد",
              "چرس تاکی",
              " رابی کینگ تاکی (روبی کینگ) ",
              "رد داینستی سمینیس",
              "رد روکی ساکاتا",
              "رد گلوب میکادو",
              "روبی بال (رابی بال) تاکی ",
              "روبی پرفکشن تاکی",
              "ریربال باکسیل",
              "ریما",
              "سایر",
              "کایرو بیجو",
              "گرگورین سینجنتا",
              "گرین استیج تاکی",
              "گرین کرون توکیتا",
              "گرین کورونت",
              "گرین مکس",
              "گلوب مستر تاکی",
              "نوژا اگروتیپ",
            ],
          },
          {
            name: "کلم سایر",
            productItem: ["کلم سایر"],
          },
          {
            name: "کلم قمری",
            productItem: [
              "اگروتیپ",
              "بونانزا",
              "پروفیت",
              "پوپ",
              "چمپیون",
              "روزن سید",
              "سایر",
              "سایس",
              "سبز وایت وینا سایس",
              "قرمز روزن سید",
              "کوهل رابی",
              "لاروسا",
              "وینر تاکی",
            ],
          },
          {
            name: "گل کلم",
            productItem: [
              "آریزونا",
              "آلپس کلوسیوم",
              "آمیدالا",
              "آمیدالا سینجنتا",
              "آوالانچ",
              "ارگانزا کلوز",
              "اسپیس استار",
              "اسندی سینجنتا",
              "اسمیلا",
              "اسنوتاپ نیکرسون",
              "اسنو مایستیک تاکی",
              "اسنو وایت هلند زادن",
              "البرز",
              "اویندولی",
              "بیانکا",
              "بیانکا 512",
              "بیانکا 585",
              "پوندرت",
              "پوندرت رکسوان",
              "سایر",
              "سنتروم روزن سید",
              "سیلوبلانکو سمینیس",
              "شیرین رکسوان",
              "صدف روزن سید",
              "فوجی یاما توکیتا",
              "قرمز دپرپل سینجتا",
              "کارن",
              "کارن سینجنتا",
              "کاسپر",
              "کرونر سینجنتا",
              "کلودیس ویلمورین",
              "کینگ استار",
              "کینگ استار ویلمورین",
              "مارمورکس",
              "والیانت",
              "ویستلر",
            ],
          },
        ],
      },
      {
        name: "گوجه فرنگی",
        secondSubDomain: [
          {
            name: "گوجه فرنگی",
            productItem: [
              "8320",
              "857 کانیون",
              "آر اف تی",
              "ازمیر",
              "الیسا",
              "ایدن",
              "باسیمو",
              "برلین",
              "برنتا",
              "بریویو",
              "پریمو",
              "پویا",
              "پیل 303",
              "تانیا",
              "تمپلر",
              "تیفال",
              "ثمین",
              "ثنا",
              "چریستا",
              "چیکو",
              "رد وسترن",
              "ریوگراند",
              "ساکاتا",
              "سانسید",
              "سایر",
              "سما",
              "سوپر اور بانا",
              "سوپر چیف",
              "سی اچ",
              "سی ال ایکس",
              "فرمونت",
              "کرال",
              "گارنت",
              "گلسار",
              "لئوناردو",
              "مارول",
              "متین",
              "موناکو",
              "میلان",
              "ناتالی",
              "نادیا",
              "وادارو",
            ],
          },
        ],
      },
      {
        name: "لوبیا سبز",
        secondSubDomain: [
          {
            name: "لوبیا سبز",
            productItem: [
              "آریان",
              "باسلیم",
              "بذر لوبیاسبز پوپ",
              "بیکر",
              "تروبرد سمینیس",
              "جی اس ان",
              "سان ری الدور ادو",
              "سان ری الیت",
              "سان ری پروسید",
              "سان ری ثمین بذر",
              "سان ری روزن سید",
              "سان ری سرویس پلاس",
              "سان ری کانیون",
              "سان ری گلچین",
              "سان ری هد",
              "سان ری هلند زادن",
              "سایر",
              "سپهر",
              "سلین",
              "سیباریس سمینیس",
              "کبرا",
              "کشتزار",
              "گریفاتون",
              "گلدن",
              "هاروی دورسینگ",
              "هد",
              "هورتوس",
              "یکتا جماران",
            ],
          },
        ],
      },
      {
        name: "نخود فرنگی",
        secondSubDomain: [
          {
            name: "نخود فرنگی",
            productItem: [
              "آسگرو",
              "آلدرمن",
              "آنسم",
              "اتریلو سمینیس",
              "بیوتک",
              "ثمین",
              "راندی",
              "سایر",
              "کولک شار",
              "کی سی",
              "گرین آرو رویال اسلویس",
              "گرین آرو زدکا آی (ZKI)",
              "گلدن سید",
              "واندو",
              "ولف دلتاسید",
              "ولف روزن سید",
              "ولف کی سی",
              "ولف هلند زادن",
            ],
          },
        ],
      },
      {
        name: "هویج",
        secondSubDomain: [
          {
            name: "هویج",
            productItem: [
              "1140 سمینیس",
              "2313 سمینیس",
              "4030",
              "آرا اماسید",
              "آنکا کلوز",
              "اپتیمو ویلمورین",
              "اسکار آنتاریس",
              "الدو ادو ویلمورین",
              "النا",
              "النا سن مارتین سید",
              "ای بی 4030 ماگرووت",
              "بنگالا",
              "پیکو ویلمورین",
              "تیارا",
              "تیارا گرین دیاموند سید",
              "داتیس روزن سید",
              "دور دوگن سینجنتا",
              "دوریانا اگروتیپ",
              "دورین بروسید",
              "دیسکاوری",
              "رایموند هلند زادن",
              "رومانس نانهمز",
              "سایر",
              "سیرانو ویلمورین",
              "سیرکانا نانهمز",
              "فورتو سمینیس",
              "فیدرا رکسوان",
              "کارازو سمینیس",
              "کاراول",
              "کاردیگان",
              "کارورا سمینیس",
              "کالانتیس",
              "کالیندور",
              "کریستو ویلمورین",
              "کوریگان ساکاتا",
              "کینگ تاکی",
              "گریفین سید",
              "لاگونا نانهمز",
              "لیدر روزن سید",
              "مارس سیمولند",
              "مرسدس تاکی",
              "مرسی",
              "مرسی کاروسم",
              "مورلیا رکسوان",
              "ناتیوا ساکاتا",
              "نانتس 2000 سمینیس",
              "نانتس 2 پروسید",
              "نانتس 2 پوپ",
              "نانتس 2 تیتو نیکرسون",
              "نانتس آرگتو",
              "نانتس آفریگرو",
              "نانتس آوالون",
              "نانتس اگروتیپ",
              "نانتس اماسید",
              "نانتس اولسوننکه",
              "نانتس بروسید",
              "نانتس پروفیت",
              "نانتس جی اس ان (gsn)",
              "نانتس روزن سید",
              "نانتس سایس",
              "نانتس مونانتا رکسوان",
              "نانتس هد",
              "نانتس وجتیبل سید",
              "نانتس وسترن",
              "نبا سانمیک",
              "هیبرید بنگالا",
              "هیبرید کاراول",
              "هیبرید کاردیگان کانیون",
              "هیبرید کالانتیس",
              "هیبرید کالیندور",
              "وسترن",
              "ویکیما",
              "یونیژن",
            ],
          },
        ],
      },
    ],
  },

  {
    name: "جالیز",
    firstSubDomain: [
      {
        name: "خربزه",
        secondSubDomain: [
          {
            name: "خربزه",
            productItem: [
              "اباتر",
              "ابراهیم خانی (اصفهان)",
              "اکبر آبادی",
              "باخرمن",
              "بلخی",
              "بهار همدان",
              "تاشکندی",
              "تخم محمد",
              "جباری",
              "جعفر آبادی",
              "حاج ماشااللهی",
              "خاتونی",
              "خاقانی یا شخته مشهد",
              "خرچه زنجان",
              "درگزی",
              "زرچه اصفهان",
              "زرد ایوانکی",
              "زرد جلال",
              "زرد کرج",
              "زردگر مساری",
              "زرند ساوه",
              "سایر",
              "سوسکی علمداری",
              "شمال ایران",
              "عباس شوری",
              "علم گرگر",
              "(علم گرگر قوونی)",
              "(علی آبادی)",
              "قلم قاش (قلم قاش قوونی)",
              "کدخدا حسینی",
              "گرگاب",
              "مرند(مرت قوونی)",
              "مهاجران همدان",
            ],
          },
        ],
      },
      {
        name: "خیار",
        secondSubDomain: [
          {
            name: "خیار فضای باز",
            productItem: [
              "4196",
              "افیسر کلوز",
              "آگنس سمان",
              "آمازون",
              "آیلار",
              "اس 2)(s2)یونیژن",
              "اسمارت بونانزا",
              "الدورادو",
              "الیور کلوز",
              "امبا امرالد",
              "امپراطور",
              "امیران پی اس",
              "انیس",
              "اورکا کلوز",
              "ایمپرس ویلمورین (هشت خیاره)",
              "ایمپریال هولار (امپریال)",
              "بابل یو اس اگرسید",
              "بوسکو پی اس",
              "بومبای سید وی",
              "بیت آلفا بروسید",
              "بیت آلفا روزن سید",
              "بیت آلفا هد",
              "بیت آلفا وسترن",
              "بیت آلفا وسترن سید",
              "بینگو هولار",
              "پی ایکس 8306",
              "جنتو ممتاز",
              "چنبر کلش",
              "چنبر هیبرید بهاونا کلش",
              "خیار 009",
              "خیار 8306",
              "خیار آفیسر",
              "خیار امپراطور",
              "خیار اورکا",
              "خیار بومبای",
              "خیار دلتا",
              "2N خیار",
              "خیار رایدر",
              "خیار سارا",
              "خیار سهیل",
              "خیار سوپر دومینوس",
              "خیار سورینا",
              "خیار کیش",
              "خیار ماکسیموس",
              "خیار مایا",
              "خیار ممتاز",
              "خیار هایک",
              "خیار وکتور",
              "دانوب نیاگارا",
              "دنور نیاگارا",
              "دو ان نادا ",
              "دیانا نیوورد سید",
              "راژان",
              "راژان جنتو",
              "رایدر یواس اگری سید",
              "رخش پلاریس",
              "رولر نیوورد سید",
              "رویال پلاس هایتک",
              "ساونا کانیون",
              "ساوونا (ساوانا) کانیون",
              "سایر",
              "سلیل نونگوبیو",
              "سلیل نوونگو",
              "سهیل",
              "سهیل نانگوبیو",
              "سوپر ارشیا (عرشیا) یو اس اگری سید",
              "سوپر استار یو اس اگری سید",
              "سوپر دومینوس",
              "سوپر نیا گریفاتون",
              "سوپرینا نیکرسون",
              "سورینا نیوورد سید",
              "سورینا یو اس اگری سید",
              "شوان ناسکو (شوآن)",
              "طلوع سان رایز",
              "غنیمت ارگتو",
              "قشم نیوورد سید",
              "کامرون استلا سید",
              "کرانچ",
              "کرانچ کل ام سید",
              "کروز (شش خیاره) ویلمورین",
              "کمرون",
              "کنکورد",
              "کیش یو اس اگرسید",
              "گلوریا 007",
              "گلوریا 009",
              "لیلیان",
              "لیلیان بیزادن",
              "ماکان نیوورد سید",
              "ماکسیموس",
              "ماهان یواس اگری سید",
              "مایا فلات",
              "ممتاز (نفر) یوکسل",
              "هامین",
              "هامین اکو سیدز",
              "هایک یو اس اگری سید",
              "هیمالیا نیکرسون",
              "وای تاپ جنتو",
              "وندا پلاریس",
              "ویکتور سمینیس",
              "ویکتوریا هانرس",
              "یاری هد",
            ],
          },
        ],
      },
      {
        name: "دستنبو",
        secondSubDomain: [
          {
            name: "دستنبو",
            productItem: ["دستنبو"],
          },
        ],
      },
      {
        name: "سایر محصولات جالیزی",
        secondSubDomain: [
          {
            name: "سایر محصولات جالیزی",
            productItem: ["سایر محصولات جالیزی"],
          },
        ],
      },
      {
        name: "سایر ملون",
        secondSubDomain: [
          {
            name: "سایر ملون",
            productItem: ["سایر ملون"],
          },
        ],
      },
      {
        name: "طالبی",
        secondSubDomain: [
          {
            name: "طالبی",
            productItem: [
              "آبهینه تربن جام",
              "تپل طرق",
              "سایر",
              "سمسوری",
              "طالبی ساوه",
            ],
          },
        ],
      },
      {
        name: "کدو سبز",
        secondSubDomain: [
          {
            name: "کدو سبز",
            productItem: [
              "613",
              "آتلانتا",
              "آدا",
              "آردندو 174",
              "آرندو 249",
              "آرلیکا",
              "آکو",
              "آلبا",
              "اتو",
              "اثبات",
              "احسان",
              "ار اس 841",
              "ارلیکا",
              "ازرا",
              "ازید",
              "اسپادا",
              "اسکندریه",
              "اسما",
              "اس وی ار 13056682",
              "اقصر",
              "البرز",
              "النا",
              "الینا",
              "الیون",
              "اولیویا",
              "اونیکس",
              "باترنات سوپریم",
              "بوترنات اولیه",
              "بوش",
              "بیتا",
              "پارس",
              "پاسکال",
              "پالوما",
              "پلوتون",
              "پی اس 13056719",
              "پیکاسو",
              "تارا",
              "تلما",
              "جمیلا",
              "چای",
              "دارین",
              "دافنه",
              "دلو",
              "دنا",
              "دنیا",
              "رابر",
              "رحمت",
              "رهام",
              "روناش",
              "ریندالا",
              "زینا",
              "سابینا",
              "سایر",
              "سلمان",
              "سما",
              "سمر",
              "سها",
              "سولکا",
              "سیاره",
              "سیدن",
              "سیما",
              "شیراز",
              "شینتوزا",
              "صدومان",
              "صوفیه",
              "فلوریدا",
              "فوروت 249",
              "قهرمان",
              "کارامبا",
              "کارملیتا",
              "کارولینا",
              "کاریسما",
              "کاسپین",
              "کاملیا",
              "کلاریتا",
              "کلاریتا اس ال",
              "کوردلیا",
              "کولیندا",
              "کویرینال",
              "گلدما",
              "گلدن",
              "گنا",
              "لولا",
              "لین",
              "ماتیلدا",
              "ماکسین",
              "ماهور",
              "مایاده",
              "مهر",
              "نوبل",
              "نور",
              "نویرا",
              "نیکو",
              "هابیت",
              "هدف",
              "والتام باترنات",
              "وانا",
              "یسنا",
              "یوسرا",
            ],
          },
        ],
      },
      {
        name: "گرمک",
        secondSubDomain: [
          {
            name: "گرمک",
            productItem: ["گرمک"],
          },
        ],
      },
      {
        name: "لیف یا لوفا",
        secondSubDomain: [
          {
            name: "لیف یا لوفا",
            productItem: ["لیف یا لوفا"],
          },
        ],
      },
      {
        name: "هندوانه",
        secondSubDomain: [
          {
            name: "هندوانه",
            productItem: [
              "003 امریکن سید",
              "008 سی ان یوز (cnus)",
              "043 ارگون",
              "168 آمریکن سید",
              "1740 اینوواسید",
              "1748 اینوواسید",
              "243 ساکاتا",
              "ائودری (اودری)",
              "آتلانتا هولار",
              "آتلانتیس",
              "آجیلی بیهق ثمین",
              "آدنا",
              "آراشان",
              "آر دی زد",
              "آرگتو",
              "آرمان",
              "آرماندو",
              "آریو آتلانتیک",
              "آریوس",
              "آستاراخان",
              "آگوستا",
              "آگوستا سینجنتا",
              "آوا",
              "آوارد کوانتوم",
              "آوالون",
              "آوا ناسکو",
              "اچ 52 آسگرو",
              "ارگون 103",
              "ارلی دراگون",
              "اسپیدی",
              "اسپیدی دونگیا",
              "استار پلاس جنتو",
              "استارفیلد",
              "استورم گلوریا",
              "استون یو اس",
              "اسکارلت",
              "اسکارلت ناسکو",
              "اسکندر گلوریا",
              "افرا ناسکو",
              "اکستریم",
              "اکسدرا سمینیس",
              "اگروتیپ",
              "التا",
              "الدورادو",
              "الیت",
              "ام 2154 اینوواسید",
              "اماسید",
              "امرالد",
              "امید مریدیم",
              "انرژی",
              "اوپال",
              "اودیسی ساکاتا",
              "اورکا سمان",
              "اولسوننکه",
              "ای ال اس 62 الماس سید",
              "ایسترن",
              "اینوواسید",
              "باراکا",
              "بارلو سینجنتا",
              "بذر هندوانه رستا اینوواسید",
              "برتونا رکسوان",
              "بست یلد اماسید",
              "بلک جک",
              "بلوکی جم استون پریموس سید",
              "بونانزا",
              "بونتا سمینیس",
              "بی 232 الیت",
              "بی 32",
              "بی 34 سمینیس",
              "بی 52 باکسیل",
              "بیکر",
              "پاسکال جنتو",
              "پالادین ساکاتا",
              "پالرمو",
              "پانیذ فاین سید",
              "پاور سید وی",
              "پترا ریچموند",
              "پروسید",
              "پلاریس",
              "پوپ",
              "پوست سفید",
              "پی اس بی 445 دیاموند",
              "تاپگان",
              "تاپ لایف کل ام",
              "تاپ یلد ساکاتا",
              "تاکی (تیتان)",
              "تاکی (گلوری جامبو)",
              "تامرلن",
              "تگزاس",
              "تندر",
              "توتان نیو ورد سید",
              "توربو",
              "توربو پریموس سید",
              "توسین",
              "ثمر یو اس",
              "جم استون",
              "جنان اگروتیپ",
              "جوبیلی اینوواسید",
              "جوکر جنتو",
              "(چارلستون) 110 ارگون",
              "(چارلستون) آتلانتیس",
              "(چارلستون) ادنا",
              "(چارلستون) الیت",
              "(چارلستون) امرالد",
              "(چارلستون)اورست ",
              "(چارلستون)اینوواسید",
              "(چارلستون)بونانزا",
              "(چارلستون) بیکر",
              "(چارلستون) پروسید",
              "(چارلستون) پلاریس",
              "(چارلستون) پوپ",
              "(چارلستون)زیدا",
              "(چارلستون) سه حلقه",
              "(چارلستون) کلوز",
              "(چارلستون) کوانتوم",
              "(چارلستون) کی سی",
              "(چارلستون)کینگ دیسکاوری",
              "(چارلستون) نیاگارا",
              "(چارلستون) نیوورد سید",
              "(چارلستون) هد",
              "(چارلستون) وجتیبل",
              "(چارلستون) ویکیما",
              "(چارلستون) ویلمورین",
              "(چارلستون) یو اس اگرسید",
              "چارلی کانیون",
              "چارمینگ یو اس",
              "حبوب",
              "داکورا",
              "داکورا کانیون",
              "دایتونا",
              "دراگون",
              "دنور هولار",
              "دومارا",
              "دونگا یو اس اگرسید",
              "دونگیا",
              "دیانا",
              "دیانا لویدا ژنتیک",
              "دیسکاوری",
              "ردهون",
              "ردهون سمینیس",
              "رستا اینوواسید",
              "روبی ساکاتا",
              "روبیکان کانیون",
              "رودخان اماسید",
              "روزن سید",
              "روکسان (رکسان)",
              "ریا",
              "ریور 32",
              "زودیاک نیکرسون",
              "ساتبرگ",
              "ساکاتا",
              "سالوادور",
              "سالینا اینوواسید",
              "سامانتا یونیژن",
              "سانتاماتیلده",
              "ساندیا هلندز ادن",
              "سانمیک",
              "سایر",
              "سرویس پلاس",
              "سناتور کل ام",
              "سوئیت استون ورلد واید",
              "سوپر دراگون دونگیا",
              "سوپر ساترن میکادو",
              "سوران",
              "سورن",
              "سورن لویدا ژنتیک",
              "سونا باکسیل",
              "سوییت استون",
              "سی اس",
              "سیالوما",
              "سی ان یوز (cnus)",
              "سینجنتا wdl 8040",
              "شریف آباد",
              "شهرزاد روزن سید",
              "شوش امرالد سید",
              "شوگر بلک استار تراست سید",
              "شوگر بیبی اگوستا سیدوی ",
              "شوگر بیبی آگوستا سینجنتا",
              "شوگر بیبی اپال پریموس سید",
              "شوگر بی بی انگوریا",
              " بلک جگ نویا سید (شوگر بی بی)",
              "(شوگر بی بی) پانگ سید",
              "(شوگر بی بی) دیسکاوری",
              "(شوگر بی بی) سمینیس",
              "(شوگر بی بی) فلات",
              "(شوگر بی بی) یونیژن",
              "(شوگر) ساهارا یو اس اگرسید",
              "شیرین فاین سید",
              "شیرین گلوریا",
              "عرش الیت",
              "فارائو",
              "فالکون کانیون",
              "فایربال ساکاتا",
              "فریزین یوکسل",
              "فونیکس تاکی",
              "قرقی",
              "کارناک یونیژن",
              "کارول رکسوان",
              "کارولینا جی اس ان",
              "کارولینا هولار",
              "کاریزنو",
              "کاریستان",
              "کاسپین",
              "کالیفرنیا",
              "کانیون",
              "کرست هولار",
              "کرون سید",
              "کریسپید ساکاتا",
              "کریمسون آسگرو",
              "کریمسون پلاس هولار",
              "کسری",
              "کل ام",
              "کلوز",
              "کوآنتوم",
              "کوماندو",
              "کیانا",
              "کیتو 12 استارسید",
              "کیتو 16 استارسید",
              "کیتو وایت",
              "کی سی",
              "کیهان استار سید",
              "گاردسکو",
              "گالا",
              "گالاتا سینجنتا",
              "گالینا",
              "گالینا (قالینا) سینجنتا",
              "گرد تاکی (یونیکورن)",
              "گریفاتون",
              "گلد 20 توکیتا",
              "گلد گریس میکادو",
              "گلکسی گلدن سید",
              "لوبکس نانهمز",
              "لیانکا",
              "لیمان ناسکو",
              "لینکا هولار",
              "ماداگا ویلمورین",
              "مارال ",
              "مارتا یو اس",
              "مارس",
              "مارس پریموس سید",
              "مارشال ",
              "ماکرو سمینیس",
              "محبوبی",
              "مراد یو اس اگرسید",
              "مکان گیرنده",
              "مگنوم امرالد",
              "ملیسا اینوواسید",
              "ملیسا اینوواسید",
              "منارا",
              "مونتانا",
              "موهپو اینوواسید",
              "میراج",
              "میراژ",
              "میرسینی سینجنتا",
              "میکا اینووا",
              "میکادو گرد ساترن",
              "ناسکو",
              "ناواژو (ناواجو) کانیون",
              "نستور کانیون",
              "نیاگارا",
              "نیان",
              "نیو ورد سید",
              "هایتک",
              "هد",
              "هکتور گلوریا",
              "هلیا اینوواسید",
              "هورتوس",
              "هورتی سید",
              "هولار",
              "هیبرید اکسدرا سمینیس",
              "هیبرید سانمیک",
              "وجتیبل",
              "وردا نیکرسون",
              "ورلد کوئین میکادو",
              "ونوس اماسید",
              "ویکیما",
              "وینال",
              "وینستون سید وی",
              "یامی آپولو",
              "یلدا آپولو",
              "یواس اگر سید",
              "یونیژن",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "علوفه",
    firstSubDomain: [
      {
        name: "ذرت علوفه ای",
        secondSubDomain: [
          {
            name: "ذرت علوفه ای",
            productItem: [
              "1203",
              "6589",
              "703",
              "704 برکت جوین",
              "704 مغان",
              "agn 601 آمریکن زنتیک",
              "agn 720",
              "agn 720 آمریکن ژنتیکد",
              "bc 678",
              "آفرین آمریکن ژنتیک",
              "انجل",
              "اروند امریکن ژنتیک",
              "اگزوپری",
              "اگزوپری رایال",
              "ان اس",
              "ان اس 640",
              "اینکا",
              "اینکا ماگرووت",
              "بالاتون",
              "براتلا",
              "برکت جوین",
              "بی کی 66",
              "جولی آمریکن ژنتیک",
              "راکوئل",
              "زد پی 600",
              "سایر",
              "سلیم",
              "شیرین تمپتیشن",
              "عجیب",
              "عجیب رایال",
              "فراز آمریکن ژنتیک",
              "فونیکس",
              "فونیکس سینجنتا",
              "کوپر",
              "ماکا",
              "کوردونا گلدن وست",
              "ماکا",
              "ماکسیما ایرانی",
              "ماکسیما مجارستان",
              "مای 8970",
              "هازار بیوتک",
              "هکتور",
              "همیلتون آمریکن ژنتیک",
              "هیبرید سینگل کراس BC678",
              "هیدو مای",
              "وایپر",
              "وایپر فوربیسید",
              "ویچیتا",
              "ویچیتا آمریکن ژنتیک",
            ],
          },
        ],
      },
      {
        name: "سایر نباتات علوفه ای",
        secondSubDomain: [
          {
            name: "ارزن علوفه ای",
            productItem: ["Nutrifeed", "باستان", "پیشاهنگ", "سایر"],
          },
          {
            name: "اسپرس خشک",
            productItem: [
              "بردبار 1",
              "بردبار 2",
              "بهار",
              "سایر",
              "کوهین",
              "مختار",
            ],
          },
          {
            name: "چاودار علوفه ای ",
            productItem: ["چاودار علوفه ای "],
          },
          {
            name: "چغندر علوفه ای",
            productItem: [
              "alianka",
              "feldher",
              "jamon",
              "kyros",
              "timbale",
              "سایر",
              "کارا",
            ],
          },
          {
            name: "خلر",
            productItem: ["آراز", "سایر"],
          },
          {
            name: "سایر نباتات علوفه ای",
            productItem: ["سایر نباتات علوفه ای"],
          },
          {
            name: "سودانگراس",
            productItem: ["سودانگراس"],
          },
          {
            name: "سورگوم علوفه ای",
            productItem: [
              "اسپیدفید",
              "بهشت",
              "پگاه",
              "جامبو",
              "سایر",
              "شوگرگریز",
              "منصور",
            ],
          },
          {
            name: "شلغم علوفه ای",
            productItem: ["شلغم علوفه ای"],
          },
          {
            name: "گاودانه",
            productItem: ["گاودانه"],
          },
          {
            name: "ماشک",
            productItem: ["سایر", "طلوع", "گل ", "گلشن", "لامعی", "مراغه"],
          },
          {
            name: "منداب",
            productItem: ["منداب"],
          },
          {
            name: "هویج علوفه ای",
            productItem: ["هویج علوفه ای"],
          },
        ],
      },
      {
        name: "شبدر",
        secondSubDomain: [
          {
            name: "شبدر تر",
            productItem: ["شبدر تر"],
          },
          {
            name: "شبدر خشک",
            productItem: ["البرز", "پارس", "سایر", "نسیم"],
          },
        ],
      },
      {
        name: "قصیل",
        secondSubDomain: [
          {
            name: "سایر قصیل",
            productItem: ["سایر قصیل"],
          },
          {
            name: "قصیل جو",
            productItem: [
              "بهرخ",
              "سایر",
              "فراز",
              "مهتاب",
              "نانیوس",
              "نصرت",
              "یوسف",
            ],
          },
        ],
      },
      {
        name: "یونجه خشک",
        secondSubDomain: [
          {
            name: "یونجه خشک",
            productItem: [
              "آذر",
              "آهنگ",
              "اتلانتیک",
              "امید",
              "اورستان",
              "بالتیک",
              "بوفالو",
              "پرواس",
              "تالنت",
              "رنجر",
              "رولاک",
              "سایر",
              "قره یونجه",
              "کانادایی",
              "کدی",
              "کورسا",
              "کوزاک",
              "گریم",
              "لوکسور",
              "مائوپا",
              "مساسیرسا",
              "موآپا",
              "ناراگامنت",
              "نفیس",
              "هاردیگان",
              "هاریسان",
              "ویلیامس یورگ",
              "یونجه بغدادی",
              "یونجه بمی",
              "یونجه شیرازی",
              "یونجه نیک شهری",
              "یونجه یزدی",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "کشت مخلوط (چند محصولی)",
    firstSubDomain: [
      {
        name: "زراعت و زنبورداری",
        secondSubDomain: [
          {
            name: "عسل و سایر",
            productItem: ["عسل و سایر"],
          },
        ],
      },
      {
        name: "کشتهای مختلف",
        secondSubDomain: [
          {
            name: "کشتهای مختلف",
            productItem: ["کشتهای مختلف"],
          },
        ],
      },
      {
        name: "مرتعدای و زنبورداری",
        secondSubDomain: [
          {
            name: "عسل،گرده و ژل رویال",
            productItem: ["عسل،گرده و ژل رویال"],
          },
        ],
      },
    ],
  },
  {
    name: "سایر محصولات زراعی",
    firstSubDomain: [
      {
        name: "تولید بذر",
        secondSubDomain: [
          {
            name: "تولید بذر",
            productItem: ["تولید بذر"],
          },
        ],
      },
      {
        name: "جارو",
        secondSubDomain: [
          {
            name: "جارو",
            productItem: ["جارو"],
          },
        ],
      },
      {
        name: "خردل",
        secondSubDomain: [
          {
            name: "خردل",
            productItem: ["صادق", "سایر"],
          },
        ],
      },
      {
        name: "سایر محصولات زراعی",
        secondSubDomain: [
          {
            name: "سایر محصولات زراعی",
            productItem: ["سایر محصولات زراعی"],
          },
        ],
      },
      {
        name: "کینوآ",
        secondSubDomain: [
          {
            name: "کینوآ",
            productItem: ["رحمت", "صدوق", "سایر"],
          },
        ],
      },
      {
        name: "مارچوبه",
        secondSubDomain: [
          {
            name: "مارچوبه",
            productItem: ["مارچوبه"],
          },
        ],
      },
    ],
  },
  {
    name: "میوه های دانه دار",
    firstSubDomain: [
      {
        name: "به",
        secondSubDomain: [
          {
            name: "به",
            productItem: [
              "به اصفهان",
              "به پاکوتاه",
              "بهتا",
              "به ترش",
              "به شاهرودی",
              "به قرمز",
              "به کوزه ای",
              "به گلابی",
              "به محلی",
              "سایر",
              "ویدیوجا",
            ],
          },
        ],
      },
      {
        name: "سیب",
        secondSubDomain: [
          {
            name: "سیب ترش",
            productItem: [
              "بوسکوپ",
              "سایر",
              "گرانی اسمیت",
              "گراوان اشتاین",
              "مک انیتاش",
            ],
          },
          {
            name: "سیب زرد",
            productItem: [
              "اخملد مشهد",
              "اوزارک گلد",
              "پرایم گلد",
              "سایر",
              "سیب زرد لبنان (گلدن دلیشز)",
              "سیب فوجی",
              "قره قاچ",
              "کاکس ارنج",
              "گلدن اسموتی",
              "گلشاهی",
              "یلو اسپور",
              "یلو تر انسپارنت",
            ],
          },
          {
            name: "سیب قرمز",
            productItem: [
              "آستاراخان قرمز",
              "آل رد جاناتان",
              "ارلی ردوان",
              "اسپارتان",
              "استیمن واینسپ",
              "اهر",
              "اورگون اسپور",
              "اورلئان",
              "جاناتان",
              "جوناتان",
              "حیدرزاده",
              "دلبار استیوال",
              "رد اسپور دلیشز",
              "ردچیف",
              "رم بیوتی",
              "زنوز",
              "سایر",
              "سلطانی شبستر",
              "سوپر چیف",
              "سیب فوجی",
              "سیب قرمز لبنان یا رد دلیشز",
              "سیب گالا",
              "شربتی",
              "شفیع آباد",
              "شمیرانی",
              "شیخ احمد",
              "عباسی",
              "قرمز رضاییه",
              "قره یرپاغ",
              "کوپرفوز",
              "کولی محلات",
              "گل بهار",
              "گلوکناپفل",
              "مپایر آل رد",
              "محله شیخی",
              "مراغه",
              "مربایی",
              "مشهد",
              "مشهد نوری",
              "نوردرن اسپای",
              "واین سپ",
              "ولثی",
            ],
          },
          {
            name: "سیب گلاب",
            productItem: [
              "سایر",
              "گلاب اصفهان",
              "گلاب تربت حیدریه",
              "گلاب شهریار",
              "گلاب شیراز",
              "گلاب کهنز",
            ],
          },
          {
            name: "سایر سیب ",
            productItem: ["سایر سیب "],
          },
        ],
      },
      {
        name: "گلابی",
        secondSubDomain: [
          {
            name: "گلابی",
            productItem: [
              "آسیایی (سیبی)",
              "انجو (قرمز)",
              "اباته فتل",
              "اسپادانا",
              "بارتلت",
              "بلا دی جونیو",
              "بیروتی",
              "پکهامز تریومف",
              "پیرودوارف",
              "تاشکندی",
              "درگزی",
              "دم کجی",
              "دویان دو کومیس",
              "سانتا ماریا",
              "سایر",
              "سبری",
              "سردودی",
              "سه فصله",
              "سوپر الیوت",
              "سیف تبریز",
              "شاه میوه",
              "شینگو",
              "فلسطینی",
              "قوسی",
              "کوشیا",
              "لوییزبون",
              "محمد علی",
              "مشو",
              "ملینا",
              "نطنزی",
              "وحشی",
              "ویلیام دوشس",
            ],
          },
        ],
      },
      {
        name: "سایر میوه های دانه دار",
        secondSubDomain: [
          {
            name: "سایر میوه های دانه دار",
            productItem: ["سایر میوه های دانه دار"],
          },
        ],
      },
    ],
  },
  {
    name: "میوه های هسته دار",
    firstSubDomain: [
      {
        name: "آلبالو",
        secondSubDomain: [
          {
            name: "آلبالو",
            productItem: [
              "اسرائیلی",
              "بوترمو",
              "سایر",
              "سیکانی",
              "فرنگی",
              "گوشواره ای",
              "گیسی",
              "مجاری",
              "محلی",
              "مورلو",
              "مونت مونسی",
            ],
          },
        ],
      },
      {
        name: "آلو",
        secondSubDomain: [
          {
            name: "آلو",
            productItem: [
              "آلینا",
              "آنجلینو",
              "اختری",
              "اراک",
              "ارلیگلدن",
              "استانلی",
              "بخارا",
              "بلک آمبر",
              "بلک استار",
              "بلک دیاموند",
              "بوراکا",
              "بور بانک",
              "پرزیدنت",
              "تخم مرغی",
              "تنسگل",
              "تو سرخ",
              "دامسونز",
              "رویال بلک",
              "رین کلود",
              "زرد استنلی",
              "زوککلا",
              "سانتارزا",
              "سانتروزا",
              "سایر",
              "سیمکا",
              "شابلون",
              "شبرنگ",
              "شوگر ",
              "شیرو",
              "غلامان",
              "غول پاییز",
              "گالیفرنیا",
              "لاتیتیا",
              "لاوردا",
              "لری ان",
              "لومبارد",
              "مراغه",
              "هانی رزا",
              "هاوران",
              "واعظی",
              "یلو اگ",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
          },
        ],
      },

      {
        name: "آلو قطره طلا",
        secondSubDomain: [
          {
            name: "آلو قطره طلا",
            productItem: ["آلو قطره طلا"],
          },
        ],
      },
      {
        name: "زردآلو و قیسی",
        secondSubDomain: [
          {
            name: "زردآلو قیسی",
            productItem: [
              "آپری گولد",
              "آلفرد",
              "آیباتان",
              "اردوباد",
              "اردوباد 90",
              "السگردی",
              "انبه ی",
              "باقری",
              "بوداپیست",
              "پاترسون",
              "پارسی",
              "پرتغالی",
              "تبرزه",
              "تومکوت",
              "تیلتون",
              "جهانگیری",
              "چتری (مجنون)",
              "درخشان",
              "رجبعلی",
              "رکسانا",
              "رویال",
              "سایر",
              "شانلی",
              "شصتمی",
              "شکرپاره",
              "شمس",
              "فالمینگ گولد",
              "فالور کوت",
              "قربان مراغه-سهند",
              "قربان مراغه-شاهرود",
              "قرمز شاهرود",
              "قیصی",
              "قیصی اصفهان",
              "کاستل بریت",
              "کانینو-کرج",
              "کانینو-مشهد",
              "کانیوا",
              "کتی",
              "گلدریچ",
              "گونسی ماجیار",
              "لاسگردی",
              "مراغه 90",
              "ملایر",
              "مورپارک",
              "نادری",
              "نخجوان",
              "نصیری ",
              "نصیری90",
              "هارالین",
              "هارکوت",
              "هارگلو",
              "هارمات",
              "هاروبلوش اچ دابلیو 436",
              "هاروبلوش اچ دابلیو 446",
              "هاروبلوش اچ دابلیو 449",
              "هنگاریان بست",
              "و هفت برادران تسوج",
            ],
          },
        ],
      },
      {
        name: "شفتالو",
        secondSubDomain: [
          {
            name: "شفتالو",
            productItem: ["شفتالو"],
          },
        ],
      },
      {
        name: "شلیل",
        secondSubDomain: [
          {
            name: "شلیل",
            productItem: [
              "استارد گلد",
              "انجیری",
              "ایندیپندنس",
              "ردگلد",
              "رفعتی",
              "زرد مشهد",
              "سانکینک",
              "سان گلد",
              "سایر",
              "شبرنگ",
              "شمس",
              "فانتازیا",
              "قرمز بهاره",
              "کیوتا",
              "مغان",
            ],
          },
        ],
      },
      {
        name: "گوجه سبز",
        secondSubDomain: [
          {
            name: "گوجه سبز",
            productItem: [
              "آذرشهر",
              "برغان",
              "ترکیه",
              "سایر",
              "سررودی",
              "سلطانی",
              "شهریار",
              "صفا",
              "قرمز",
              "گلاب",
              "مراغه",
              "ملایر",
              "نخجوان",
            ],
          },
        ],
      },
      {
        name: "گیلاس",
        secondSubDomain: [
          {
            name: "گیلاس",
            productItem: [
              "90",
              "آرسینا",
              "آمبرانس",
              "آنابلا",
              "ارلی ریورز",
              "امپرورفرانسیس",
              "بوتنرزاسات",
              "پیش رس ",
              "پیش رس مششهد",
              "تک دانه",
              "تک دانه مشهد",
              "توس",
              "چینوک",
              "حاجی یوسفی",
              "حاجی یوسفی 2",
              "دانشکده",
              "دلارکا",
              "دل مونت",
              "دوم رس مشهد",
              "رژینا",
              "رضاییه",
              "زرد 90",
              "زرد دانشکده",
              "زرشک",
              "سایر",
              "سیلویا",
              "شبستر",
              "شیشه ای مشهد",
              "عدلی",
              "فرنگی",
              "کولنی",
              "کاستور",
              "گیزیلا",
              "مشهد",
              "ناپلئونی",
            ],
          },
        ],
      },
      {
        name: "هلو",
        secondSubDomain: [
          {
            name: "هلو",
            productItem: [
              "GF677",
              "آلبرتا",
              "آلستار",
              "آمسدون",
              "آیتم استار",
              "اسپرینگ تایم",
              "اسپرینگ کرست",
              "اسپرینگ بل",
              "استار فایر",
              "الی لستار",
              "انجیری",
              "ایرلی رد",
              "ایرلی گلو",
              "بابی گلد",
              "برایت استار",
              "پاییزی",
              "پلنگی",
              "جوادی",
              "جی اچ هال",
              "حاج کاظمی",
              "دکسی رد",
              "رداسکین",
              "ردتاپ",
              "ردهاون",
              "رم استار",
              "رم فایت",
              "روبین",
              "زعفرانی",
              "سانکرست",
              "سایر",
              "سرخابی",
              "شاستا",
              "عسلی",
              "فرانسه",
              "کاردی",
              "کرال استار",
              "گلوئینک استار",
              "لورینگ",
              "مریل سونداس",
              "هسته جدا",
              "هلو بلشینگ",
              "هلو سبز",
              "ولد اباد",
              "ولوت",
            ],
          },
        ],
      },
      {
        name: "سایر میوه های هسته دار",
        secondSubDomain: [
          {
            name: "سایر میوه های هسته دار",
            productItem: ["سایر میوه های هسته دار"],
          },
        ],
      },
    ],
  },
  {
    name: "میوه های دانه ریز",
    firstSubDomain: [
      {
        name: "انگور",
        secondSubDomain: [
          {
            name: "انگور",
            productItem: [
              "آنتپ",
              "اسپوتا",
              "امید",
              "امین",
              "بلک سیدلس",
              "بیدانه",
              "بیدانه قرمز",
              "پرلت",
              "پیکانی کاشمر",
              "تامسون سید لس",
              "ترکمن",
              "توبی سیدلس",
              "چفته ",
              "خلیلی",
              "خوشنار",
              "راژن",
              "ردکارنت",
              "ردگلوب",
              "رشه ",
              "ریش بابا",
              "ریش بابا قرمز",
              "سایر انگور",
              "سیادس",
              "شامپاین",
              "شاهانی",
              "شاهرودی",
              "شصت عروس",
              "شمشیری",
              "شوگروان (سوپریوم)",
              "صاحبی",
              "عزتی",
              "عسگری",
              "فخری",
              "فسا",
              "فلیم سیدلس",
              "قرمز",
              "کررویه",
              "کریمسون",
              "کلاهداری",
              "لعل",
              "مایان",
              "مکیاج",
              "موسکات",
              "موندراپ",
              "میش پستان",
              "مینودر",
              "ناظمیه",
              "یاقوتی",
              "یزدان دائی (گزندایی)",
            ],
          },
        ],
      },
      {
        name: "بری",
        secondSubDomain: [
          {
            name: "انواع بری",
            productItem: ["انواع بری"],
          },
        ],
      },
      {
        name: "تمشک",
        secondSubDomain: [
          {
            name: "تمشک",
            productItem: ["تمشک"],
          },
          {
            name: "تمشک بنفش",
            productItem: ["Royaltry", "سایر", "سودوس"],
          },
          {
            name: "تمشک زرد",
            productItem: ["پاسسز طلائی", "تمشک طلائی", "سایر", "کهربا"],
          },
          {
            name: "تمشک سیاه",
            productItem: [
              "بریستول",
              "بی تیغ فرانسه",
              "توپای",
              "داندی",
              "سایر",
              "سیلوان",
              "کومبرلند",
              "لوگان بری",
              "ماریون",
              "مرتون",
            ],
          },
          {
            name: "تمشک قرمز",
            productItem: [
              "(bababerry) بابابری",
              "Heritage",
              "Latham",
              "Prelude",
              "Primocane",
              "بوین",
              "تئودر ریمر",
              "تمشک ژاپنی",
              "سایر",
              "کانبی",
              "ماموت",
              "ولیرکان",
              "ویلسون",
            ],
          },
        ],
      },
      {
        name: "توت درختی",
        secondSubDomain: [
          {
            name: "توت سفید",
            productItem: ["توت سفید"],
          },
          {
            name: "توت سیاه",
            productItem: ["توت سیاه"],
          },
          {
            name: "شاه توت",
            productItem: ["شاه توت"],
          },
          {
            name: "نوغان ابریشم",
            productItem: ["نوغان ابریشم"],
          },
          {
            name: "سایر توت",
            productItem: ["سایر توت"],
          },
        ],
      },
      {
        name: "توت فرنگی",
        secondSubDomain: [
          {
            name: "توت فرنگی",
            productItem: [
              "آلبا",
              "آلیسو",
              "اتابکی",
              "اسیتا",
              "الگانس",
              "پاجارو",
              "پورتولا",
              "توری",
              "تیوگا",
              "چندلر",
              "ردگانتلت",
              "رومینا",
              "سمفونی",
              "فالمنکو",
              "فرسنا",
              "کاتس کیل",
              "گورلا",
              "لامبادا",
              "ونتا",
              "ویکتوریا",
            ],
          },
        ],
      },
      {
        name: "سایر میوه های دانه ریز",
        secondSubDomain: [
          {
            name: "سایر میوه های دانه ریز",
            productItem: ["سایر میوه های دانه ریز"],
          },
        ],
      },
    ],
  },
  {
    name: "میوه های خشک",
    firstSubDomain: [
      {
        name: "بادام",
        secondSubDomain: [
          {
            name: "بادام",
            productItem: [
              "K-16-25",
              "آذر",
              "آراز",
              "آیدین",
              "اسکندر",
              "بی نظیر",
              "پسته ای",
              "تاجری",
              "تونو",
              "حریر",
              "خوشه ای (سوپرنوا)",
              "ربیع",
              "سایر",
              "سنگی مشهد",
              "سهند",
              "شاهرود 21",
              "شکوفه",
              "شمشیری",
              "صبا",
              "فرانیس",
              "کاغذی",
              "مامایی",
              "محب علی",
              "منقا",
              "نوپاریل",
              "یلدا",
            ],
          },
        ],
      },
      {
        name: "پسته",
        secondSubDomain: [
          {
            name: "پسته",
            productItem: [
              "احمد آقایی",
              "اکبری",
              "امیر",
              "اوحدی",
              "بادامی",
              "بهار",
              "چروک",
              "خنجری دامغان",
              "رضایی زودرس",
              "سایر",
              "شاه پسند",
              "عباسعلی",
              "فندقی 48",
              "کله بزی",
              "کله قوچی",
              "ممتاز",
            ],
          },
        ],
      },
      {
        name: "پکان",
        secondSubDomain: [
          {
            name: "پکان",
            productItem: ["پکان"],
          },
        ],
      },
      {
        name: "سنجد",
        secondSubDomain: [
          {
            name: "سنجد",
            productItem: [
              "حاج حسین",
              "حاج رحیمی",
              "خرمایی",
              "خوشه ای",
              "دره وار",
              "سایر",
              "قزل",
              "کلاغی",
              "محلی",
              "نقلی",
            ],
          },
        ],
      },
      {
        name: "فندق",
        secondSubDomain: [
          {
            name: "فندق",
            productItem: [
              "انیس ترکیه",
              "باتلر",
              "بارسلونا",
              "پاییزه",
              "پشمینه 89",
              "تابستانه",
              "توندا",
              "داویانا",
              "دوبول و ویللر",
              "دو پیمون",
              "رسمی",
              "زنجان",
              "سایر",
              "سگورب",
              "شصتک",
              "شیروانی",
              "فرتیل دوکوتارد",
              "قزوین",
              "قم",
              "کاسفورد",
              "گرچه",
              "گرداشکورات",
              "گردوئی 89",
              "لونگ داسپاین",
              "ماکادمیا",
              "محلی کرج",
              "ناوان",
              "نخجوان",
              "نگرت",
            ],
          },
        ],
      },
      {
        name: "گردو",
        secondSubDomain: [
          {
            name: "گردو",
            productItem: [
              "B21",
              "K72",
              "Z53",
              "Z60",
              "Z67",
              "اسرائیلی",
              "الوند",
              "ایتاکا یونانی",
              "بلیچک",
              "پاریزین",
              "پدرو",
              "پرشیا",
              "پیکان",
              "پورتوریکو",
              "تخم مرغی",
              "توسرخ",
              "تویسرکان",
              "جمال",
              "جمال z63",
              "چالدران",
              "چندلر",
              "خوشه ای",
              "دماوند",
              "روند دمونتیگناک",
              "سانلند",
              "سایر",
              "سجنو",
              "شبین",
              "ضیاء آباد",
              "فرانکت",
              "فرنور",
              "کاسپین",
              "کاما",
              "کانادایی",
              "گردو شیلی",
              "لارا",
              "مراغه",
              "هارتلی",
              "وینا",
            ],
          },
        ],
      },
      {
        name: "سایر میوه های خشک",
        secondSubDomain: [
          {
            name: "سایر میوه های خشک",
            productItem: ["سایر میوه های خشک"],
          },
        ],
      },
    ],
  },
  {
    name: "میوه های سردسیری",
    firstSubDomain: [
      {
        name: "ازگیل",
        secondSubDomain: [
          {
            name: "ازگیل",
            productItem: ["پیوندی", "جنگلی", "ژاپنی", "سایر", "محلی"],
          },
        ],
      },
      {
        name: "زالزالک",
        secondSubDomain: [
          {
            name: "زالزالک",
            productItem: [
              "اروپایی",
              "خارتیر",
              "زرد",
              "سایر",
              "سبز",
              "سرخ ولیک",
              "شرقی",
              "قرمز",
              "کرکی",
              "مکزیکی",
              "وحشی",
            ],
          },
        ],
      },
      {
        name: "زرشک",
        secondSubDomain: [
          {
            name: "زرشک",
            productItem: [
              "اورینتالیس",
              "اینترگریما",
              "بی دانه",
              "پفکی",
              "داروینی",
              "دانه اناری",
              "دنسی فلورا",
              "ژاپنی",
              "ژولیانه",
              "سایر",
              "فورتونئی",
              "کراتاجینا",
              "کوهی",
              "نومولاریا",
              "ولکاریس",
            ],
          },
        ],
      },
      {
        name: "زغال اخته",
        secondSubDomain: [
          {
            name: "زغال اخته",
            productItem: [
              "آسترالیز",
              "آلبا",
              "آنکینا",
              "الگانت",
              "اورآ",
              "پاییزه",
              "پیونیر",
              "جولیکو",
              "رد استار",
              "رداستون",
              "سایر",
              "فرانسه",
              "",
              "",
              "",
              "سایر",
              "فرانسه",
              "فروکتا ویولاسه",
              "فلاوا",
              "فلوریدا",
              "فومینا",
              "کاغذی",
              "کانادنیس",
              "گردشیرین",
              "گلابی شکل",
              "گلدن گلوری",
              "ماکروکارپا",
              "محلی",
              "نانا",
              "نورمن هودن",
              "هیایر آپرایت",
              "وریگیتا",
            ],
          },
        ],
      },
      {
        name: "سماق",
        secondSubDomain: [
          {
            name: "سماق",
            productItem: [
              "آمریکایی",
              "ایرانی",
              "خوشه ای",
              "سایر",
              "سماق قرمز",
              "سماق کردستان",
              "کوهی",
              "محلی",
            ],
          },
        ],
      },
      {
        name: "عناب",
        secondSubDomain: [
          {
            name: "عناب",
            productItem: [
              "بیرجند",
              "ترکیه",
              "چینی",
              "سایر",
              "سیوجان",
              "قائنات",
              "کلون تجاری",
              "لانگ",
              "لی",
              "ماژان",
            ],
          },
        ],
      },
      {
        name: "سایر میوه های سردسیری",
        secondSubDomain: [
          {
            name: "سایر میوه های سردسیری",
            productItem: ["سایر میوه های سردسیری"],
          },
        ],
      },
    ],
  },
  {
    name: "مرکبات",
    firstSubDomain: [
      {
        name: "پرتقال",
        secondSubDomain: [
          {
            name: "پرتقال",
            productItem: [
              "الیند والنسیا",
              "بروهن",
              "بیروتی",
              "پارسون براون",
              "پارنت ناول",
              "پاین اپل",
              "تاروکو",
              "تامسون ناول",
              "تمپل",
              "روبی",
              "رود رد",
              "سالوستیانا",
              "سانگینلا",
              "سایر",
              "سیاورز",
              "شموطی",
              "فراست ناول",
              "فراست والنسیا",
              "کاراکارا",
              "کمپل والنسیا",
              "گروس سانگین",
              "لین لیت",
              "مارس",
              "مورو",
              "ناولیت",
              "ناولینا",
              "نیوهال",
              "هاملین",
              "واشنگتن ناول",
              "والنسیا",
            ],
          },
        ],
      },
      {
        name: "گریپ فروت",
        secondSubDomain: [
          {
            name: "گریپ فروت",
            productItem: [
              "استار روبی",
              "تامپسون",
              "دانکن",
              "ردبلاش",
              "سایر",
              "مارش",
            ],
          },
        ],
      },
      {
        name: "لیموترش",
        secondSubDomain: [
          {
            name: "لیموترش",
            productItem: [
              "اورکا",
              "پرشین لایم",
              "تاهیتی",
              "سایر",
              "عمانی",
              "کالامانسی",
              "لیسبون",
              "مکزیکن لایم",
              "میر",
            ],
          },
        ],
      },
      {
        name: "لیمو شیرین",
        secondSubDomain: [
          {
            name: "لیمو شیرین",
            productItem: ["لیمو شیرین"],
          },
        ],
      },
      {
        name: "نارنج",
        secondSubDomain: [
          {
            name: "نارنج",
            productItem: ["خوشه ای", "سایر", "معمولی"],
          },
        ],
      },
      {
        name: "نارنگی",
        secondSubDomain: [
          {
            name: "نارنگی",
            productItem: [
              "ارلاندو تانجلو",
              "انشو معمولی",
              "پرنا",
              "پونکن",
              "جهانگیر",
              "خرم",
              "دنسی",
              "سایر",
              "شاهین",
              "کارا",
              "کلمانتین کادوس",
              "کینو",
              "مینئولا تانجلو",
              "نارنگی پیچ",
              "نوشین",
              "ورا",
              "یاشار",
            ],
          },
        ],
      },
      {
        name: "سایر مرکبات",
        secondSubDomain: [
          {
            name: "سایر مرکبات",
            productItem: ["سایر مرکبات"],
          },
        ],
      },
    ],
  },
  {
    name: "میوه های نیمه گرمسیری",
    firstSubDomain: [
      {
        name: "انار",
        secondSubDomain: [
          {
            name: "انار",
            productItem: [
              "اردستانی",
              "الک",
              "بجستانی",
              "تبریز",
              "خزر",
              "دانه",
              "رباب",
              "رباب نیریز",
              "زاغ",
              "سایر",
              "شیرین شهوار",
              "شیشه گپ",
              "قجاق",
              "گل تفتی",
              "ملس ساوه",
              "ملس عقداء",
              "ملس یزدی",
              "میخوش",
              "نادری",
              "یوسف خانی",
            ],
          },
        ],
      },
      {
        name: "انجیر",
        secondSubDomain: [
          {
            name: "انجیر تازه",
            productItem: [
              "المی",
              "انجیر زرد",
              "انجیر قهوه ای",
              "برانجیر پوزدنبالی",
              "برانجیر دانه",
              "چناری",
              "دهدز",
              "رونو",
              "سان پدرو",
              "سایر",
              "قرمز",
              "کشکی",
              "کلسته",
              "گلابی",
              "متی",
              "مشکی",
              "منجیفی",
            ],
          },
          {
            name: "انجیر خشک",
            productItem: [
              "انجیر زد",
              "انجیر قهوه ای",
              "بردانه",
              "پیوس",
              "چناری",
              "رونو",
              "سایر",
              "سبز استهبال",
              "کالی میرنا",
              "کلسته",
              "کوداتا",
              "منجیفی",
              "میسیشن",
            ],
          },
        ],
      },
      {
        name: "چای",
        secondSubDomain: [
          {
            name: "چای",
            productItem: ["سایر", "لاهیج", "کاشف"],
          },
        ],
      },
      {
        name: "خرمالو",
        secondSubDomain: [
          {
            name: "خرمالو",
            productItem: [
              "ایورکا",
              "تاموپان",
              "تانناشی",
              "تریومف",
              "ژاپنی",
              "سایر",
              "سایی جو",
              "سیبی",
              "شنگ",
              "گریت وال",
              "گوجه ای",
              "موزی انبه ای",
              "هاشیا",
              "هانون رد",
              "یوکونو",
            ],
          },
        ],
      },
      {
        name: "زیتون",
        secondSubDomain: [
          {
            name: "زیتون",
            productItem: [
              "آربکین",
              "آسکوالنا",
              "آمیگدالولیا",
              "آوان",
              "الک ترکیه",
              "امین",
              "بلیدی",
              "تخم کبکی",
              "دراک",
              "دزفول",
              "دزفول - صفی آباد",
              "دزفولی فارس (دوستی)",
              "دهقان",
              "دیره",
              "روغنی طارم",
              "زرد",
              "زرد گلوله",
              "سایر",
              "سویالنا",
              "شنگه",
              "شیراز",
              "فرانتویو",
              "فیشمی",
              "کراتینا",
              "کرونیکی",
              "کنسروالیا",
              "لچینو",
              "ماری",
              "مانزانیال",
              "ماوی باغملک",
              "محزم ابوسطل",
              "مشکات",
              "میشن",
            ],
          },
        ],
      },
      {
        name: "کیوی",
        secondSubDomain: [
          {
            name: "کیوی",
            productItem: ["ابوت", "برنو", "سایر", "مونتی", "هایوارد"],
          },
        ],
      },
      {
        name: "نخل",
        secondSubDomain: [
          {
            name: "نخل",
            productItem: [
              "آبو",
              "آزاد",
              "آزار",
              "استعمران",
              "برحی",
              "بریم",
              "بریمی",
              "بلیانی",
              "بیگم جنگی",
              "پنجه عروس",
              "پوپو",
              "پورکو (شمسایی)",
              "پیارم",
              "پیمازو",
              "تورز",
              "توری",
              "حاج قنبری",
              "حاجی محمدی",
              "حسن مهدی",
              "حلاوی",
              "خاصویی",
              "خاصی",
              "خصب",
              "خضراوی",
              "خنیزی",
              "دگلت نور",
              "دهدار مرادی",
              "دیری",
              "ربی",
              "رنگنو",
              "زاهدی",
              "سایر",
              "سنگ شکن (اشکندک)",
              "سویدانی",
              "شاهخونی",
              "شکر",
              "شهابی",
              "شهابی (شهابی تلخو)",
              "عویدی",
              "فرسی",
              "قربانی",
              "قصب",
              "قندی",
              "کبکاب",
              "کبکاب سرخ",
              "کریته",
              "کلگی",
              "کلوته",
              "گنطار",
              "گیوونی",
              "لشت",
              "ماکیلی",
              "مجول",
              "مرادسنگ",
              "مراسنگ(مردارسنگ)",
              "مضافتی",
              "مکتوب (مکتوم)",
              "نه دانه گزی",
              "نیکدینی (نیک الدینی)",
              "هلیله ای",
              "هلیله گون",
            ],
          },
        ],
      },
      {
        name: "سایر میوه های نیمه گرمسیری",
        secondSubDomain: [
          {
            name: "سایر میوه های نیمه گرمسیری",
            productItem: ["سایر میوه های نیمه گرمسیری"],
          },
        ],
      },
    ],
  },
  {
    name: "میوه های  گرمسیری",
    firstSubDomain: [
      {
        name: "آناناس",
        secondSubDomain: [
          {
            name: "آناناس",
            productItem: [
              "اسپانیایی",
              "ام-دی-2",
              "پرنامبوکو",
              "سایر",
              "کاینه",
              "ملکه",
            ],
          },
        ],
      },
      {
        name: "انبه",
        secondSubDomain: [
          {
            name: "انبه",
            productItem: [
              "آلفونسو",
              "آلمهتری",
              "انوار رتاول",
              "بلک چونسا",
              "تامی اتکینز",
              "چارک",
              "خوشه ای",
              "داشهری (دوسهری،داشری)",
              "دودو",
              "سامر باهشیت چونسا",
              "سایر",
              "سندری",
              "سنسیشن",
              "شاهانی",
              "شوز انبه",
              "فجری",
              "قیاسی",
              "کالنفر",
              "کنسینگتون پراید",
              "کیت",
              "گلن",
              "گیالسی",
              "لانگرا",
              "لیت رتاول (رتاول-12)",
              "مالیکا",
              "مجلسی",
              "مشک",
              "میخک",
              "نام داک مای",
              "نغال",
              "هادان",
              "هلیلی",
              "هندی",
              "وایت چونسا",
            ],
          },
        ],
      },
      {
        name: "پاپایا",
        secondSubDomain: [
          {
            name: "پاپایا",
            productItem: [
              "جیانت",
              "دلیشیز",
              "دوارف",
              "سان رایز-سولو",
              "سایر",
              "کرگ-هانی دیو",
              "کوتری (co.3)",
              "کوتو (co.2)",
              "کو سیکس(co.6)",
              "کو فایو (co.5)",
              "کو فور (co.4)",
              "کو وان (co.1)",
              "مجستی",
              "هان دیو",
              "واشینگتن",
              "ویمانلو",
            ],
          },
        ],
      },
      {
        name: "تمرهندی",
        secondSubDomain: [
          {
            name: "تمرهندی",
            productItem: ["تمرهندی"],
          },
        ],
      },
      {
        name: "چیکو",
        secondSubDomain: [
          {
            name: "چیکو",
            productItem: ["چیکو"],
          },
        ],
      },
      {
        name: "کنار",
        secondSubDomain: [
          {
            name: "کنار",
            productItem: [
              "ایلاچی",
              "بدون هسته",
              "بنارسی کادانا",
              "بهزادی",
              "بوشهری",
              "جوجیا",
              "حنیفی",
              "خاوران",
              "داندان",
              "رملیک",
              "سایر",
              "سنور",
              "سوپی",
              "سورتی کاتا",
              "سیبی",
              "سیو",
              "عمران",
              "فرزادی",
              "کاتا پال",
              "کیتالی",
              "گولا",
              "مهران",
              "موریتانیایی",
            ],
          },
        ],
      },
      {
        name: "گواوا",
        secondSubDomain: [
          {
            name: "گواوا",
            productItem: [
              "الله آباد",
              "بیومونت",
              "پاتیلو",
              "روبی",
              "سایر",
              "فانریتیف",
              "کاهو-کوا",
              "مکزیکن کرم",
              "هنگ کنگ پینک",
              "وایت ایندیا",
            ],
          },
        ],
      },
      {
        name: "موز",
        secondSubDomain: [
          {
            name: "موز",
            productItem: [
              "بونتا",
              "پوآن",
              "دوارف کاوندیش",
              "راستالی",
              "سایر",
              "سفت ولچی",
              "گروس میشل",
              "لال کلا",
              "نندران",
              "هاریچال",
            ],
          },
        ],
      },
      {
        name: "نارگیل",
        secondSubDomain: [
          {
            name: "نارگیل",
            productItem: ["نارگیل"],
          },
        ],
      },
      {
        name: "سایر میوه های گرمسیری",
        secondSubDomain: [
          {
            name: "سایر میوه های گرمسیری",
            productItem: ["سایر میوه های گرمسیری"],
          },
        ],
      },
    ],
  },
  {
    name: "باغات مخلوط (چندمحصول)",
    firstSubDomain: [
      {
        name: "باغ و زراعت",
        secondSubDomain: [
          {
            name: "میوه و محصولات زراعی",
            productItem: ["میوه و محصولات زراعی"],
          },
        ],
      },
      {
        name: "باغ و زنبورداری",
        secondSubDomain: [
          {
            name: "میوه و عسل",
            productItem: ["میوه و عسل"],
          },
        ],
      },
      {
        name: "درختان مختلف",
        secondSubDomain: [
          {
            name: "انواع میوه",
            productItem: ["انواع میوه"],
          },
        ],
      },
    ],
  },
  {
    name: "محصولات گلخانه ای",
    firstSubDomain: [
      {
        name: "بادنجان گلخانه ای",
        secondSubDomain: [
          {
            name: "بادنجان گلخانه ای",
            productItem: [
              "1574 سمینیس",
              "1779 سمینیس",
              "آرلن سمینیس",
              "آنامور رکسوان",
              "اسکارلتی",
              "اسکورپیو",
              "ای جی 302",
              "بارتوک انزا",
              "بلک پرل",
              "بلن سمینیس",
              "تاروس",
              "جگوار",
              "دنیز",
              "راف",
              "روناک",
              "ساکاتا 1882",
              "سالی رکسوان",
              "سایر",
              "سیسیلیا فیتو",
              "فاسیلیس",
              "گالین",
              "گرتا",
              "گرین دیاموند",
              "لانگو",
              "لایر رکسوان",
              "لیما فیتو",
              "ملوسینا",
              "میروال",
              "هادریان",
            ],
          },
        ],
      },
      {
        name: "توت فرنگی گلخانه ای",
        secondSubDomain: [
          {
            name: "توت فرنگی گلخانه ای",
            productItem: [
              "آلبیون",
              "استرا",
              "پاروس",
              "سایر",
              "سیلوا",
              "کاماروسو",
              "کردستان",
              "بوئین زهرا",
              "گاوبوتا",
              "هومی چن",
            ],
          },
        ],
      },
      {
        name: "خیار گلخانه ای",
        secondSubDomain: [
          {
            name: "خیار گلخانه ای",
            productItem: [
              "485 نیکرسون",
              "Ps-29033",
              "آتا",
              "آترین",
              "آدرین سالار",
              "آر زد",
              "آریا",
              "آصف",
              "آلفرد",
              "آلوین",
              "آمفوروت",
              "امیرال",
              "آویسا",
              "احسان",
              "اسپادانا",
              "اسپارک",
              "اسپریت کانیون",
              "اسپینو",
              "استورم",
              "اسمارت ویلمورین",
              "اطلس گلوریا",
              "اکلیپس",
              "الدیس",
              "MRC",
              "اماسید",
              "اورانو",
              "ایدر ساکاتا",
              "ایساتیس",
              "اینجی انزا",
              "ایور",
              "باراکودا",
              "بالستیک",
              "برهان",
              "بهار",
              "بیتا",
              "پالیز",
              "پایلوت",
              "پدارم",
              "پدیده",
              "پویا",
              "پیک اسکور",
              "تالیسیا",
              "تانیا",
              "ترمه",
              "تریتون",
              "تونکا",
              "تیدا",
              "ثمن",
              "ثنا",
              "جزیل",
              "خسیب",
              "داتیس",
              "دانیتو",
              "دبورا تاکی",
              "دریا",
              "دلتا",
              "دلتا استار",
              "دلوکس",
              "دنیا",
              "رادیانت",
              "رادین",
              "رانیم",
              "روبرتو",
              "رویال",
              "ریپلی",
              "زئوس پلاریس",
              "زبرا",
              "زحل",
              "ژاپنی",
              "ساتورن",
              "سامان",
              "سامر استار",
              "سانتور",
              "سایر",
              "سزار",
              "سلطان",
              "سلینوس",
              "سما",
              "سما 2",
              "سمیر",
              "سنم",
              "سها",
              "سهم",
              "سوپر دومینوس",
              "یودا آتلانتیک",
              "سوکراتس",
              "سوین",
              "سینا",
              "شهاب",
              "صبا",
              "صحرا",
              "صدرا",
              "صدف",
              "صنم",
              "طاها",
              "فادیا",
              "کاترینا",
              "کاریز",
              "کریم گاوریش",
              "کیان",
              "کیمیا",
              "کیهان",
              "کیوالیف",
              "کیولایف",
              "گالاردو",
              "گرین مجیک",
              "گوهر",
              "لادیس",
              "لامبدا",
              "لانا",
              "ماتیلدا",
              "مارکت مور",
              "محلی",
              "مکسول",
              "مهر",
              "مونزا",
              "ناگین",
              "ناهید",
              "نبیل",
              "ندا",
              "نستار",
              "نسیم",
              "نوادا",
              "نوبل",
              "نوین",
              "هستی",
              "هلیوم اتلانتیکا",
              "هیلتون",
              "واتیکان",
              "وارنا جی وی سید",
              "ویستا",
              "ویلا",
              "ویلمورین",
              "ویهان",
              "ویوال",
              "ویولا",
              "یازان",
              "یکتا فیتو",
              "یلدا",
              "یونس",
            ],
          },
        ],
      },
      {
        name: "درختچه زینتی گلخانه ای",
        secondSubDomain: [
          {
            name: "درختچه زینتی گلخانه ای",
            productItem: ["درختچه زینتی گلخانه ای"],
          },
        ],
      },
      {
        name: "سایر سبزی و صیفیجات گلخانه ای",
        secondSubDomain: [
          {
            name: "سایر سبزی و صیفیجات گلخانه ای",
            productItem: ["سایر سبزی و صیفیجات گلخانه ای"],
          },
        ],
      },
      {
        name: "سایر کشتهای گلخانه ای",
        secondSubDomain: [
          {
            name: "سایر کشتهای گلخانه ای",
            productItem: ["سایر کشتهای گلخانه ای"],
          },
        ],
      },
      {
        name: "فلفل گلخانه ای",
        secondSubDomain: [
          {
            name: "فلفل دلمه گلخانه ای",
            productItem: [
              "1421",
              "7414",
              "آوانته",
              "ارانیکا",
              "اینسپریشن",
              "پارامو",
              "پلوتونیو",
              "تارانتو",
              "دنیرو",
              "دوتان",
              "دی کاپریو",
              "رامونا",
              "روبینا",
              "سایر",
              "سیرتو",
              "شانگهای",
              "کائوبا",
              "کادیا",
              "کارول",
              "گاسپل",
              "گلد فیلم",
              "لورکا",
              "لوموس",
              "لیاد",
              "مارکوزا",
              "مازونا",
              "مگنو",
              "نیروین",
              "ورگاسا",
              "وست لند 1225",
              "وست لند 2090",
              "وست لند 4305",
              "وست لند 5345",
              "وست لند 8104",
            ],
          },
          {
            name: "فلفل قلمی گلخانه ای",
            productItem: [
              "آناتولین کانیون",
              "ارمغان اگروتیپ",
              "ایران بذر",
              "ایندام 67",
              "پویا سید",
              "پیمان",
              "پینوکیو کانیون",
              "تانگو یو اس",
              "تندر",
              "راما ثمین",
              "رد واندر کورگان",
              "سانمیک",
              "سایر",
              "سیگارتا",
              "سینگر",
              "عنبری",
              "کنزو",
              "کیزیل آرگتو",
              "گازر",
              "گرین چیلی",
              "لامبادا",
              "مرت",
              "میانکشی",
              "نارین ثمین",
              "ناز",
              "هاشمی پی اس",
              "هالوپینو 1037",
              "هانا ثمین",
              "وانیا سید",
              "ویکونا",
            ],
          },
          {
            name: "فلفل کاپی گلخانه ای",
            productItem: [
              "اچ کا 400",
              "بلیسا رکسوان",
              "ترکیه",
              "تیزنا",
              "تیزنا یوکسل",
              "رکسوان",
              "سایر",
              "سمرقند",
              "گلدیسا رکسوان",
              "مجارستان",
              "میراکل جنتو",
              "هلندیسا رکسوان",
            ],
          },
        ],
      },
      {
        name: "گل شاخه بریده گلخانه ای",
        secondSubDomain: [
          {
            name: "گل شاخه بریده گلخانه ای",
            productItem: ["رز", "سایر"],
          },
        ],
      },
      {
        name: "گل و گیاهان زینتی گلدانی گلخانه ای",
        secondSubDomain: [
          {
            name: "گل و گیاهان زینتی گلدانی گلخانه ای",
            productItem: [
              "کنف نیوزلندی",
              "کوردیلین",
              "لاله واژگون",
              "لیسیانتوس",
              "لیندا",
              "مارچوبه زینتی",
              "مونسترا",
              "میخک هندی",
              "نخل ماداگاسکار",
              "نخل مرداب",
              "نرگس",
              "نی خیزران",
              "یاسمن هندی",
              "یوکا",
              "ارالیا",
              "آزالیا",
              "آکوبا",
              "آنمون",
              "آهار",
              "اریکا",
              "اسپاتی فیلوم",
              "استرلیتزیا",
              "استکانی",
              "اشرفی",
              "اطلسی",
              "برگ انجیری",
              "برگ بیدی",
              "برگ عبایی",
              "بگونیا",
              "بنت القنسول",
              "بنفشه",
              "بنفشه آفریقایی",
              "بونسای فیکوس میکرو کارپا",
              "پامچال",
              "پاندانوس",
              "پتوس",
              "پیرومیا",
              "پیله آ",
              "تاج الملوک",
              "تاج خروس",
              "جعفری",
              "حنا",
              "داوودی",
              "دراسنا",
              "دیفن باخیا",
              "زامی فولیا",
              "ژربرا",
              "سانسوریا",
              "سایر",
              "ستاره ای",
              "سوسن چلچراغ",
              "سیکلامن",
              "سینگونیوم",
              "شامودرا",
              "شفلرا",
              "شمعدانی",
              "فیتونیا",
              "فیکوس بنجامین",
              "فیلودندرون",
              "کاخ مطبق",
              "کالادیوم",
              "کالانکوئه",
              "کروتون",
              "کلم زینتی",
              "کنتیا",
            ],
          },
        ],
      },
      {
        name: "گل و گیاهان زینتی نشائی گلخانه ای",
        secondSubDomain: [
          {
            name: "گل و گیاهان زینتی نشائی گلخانه ای",
            productItem: ["گل و گیاهان زینتی نشائی گلخانه ای"],
          },
        ],
      },
      {
        name: "گوجه فرنگی گلخانه ای",
        secondSubDomain: [
          {
            name: "گوجه فرنگی گلخانه ای",
            productItem: [
              "1012",
              "3275 سمینیس",
              "4129",
              "7806",
              "آذین جنتو",
              "آراگون",
              "النا",
              "الویت",
              "اویسیس کلوز",
              "ایزابلا",
              "ایزومونو",
              "ای سرن",
              "اینفینیتی",
              "اینیستا",
              "پارمیس",
              "پردیس",
              "پلاترو",
              "جگوار",
              "جواهر",
              "دافینیس",
              "رخسار",
              "روبی",
              "ساخیا",
              "سانتیاگو",
              "سایر",
              "سمیرا",
              "سولماز",
              "سیتا",
              "سیران",
              "سیریو پروفیت",
              "سیلویانا",
              "شقایق",
              "شهرزاد",
              "عسل",
              "فادو",
              "فسا",
              "فیلوس",
              "کاپیتان",
              "کلوز فرانسه",
              "کوئین",
              "کومودو",
              "گلدی",
              "گلکسی",
              "ماسا",
              "مایا",
              "نمارین",
              "نورا",
              "نیلوفر",
              "نیوتن",
              "هالی334",
              "هانا",
              "هانی",
              "هدیه",
              "همرس",
              "هوران",
              "هیراد",
              "هیرو",
              "والنسپا",
              "والور",
              "یاقوت",
            ],
          },
        ],
      },
      {
        name: "گیاهان داروئی گلخانه ای",
        secondSubDomain: [
          {
            name: "گیاهان داروئی گلخانه ای",
            productItem: ["گیاهان داروئی گلخانه ای"],
          },
        ],
      },
    ],
  },
  {
    name: "گیاهان داروئی",
    firstSubDomain: [
      {
        name: "آلوئه ورا",
        secondSubDomain: [
          {
            name: "آلوئه ورا",
            productItem: ["آلوئه ورا (صبر زرد)"],
          },
        ],
      },
      {
        name: "آنغوزه",
        secondSubDomain: [
          {
            name: "آنغوزه",
            productItem: ["آنغوزه"],
          },
        ],
      },
      {
        name: "آویشن",
        secondSubDomain: [
          {
            name: "آویشن",
            productItem: [
              "اوراسیاسی",
              "پشمی",
              "ثومون",
              "خزنده",
              "دنایی",
              "زیره ای",
              "سایر",
              "سوسنبر",
              "لیمویی",
              "معمولی",
              "مورودر",
              "",
            ],
          },
        ],
      },
      {
        name: "اسطوخودوس",
        secondSubDomain: [
          {
            name: "اسطوخودوس",
            productItem: ["اسطوخودوس"],
          },
        ],
      },
      {
        name: "بابونه",
        secondSubDomain: [
          {
            name: "بابونه",
            productItem: ["آلمانی", "سایر ", "گاوی"],
          },
        ],
      },
      {
        name: "بادرنجبویه",
        secondSubDomain: [
          {
            name: "بادرنجبویه",
            productItem: ["بادرنجبویه"],
          },
        ],
      },
      {
        name: "باریجه",
        secondSubDomain: [
          {
            name: "باریجه",
            productItem: ["باریجه"],
          },
        ],
      },
      {
        name: "بومادران",
        secondSubDomain: [
          {
            name: "بومادران",
            productItem: ["بومادران"],
          },
        ],
      },
      {
        name: "پنیرک",
        secondSubDomain: [
          {
            name: "پنیرک",
            productItem: ["پنیرک"],
          },
        ],
      },
      {
        name: "پونه",
        secondSubDomain: [
          {
            name: "پونه",
            productItem: ["پونه"],
          },
        ],
      },
      {
        name: "چای ترش",
        secondSubDomain: [
          {
            name: "چای ترش",
            productItem: ["چای ترش"],
          },
        ],
      },
      {
        name: "حنا",
        secondSubDomain: [
          {
            name: "حنا",
            productItem: ["حنا"],
          },
        ],
      },
      {
        name: "خاکشیر",
        secondSubDomain: [
          {
            name: "خاکشیر",
            productItem: ["خاکشیر"],
          },
        ],
      },
      {
        name: "رازیانه",
        secondSubDomain: [
          {
            name: "رازیانه",
            productItem: ["رازیانه"],
          },
        ],
      },
      {
        name: "رزماری",
        secondSubDomain: [
          {
            name: "رزماری",
            productItem: ["رزماری"],
          },
        ],
      },
      {
        name: "رناس",
        secondSubDomain: [
          {
            name: "رناس",
            productItem: ["رناس"],
          },
        ],
      },
      {
        name: "زعفران",
        secondSubDomain: [
          {
            name: "زعفران",
            productItem: ["ایران", "محلی ", "سایر"],
          },
        ],
      },
      {
        name: "زنیان",
        secondSubDomain: [
          {
            name: "زنیان",
            productItem: ["زنیان"],
          },
        ],
      },
      {
        name: "زیره سبز",
        secondSubDomain: [
          {
            name: "زیره سبز",
            productItem: [
              "آب شیرین کاشان",
              "اسلام آباد قاین",
              "بیابانک سمنان",
              "بیمرق گناباذ",
              "تبریز",
              "تربت جام",
              "خیرآباد سمنان",
              "دستگردان طبس",
              "سایر",
              "سبزوار",
              "شیراز",
              "طالخونچه اصفهان",
              "عشق آباد نیشابور",
              "فردوس",
              "فیض آباد تربت حیدریه",
              "کاشمر",
              "کرمان",
              "کفکی نیشابور",
            ],
          },
        ],
      },
      {
        name: "زیره سیاه",
        secondSubDomain: [
          {
            name: "زیره سیاه",
            productItem: ["زیره"],
          },
        ],
      },
      {
        name: "سیاه دانه",
        secondSubDomain: [
          {
            name: "سیاه دانه",
            productItem: ["سیاه دانه"],
          },
        ],
      },
      {
        name: "شیرین بیان",
        secondSubDomain: [
          {
            name: "شیرین بیان",
            productItem: ["شیرین بیان"],
          },
        ],
      },
      {
        name: "گزنه",
        secondSubDomain: [
          {
            name: "گزنه",
            productItem: ["گزنه"],
          },
        ],
      },
      {
        name: "گل ختمی",
        secondSubDomain: [
          {
            name: "گل ختمی",
            productItem: ["گل ختمی"],
          },
        ],
      },
      {
        name: "گل راعی",
        secondSubDomain: [
          {
            name: "گل راعی",
            productItem: ["گل راعی"],
          },
        ],
      },
      {
        name: "گل گاو زبان",
        secondSubDomain: [
          {
            name: "گل گاو زبون",
            productItem: ["گل گاو زبون"],
          },
        ],
      },
      {
        name: "مرزنجوش",
        secondSubDomain: [
          {
            name: "مرزنجوش",
            productItem: ["مرزنجوش"],
          },
        ],
      },
      {
        name: "موسیر",
        secondSubDomain: [
          {
            name: "موسیر",
            productItem: ["موسیر"],
          },
        ],
      },
      {
        name: "نعناع فلفلی",
        secondSubDomain: [
          {
            name: "نعناع فلفلی",
            productItem: ["نعناع فلفلی"],
          },
        ],
      },
      {
        name: "وسمه",
        secondSubDomain: [
          {
            name: "وسمه",
            productItem: ["وسمه"],
          },
        ],
      },
      {
        name: "سایر گیاهان داروئی",
        secondSubDomain: [
          {
            name: "سایر گیاهان داروئی",
            productItem: ["سایر گیاهان داروئی"],
          },
        ],
      },
    ],
  },
  {
    name: "گل و گیاهان زینتی ",
    firstSubDomain: [
      {
        name: "درخت و درختچه زینتی",
        secondSubDomain: [
          {
            name: "درخت و درختچه زینتی",
            productItem: [
              "آریستولوشیا",
              "پیچ آبجویی",
              "پیچ امین الدوله",
              "پیچ پلی کونوم",
              "پیچ تلگرافی",
              "پیچ کلسین ",
              "پیچ کلماتیس",
              "پیچ موری",
              "درخت ارغوان",
              "درخت افرا",
              "درخت اقاقیا",
              "درخت بید",
              "درخت پالم",
              "درخت پر",
              "درخت توری",
              "درختچه اسپیره",
              "درخچه بداغ",
              "درخچه به ژاپنی",
              "درخچه پیراکانتا",
              "درخچه ختمی",
              "درخچه خرزهره",
              "درخچه رز",
              "درخجه زرشک زینتی",
              "درخچه کاملیا",
              "درخجه گل کاغذی",
              "درخچه گل یخ",
              "درخچه مروارید",
              "درخچه یاس",
              "درخت شمشاد",
              "درخت ماگنولیای",
              "درخت نارون",
              "سایر",
              "شمشاد پیچ",
              "فیکوس پیچ",
              "کاج الدار",
              "کاج بروسیا",
              "کاج پیناستر",
              "کاج جنگلی",
              "کاج حلب",
              "کاج دریایی",
              "کاج سیاه",
              "کاج قناری",
              "کاج کاشفی",
              "معین التجار",
              "موچسب",
              "هورتانسیای پیچ",
              "یاس برفی",
              "یچ ساعتی",
              "یلوفر پیچ",
            ],
          },
        ],
      },
      {
        name: "گل شاخه بریده",
        secondSubDomain: [
          {
            name: "گل شاخه بریده",
            productItem: ["گل شاخه بریده"],
          },
        ],
      },
      {
        name: "گل محمدی",
        secondSubDomain: [
          {
            name: " گلستان (گل محمدی)",
            productItem: [" گلستان (گل محمدی)"],
          },
        ],
      },
      {
        name: "گل و گیاهان زینتی گلدانی",
        secondSubDomain: [
          {
            name: "گل و گیاهان زینتی گلدانی",
            productItem: ["گل و گیاهان زینتی گلدانی"],
          },
        ],
      },
      {
        name: "گل و گیاهان زینتی نشائی",
        secondSubDomain: [
          {
            name: "گل و گیاهان زینتی نشائی",
            productItem: ["گل و گیاهان زینتی نشائی"],
          },
        ],
      },
    ],
  },

  {
    name: "نهالستان و غیرثمر ",
    firstSubDomain: [
      {
        name: "درختان غیر مثمر",
        secondSubDomain: [
          {
            name: "درختان غیر مثمر",
            productItem: ["درختان غیر مثمر"],
          },
        ],
      },
      {
        name: "زراعت چوب",
        secondSubDomain: [
          {
            name: "زراعت چوب",
            productItem: [
              "اکالیپتوس",
              "پالونیا",
              "سایر",
              "صنوبر البرز",
              "صنوبر رحمتی",
              "صنوبر سالاری",
              "صنوبر گیلدار",
              "صنوبر مفید",
              "گز شاهی",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
          },
        ],
      },
      {
        name: "نهالستان",
        secondSubDomain: [
          {
            name: "نهالستان",
            productItem: ["نهالستان"],
          },
        ],
      },
    ],
  },
  {
    name: "قارچ ",
    firstSubDomain: [
      {
        name: "قارچ دکمه ای",
        secondSubDomain: [
          {
            name: "قارچ دکمه ای",
            productItem: ["قارچ دکمه ای"],
          },
        ],
      },
      {
        name: "قارچ صدفی",
        secondSubDomain: [
          {
            name: "قارچ صدفی",
            productItem: ["قارچ صدفی"],
          },
        ],
      },
      {
        name: "سایر قارچ ها",
        secondSubDomain: [
          {
            name: "سایر قارچ ها",
            productItem: ["سایر قارچ ها"],
          },
        ],
      },
    ],
  },
  {
    name: "سایر محصولات باغی ",
    firstSubDomain: [
      {
        name: "ازگیل ژاپنی(گلابی جنگلی)",
        secondSubDomain: [
          {
            name: "ازگیل ژاپنی(گلابی جنگلی)",
            productItem: ["ازگیل ژاپنی(گلابی جنگلی)"],
          },
        ],
      },
      {
        name: "سایر محصولات باغی",
        secondSubDomain: [
          {
            name: "سایر محصولات باغی",
            productItem: ["سایر محصولات باغی"],
          },
        ],
      },
    ],
  },
  {
    name: "دامپروری ",
    firstSubDomain: [
      {
        name: "پرورش شتر",
        secondSubDomain: [
          {
            name: "حاشی",
            productItem: [],
          },
          {
            name: "شیر شتر",
            productItem: [],
          },
          {
            name: "کود شتر",
            productItem: [],
          },
          {
            name: "گوشت شتر",
            productItem: [],
          },
        ],
      },
      {
        name: "پرورش گاو",
        secondSubDomain: [
          {
            name: "گوساله",
            productItem: [],
          },
          {
            name: "شیر گاو",
            productItem: [],
          },
          {
            name: "کود گاو",
            productItem: [],
          },
          {
            name: "گوشت گاو",
            productItem: [],
          },
        ],
      },
      {
        name: "پرورش گاومیش",
        secondSubDomain: [
          {
            name: "گوساله گاومیش",
            productItem: [],
          },
          {
            name: "شیر گاومیش",
            productItem: [],
          },
          {
            name: "کود گاومیش",
            productItem: [],
          },
          {
            name: "گوشت گاومیش",
            productItem: [],
          },
        ],
      },
      {
        name: "پرورش گوسفند و بز",
        secondSubDomain: [
          {
            name: "بره و بزغاله",
            productItem: [],
          },
          {
            name: "شیر گوسفند و بز",
            productItem: [],
          },
          {
            name: "کود گوسفند و بز",
            productItem: [],
          },
          {
            name: "گوشت بره، گوسفند و بز",
            productItem: [],
          },
        ],
      },
    ],
  },
  {
    name: "طیور ",
    firstSubDomain: [
      {
        name: "پرورش بلدرچین",
        secondSubDomain: [
          {
            name: "تخم بلدرچین",
            productItem: [],
          },
          {
            name: "کود بلدرچین",
            productItem: [],
          },
          {
            name: "گوشت بلدرچین",
            productItem: [],
          },
        ],
      },
      {
        name: "پرورش بوقلمون",
        secondSubDomain: [
          {
            name: "تخم بوقلمون",
            productItem: [],
          },
          {
            name: "کود بوقلمون",
            productItem: [],
          },
          {
            name: "گوشت بوقلمون",
            productItem: [],
          },
        ],
      },
      {
        name: "پرورش شترمرغ",
        secondSubDomain: [
          {
            name: "تخم شترمرغ",
            productItem: [],
          },
          {
            name: "سایر محصولات شترمرغ",
            productItem: [],
          },
          {
            name: "گوشت شترمرغ",
            productItem: [],
          },
        ],
      },
      {
        name: "پرورش کبک",
        secondSubDomain: [
          {
            name: "تخم کبک",
            productItem: [],
          },
          {
            name: "کود کبک",
            productItem: [],
          },
          {
            name: "گوشت کبک",
            productItem: [],
          },
        ],
      },
      {
        name: "پرورش مرغ بومی",
        secondSubDomain: [
          {
            name: "تخم مرغ محلی",
            productItem: [],
          },
          {
            name: "کود مرغی",
            productItem: [],
          },
          {
            name: "گوشت مرغ محلی",
            productItem: [],
          },
        ],
      },
      {
        name: "پرورش سایر طیور",
        secondSubDomain: [
          {
            name: "تخم سایر طیور",
            productItem: [],
          },
          {
            name: "گوشت سایر طیور",
            productItem: [],
          },
        ],
      },
      {
        name: "مرغداری صنعتی",
        secondSubDomain: [
          {
            name: "تخم مرغ",
            productItem: [],
          },
          {
            name: "گوشت مرغ",
            productItem: [],
          },
          {
            name: "کود مرغی",
            productItem: [],
          },
          {
            name: "جوجه یک روزه",
            productItem: [],
          },
          {
            name: "مرغ اجداد",
            productItem: [],
          },
          {
            name: "مرغ لاین",
            productItem: [],
          },
          {
            name: "مرغ مادر",
            productItem: [],
          },
        ],
      },
    ],
  },
  {
    name: "آبزیان",
    firstSubDomain: [
      {
        name: "خاویاری",
        secondSubDomain: [
          {
            name: "انواع خاویاری",
            productItem: [],
          },
        ],
      },
      {
        name: "سردآبی",
        secondSubDomain: [
          {
            name: "قزل آلا",
            productItem: [],
          },
        ],
      },
      {
        name: "صیادی",
        secondSubDomain: [
          {
            name: "صید جنوب",
            productItem: [],
          },
          {
            name: "صید شمال",
            productItem: [],
          },
        ],
      },
      {
        name: "گرم آبی",
        secondSubDomain: [
          {
            name: "کپورماهیان",
            productItem: [],
          },
        ],
      },
      {
        name: "میگو",
        secondSubDomain: [
          {
            name: "انواع میگو",
            productItem: [],
          },
        ],
      },
    ],
  },
  {
    name: "زنبورداری",
    firstSubDomain: [
      {
        name: "زنبورعسل",
        secondSubDomain: [
          {
            name: "ژل  رویال",
            productItem: [],
          },
          {
            name: "عسل",
            productItem: [],
          },
          {
            name: "گرده گل",
            productItem: [],
          },
        ],
      },
    ],
  },
  {
    name: "باغچه های شهری",
    firstSubDomain: [
      {
        name: "باغچه خانگی",
        secondSubDomain: [
          {
            name: "فضای سبز",
            productItem: [],
          },
        ],
      },
      {
        name: "باغ ویلا",
        secondSubDomain: [
          {
            name: "تفرجگاه",
            productItem: [],
          },
        ],
      },
      {
        name: "گلهای آپارتمانی",
        secondSubDomain: [
          {
            name: "طراوت محیط",
            productItem: [],
          },
        ],
      },
    ],
  },
];

export const productsEn = [
  {
    name: "Cereals",
    firstSubDomain: [
      {
        name: "Barley",
        secondSubDomain: [
          {
            name: "Barley",
            productItem: [
              "4 Shouri",
              "CB 79-10",
              "MB 83-3",
              "UH-12",
              "WB 81-15",
              "Abidar",
              "Azaran",
              "Artan",
              "Arda",
              "Aydin",
              "Armaghan",
              "Afsal",
              "Oxin",
              "Ansar",
              "Izeh",
              "Behdan",
              "Be Rokh",
              "Bahman",
              "Jolge",
              "Jonoub",
              "Khoram",
              "Dasht",
              "Rashin",
              "Roudasht",
              "Reyhan 03",
              "Zahak",
              "Sararoud1",
              "Sahand",
              "Shahbed",
              "Sahra",
              "Fajr 30",
              "Faraz",
              "Fardan",
              "Qaflan",
              "Kavir",
              "Garis",
              "Golchin",
              "Goharan",
              "Loot",
              "Makooi",
              "Nowbahar",
              "Nowrouz",
              "Vaksima",
              "Valfajr",
              "Nader",
              "Nosrat",
              "Vilma",
              "Yousef",
              "Other",
            ],
          },
        ],
      },
      {
        name: "Grain Corn",
        secondSubDomain: [
          {
            name: "Floury Grain Corn",
            productItem: [
              "AS 66",
              "AS 71",
              "Bc 582",
              "Bc 678",
              "BK 65",
              "DKC 6589",
              "DKC 6876",
              "KSC 301",
              "KSC 302",
              "KSC 500",
              "KSC 700",
              "KSC 703",
              "KSC 705",
              "KSC 706",
              "LG 367",
              "MV 500",
              "NK Agrano",
              "NK Galactic",
              "NK Vitorino",
              "NP 2128",
              "NS 3014",
              "NS 4015",
              "NS 444 Ultra",
              "NS 540",
              "NS 610",
              "NS 640",
              "NS 770",
              "OSSK 62",
              "safalz1",
              "zp 434",
              "zp 548",
              "zp 677",
              "zp 684",
              "zp 758",
              "Bolson",
              "Tono",
              "Jeta",
              "Jovin 1",
              "Chavosh",
              "Choukan",
              "Daminit",
              "Drama",
              "Dehghan",
              "Zarin 1",
              "Zarin 2",
              "Zarin 3",
              "Zola",
              "Sagunto",
              "Si Ino",
              "Simon",
              "Single Cross 301",
              "Single Cross 604 (Zarin)",
              "Single Cross 704",
              "Single Cross 711",
              "Factor NK",
              "Fajr",
              "Karoun",
              "Konsor",
              "Kordona",
              "Koosha",
              "Gazdar",
              "Lavera",
              "Masil",
              "Maxima",
              "Mobin",
              "Meh 7089",
              "Hector",
              "Walbaum",
            ],
          },
          {
            name: "Popped Grain Corn (Popcorn)",
            productItem: ["Popped Grain Corn (Popcorn)"],
          },
          {
            name: "Sweet Grain Corn",
            productItem: [
              "1203 Royal Sluis",
              "1208",
              "1263 Seminis",
              "1280 Phyto",
              "1446 Seminis",
              "SF 1280",
              "Apadana",
              "Apadana Monarch Seed",
              "Arsen",
              "Arsen KC",
              "Altin",
              "Amila",
              "Anjil",
              "Anjil Royal Sluis",
              "Ava",
              "Arvand American Genetic",
              "Erica",
              "Sponsor",
              "Sponsor KC",
              "Stily Abbot",
              "Excession 1073 Abbot",
              "Axen Chuit",
              "Eminent",
              "Eminent Antares",
              "Obsession",
              "Orland",
              "Baron",
              "Baron Mari",
              "Bazin R",
              "Balaton",
              "Bratella",
              "Bratella Flamingo Seed",
              "Bonanza",
              "Bonanza Close",
              "Bita Baker",
              "Bisin",
              "Passion",
              "Persuit",
              "Persuit Aristo Seed",
              "Producer Close",
              "Passion",
              "Target",
              "Tyson",
              "Tessa",
              "Temptation",
              "Jessica",
              "Genesis",
              "Jolie American Genetic",
              "Challenger",
              "Chase",
              "Khan",
              "Khan Mari",
              "Danica",
              "Driver Close",
              "Diana",
              "Raquel",
              "Raquel Close",
              "Royal Sluis",
              "Royal T",
              "Sahar",
              "Sahar CN Us",
              "Salim Inno Seed",
              "Samira",
              "Sentinel",
              "Sentinel Close",
              "Super Gold",
              "Shadab",
              "Shayan",
              "Shayan KC",
              "Shamim",
              "Shamim (CN US)",
              "Fergman",
              "Caramello",
              "Cumberland Close",
              "Cooper",
              "Gloria",
              "Liscum",
              "Liscum Close",
              "Linda",
              "Linda Magroot",
              "Mana",
              "Merit",
              "Messenger Seminis",
              "Mirza",
              "Harvest Gold",
              "Hazar",
              "Honey",
              "Vega",
              "Venus",
              "Other",
            ],
          },
          {
            name: "Other Corn Grains",
            productItem: ["Other Corn Grains"],
          },
        ],
      },
      {
        name: "Other Cereals",
        secondSubDomain: [
          {
            name: "Millet",
            productItem: [
              "Bastan",
              "Pishahang",
              "Chamagi",
              "Gavars",
              "Yellow Gavars",
              "Other",
            ],
          },
          {
            name: "Triticale",
            productItem: ["Paj", "Sanabad"],
          },
          {
            name: "Rye",
            productItem: ["helltop"],
          },
          {
            name: "Other Cereals",
            productItem: ["Other Cereals"],
          },
          {
            name: "Grain Sorghum",
            productItem: ["Payam", "Sepideh", "Fouman", "Kimia", "Other"],
          },
          {
            name: "Oat (Barley)",
            productItem: ["Euro", "Quoll"],
          },
        ],
      },
      {
        name: "Rice",
        secondSubDomain: [
          {
            name: "High Yield Rice",
            productItem: [
              "Anam",
              "Tisa",
              "Haj Heidari",
              "Khazar",
              "Dorfak",
              "Deylam",
              "Rash",
              "Roshan",
              "Sahel",
              "Sepidroud",
              "Setayesh",
              "Shafagh",
              "Shahriar",
              "Shiroodi",
              "Tolou",
              "Fajr",
              "Kadoos",
              "Keshvari",
              "Kian",
              "Gohar",
              "Guilar",
              "Guilaneh",
              "Neda",
              "Ne'mat",
              "Hashemi",
              "Helal",
              "Other",
            ],
          },
          {
            name: "Champa Rice",
            productItem: [
              "Charam 1",
              "Charam 2",
              "Charam 3",
              "Yasuj 1",
              "Yasuj 2",
              "Yasuj 3",
            ],
          },
          {
            name: "Amber Rice",
            productItem: ["Amber Rice"],
          },
          {
            name: "Kamfirouz Rice",
            productItem: ["Doroudzan"],
          },
          {
            name: "Lenjan Rice",
            productItem: ["Zayandeh Roud", "Sazandegi", "Koohsar"],
          },
          {
            name: "High Quality Northern Rice",
            productItem: ["High Quality Northern Rice"],
          },
          {
            name: "Other Types of Rice",
            productItem: ["Other Types of Rice"],
          },
        ],
      },
      {
        name: "Wheat",
        secondSubDomain: [
          {
            name: "Durum Wheat",
            productItem: [
              "Aban",
              "Aran",
              "Aria",
              "Avan",
              "Behrag",
              "Tabesh",
              "Sana",
              "Dena",
              "Dehdasht",
              "Zahab",
              "Saji",
              "Savroz",
              "Sepand",
              "Samireh",
              "Shabrang",
              "Karkheh",
              "Mahan",
              "Hana",
              "Yavaros",
              "Other",
            ],
          },
          {
            name: "Common Wheat",
            productItem: [
              "N-80-19",
              "Nai 60",
              "Shanghai.7",
              "WS-82-9",
              "Azar 2",
              "Azaran",
              "Araz",
              "Arta",
              "Artiman",
              "Arman",
              "Azadegan",
              "Azadi",
              "Aseman",
              "Aftab",
              "Annapurna",
              "Antonius",
              "Aineh",
              "Apache",
              "Atrak",
              "Ehsan",
              "Aramesh",
              "Aras Bar 94",
              "Arg",
              "Arum",
              "Star",
              "Aflak",
              "Alborz",
              "Alvand",
              "Amin",
              "Ouj",
              "Ohadi",
              "Ivan",
              "Baran",
              "Basilio",
              "Bamdad",
              "Barat",
              "Back Cross Roshan Bahareh",
              "Back Cross Roshan Zemestaneh",
              "Bam",
              "Bahar",
              "Bi Tap",
              "Parsi",
              "Paniz",
              "Paya",
              "Paraav",
              "Payam",
              "Pishtaz",
              "Pishgam",
              "Tejan",
              "Taftan",
              "Tak Ab",
              "Taktaz",
              "Tosan",
              "Tirgan",
              "Jam",
              "Jalal",
              "Cheroubly",
              "Chamran",
              "Chamran 2",
              "Chenab",
              "Heidari",
              "Heiran",
              "Khalil",
              "Darab 2",
              "Dariun",
              "Danesh",
              "Darya",
              "Dez",
              "Dastan",
              "Diablon",
              "Raj",
              "Rad",
              "Radia",
              "Rahmat",
              "Rokhshan",
              "Rasoul",
              "Rasad",
              "Roshan",
              "Rizhao",
              "Zare",
              "Zagros",
              "Zarrin",
              "Zarineh",
              "Saein",
              "Sarhang",
              "Salar",
              "Sabalan",
              "Sepahan",
              "Sepehr",
              "Setareh",
              "Sahar",
              "Sardari",
              "Sela",
              "Sirvan",
              "Sistan",
              "Simonida",
              "Simin",
              "Sivand",
              "Shadab",
              "Shalan",
              "Shavour",
              "Shahriar",
              "Shah Gol",
              "Shoush",
              "Shiraz",
              "Shiroodi",
              "Saba 1",
              "Saba 2",
              "Saba 3",
              "Sadra",
              "Talayi",
              "Farine",
              "Falat",
              "Qaboos",
              "Qods",
              "Kabir",
              "Cross Bolani",
              "Karim",
              "Kaman",
              "Kouhdasht",
              "Kavir",
              "Gaspard",
              "Gascojen",
              "Gonbad",
              "Gohar",
              "Line A",
              "Locolus",
              "Morvarid",
              "Marvdasht",
              "Moghan1",
              "Moghan 3",
              "Mahtab",
              "Mahdavi",
              "Mehregan",
              "Milan",
              "Mihan",
              "Narin",
              "Noodle",
              "Navid",
              "Neyshabur",
              "Nik Nejad",
              "Hamoon",
              "Hashtroud",
              "Homa",
              "Hour",
              "Houman",
              "Hirmand",
              "Varan",
              "Valbona",
              "Virinak",
              "Winner",
              "Other",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Legumes",
    firstSubDomain: [
      {
        name: "Broad Beans (Legumes)",
        secondSubDomain: [
          {
            name: "Dried Broad Beans",
            productItem: [
              "Barekat",
              "Shadan",
              "Fayz",
              "Lozdi Atono",
              "Mehta",
              "Hystal",
              "Other",
            ],
          },
        ],
      },
      {
        name: "Lentils",
        secondSubDomain: [
          {
            name: "Large Lentils",
            productItem: [
              "Bileh Savar",
              "Sepehr",
              "Sana",
              "Kimia",
              "Gachsaran",
              "Mojdeh",
              "Nozhiyan",
              "Other",
            ],
          },
          {
            name: "Small Lentils",
            productItem: ["Small Lentils"],
          },
        ],
      },
      {
        name: "Other Legumes",
        secondSubDomain: [
          {
            name: "Other Legumes",
            productItem: ["Other Legumes"],
          },
        ],
      },
      {
        name: "Beans",
        secondSubDomain: [
          {
            name: "Black-Eyed Beans",
            productItem: ["Kamran", "Local Jiroft", "Local Mashhad", "Other"],
          },
          {
            name: "Pinto Beans",
            productItem: [
              "Sepehr",
              "Simble",
              "Saleh",
              "Sadri",
              "Ghafar",
              "Kousha",
              "Hidaj Dar",
              "Other",
            ],
          },
          {
            name: "White Beans",
            productItem: ["Almas", "Pak", "Dorsa", "Shekofa", "Bean", "Other"],
          },
          {
            name: "Black Beans",
            productItem: ["Black Beans"],
          },
          {
            name: "Bride Beans",
            productItem: ["Bride Beans"],
          },
          {
            name: "Red Beans",
            productItem: [
              "Akhtar",
              "Ofogh",
              "Dadfar",
              "Derakhshan",
              "Sayad",
              "Yaghout",
              "Other",
            ],
          },
          {
            name: "Agricultural Beans",
            productItem: ["Agricultural Beans"],
          },
        ],
      },
      {
        name: "Mung Beans",
        secondSubDomain: [
          {
            name: "Large Mung Beans",
            productItem: ["Parto", "Gohar", "Mehr", "Indian", "Other"],
          },
          {
            name: "Small Mung Beans",
            productItem: ["Small Mung Beans"],
          },
        ],
      },
      {
        name: "Chickpeas",
        secondSubDomain: [
          {
            name: "Chickpeas",
            productItem: [
              "Ata",
              "Arman",
              "Azad",
              "Ana",
              "Orum 1",
              "Orum 2",
              "Barekat",
              "Binaloud",
              "Bionic",
              "Pirooz",
              "Samin",
              "Jam",
              "Roshana",
              "Zarrin",
              "Sarar",
              "Saeed",
              "Sufi",
              "Adel",
              "Kaka",
              "Kani",
              "Kavian",
              "Kasra",
              "Mansour",
              "Nosrat",
              "Hashem",
              "Yadgar",
              "Other",
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Industrial Products",
    firstSubDomain: [
      {
        name: "Cotton",
        secondSubDomain: [
          {
            name: "Raw Cotton",
            productItem: [
              "AGN117",
              "AGN126",
              "BA440",
              "PG 2018",
              "Aptazia",
              "Adasa",
              "Armaghan",
              "Ava",
              "Babylon",
              "Bakhtagan",
              "Parto",
              "Taban",
              "Herdad",
              "Hekmat",
              "Khorshidi",
              "Sajedi",
              "Sahel",
              "Sepid",
              "Other",
              "Shayan",
              "Flash",
              "Karizma",
              "Kashmar",
              "Campo",
              "Golestan",
              "Latif",
              "Lodus",
              "Leader",
              "Lydia",
              "Mehr",
              "Mi 344",
              "Varamin",
            ],
          },
        ],
      },
      {
        name: "Tobacco",
        secondSubDomain: [
          {
            name: "Tobacco Leaves",
            productItem: [
              "Orumieh205",
              "Barley",
              "Eastern Type (Tobacco Basma)",
              "Virginia",
              "Other",
            ],
          },
        ],
      },
      {
        name: "Sugar Beet",
        secondSubDomain: [
          {
            name: "Sugar Beet",
            productItem: [
              "Aria",
              "Asia",
              "Ekbaton",
              "Pars",
              "Paya",
              "Tara",
              "Torbat",
              "Jam",
              "Jolge",
              "Rasoul",
              "Zarghan",
              "Other",
              "Sina",
              "Sharif",
              "Shekofa",
              "Kimia",
              "Gadook",
              "Motahar",
              "Nika",
              "Homa",
            ],
          },
        ],
      },
      {
        name: "Poppy",
        secondSubDomain: [
          {
            name: "Opium",
            productItem: ["Opium"],
          },
          {
            name: "Poppy Seeds",
            productItem: ["Poppy Seeds"],
          },
        ],
      },
      {
        name: "Other Industrial Products",
        secondSubDomain: [
          {
            name: "Other Industrial Products",
            productItem: ["Other Industrial Products"],
          },
        ],
      },
      {
        name: "Hemp (Industrial)",
        secondSubDomain: [
          {
            name: "Leaves and Buds",
            productItem: ["Leaves and Buds"],
          },
        ],
      },
      {
        name: "Flax",
        secondSubDomain: [
          {
            name: "Flax Fibers",
            productItem: ["Flax Fibers"],
          },
        ],
      },
      {
        name: "Jute",
        secondSubDomain: [
          {
            name: "Jute Fibers",
            productItem: ["Jute Fibers"],
          },
        ],
      },
      {
        name: "Sugarcane",
        secondSubDomain: [
          {
            name: "Sugarcane",
            productItem: ["Sugarcane"],
          },
        ],
      },
    ],
  },

  {
    name: "Oilseeds and Nuts",
    firstSubDomain: [
      {
        name: "Mixed Nuts",
        secondSubDomain: [
          {
            name: "Mixed Sunflower Seeds",
            productItem: [
              "Dor",
              "Other",
              "Sanegari",
              "Gholami",
              "Kalleh Goochi",
              "Mikhi",
            ],
          },
        ],
      },
      {
        name: "Oil Sunflower",
        secondSubDomain: [
          {
            name: "Oil Sunflower Seed",
            productItem: [
              "S300",
              "S400",
              "Azargol",
              "Ahmadbi",
              "Astirpel",
              "Barzgar",
              "Rakood",
              "Zarya",
              "Zarin",
              "Shams",
              "Ghasem",
              "Zarya",
              "Gabour",
              "Goldis",
              "Golasa",
              "Golshid",
              "Other",
            ],
          },
        ],
      },
      {
        name: "Peanuts",
        secondSubDomain: [
          {
            name: "Peanuts",
            productItem: [
              "NC2",
              "NC7",
              "Spanish",
              "Improved 306",
              "Improved 308",
              "Other",
              "Shulamit",
              "Valencia",
              "Virginia",
            ],
          },
        ],
      },
      {
        name: "Other Seeds",
        secondSubDomain: [
          {
            name: "Other Oilseeds",
            productItem: ["Other Oilseeds"],
          },
        ],
      },
      {
        name: "Soybeans",
        secondSubDomain: [
          {
            name: "Soybean",
            productItem: [
              "M7",
              "M9",
              "Arian",
              "Amir",
              "Parsa",
              "Tapur",
              "Talar",
              "Rahmat",
              "Zan",
              "Sari",
              "Saland",
              "Saman",
              "Sahar",
              "Selna",
              "Saba",
              "Caspian",
              "Ketol",
              "Kosar",
              "Gorgan-3",
              "Golestan",
              "Nikader",
              "Williams",
              "Other",
            ],
          },
        ],
      },
      {
        name: "Watermelon Seeds (Nuts)",
        secondSubDomain: [
          {
            name: "Watermelon Seeds",
            productItem: ["Watermelon Seeds"],
          },
        ],
      },
      {
        name: "Linseed",
        secondSubDomain: [
          {
            name: "Linseed",
            productItem: ["Tekapo", "Golchin", "Other"],
          },
        ],
      },
      {
        name: "Pumpkin Seeds",
        secondSubDomain: [
          {
            name: "Pumpkin Seeds",
            productItem: ["Linda"],
          },
        ],
      },
      {
        name: "Chia Seeds",
        secondSubDomain: [
          {
            name: "Chia Seeds",
            productItem: ["Chia Seeds"],
          },
        ],
      },
      {
        name: "Canola",
        secondSubDomain: [
          {
            name: "Canola Seed",
            productItem: [
              "PGS003",
              "SLM046",
              "Aram",
              "Agamaks",
              "Angelica",
              "Abunite",
              "Opera",
              "Ademex",
              "Arshitect",
              "Asa",
              "Elite",
              "Okapi",
              "Protos",
              "Bleanda",
              "Baharan",
              "Pishro",
              "Pilani",
              "Taban",
              "Trapper",
              "Travita",
              "Dariot",
              "Draco",
              "Delgan",
              "Roshana",
              "Zarfam",
              "Zalanta",
              "Zaman",
              "Zurika",
              "Sari Gol",
              "Other",
              "Simba",
              "Talaye",
              "Zafer",
              "Kodiak",
              "Liqord",
              "Marathon",
              "Media",
              "Mahtab",
              "Modena",
              "Nafis",
              "Niloofar",
              "Nima",
              "Haila 308",
              "Haila 401",
              "Haila 420",
              "Haila 432",
              "Haila 48115",
              "Haila 50",
              "Haila 60",
            ],
          },
        ],
      },
      {
        name: "Sesame Seeds",
        secondSubDomain: [
          {
            name: "Sesame Seed",
            productItem: [
              "Ultan",
              "Pakistan",
              "Jiroft 13",
              "Darab 14",
              "Darab 2",
              "Dakoub 1",
              "Dashtestan 2",
              "Other",
              "Sardar",
              "Shooin",
              "Fakhra",
              "Mina",
              "Naze Chand Shakhe",
              "Hilal",
              "Varamin 2822",
              "Yekta",
            ],
          },
        ],
      },
      {
        name: "Safflower Seeds",
        secondSubDomain: [
          {
            name: "Safflower Seed",
            productItem: [
              "Omid",
              "Amir",
              "Peydeh",
              "Parnian",
              "Other",
              "Sina",
              "Safah",
              "Farman",
              "Gol Dasht",
              "Golmehr",
            ],
          },
        ],
      },
      {
        name: "Hemp (Shabank)",
        secondSubDomain: [
          {
            name: "Hemp Seed",
            productItem: ["Hemp Seed"],
          },
        ],
      },
    ],
  },
  {
    name: "Herbs and Vegetables",
    firstSubDomain: [
      {
        name: "Eggplant",
        secondSubDomain: [
          {
            name: "Eggplant Dolmeh",
            productItem: [
              "Black Orient",
              "Black Beauty",
              "Black Magic",
              "Black Moon",
              "Bonica Clove",
              "Poulsar",
              "Teti Profit",
              "Dapan",
              "Other",
              "Sepehr Clash",
              "Chantal",
              "Galina",
              "Lima",
            ],
          },
          {
            name: "Eggplant Pencil",
            productItem: [
              "Armaghan 513 Safal Bio Seed",
              "Armaghan Pars Seed",
              "Afsoon Wilmoreen",
              "Ultra Profit",
              "Iran Seed",
              "AG 302",
              "Black Diamond",
              "Black One Iwa Seed",
              "PSB 330",
              "Jaguar",
              "Jehoon Gento",
              "Darko",
              "Dehghan",
              "Raf",
              "Rona",
              "Other",
              "Sempra Antares",
              "Sorme Bruce Seed",
              "Cecilia Phyto",
              "Shadow Clash",
              "Vasilis",
              "Close",
              "Long Black Prosecution",
              "Long Parel",
              "Lima Phyto",
              "Marjan Gento",
              "Negro Green",
              "Hadrian",
              "Valentia",
              "Yami",
            ],
          },
          {
            name: "Eggplant Lamp",
            productItem: [
              "Anamis Wilmoreen",
              "Arall",
              "Arlen Seminis",
              "Agatha",
              "Black Beauty",
              "Blackie",
              "Blen Seminis",
              "Baker",
              "Denis",
              "Rebellion Antares",
              "Sakata 1882",
              "Other",
              "Phoenix Antares",
              "Galine Clove",
            ],
          },
        ],
      },
      {
        name: "Green Peas",
        secondSubDomain: [
          {
            name: "Green Peas",
            productItem: [
              "Barkat",
              "Other",
              "Shadan",
              "Fayez",
              "Luzdy Phyto",
              "Mehta",
              "Hastal",
            ],
          },
        ],
      },
      {
        name: "Okra",
        secondSubDomain: [
          {
            name: "Okra",
            productItem: [
              "0001 Seminis",
              "8999 Seminis",
              "Argato",
              "Epi Clash",
              "Eldor Edou",
              "Prosecution",
              "Baker",
              "Prosecution",
              "Pope",
              "Top Harust",
              "Sanmik",
              "Other",
              "Keshtzar",
              "Clash",
              "Klemsun Spinless Head Seed",
              "Western Clemens Spinless Seed",
              "Key C",
              "Head",
              "Hybrid Clash",
              "Western",
              "Wikima",
            ],
          },
        ],
      },
      {
        name: "Onion",
        secondSubDomain: [
          {
            name: "Onion",
            productItem: [
              "Emerald",
              "Iranshahr",
              "Brazjan",
              "Primavera",
              "Texas",
              "Khomein",
              "In Esfahan",
              "Radashtaar",
              "Rio Bravo",
              "Zarin",
              "Zanjan",
              "Savannah Sweet",
              "Other",
              "Siroplus",
              "Spanish Sweat",
              "Taram",
              "Red Azarshahr",
              "Qom",
              "Qoli Qasheh Zanjan",
              "Kazeroon",
              "Kashan",
              "Gorgan",
              "Lindavista",
              "Local Behbahan",
              "Local Zabol",
              "Minrova",
            ],
          },
        ],
      },
      {
        name: "Turnip",
        secondSubDomain: [
          {
            name: "Turnip",
            productItem: [
              "Profit",
              "PS",
              "Delta Seed",
              "Red Meat Taki",
              "Other",
              "Clash",
              "Carentan Prosecution",
              "Minu Taki",
              "Minu Korgan",
            ],
          },
        ],
      },
      {
        name: "Sugar Beet",
        secondSubDomain: [
          {
            name: "Sugar Beet",
            productItem: [
              "El Dorado",
              "Elite",
              "Bonanza",
              "Purple Top Prosecution",
              "Purple Top White Globe Western",
              "Profit",
              "Top White AfriGro",
              "Top White Professional",
              "Top White Pope",
              "Top White GS AN",
              "Top White US Aggresid",
              "Rose Seed",
              "Sakata",
              "Other",
              "Service Plus",
              "Three Rings",
              "Canyon",
              "Keshar",
              "KS",
              "Grafton",
              "Griffin Seed",
              "Golden Seed",
              "Holland Eden",
              "White Globe Olsson's",
              "White Globe Pandya Seed",
              "White Globe Green Pearl",
              "Western",
              "Wikima",
              "Wilmoreen",
            ],
          },
        ],
      },
      {
        name: "Beetroot",
        secondSubDomain: [
          {
            name: "Beetroot",
            productItem: [
              "Atousa",
              "Arizona",
              "Agra",
              "Alpha",
              "Almira",
              "Amani",
              "Ottawa",
              "Edoni",
              "Aroma",
              "Sprout",
              "Anousha",
              "Oudsa",
              "Baraka",
              "Banba",
              "Superior",
              "Borne",
              "Panama",
              "Pashandi",
              "Picasso",
              "Takta",
              "Toronto",
              "Thor",
              "Javid",
              "Jelly",
              "Georgina",
              "Challenger",
              "Khavaran",
              "Dayfla",
              "Draga",
              "Dizire",
              "Dona",
              "Rashida",
              "Ranomi",
              "Rosita",
              "Rumba",
              "Rona",
              "Royal",
              "Satina",
              "Sagitta",
              "Savalan",
              "Other",
              "Caesar",
              "Sifra",
              "Silvana",
              "Sinora",
              "Shirin",
              "Fabula",
              "Falucca",
              "Fontane",
              "Red",
              "Carara",
              "Carlia",
              "Kaiser",
              "Chlor",
              "Columba",
              "Climax",
              "Kanbak",
              "Constance",
              "Concordia",
              "Gwen",
              "Leonardo",
              "Liza",
              "Marfona",
              "Maloo",
              "Innovator",
              "Montreal",
              "Mondial",
              "Milva",
              "Hannah",
              "Valensa",
              "Wam",
            ],
          },
        ],
      },
      {
        name: "Garlic",
        secondSubDomain: [
          {
            name: "Dry Garlic",
            productItem: [
              "Other",
              "Garlic",
              "Azar Garlic",
              "Spanish Garlic",
              "Pink Garlic",
              "Taram Garlic",
              "Red Garlic",
            ],
          },
        ],
      },
      {
        name: "Pepper",
        secondSubDomain: [
          {
            name: "Bell Pepper",
            productItem: [
              "Seminis 301",
              "Seminis 302",
              "Seminis 7141",
              "Seminis 9325",
              "Aristotle",
              "Ibis",
              "Ibis (IBIS) Clove",
              "Archimedes Seminis",
              "Agrotip",
              "Amacid",
              "Amara",
              "Alberto",
              "Anton",
              "Aries",
              "Athena",
              "Altar",
              "Achat",
              "Sharon",
              "Sophie",
              "Antonio",
              "Alicia",
              "Anatolia",
              "Other",
              "Rebellion Antares",
              "Red",
              "Bamba",
              "Brisbane",
              "Bambar Clutch",
              "Draco",
              "Diamond",
              "Dune Clove",
              "Destiny",
              "Deni",
              "Dehghan",
              "Dehghan",
              "Sabin",
              "Scholar",
              "Sacha",
              "Scud",
              "Scud Aggro",
              "Scorpion",
              "Sethant",
              "Shadow Clove",
              "Shah Alam",
              "Shine Clove",
              "Shiny",
              "Shiny Profit",
              "Spartacus",
              "Spartan Clove",
              "Spanish Blue",
              "Stacy",
              "Sorbonne",
              "Sun Clove",
              "Lack",
              "Tours",
              "Tower",
              "Lima",
              "Linda",
              "Lydia",
              "Linda and Nyall",

              "Lima",
              "Long Green",
              "Long Parel",
              "Long Burgundy",
              "Parel",
              "Parmis",
              "Pistachio",
              "Brilliant",
              "Burn",
              "Bainade",
              "Vijaya",
              "Vivid",
              "Vase",
              "Vigil",
              "Zahra",
              "Zain",
            ],
          },
          {
            name: "Pepper",
            productItem: [
              "Premon",
              "Red Bell Pepper",
              "Crimson",
              "Primavera",
              "Boats",
              "Boston",
              "Boats",
              "Blue Bell Pepper",
              "Valentine",
              "Valley Gem",
              "Red Panama",
              "Tong",
              "Windmill",
              "Color Profit",
              "Cleopatra",
              "Clash",
              "Clash",
              "George",
              "George Star",
              "Jay",
              "Jose",
              "High",
              "Holy Profit",
              "Heating",
              "Iliad",
              "Isadora",
              "Indigo Bell Pepper",
              "Iron",
              "Indigo",
              "Iset",
              "Indigo Clove",
              "Epic",
              "Crimson",
              "Jaya Profit",
              "Crimson",
              "Jane and her husband",
              "Holly",
              "John",
              "Jewel",
              "Cleopatra",
              "Cleopatra",
              "Cleopatra and Salm",
              "Dandelion Profit",
              "Cleopatra",
              "Cleopatra Profit",
              "Prophet Profit",
            ],
          },
          {
            name: "Chili Pepper",
            productItem: [
              "Freddy",
              "Crimson",
              "Brown",
              "Crimson",
              "San Diego",
              "3 Elite 918",
              "UHM",
              "Sequoia",
              "Shaker",
              "Sequoia 4 San Diego",
              "Sequoia",
              "Landscape",
              "Residential",
              "Residential",
              "Iron",
              "Robert",
              "Rehabilitation",
              "Aster",
              "Anatolia",
              "Other",
              "Rascal",
              "Bay",
              "Epic",
              "Aaron",
              "Alex",
              "Amara",
              "Athena",
              "Indigo",
              "Ismael",
              "Sapphire",
              "Beatrice",
              "Belarosa",
              "Elton",
              "Bombers",
              "Basil",
            ],
          },
        ],
      },
      {
        name: "Cucumber",
        secondSubDomain: [
          {
            name: "Cucumber",
            productItem: [
              "Spear",
              "Cobra",
              "Soybean",
              "Ahuja",
              "Andy's",
              "Ezra",
              "Ben Azza",
              "Ezra",
              "Eduard",
              "Energy",
              "Eli",
              "Esma",
              "Esha",
              "Falcon",
              "Spartacus",
              "Spencer",
              "Sarastea",
              "Scorpion",
              "Celine",
              "Epic",
              "Daring",
              "Zach",
              "Zikl",
              "Lara",
              "Albert",
              "Iliad",
              "Aurel",
              "Achilles",
              "Baghdad",
              "Bomber",
              "Blue Brown",
              "Bethlehem",
              "Bahar",
              "Lima",
              "Meredith",
              "Moksha",
              "Montana",
              "Ranger",
              "Other",
              "Red",
              "Shu",
              "David's",
              "Zalotra",
            ],
          },
        ],
      },
    ],
  },
];
