import {
  AccessTimeOutlined,
  CloudDownloadOutlined,
  DisplaySettingsOutlined,
  EditOutlined,
  KeyboardVoiceOutlined,
  LogoutOutlined,
  Menu,
  MessageOutlined,
  NotificationsOutlined,
  PeopleOutlineOutlined,
  PersonOutlineOutlined,
  RecommendOutlined,
  ReportProblemOutlined,
  RoomPreferencesOutlined,
  SettingsOutlined,
  UpdateOutlined,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import profileImage from "../../assets/StaffImg/9.jpg";

import ListSidebarMob from "./ListSidebarMob";
import { Link, useNavigate } from "react-router-dom";
import {
  CustomButton,
  CustomMenuItem,
  HoverIconButton,
} from "components/styledComponents/common";
import FlexBetween from "components/FlexBetween/FlexBetween";
import NavbarPopover from "components/NavbarPopover/NavbarPopover";
import { setLogout } from "States";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "Services/Http/http.service";
import { useQuery } from "react-query";
import { setShoppingCartOpen } from "States/shopping";
import ShoppingCartDark from "../../assets/icon/shopping-cart-dark.svg";
import ShoppingCartLight from "../../assets/icon/shopping-cart-light.svg";

const NavbarMob = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [settingAnchor, setSettingAnchor] = useState(null);
  const [updateAnchor, setUpdateAnchor] = useState(null);
  const [alarmsAnchor, setAlarmsAnchor] = useState(null);
  const [profileAnchor, setProfileAnchor] = useState(null);
  const alarmCount = useSelector((state) => state.global.alarmCount);
  const [profile, setProfile] = useState(false);
  const token = useSelector((state) => state.global.token);
  const emailUser = useSelector((state) => state.global.email);
  const fullNameUser = useSelector((state) => state.global.fullName);
  const profileImageUser = useSelector((state) => state.global.profileImage);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const notificationQuery = useQuery(["", token], () => {
    return getNotifications(token);
  });
  const {
    isLoading: notificationLoading,
    isIdle: notificationIdle,
    isSuccess: notificationSuccess,
    data: notificationData,
  } = notificationQuery;
  const mode = useSelector((state) => state.global.mode);
  const shoppingIcon = mode === "dark" ? ShoppingCartDark : ShoppingCartLight;
  const carts = useSelector((state) => state.shopping.cart);
  const lang = useSelector((state) => state.global.language);
  return (
    <>
      <Stack
        width="100%"
        height="56px"
        direction="row"
        justifyContent="space-between"
        sx={{ backgroundColor: "background.second", zIndex: 1000 }}
      >
        <HoverIconButton onClick={toggleDrawer("right", true)}>
          <Menu />
        </HoverIconButton>
        <Stack direction="row">
          <HoverIconButton>
            <IconButton
              onClick={() =>
                dispatch(setShoppingCartOpen({ shoppingCartOpen: true }))
              }
            >
              <Badge
                overlap="circular"
                badgeContent={carts.length}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                invisible={false}
                sx={{
                  "& 	.MuiBadge-badge": {
                    backgroundColor: "others.second",
                    minWidth: "16px",
                    width: "16px",
                    height: "16px",
                    color: "white",
                  },
                }}
              >
                <Box component="img" width="24px" src={shoppingIcon} />
              </Badge>
            </IconButton>
          </HoverIconButton>
          <Link style={{ display: "flex" }} to="/voiceAssistant">
            <HoverIconButton>
              <KeyboardVoiceOutlined />
            </HoverIconButton>
          </Link>
          {/* <HoverIconButton onClick={(e) => setSettingAnchor(e.currentTarget)}>
            <SettingsOutlined />
          </HoverIconButton>
          <HoverIconButton onClick={(e) => setUpdateAnchor(e.currentTarget)}>
            <CloudDownloadOutlined />
          </HoverIconButton> */}
          <HoverIconButton onClick={(e) => setAlarmsAnchor(e.currentTarget)}>
            <Badge
              badgeContent={
                notificationSuccess ? notificationData?.data?.unseenAlarms : 0
              }
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              invisible={false}
              sx={{
                "& 	.MuiBadge-badge": {
                  backgroundColor: "others.second",
                  minWidth: "16px",
                  width: "16px",
                  height: "16px",
                },
              }}
            >
              <NotificationsOutlined />
            </Badge>
          </HoverIconButton>

          <HoverIconButton
            width="32px"
            height="32px"
            onClick={(e) => setProfileAnchor(e.currentTarget)}
          >
            {profileImageUser ? (
              <Box
                component={"img"}
                alt="profile"
                src={`https://api.rahbaan.ir/public/profile-images/${profileImageUser}`}
                height="24px"
                width="24px"
                borderRadius={"50%"}
                sx={{ objectFit: "cover" }}
              />
            ) : (
              !profile && (
                <PersonOutlineOutlined sx={{ width: "24px", height: "24px" }} />
              )
            )}
          </HoverIconButton>
        </Stack>

        <React.Fragment>
          <Drawer
            anchor={lang === "Fa" ? "right" : "left"}
            open={open}
            onClose={toggleDrawer("right", false)}
            sx={{
              zIndex: 1999999,
              backgroundColor: "transparent",
              "& .MuiPaper-root": {
                backgroundColor: "background.second",
                borderRadius: "16px 0 0 16px",
                backgroundImage: "none",
              },
            }}
          >
            <ListSidebarMob setOpen={setOpen} />
          </Drawer>
        </React.Fragment>
      </Stack>
      {/* <NavbarPopover anchorEl={settingAnchor} setAnchorEl={setSettingAnchor}>
        <Box display="flex" flexDirection="column" p="10px 8px">
          <Link
            style={{
              display: "flex",
              textDecoration: "none",
              color: "unset",
            }}
            to="/setting"
          >
            <CustomMenuItem>
              <DisplaySettingsOutlined sx={{ width: "24px", height: "24px" }} />
              <Typography variant="body">تنظیمات سامانه</Typography>
            </CustomMenuItem>
          </Link>
          <Link
            style={{
              display: "flex",
              textDecoration: "none",
              color: "unset",
            }}
            to="/setting"
          >
           
          </Link>
          <Link
            style={{
              display: "flex",
              textDecoration: "none",
              color: "unset",
            }}
            to="/setting"
          >
            <CustomMenuItem>
              <RoomPreferencesOutlined sx={{ width: "24px", height: "24px" }} />
            </CustomMenuItem>
          </Link>
        </Box>
      </NavbarPopover> */}
      {/* <NavbarPopover anchorEl={updateAnchor} setAnchorEl={setUpdateAnchor}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="stretch"
          p="1rem"
          gap="1rem"
        >
          <Stack>
            <Typography variant="body" color={"fontColor.main"}>
              بروز رسانی سنسورها
            </Typography>
            <FlexBetween>
              <AccessTimeOutlined
                sx={{
                  width: "13px",
                  height: "13px",
                  color: "fontColor.alt",
                }}
              />
              <Typography variant="smallestTextPrimary" color="fontColor.alt">
                سه شنبه 1401/10/06 - 20:08
              </Typography>
            </FlexBetween>
          </Stack>
          <CustomButton height="27px">
            <UpdateOutlined
              sx={{ width: "13px", height: "13px", ml: "0.25rem" }}
            />
            <Typography variant="smallestTextPrimary">
              شروع بروز رسانی
            </Typography>
          </CustomButton>
        </Box>
      </NavbarPopover> */}
      <NavbarPopover anchorEl={alarmsAnchor} setAnchorEl={setAlarmsAnchor}>
        <Box display="flex" flexDirection="column" p="10px 8px">
          <Link
            style={{
              display: "flex",
              textDecoration: "none",
              color: "unset",
              width: "100%",
            }}
            to="/alarms"
          >
            <CustomMenuItem>
              <FlexBetween width="100%" gap="8px">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <ReportProblemOutlined
                    sx={{ width: "24px", height: "24px" }}
                  />
                  <Typography variant="body">هشدار ها</Typography>
                </Box>
                <Box
                  width="20px"
                  height="20px"
                  sx={{
                    backgroundColor: "others.second",
                    borderRadius: "6px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& span": {
                      position: "absolute",
                      right: "50%",
                      top: "50%",
                      transform: "translate(48%,-45%)",
                    },
                  }}
                >
                  {notificationSuccess
                    ? notificationData?.data?.unseenAlarms
                    : 0}
                </Box>
              </FlexBetween>
            </CustomMenuItem>
          </Link>
        </Box>
      </NavbarPopover>

      <NavbarPopover anchorEl={profileAnchor} setAnchorEl={setProfileAnchor}>
        <Box p="10px 8px" zIndex={1000000}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p="10px 20px"
            mb="15px"
          >
            {profileImageUser ? (
              <Box
                component="img"
                src={`https://api.rahbaan.ir/public/profile-images/${profileImageUser}`}
                width="48px"
                height="48px"
                borderRadius="50%"
                mb="0.2rem"
              />
            ) : (
              !profile && (
                <PersonOutlineOutlined sx={{ width: "32px", height: "32px" }} />
              )
            )}
            <Typography variant="subHeading" color={"fontColor.main"}>
              {fullNameUser}
            </Typography>
            <Typography variant="smallestTextSecondary" color={"fontColor.alt"}>
              {emailUser}
            </Typography>
          </Box>
          <Link
            style={{
              display: "flex",
              textDecoration: "none",
              color: "unset",
              width: "100%",
            }}
            to="/setting"
          >
            <CustomMenuItem
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                gap: "1rem",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <EditOutlined
                  sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                />
                <Typography>ویرایش پروفایل</Typography>
              </Box>
            </CustomMenuItem>
          </Link>
          <CustomMenuItem
            display="flex"
            sx={{ width: "100%", gap: "1rem" }}
            onClick={() => {
              dispatch(setLogout());
              navigate("/login");
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <LogoutOutlined
                sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
              />
              <Typography>خروج</Typography>
            </Box>
          </CustomMenuItem>
        </Box>
      </NavbarPopover>
    </>
  );
};

export default NavbarMob;
