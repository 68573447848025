import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import SuccessIcon from "../../assets/Notifications/success.png";
import ErrorIcon from "../../assets/Notifications/error.png";

const DisplayToast = ({ type, message }) => {
  const theme = useTheme();
  const isError = type === "error";

  return (
    <Box
      display="flex"
      alignItems="center"
      padding="0.5rem 0.625rem"
      borderRadius="0.5rem"
      gap="0.5rem"
      sx={{
        mt: "3.25rem",
        width: "250px", // Fixed width
        bgcolor: isError ? "#461731" : "#22342F",
        boxShadow: theme.shadows[2],
        color: "white",
        flexDirection: "row", // Align items in a row
      }}
    >
      <Box
        component="img"
        src={isError ? ErrorIcon : SuccessIcon}
        width="32px"
        height="32px"
      />
      <Typography variant="body1">
        {message
          ? message
          : isError
          ? "عملیات ناموفق بود. لطفا مجددا تلاش نمایید."
          : "عملیات با موفقیت انجام شد."}
      </Typography>
    </Box>
  );
};

export default DisplayToast;
