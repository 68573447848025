import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import FarmIcon from "../../assets/Modules/weather1.png";
import { useDispatch } from "react-redux";
import { setShoppingServices } from "States/shopping";
import { useTranslation } from "react-i18next";

const ServiceItem = ({ title, service }) => {
  console.log("🚀 ~ ServiceItem ~ title:", title)
  console.log("🚀 ~ ServiceItem ~ service:", service);
  const theme = useTheme();
  const { t } = useTranslation();
  const blue = theme.palette.others.first;
  const dispatch = useDispatch();

  return (
    <Stack direction="row" alignItems="center" gap="16px" m="2rem 0">
      <Box
        component="img"
        width="56px"
        hieght="56px"
        borderRadius="8px"
        src={FarmIcon}
      />
      <Stack direcion="column" justifyContent="space-between" height="56px">
        <Typography variant="h5" color="fontColor.main">
          {title}
        </Typography>
        <Typography variant="subHeading" color="others.first">
          <span
            style={{ borderBottom: `1px solid ${blue}`, cursor: "pointer" }}
            onClick={() => dispatch(setShoppingServices({ services: service }))}
          >
            {t("more")}
          </span>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ServiceItem;
