import { createSelector, createSlice } from "@reduxjs/toolkit";
import { REMOTE_SENSING_DEFAULT_PERIOD, TODAY } from "constants/constant";
const today = new Date();
const sevenDaysAgo = new Date(today);
sevenDaysAgo.setDate(today.getDate() - 7);
const initialState = {
  remoteSensingHelp: true,
  remoteSensingHelper: {
    welcome: true,
    rangeDate: false,
    listIndexes: false,
    uniformity: false,
    sensors: false,
    layout: false,
    chart: false,
    map: false,
  },
  farms: null,
  position: null,
  activeFarmIndex: 0,
  boundary: null,
  idFarm: null,
  indexes: null,
  activeIndex: 0,
  nameIndex: "NDVI",
  layers: null,
  selectedLayer: null,
  chartData: null,
  secondDate: `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
  date1: null,
  firstDate: `${sevenDaysAgo.getFullYear()}-${(sevenDaysAgo.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${sevenDaysAgo.getDate().toString().padStart(2, "0")}`,
  date2: null,
};
export const RemoteSensingSlice = createSlice({
  name: "remoteSensing",
  initialState,
  reducers: {
    setFarms: (state, action) => {
      state.farms = action.payload.farms;
    },
    setPosition: (state, action) => {
      state.position = action.payload.position;
    },
    setIdFarm: (state, action) => {
      state.idFarm = action.payload.idFarm;
    },
    setActiveFarmIndex: (state, action) => {
      state.activeFarmIndex = action.payload.activeFarmIndex;
    },
    setBoundary: (state, action) => {
      state.boundary = action.payload.boundary;
    },
    setIndexes: (state, action) => {
      state.indexes = action.payload.indexes;
    },
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload.activeIndex;
    },
    setNameIndex: (state, action) => {
      state.nameIndex = action.payload.nameIndex;
    },
    setLayers: (state, action) => {
      state.layers = action.payload.layers;
    },
    setSelectedLayer: (state, action) => {
      state.selectedLayer = action.payload.selectedLayer;
    },
    setChartData: (state, action) => {
      state.chartData = action.payload.chartData;
    },
    setFirstDateR: (state, action) => {
      state.firstDate = action.payload.firstDate;
    },
    setDate1R: (state, action) => {
      state.date1 = action.payload.date1;
    },
    setSecondDateR: (state, action) => {
      state.secondDate = action.payload.secondDate;
    },
    setDate2R: (state, action) => {
      state.date2 = action.payload.date2;
    },
    setRemoteSensingHelper: (state, action) => {
      state.remoteSensingHelper = action.payload.remoteSensingHelper;
    },
    setRemoteSensingHelp: (state, action) => {
      state.remoteSensingHelp = action.payload.remoteSensingHelp;
    },
  },
});
export const selectFarmId = createSelector(
  (state) => state.remoteSensing.farms,
  (farms) => (farms && farms[0] ? farms[0].id : null)
);
export const {
  setFarms,
  setPosition,
  setIdFarm,
  setActiveFarmIndex,
  setBoundary,
  setIndexes,
  setActiveIndex,
  setNameIndex,
  setLayers,
  setSelectedLayer,
  setChartData,
  setFirstDateR,
  setDate1R,
  setSecondDateR,
  setDate2R,
  setRemoteSensingHelper,
  setRemoteSensingHelp,
} = RemoteSensingSlice.actions;
export default RemoteSensingSlice.reducer;
