import agriculture_bavan from "../assets/layers/1_/2023-07-01-00_00_2023-07-01-23_59_Sentinel-2_L2A_Agriculture.png";
import agriculture_sarbandan from "../assets/layers/1/2023-07-01-00_00_2023-07-01-23_59_Sentinel-2_L2A_Agriculture.png";

export const farmData = [
  {
    id: "54cdec90-0f61-11ee-8e46-bb7ceec80128",
    title: "سربندان",
    boundary: {
      type: "Polygon",
      coordinates: [
        [
          [35.63756533193985, 52.22486391383863],
          [35.6368424283126, 52.22690776756859],
          [35.63835010593118, 52.22765149132153],
          [35.63893821377864, 52.2258436395649],
          [35.6387569327276, 52.22568225217225],
          [35.638470337439, 52.22551259364648],
          [35.63808414629099, 52.2251848602429],
          [35.63793108763544, 52.2250641429807],
          [35.63756533193985, 52.22486391383863],
        ],
      ],
    },
    imageUrl: agriculture_sarbandan,
    zones: null,
    active: false,
    points: "180,50 180,80 180,130 100,160 20,130 20,50",
  },
  {
    id: "35f83720-c174-11ed-bc17-6b0808db3eef",
    title: "زمین باوان",
    boundary: {
      type: "Polygon",
      coordinates: [
        [
          [35.647550594895634, 52.22331316029803],
          [35.638096669225014, 52.21837714993323],
          [35.6371599094184, 52.22010975909988],
          [35.63762583574791, 52.22089136520344],
          [35.638194959920924, 52.22134817072747],
          [35.63848941280419, 52.22177061916994],
          [35.63864353470959, 52.22254154568463],
          [35.6388888008636, 52.22295780496065],
          [35.63944348048854, 52.2235763919431],
          [35.63961981778828, 52.22416346617884],
          [35.64338813679935, 52.22637798168365],
          [35.64613337696015, 52.22809061786306],
          [35.647550594895634, 52.22331316029803],
        ],
      ],
    },
    imageUrl: agriculture_bavan,
    zones: null,
    active: true,
    points: "0,0 100,20 150,50 150,150 50,150",
  },
];

export const reportsFarmData = [
  {
    name: "آقای طالبی",
    prodType: "گندم",
    farmType: "زراعی",
    address: "سربندان",
    area: "500 هکتار",
    plantDate: "1401/11/23",
    prodDigit: "الوند - پیشتاز",
    plantMethod: "دستی",
    plantDensity: "متوسط",
    rowDistance: "20 CM",
    plantDistance: "20 CM",
    wateringKind: "دیم",
    wateringType: "سطحی",
    wateringSystem: "نواری",
    wateringNeed: "بله",
    wateringEC: "800 ds/m",
    poisonName: "حشره کش کائولین",
    diseaseName: "شپشک",
    poisoningMethod: "دستی",
    fertilizerName: "کود کامل",
    fertilizingMethod: "سرک",
    FertilizerAmount: "50 kg",
  },
  {
    name: "آقای سعیدی",
    prodType: "ذرت",
    farmType: "زراعی",
    address: "اصفهان",
    area: "500 هکتار",
    plantDate: "1401/11/23",
    prodDigit: "الوند - پیشتاز",
    plantMethod: "دستی",
    plantDensity: "متوسط",
    rowDistance: "20 CM",
    plantDistance: "20 CM",
    wateringKind: "دیم",
    wateringType: "سطحی",
    wateringSystem: "نواری",
    wateringNeed: "بله",
    wateringEC: "800 ds/m",
    poisonName: "حشره کش کائولین",
    diseaseName: "شپشک",
    poisoningMethod: "دستی",
    fertilizerName: "کود کامل",
    fertilizingMethod: "سرک",
    FertilizerAmount: "50 kg",
  },
  {
    name: "آقای اکبری",
    prodType: "جو",
    farmType: "زراعی",
    address: "زنجان",
    area: "500 هکتار",
    plantDate: "1401/11/23",
    prodDigit: "الوند - پیشتاز",
    plantMethod: "دستی",
    plantDensity: "متوسط",
    rowDistance: "20 CM",
    plantDistance: "20 CM",
    wateringKind: "دیم",
    wateringType: "سطحی",
    wateringSystem: "نواری",
    wateringNeed: "بله",
    wateringEC: "800 ds/m",
    poisonName: "حشره کش کائولین",
    diseaseName: "شپشک",
    poisoningMethod: "دستی",
    fertilizerName: "کود کامل",
    fertilizingMethod: "سرک",
    FertilizerAmount: "50 kg",
  },
  {
    name: "آقای احمدی",
    prodType: "سیب",
    farmType: "باغی",
    address: "دماوند",
    area: "500 هکتار",
    plantDate: "1400/12/23",
    prodDigit: "سیب سبز - سیب قرمز",
    plantMethod: "مکانیزه",
    plantDensity: "بالا",
    rowDistance: "20 CM",
    plantDistance: "20 CM",
    wateringKind: "فاریاب",
    wateringType: "تحت فشار",
    wateringSystem: "بارانی",
    wateringNeed: "خیر",
    wateringEC: "بین 0 تا 2",
    poisonName: "حشره کش کائولین",
    diseaseName: "شپشک",
    poisoningMethod: "شیمابیاری",
    fertilizerName: "بقایای گیاهی",
    fertilizingMethod: "کودآبیاری",
    FertilizerAmount: "50 kg",
  },
  {
    name: "آقای حسنی",
    prodType: "پرتقال",
    farmType: "باغی",
    address: "رامسر",
    area: "500 هکتار",
    plantDate: "1400/12/23",
    prodDigit: "پرتقال تامسون",
    plantMethod: "مکانیزه",
    plantDensity: "بالا",
    rowDistance: "20 CM",
    plantDistance: "20 CM",
    wateringKind: "فاریاب",
    wateringType: "تحت فشار",
    wateringSystem: "بارانی",
    wateringNeed: "خیر",
    wateringEC: "بین 0 تا 2",
    poisonName: "حشره کش کائولین",
    diseaseName: "شپشک",
    poisoningMethod: "شیمابیاری",
    fertilizerName: "بقایای گیاهی",
    fertilizingMethod: "کودآبیاری",
    FertilizerAmount: "50 kg",
  },
];
