import WidgetWrapper from "components/WidgetWrapper";
import FarmItem from "./FarmItem";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import {
  InputBase,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Typography,
  styled,
  Box,
} from "@mui/material";
import {
  AddOutlined,
  Filter,
  FilterAltOutlined,
  Search,
} from "@mui/icons-material";
import { CssTextField, CustomButton } from "components/styledComponents/common";
import { landData } from "Services/Http/http.service";
import Skelton from "components/Skelton/Skelton";
import { products } from "mob/RecordFarmMob/Products";
const StyledMenuList = styled(MenuList)(({ theme }) => ({
  /* Use the prop `theme` to apply dynamic styles */
  backgroundColor: `${theme.palette.background.third} !important`,
  ":hover": {
    backgroundColor: `${theme.palette.fontColor.third} !important`,
  },
  // backgroundColor: theme.palette.background.third,
  boxShadow: "none !important",
  zIndex: 20000000,
}));
const mockData = [
  {
    farmName: "آقای بهرامی",
    farmType: "زراعی",
    productType: "گندم",
    Coordinates: [51, 30],
    area: "500 هکتار",
    dateCultivation: "1402/02/03",
    productNumber: "پیشتاز الوند",
    points: "100,20 180,50 180,130 100,160 20,130 20,50",
  },
  {
    farmName: "آقای احمدی",
    farmType: "باغی",
    productType: "سیب",
    Coordinates: [51, 32],
    area: "180 هکتار",
    dateCultivation: "1402/02/02",
    productNumber: "سیب قرمز - سیب سبز",
    points: "100,100 150,50 150,150 50,150",
  },
  {
    farmName: "آقای صالحی",
    farmType: "باغی",
    productType: "پرتغال",
    Coordinates: [50, 29],
    area: "230 هکتار",
    dateCultivation: "1402/02/01",
    productNumber: "پرتغال تامسون",
    points: "100,10 150,50 150,150 50,150",
  },
];

const Farms = () => {
  const [search, setSearch] = useState("");
  const [filterOption, setFilterOption] = useState("همه");
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const token = useSelector((state) => state.global.token);

  const landsQuery = useQuery(["landsQuery", token], () => {
    return landData(token);
  });
  console.log("🚀 ~ file: Farms.jsx:81 ~ landsQuery ~ landsQuery:", landsQuery);

  return landsQuery.isLoading ? (
    <Skelton />
  ) : landsQuery.data?.data.length > 0 ? (
    <>
      <FlexBetween p="1rem 0.5rem" gap="0.5rem">
        <FlexBetween
          sx={{
            alignItems: "center",
            border: `1px solid`,
            borderColor: "fontColor.alt",
            borderRadius: "8px",
            height: "36px",
            px: "0.5rem",
          }}
        >
          <Search sx={{ mr: "0.2rem" }} />
          <InputBase
            disableUnderline
            placeholder="جست و جو"
            value={search}
            onChange={handleSearch}
            id="inputSearchRemote"
            sx={(theme) => ({
              paddingRight: "5px",
              backgroundColor: "inherit",
              "& .MuiInputBase-input::placeholder": {
                color: `${theme.palette.fontColor.main} !important`,
              },
              height: "36px",
              width: "100%",
              borderRadius: "8px",
              color: theme.palette.fontColor.alt,
              outline: "none",
              borderBottom: "none",
            })}
          />
        </FlexBetween>
        <CssTextField
          width="50%"
          select
          filterinput
          value={filterOption}
          onChange={(e) => setFilterOption(e.target.value)}
          height="36px"
        >
          <MenuItem value={"همه"}>همه زمین ها</MenuItem>
          {products.map((item) => (
            <MenuItem value={item.name}>{item.name}</MenuItem>
          ))}
        </CssTextField>
      </FlexBetween>
      <WidgetWrapper mx="0.5rem">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h4" color={"fontColor.main"}>
            زمین ها
          </Typography>
          <Link to="/recordfarm">
            <CustomButton height="36px">
              <Stack direction="row" alignItems="center" gap="0.2rem">
                <AddOutlined sx={{ width: "24px", height: "24px" }} />
                <Typography>زمین جدید</Typography>
              </Stack>
            </CustomButton>
          </Link>
        </Stack>
        <Box
          sx={{
            height: "65vh",
            overflow: "auto",
          }}
        >
          {landsQuery.data?.data
            .filter((item) => item.land?.name.includes(search))
            .filter(
              (item) =>
                filterOption === "همه" ||
                filterOption === item.land?.productType
            )
            .map((item) => (
              <FarmItem item={item} />
            ))}
        </Box>
      </WidgetWrapper>
    </>
  ) : (
    <></>
  );
};

export default Farms;
