import {
  Button,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Slider,
  styled,
  Switch,
  TextField,
  useTheme,
} from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import {
  FilterAltOutlined,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import { Popup } from "react-leaflet";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { transform } from "framer-motion";
export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 13,
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 0,
    transform: "translate(3px, 2px)",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translate(18px, 2px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#5FBD15" : "#5FBD15",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 9,
    height: 9,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const CustomInputBase = styled((props) => (
  <InputBase disableUnderline {...props} />
))(({ theme }) => ({
  height: "36px",
  width: "100%",
  borderRadius: "8px",
  color: theme.palette.fontColor.main,
  outline: "none",
  borderBottom: "none",
  paddingRight: "5px",
  "& input": {
    "&::-webkit-input-placeholder": {
      color: `${theme.palette.fontColor.main} !important`,
      opacity: 1,
    },
    "&::-moz-placeholder": {
      color: `${theme.palette.fontColor.main} !important`,
      opacity: 1,
    },
    "&::-ms-placeholder": {
      color: `${theme.palette.fontColor.main} !important`,
      opacity: 1,
    },
    "&::placeholder": {
      color: `${theme.palette.fontColor.main} !important`,
      opacity: 1,
    },
  },
}));

export const HoverIconButton = styled((props) => <IconButton {...props} />)(
  ({ theme }) => ({
    "@media (hover: hover)": {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  })
);

export const Accordion = styled("div")((props) => ({
  maxHeight: props.open ? "100vh" : 0,
  display: "flex",
  flexDirection: "column",
  gap: props.gap || "0.5rem",
  overflow: "hidden",
  marginTop: props.open ? "0.8rem" : 0,
  transition: "all 0.3s",
  ...props.sx,
}));

export const CustomButton = styled(Button)(({ theme, mode, ...props }) => ({
  fontFamily: "YekanBakhFaNum-Light",
  padding: "0 20px",
  height: props.height || "40px",
  textTransform: "none",
  color: props.secondary
    ? theme.palette.mode === "dark"
      ? theme.palette.fontColor.main
      : theme.palette.primary.main
    : "white",
  backgroundColor: props.secondary
    ? theme.palette.background.third
    : theme.palette.primary.main,
  boxShadow: "none",
  borderRadius: "8px",
  opacity: 1,
  "& .MuiTypography-root": {
    transform: "translateX(10px)",
  },
  "&:focus": {
    backgroundColor: props.secondary
      ? theme.palette.background.third
      : theme.palette.primary.main,
  },
  "&:hover": {
    backgroundColor: props.secondary
      ? theme.palette.background.third
      : theme.palette.primary.main,
    opacity: 0.9,
    "& .MuiTypography-root": {
      transform: "translateX(0px) scale(1.1)", // Translate text along the X-axis on hover
    },
  },
  "&  .MuiTypography-root": {
    transition: "transform 0.3s ease", // Adding transition for the transform property
  },
  "&.Mui-disabled": {
    backgroundColor: props.secondary
      ? theme.palette.background.third
      : "rgba(42, 128, 244, 0.3)",
    color: props.secondary
      ? theme.palette.mode === "dark"
        ? theme.palette.fontColor.main
        : theme.palette.primary.main
      : "white",
  },
}));
export const CssTextField = styled((props) => {
  const theme = useTheme();
  return (
    <TextField
      autoComplete="off"
      variant="outlined"
      SelectProps={{
        IconComponent: props.filterinput
          ? props.filterIcon
            ? props.filterIcon
            : FilterAltOutlined
          : KeyboardArrowDownOutlined,
        MenuProps: {
          PaperProps: {
            sx: {
              ...theme.palette.popper,
              backgroundImage: "none",
              borderRadius: "8px",
              maxHeight: "40vh",
              scrollbarColor: `${theme.palette.background.first} ${theme.palette.background.third}`,
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "5px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
                borderRadius: "5px",
              },

              "&::-webkit-scrollbar-thumb": {
                background: theme.palette.others.lineChart,
                borderRadius: "5px",
              },
            },
          },
          MenuListProps: {
            variant: "menu",
            sx: {
              "& .MuiMenuItem-root": {
                bgcolor: theme.palette.popper.backgroundColor,
                mx: "8px",
                borderRadius: "8px",
              },
              "& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected": {
                bgcolor: theme.palette.menuItem.backgroundColor,
              },
              "& .MuiMenuItem-root.Mui-selected": {
                bgcolor: theme.palette.menuItem.backgroundColor,
                "@media (hover: none)": {
                  "&:hover": {
                    backgroundColor: theme.palette.menuItem.backgroundColor,
                  },
                },
              },
            },
          },
        },
      }}
      {...props}
    />
  );
})((props) => ({
  width: props.width ?? "100%",
  minWidth: "unset",
  ...(props.filterinput && {
    "& .MuiSvgIcon-root": {
      transform: "rotate(0deg)",
    },
  }),
  "& .MuiInputBase-root ": {
    height: props.multiline ? "unset" : props.height ? props.height : "45px",
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 13px) scale(1)",
    fontFamily: "YekanBakhFaNum-Light",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(16px, -9px) scale(0.75)",
  },
  "& .MuiFormLabel-root": {
    marginLeft: "-4px",
  },
  "& .MuiOutlinedInput-input:focused": {
    borderColor: "green",
  },
  "& label.Mui-focused": {
    color: props.theme.palette.fontColor.main,
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: props.theme.palette.fontColor.main,
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: props.theme.palette.fontColor.main,
    borderRadius: "8px",

    "& fieldset": {
      borderColor: props.theme.palette.fontColor.alt,
    },
    "&:hover fieldset": {
      borderColor: props.theme.palette.fontColor.alt,
    },
    "&.Mui-focused fieldset": {
      borderColor: props.theme.palette.fontColor.alt,
    },
    "& input::-webkit-outer-spin-button , & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
}));

export const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export const CustomSlider = styled(Slider)(({ theme, customColor, side }) => ({
  color: customColor,
  height: 6,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 12,
    width: 12,
    backgroundColor: customColor,
    "&:hover": {
      boxShadow: `0 0 8px 0 ${customColor}`,
    },
    "&:focus": {
      boxShadow: `0 0 8px 0 ${customColor}`,
    },
  },
  "& .MuiSlider-track": {
    height: 6,
    backgroundColor: customColor,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.others.lineChart,
    height: 6,
    ...(side === "left"
      ? {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        }
      : side === "right"
      ? {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }
      : {}),
  },
}));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  width: "100%",
  minHeight: "auto",
  borderRadius: "8px",
  display: "flex",
  gap: "4px",
  padding: "6px 8px",
  "@media (hover: hover)": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export const MobileFilterButtons = styled(Button)(
  ({ theme, active = false }) => ({
    whiteSpace: "nowrap",
    color: active ? "#F7F7F7" : theme.palette.fontColor.main,
    background: active
      ? "linear-gradient(to bottom, #2A80F4 -11%, rgba(42, 128, 244, 0.35) 117%)"
      : `linear-gradient(to bottom, ${theme.palette.background.third},transparent)`,
    padding: "8px",
    borderRadius: "8px",
    minWidth: "unset",
  })
);

export const CustomSelectInput = styled((props) => (
  <Select
    disableUnderline
    IconComponent={KeyboardArrowDownOutlined}
    inputProps={{ "aria-label": "Without label" }}
    MenuProps={{
      PaperProps: {
        sx: {
          backgroundColor: "background.third",
          boxShadow: "none",
          backgroundImage: "none",
          borderRadius: "8px",
          maxHeight: "30vh",
        },
      },
      MenuListProps: {
        variant: "menu",
        sx: {
          "& .MuiMenuItem-root": {
            bgcolor: "background.third",
            mx: "8px",
            borderRadius: "8px",
          },
          "& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected": {
            bgcolor: "background.forth",
          },
          "& .MuiMenuItem-root.Mui-selected": {
            bgcolor: "background.forth",
            "@media (hover: none)": {
              "&:hover": {
                backgroundColor: "background.forth",
              },
            },
          },
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  borderRadius: "8px",
  minWidth: "140px",
  backgroundColor: theme.palette.others.fifth,
  backdropFilter: "blur(7px)",
  strokeWidth: 0,
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
    borderColor: theme.palette.others.fifth,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
    borderColor: theme.palette.others.fifth,
  },
  "& .MuiInputBase-root": {
    lineHeight: "20px",
  },
  "& .MuiSelect-select": {
    padding: "5px 14px 2px",
  },
}));

export const UploaderInput = styled("input")({
  display: "none",
});

export const CSSPOP = styled(Popup)((props) => ({
  "& .leaflet-popup-content-wrapper": {
    transform: "translateY(0px)",
  },
  "& .leaflet-popup-content-wrapper, .leaflet-popup-tip": {
    background: "transparent",
    boxShadow: "none !important",
    minWidth: props.width ?? "180px",
  },
  "& .leaflet-popup-tip-container .leaflet-popup-tip": {
    background: "transparent",
  },
  "& .leaflet-popup-content": {
    margin: 0,
  },
}));

export const CustomTimePicker = styled((props) => {
  const theme = useTheme();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        // autoComplete="off"
        // variant="outlined"
        value={props.value}
        SelectProps={{
          IconComponent: props.filterinput
            ? props.filterIcon
              ? props.filterIcon
              : FilterAltOutlined
            : KeyboardArrowDownOutlined,
          MenuProps: {
            PaperProps: {
              sx: {
                ...theme.palette.popper,
                backgroundImage: "none",
                borderRadius: "8px",
                maxHeight: "40vh",
                scrollbarColor: `${theme.palette.background.first} ${theme.palette.background.third}`,
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "5px",
                  height: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                  borderRadius: "5px",
                },

                "&::-webkit-scrollbar-thumb": {
                  background: theme.palette.others.lineChart,
                  borderRadius: "5px",
                },
              },
            },
            MenuListProps: {
              variant: "menu",
              sx: {
                "& .MuiMenuItem-root": {
                  bgcolor: theme.palette.popper.backgroundColor,
                  mx: "8px",
                  borderRadius: "8px",
                },
                "& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected": {
                  bgcolor: theme.palette.menuItem.backgroundColor,
                },
                "& .MuiMenuItem-root.Mui-selected": {
                  bgcolor: theme.palette.menuItem.backgroundColor,
                  "@media (hover: none)": {
                    "&:hover": {
                      backgroundColor: theme.palette.menuItem.backgroundColor,
                    },
                  },
                },
              },
            },
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
})((props) => ({
  width: props.width ?? "100%",
  minWidth: "unset",
  ...(props.filterinput && {
    "& .MuiSvgIcon-root": {
      transform: "rotate(0deg)",
    },
  }),
  "& .MuiInputBase-root ": {
    height: props.multiline ? "unset" : props.height ? props.height : "45px",
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 13px) scale(1)",
    fontFamily: "YekanBakhFaNum-Light",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(16px, -9px) scale(0.75)",
  },
  "& .MuiFormLabel-root": {
    marginLeft: "-4px",
  },
  "& .MuiOutlinedInput-input:focused": {
    borderColor: "green",
  },
  "& label.Mui-focused": {
    color: props.theme.palette.fontColor.main,
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: props.theme.palette.fontColor.main,
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: props.theme.palette.fontColor.main,
    borderRadius: "8px",

    "& fieldset": {
      borderColor: props.theme.palette.fontColor.alt,
    },
    "&:hover fieldset": {
      borderColor: props.theme.palette.fontColor.alt,
    },
    "&.Mui-focused fieldset": {
      borderColor: props.theme.palette.fontColor.alt,
    },
    "& input::-webkit-outer-spin-button , & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
}));
