import { Search } from "@mui/icons-material";
import { Box, InputAdornment, MenuItem, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { CssTextField, CustomButton } from "components/styledComponents/common";
import Table from "components/table/Table";
import { useState } from "react";
const mockData = [
  {
    service: "سنجش از دور",
    time: "۶ ماهه",
    status: "failed",
    price: "200,000 ",
    date: "1401/02/03  - 20:30",
    id: "6384683235",
  },
  {
    service: "بسته برنزی",
    time: "۶ ماهه",
    status: "successful",
    price: "200,000 ",
    date: "1401/02/03  - 20:30",
    id: "23452621",
  },
  {
    service: "سنجش از دور",
    time: "۶ ماهه",
    status: "pending",
    price: "200,000 ",
    date: "1401/02/03  - 20:30",
    id: "67456342",
  },
  {
    service: "بسته برنزی",
    time: "۶ ماهه",
    status: "failed",
    price: "200,000 ",
    date: "1401/02/03  - 20:30",
    id: "7234523426",
  },
  {
    service: "مدیریت سنسور ها",
    time: "۶ ماهه",
    status: "pending",
    price: "200,000 ",
    date: "1401/02/03  - 20:30",
    id: "35143541",
  },
  {
    service: "بسته برنزی",
    time: "۶ ماهه",
    status: "failed",
    price: "200,000 ",
    date: "1401/02/03  - 20:30",
    id: "8713541397",
  },
  {
    service: "هوا شناسی",
    time: "۶ ماهه",
    status: "pending",
    price: "200,000 ",
    date: "1401/02/03  - 20:30",
    id: "8713541371",
  },
  {
    service: "بسته اقتصادی",
    time: "۶ ماهه",
    status: "failed",
    price: "200,000 ",
    date: "1401/02/03  - 20:30",
    id: "31435419",
  },
  {
    service: "سنجش از دور",
    time: "۶ ماهه",
    status: "pending",
    price: "200,000 ",
    date: "1401/02/03  - 20:30",
    id: "67456342",
  },
  {
    service: "بسته برنزی",
    time: "۶ ماهه",
    status: "failed",
    price: "200,000 ",
    date: "1401/02/03  - 20:30",
    id: "7234523426",
  },
];
const Transactions = ({ setTransactions }) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedType, setSelectedType] = useState("all");
  const tableData = mockData
    .filter((item) => selectedType === "all" || item.service === selectedType)
    .filter((item) => item.service.includes(searchValue))
    .map((item) => ({
      data: {
        نوع: item.service,
        "مدت زمان": item.time,
        "مبلغ(تومان)": item.price,
        "تاریخ پرداخت": item.date,
        وضعیت: (
          <Box
            sx={{
              m: "0 auto",
              width: "92px",
              height: "23px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                item.status === "successful"
                  ? "others.third"
                  : item.status === "pending"
                  ? "others.first"
                  : "others.second",
            }}
          >
            <Typography
              color={"background.second"}
              variant="smallestTextPrimary"
            >
              {item.status === "successful"
                ? "موفق"
                : item.status === "pending"
                ? "در انتظار پرداخت"
                : "ناموفق"}
            </Typography>
          </Box>
        ),
        "کد پیگیری": item.id,
      },
    }));
  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100% - 110px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: "1rem",
        overflow: "auto",
        p: "16px 16px 0 16px",
      }}
    >
      <WidgetWrapper width="100%">
        <FlexBetween mb="1rem">
          <Typography variant="h3">تراکنش ها</Typography>
          <Box sx={{ display: "flex", gap: "8px", alignItems: "flex-end" }}>
            <CustomButton
              secondary
              onClick={() => setTransactions(false)}
              height="27px"
            >
              بازگشت به فروشگاه
            </CustomButton>
            <CssTextField
              height="27px"
              placeholder="جست و جو"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              sx={{ width: "150px", "& input": { p: 0 } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <CssTextField
              select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              height="27px"
              sx={{ width: "150px" }}
            >
              <MenuItem value="all">همه</MenuItem>
              {[...new Set(mockData.map((item) => item.service))].map(
                (item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                )
              )}
            </CssTextField>
          </Box>
        </FlexBetween>
        <Table data={tableData} />
      </WidgetWrapper>
    </Box>
  );
};

export default Transactions;
