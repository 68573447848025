import { Badge, Box, IconButton, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Plan from "./Plan";
import { planData } from "./mockData";
import ServiceItem from "scenes/Wallet/ServiceItem";
import { ServicesData } from "mob/WalletMob/data";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getPackData } from "Services/Http/http.service";
import Skelton from "components/Skelton/Skelton";
import { setAllServies, setShoppingCartOpen } from "States/shopping";
import ShoppingCartDark from "../../assets/icon/shopping-cart-dark.svg";
import ShoppingCartLight from "../../assets/icon/shopping-cart-light.svg";
import { useTranslation } from "react-i18next";
const SoftWareStore = ({ setServiceType }) => {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.global);
  const softwareDataQuery = useQuery(
    ["softwareDataQuery", token, true, "SOFTWARE"],
    () => {
      return getPackData(token, true, "SOFTWARE");
    }
  );
  console.log("🚀 ~ SoftWareStore ~ softwareDataQuery:", softwareDataQuery);
  const softServiceDataQuery = useQuery(
    [("softServiceDataQuery", token, false, "SOFTWARE")],
    () => {
      return getPackData(token, false, "SOFTWARE");
    }
  );

  const { isLoading, isIdle, isSuccess, data } = softServiceDataQuery;

  function categorizePlansByService(plans) {
    const categorizedPlans = {};

    plans?.forEach((plan) => {
      plan?.services.forEach((service) => {
        if (!categorizedPlans[service]) {
          categorizedPlans[service] = [];
        }
        categorizedPlans[service].push(plan);
      });
    });

    return categorizedPlans;
  }

  let categorizedPlans = categorizePlansByService(data?.data?.foundPlan);
  console.log("🚀 ~ SoftWareStore ~ categorizedPlans:", categorizedPlans);

  let translationMapping = {
    هواشناسی: "weather",
    هشدارها: "alarms",
    "سنجش از راه دور": "remoteSensing",
    عملگرها: "operators",
    "مدیریت کارکنان": "staffManagement",
    "مدیریت سنسور ها": "sensorManagement",
    "شاخص هوشمند": "smartIndex",
    "مراحل رشد گیاه": "plantGrowthStages",
    "توصیه ها": "recommendations",
    "پیام رسان": "messenger",
    // Add more translations as needed
  };

  // Function to translate keys
  function translateKeys(obj) {
    let translatedData = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let translatedKey = translationMapping[key] || key; // Use translation if available, otherwise use the original key
        translatedData[translatedKey] = obj[key];
      }
    }
    return translatedData;
  }

  // Translated data
  let translatedServices = translateKeys(categorizedPlans);
  console.log("🚀 ~ SoftWareStore ~ translatedServices:", translatedServices);

  const servicesArray = Object.entries(translatedServices).map(
    ([type, services]) => ({ type, services })
  );
  console.log("🚀 ~ SoftWareStore ~ servicesArray:", servicesArray);
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.global.mode);

  dispatch(setAllServies({ allServices: servicesArray }));
  const shoppingIcon = mode === "dark" ? ShoppingCartDark : ShoppingCartLight;
  const carts = useSelector((state) => state.shopping.cart);

  return softwareDataQuery.isLoading ? (
    <Skelton />
  ) : (
    <>
      <FlexBetween>
        <Typography mb="1rem" variant="h3">
          {t("packages")}
        </Typography>
      </FlexBetween>
      <FlexBetween gap="1rem" flexWrap="wrap" justifyContent="flex-start">
        {softwareDataQuery.isSuccess &&
        softwareDataQuery.data?.data.foundPlan.length > 0 ? (
          <>
            {softwareDataQuery.data?.data.foundPlan
              .filter((item) => item.isFree === false) 
              .map((plan, index) => (
                <Plan plan={plan} key={index} />
              ))}
          </>
        ) : null} 
      </FlexBetween>
      <Typography mb="1rem" mt="2rem" variant="h3">
        {t("services")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          // alignItems: "flex-start",
          gap: "1rem",
          // justifyContent: "",
          flexWrap: "wrap",
        }}
      >
        {servicesArray?.map((service, index) => (
          <ServiceItem
            setServiceType={setServiceType}
            data={ServicesData[index]}
            details={service}
          />
        ))}
      </Box>
    </>
  );
};

export default SoftWareStore;
