import SensorDevice from "assets/hardwares/sensorDevice.svg";
import AirPre from "../../assets/Sensors/Air/pre.png";
import AirTemp from "../../assets/Sensors/Air/TH.png";
import AirHumidity from "../../assets/Sensors/Air/TH.png";
import AirRain from "../../assets/Sensors/Air/baran.png";
import AirWindDir from "../../assets/Sensors/Air/winddirection.png";
import AirWindSpeed from "../../assets/Sensors/Air/windspeed.png";
import AirLux from "../../assets/Sensors/Air/lux.png";
import SoilHumidity from "../../assets/Sensors/Soil/TH.png";
import StemDiameterSensor from "../../assets/hardwares/stemDiameterSensor.svg";
import WeatherStation from "../../assets/hardwares/weatherStation.svg";
import PlantRoshd from "../../assets/Sensors/Plant/roshd.png";
import PlantHumidity from "../../assets/Sensors/Plant/H.png";
import DeviceItemMob from "./DeviceItemMob";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCart } from "States/shopping";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { convertToToman } from "Utils/Utils";
import { memo } from "react";

const details = [
  {
    title: "سنسور فشار هوا",
    type: "sensor",
    description:
      "سنسور فشار هوا با دقت بالا و پاسخ سریع برای اندازه‌گیری دقیق فشار محیط",
    image: AirPre,
  },
  {
    title: "سنسور دمای هوا",
    type: "sensor",
    description:
      "سنسور دمای هوا با دقت بالا و قابلیت پاسخ‌دهی سریع، مناسب برای نظارت و کنترل دمای محیط در کاربردهای مختلف.",
    image: AirTemp,
  },
  {
    title: "سنسور رطوبت هوا",
    type: "sensor",
    description:
      "سنسور رطوبت هوا با دقت بالا و پایداری طولانی‌مدت، ایده‌آل برای اندازه‌گیری و کنترل رطوبت در محیط‌های مختلف",
    image: AirHumidity,
  },
  {
    title: "سنسور باران سنج",
    type: "sensor",
    description:
      "سنسور باران‌سنج با دقت و حساسیت بالا، ایده‌آل برای اندازه‌گیری میزان بارش و پایش شرایط آب و هوایی در کاربردهای کشاورزی و محیط‌زیستی",
    image: AirRain,
  },
  {
    title: "سنسور جهت باد",
    type: "sensor",
    description:
      "سنسور جهت باد با دقت فوق‌العاده و طراحی مقاوم در برابر شرایط جوی، ایده‌آل برای تحلیل دقیق الگوهای باد",
    image: AirWindDir,
  },
  {
    title: "سنسور سرعت باد",
    type: "sensor",
    description:
      "سنسور سرعت باد با دقت بالا و واکنش سریع، طراحی شده برای اندازه‌گیری دقیق و قابل اعتماد سرعت باد در شرایط جوی مختلف و کاربردهای متنوع",
    image: AirWindSpeed,
  },
  {
    title: "سنسور رطوبت خاک",
    type: "sensor",
    description:
      "سنسور رطوبت خاک با دقت بالا و قابلیت اطمینان، ایده‌آل برای نظارت بر رطوبت خاک و بهینه‌سازی آبیاری در کشاورزی و باغبانی",
    image: SoilHumidity,
  },
  {
    title: "سنسور دمای خاک",
    type: "sensor",
    description:
      "سنسور دمای خاک با دقت بالا و قابلیت پایداری، مناسب برای اندازه‌گیری دقیق دمای خاک در سیستم‌های کشاورزی و محیط‌زیستی",
    image: SoilHumidity,
  },
  {
    title: "سنسور بارندگی",
    type: "sensor",
    description:
      "سنسور بارش با دقت بالا و حساسیت قابل تنظیم، طراحی شده برای اندازه‌گیری دقیق میزان بارش در مناطق مختلف",
    image: AirRain,
  },
  {
    title: "سنسور تابش",
    type: "sensor",
    description:
      "سنسور تابش با دقت بالا و قابلیت اندازه‌گیری دقیق در محدوده‌های مختلف، برای ارزیابی دقیق تابش نوری در کاربردهای کشاورزی و بهینه‌سازی رشد گیاهان",
    image: AirLux,
  },
  {
    title: "سنسور نمودار رشد گیاه",
    type: "sensor",
    description:
      "سنسور نمودار رشد گیاه، با قابلیت ثبت و نمایش دقیق فرآیند رشد گیاهان در طول زمان، مناسب برای مطالعات علمی و کاربردهای کشاورزی",
    image: PlantRoshd,
  },
  {
    title: "سنسور قطر میوه",
    type: "sensor",
    description:
      "سنسور قطر میوه با دقت بالا و قابلیت اندازه‌گیری دقیق، مناسب برای ارزیابی ابعاد و کیفیت میوه‌ها در صنایع کشاورزی و فرآوری غذایی",
    image: PlantRoshd,
  },
  {
    title: "سنسور قطر ساقه",
    type: "sensor",
    description:
      "سنسور قطر ساقه با دقت بالا و قابلیت اندازه‌گیری دقیق، مناسب برای مطالعه و اندازه‌گیری قطر و رشد ساقه‌های گیاهان",
    image: StemDiameterSensor,
  },
  {
    title: "سنسور رطوبت برگ",
    type: "sensor",
    description:
      "سنسور رطوبت برگ با دقت بالا و قابلیت اندازه‌گیری دقیق، مناسب برای نظارت و کنترل رطوبت برگ‌ها در کشت‌های گلخانه‌ای و کاربردهای تحقیقاتی در زمینه کشاورزی و باغبانی",
    image: PlantHumidity,
  },
  {
    title: "دستگاه سنسور هوا",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: SensorDevice,
  },
  {
    title: "دستگاه سنسور خاک",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: SensorDevice,
  },
  {
    title: "دستگاه سنسور گیاه",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: SensorDevice,
  },
  {
    title: "ایستگاه هواشناسی",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: WeatherStation,
  },
];
const ShopItem = ({ item, carts }) => {
  const getDetail = (item) => {
    const hardware = details.filter((i) => i.title === item.title);
    return hardware[0];
  };
  const { t } = useTranslation();
  console.log("🚀 ~ PackageItem ~ carts:", carts);
  const dispatch = useDispatch();
  const handleremoveFromCart = () => {
    const filteredCart = carts.filter((service) => service.id !== item.id);
    dispatch(setCart({ cart: filteredCart }));
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        alignItems: "stretch",
        py: "1rem",
        "&:not(:last-child)": {
          borderBottom: "1px solid",
          borderColor: "others.lineChart",
        },
      }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          padding: "0.5rem",
          bgcolor: "background.third",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={getDetail(item)?.image}
          width="40px"
          height="40px"
        />
      </Box>
      <Stack flex={1} justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h5">{item.title}</Typography>
        <Typography variant="smallestTextPrimary" color="fontColor.alt">
          {item.timeValue !== null
            ? `${item.timeValue} ${
                item.timeUnit === "MONTH"
                  ? t("monthly")
                  : item.timeUnit === "YEAR"
                  ? t("yearly")
                  : t("daily")
              }`
            : "-"}
        </Typography>
      </Stack>
      <Stack flex={1} justifyContent="space-between" alignItems="flex-end">
        <IconButton onClick={() => handleremoveFromCart()}>
          <DeleteOutline />
        </IconButton>
        <Box display="flex" alignItems="baseline" gap="4px">
          <Typography variant="h5">
            {convertToToman(item.price).toLocaleString()}
          </Typography>
          <Typography variant="smallestTextSecondary" color="fontColor.alt">
            {t("unitCurrency")}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default memo(ShopItem);
