import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const TableRow = ({
  handleClickRow,
  selectedRow,
  expandable = false,
  row,
  colSpan,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        onClick={handleClickRow ? () => handleClickRow(row) : undefined}
        component={"tr"}
        sx={(theme) => ({
          color: theme.palette.fontColor.main,

          backgroundColor:
            selectedRow && row.id === selectedRow
              ? theme.palette.background.third
              : null,
          ...(!expandable
            ? {
                "&:not(:last-child) td": {
                  borderTop: "none !important",
                  borderLeft: "none !important",
                  borderRight: "none !important",
                  borderBottom: `1px solid ${theme.palette.others.lineChart} !important`,
                },
                "&:last-child td": {
                  border: "none !important",
                },
                "& td": {
                  padding: "1rem 0.8rem",
                },
              }
            : {
                "& td": {
                  border: "none !important",
                  padding: "0.4rem 0.8rem",
                  fontWeight: "400",
                  fontSize: theme.typography.smallestTextPrimary,
                },
              }),
          "&:hover": {
            cursor:
              row.id === selectedRow || !handleClickRow ? "auto" : "pointer",
            backgroundColor:
              selectedRow && row.id === selectedRow
                ? theme.palette.background.third
                : null,
          },
        })}
        key={uuidv4()}
      >
        {Object.values(row.data).map((value, idx) => (
          <Box
            component="td"
            sx={{ textAlign: idx === 0 ? "initial" : "center" }}
            key={uuidv4()}
          >
            <Typography variant="smallestTextSecondary" color="fontColor.main">
              {value}
            </Typography>
          </Box>
        ))}
        {expandable && (
          <td>
            <Box
              component={"div"}
              sx={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 1s",
                zIndex: 2,
                width: 20,
                height: 20,
                position: "relative",
                cursor: "pointer",
                borderRadius: 20,
                "&:hover": {
                  backgroundColor: "background.third",
                },
              }}
              onClick={() => setOpen((prev) => !prev)}
            >
              <KeyboardArrowDownOutlined />
            </Box>
          </td>
        )}
      </Box>
      {expandable && (
        <Box
          component={"tr"}
          sx={(theme) => ({
            "&:not(:last-child) td": {
              borderTop: "none !important",
              borderLeft: "none !important",
              borderRight: "none !important",
              borderBottom: `1px solid ${theme.palette.others.lineChart} !important`,
            },
            "&:last-child td": {
              border: "none !important",
            },
          })}
        >
          <td colSpan={colSpan}>
            <Box
              sx={{
                maxHeight: open ? 500 : 0,
                overflowY: "hidden",
                mx: "2rem",
                my: open ? "1rem" : 0,
                transition: "all 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "2rem",
                flexWrap: "wrap",
              }}
            >
              {Object.entries(row.expanded).map(([key, value]) => {
                return (
                  <Stack gap="0.5rem" alignItems="flex-start" flex={1}>
                    <Typography variant={"subHeading"} color={"fontColor.alt"}>
                      {key}
                    </Typography>
                    <Typography variant={"subHeading"} color={"fontColor.main"}>
                      {value}
                    </Typography>
                  </Stack>
                );
              })}
            </Box>
          </td>
        </Box>
      )}
    </>
  );
};

const Table = ({ data, selectedRow, handleClickRow, expandable = false }) => {
  const [sorted_data, setSortedData] = useState(data);
  const [sorting, setSorting] = useState({
    column: -1,
    is_descending: false,
  });

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const field_mapper = data && data[0] && Object.keys(data[0].data);
  const sort = (e) => {
    let column_idx = e.target.cellIndex;
    const column = field_mapper[column_idx];
    const is_descending =
      sorting.column === column_idx && !sorting.is_descending;
    const data_clone = [...sorted_data];
    data_clone.sort((a, b) => {
      let a_column = a.data[column];
      let b_column = b.data[column];
      if (Date.parse(a[column]) && Date.parse(b[column])) {
        a_column = Date.parse(a[column]);
        b_column = Date.parse(b[column]);
      }
      if (typeof a_column === "object" || typeof b_column === "object") {
        column_idx = -1;
        return 0;
      }
      if (a_column === b_column) return 0;
      return is_descending
        ? a_column < b_column
          ? 1
          : -1
        : a_column > b_column
        ? 1
        : -1;
    });
    setSortedData(data_clone);
    setSorting({ column: column_idx, is_descending });
  };

  if (field_mapper)
    return (
      <div style={{ width: "100%", overflow: "auto" }}>
        <Box
          component={"table"}
          cellspacing="0"
          cellpadding="0"
          sx={{
            marginBottom: "2rem",
            border: "none",
            borderCollapse: "separate",
            boxSizing: "border-box",
            textAlign: "center",
            whiteSpace: "nowrap",
            minWidth: "100%",
            position: "relative",
          }}
        >
          <Box
            component={"thead"}
            onClick={sort}
            sx={(theme) => ({
              color: "fontColor.alt",
              fontSize: theme.typography.smallestTextSecondary,
              marginBottom: "1rem",
              "& tr": {
                "& th": {
                  backgroundColor: theme.palette.background.second,
                  padding: "0.8rem",
                  zIndex: 1,
                  borderBottom: `1px solid ${theme.palette.others.lineChart} !important`,
                },
                "& th:first-child": {
                  paddingRight: "0.8rem",
                },
                "& th:last-child": {
                  paddingRight: "0.8rem",
                },
              },
            })}
          >
            <tr>
              {field_mapper?.map((field, idx) => {
                return (
                  <Box
                    component={"th"}
                    sx={{
                      cursor: "pointer",
                      boxSizing: "border-box",
                      textAlign: idx === 0 ? "initial" : "center",
                      position: "-webkit-sticky" /* Safari */,
                      position: "sticky",
                      top: 0,
                    }}
                    key={field}
                  >
                    <Typography variant="subHeading" color="fontColor.alt">
                      {field}
                    </Typography>
                  </Box>
                );
              })}
              {expandable && (
                <Box
                  component={"th"}
                  sx={{
                    cursor: "pointer",
                    boxSizing: "border-box",
                    textAlign: "right",
                    position: "-webkit-sticky" /* Safari */,
                    position: "sticky",
                    top: 0,
                  }}
                ></Box>
              )}
            </tr>
          </Box>
          <tbody>
            {sorted_data?.map((row) => {
              return (
                <TableRow
                  handleClickRow={handleClickRow}
                  selectedRow={selectedRow}
                  expandable={expandable}
                  row={row}
                  colSpan={field_mapper.length + 1}
                />
              );
            })}
          </tbody>
        </Box>
      </div>
    );
};

export default Table;
