import React from "react";
import "./Loading.css"; // Make sure to import the CSS file

const Loading = () => {
  return (
    <div className="loading-dots">
      <div className="dot blue"></div>
      <div className="dot red"></div>
      <div className="dot green"></div>
      <div className="dot yellow"></div>
    </div>
  );
};

export default Loading;
