import moment from "moment";

export const REMOTE_SENSING_DEFAULT_PERIOD = moment()
  .subtract(1, "month")
  .format("YYYY-MM-DD");

export const TODAY = moment().format("YYYY-MM-DD");

export const SENSOR_MANAGEMENT_DEFAULT_PERIOD = moment()
  .subtract(1, "week")
  .format("YYYY-MM-DD");

export const MOBILE_WIDTH = 600;


export const indexLegend = [
  {
    range: "0.95 - 1.00",
    percent: 95,
    code: "#007e47",
    desc: "Better to use NDRE",
  },
  {
    range: "0.90 - 0.95",
    percent: 90,
    code: "#009755",
    desc: "Dense vegetation",
  },
  {
    range: "0.85 - 0.90",
    percent: 85,
    code: "#14aa60",
    desc: "Dense vegetation",
  },
  {
    range: "0.80 - 0.85",
    percent: 80,
    code: "#53bd6b",
    desc: "Dense vegetation",
  },
  {
    range: "0.75 - 0.80",
    percent: 75,
    code: "#77ca6f",
    desc: "Dense vegetation",
  },
  {
    range: "0.70 - 0.75",
    percent: 70,
    code: "#9bd873",
    desc: "Dense vegetation",
  },
  {
    range: "0.65 - 0.70",
    percent: 65,
    code: "#b9e383",
    desc: "Dense vegetation",
  },
  {
    range: "0.60 - 0.65",
    percent: 60,
    code: "#d5ef94",
    desc: "Dense vegetation",
  },
  {
    range: "0.55 - 0.60",
    percent: 55,
    code: "#eaf7ac",
    desc: "Moderate vegetation",
  },
  {
    range: "0.50 - 0.55",
    percent: 50,
    code: "#fdfec2",
    desc: "Moderate vegetation",
  },
  {
    range: "0.45 - 0.50",
    percent: 45,
    code: "#ffefab",
    desc: "Moderate vegetation",
  },
  {
    range: "0.40 - 0.45",
    percent: 40,
    code: "#ffe093",
    desc: "Moderate vegetation",
  },
  {
    range: "0.35 - 0.40",
    percent: 35,
    code: "#ffc67d",
    desc: "Sparse vegetation",
  },
  {
    range: "0.30 - 0.35",
    percent: 30,
    code: "#ffab69",
    desc: "Sparse vegetation",
  },
  {
    range: "0.25 - 0.30",
    percent: 25,
    code: "#ff8d5a",
    desc: "Sparse vegetation",
  },
  {
    range: "0.20 - 0.25",
    percent: 20,
    code: "#fe6c4a",
    desc: "Sparse vegetation",
  },
  {
    range: "0.15 - 0.20",
    percent: 15,
    code: "#ef4c3a",
    desc: "Open soil",
  },
  {
    range: "0.10 - 0.15",
    percent: 10,
    code: "#e02d2c",
    desc: "Open soil",
  },
  {
    range: "0.05 - 0.10",
    percent: 5,
    code: "#c5142a",
    desc: "Open soil",
  },
  {
    range: "-1.00 - 0.05",
    percent: 0,
    code: "#ad0028",
    desc: "Open soil",
  },
  {
    range: "",
    percent: 0,
    code: "#ffffff",
    desc: "Clouds",
  },
];