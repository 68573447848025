import { Box } from "@mui/material";
import { styled } from "@mui/system";

const WidgetWrapper = styled(Box)(
  ({
    theme,
    opc,
    hasScroll = false,
    hasHorizontalScroll = false,
    hasScale = false,
  }) => ({
    padding: `1rem 1rem ${hasHorizontalScroll ? "0" : "1rem"} ${
      hasScroll ? "0" : "1rem"
    }`,
    backgroundColor: theme.palette.background.second,
    borderRadius: "0.75rem",
    opacity: opc,
    transition: "box-shadow 0.3s, transform 0.3s",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Light box shadow
    ...(hasScale && {
      "&:hover": {
        transform: "scale(1.02)", // Scale on hover
        boxShadow: "0px 8px 16px rgba(25, 15, 0, 0.2)",
      },
    }),
  })
);

export default WidgetWrapper;
