import { Skeleton } from "@mui/material";
import React from "react";

const Skelton = ({ variant, customWidth, customHeight }) => {
  return (
    <Skeleton
      
      animation="wave"
      variant={variant}
      height={customHeight ? customHeight : "100%"}
      width={customWidth ? customWidth : "100%"}
    />
  );
};

export default Skelton;
