import { Box, Rating } from "@mui/material";
import FullStar from "assets/full-star.svg";
import EmptyStar from "assets/empty-star.svg";

const CustomRating = ({ value }) => {
  return (
    <Rating
      size="small"
      name="read-only"
      value={value}
      readOnly
      icon={<Box component="img" src={FullStar} />}
      emptyIcon={<Box component="img" src={EmptyStar} />}
      sx={{ flexDirection: "row-reverse" }}
    />
  );
};

export default CustomRating;
