import { CloseOutlined, DeleteOutline } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useStatus } from "./Shopping";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { CssTextField, CustomButton } from "components/styledComponents/common";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCart, setShoppingCartOpen } from "States/shopping";
import { MOBILE_WIDTH } from "constants/constant";
import { Link } from "react-router-dom";
import { convertToToman } from "Utils/Utils";
import { useTranslation } from "react-i18next";
import ShopItem from "./ShopItem";

const ShoppingCard = () => {
  const { t } = useTranslation();
  const isMobileScreens = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);

  const carts = useSelector((state) => state.shopping.cart);
  const [discount, setDiscount] = useState(false);
  let sum = 0;

  carts.forEach((item) => {
    sum += parseInt(item.price);
  });
  const dispatch = useDispatch();
  return (
    <Box
      sx={({ palette }) => ({
        p: "1rem",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "stretch",
        scrollbarColor: `${palette.background.first} ${palette.background.third}`,
        scrollbarWidth: "thin",
        "& ::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
        },
        "& ::-webkit-scrollbar-track": {
          background: "transparent",
          borderRadius: "5px",
        },

        "& ::-webkit-scrollbar-thumb": {
          background: palette.others.lineChart,
          borderRadius: "5px",
        },
      })}
    >
      <IconButton
        onClick={() =>
          dispatch(setShoppingCartOpen({ shoppingCartOpen: false }))
        }
        sx={{ position: "absolute", top: "1rem", right: "1rem" }}
      >
        <CloseOutlined />
      </IconButton>
      <Box
        sx={{ width: "100%", textAlign: "center", mt: "2.5rem", mb: "1rem" }}
      >
        <Typography variant="h4">{t("cart")}</Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            mb: "1rem",
          }}
        >
          {carts.map((item, index) => (
            <ShopItem item={item} key={index} carts={carts} />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "stretch",
          }}
        >
          <FlexBetween gap="1rem">
            {!discount && (
              <CssTextField height="40px" placeholder={t("discountCode")} />
            )}
            <CustomButton
              sx={{
                width: discount ? "100%" : "127px",
                transition: "width 0.3s",
              }}
              disabled={discount}
              height="40px"
              secondary
              onClick={() => setDiscount(true)}
            >
              {discount ? t("discountApplied") : t("apply")}
            </CustomButton>
          </FlexBetween>
          <FlexBetween>
            <Typography variant="subHeading" color="fontColor.alt">
              {t("discount")}
            </Typography>
            <Box display="flex" alignItems="baseline" gap="4px">
              <Typography variant="h3">0</Typography>
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                {t("unitCurrency")}
              </Typography>
            </Box>
          </FlexBetween>
          <FlexBetween>
            <Typography variant="subHeading" color="fontColor.alt">
              {t("amountPayable")}
            </Typography>
            <Box display="flex" alignItems="baseline" gap="4px">
              <Typography variant="h3">
                {convertToToman(sum).toLocaleString()}
              </Typography>
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                {t("unitCurrency")}
              </Typography>
            </Box>
          </FlexBetween>
          <CustomButton>
            <Link
              to="/checkout"
              onClick={() => {
                dispatch(setShoppingCartOpen({ shoppingCartOpen: false }));
              }}
            >
              <Typography color="fontColor.main" variant="subHeading">
                {discount ? t("record") : t("completePurchase")}
              </Typography>
            </Link>
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ShoppingCard);
