import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "States";
import dashboardReducer from "States/dashboard";
import sensorsReducer from "States/sensors";
import { Provider } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./States/rootReducer";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import i18n from "i18next";
import enTranslation from "./lang/en";
import faTranslation from "./lang/fa";
import { I18nextProvider } from "react-i18next";
// import {api} from 'States/api';

// if (process.env.NODE_ENV === "production") {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

i18n.init({
  interpolation: { escapeValue: false },
  lng: "Fa",
  resources: {
    En: {
      translation: enTranslation,
    },
    Fa: {
      translation: faTranslation,
    },
  },
});
setupListeners(store.dispatch);
const root = ReactDOM.createRoot(document.getElementById("root"));
const client = new QueryClient();
root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <QueryClientProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </I18nextProvider>
  // </React.StrictMode>
);
