const { Typography, Box } = require("@mui/material");
const { CustomButton } = require("components/styledComponents/common");

const DeviceItemMob = ({ index, getDetail, item, carts, handleAddToCart }) => {
  return (
    <Box
      key={index}
      m="1rem 0"
      display="flex"
      flexDirection="column"
      gap="0.5rem"
      sx={{ marginBottom: "4rem" }}
    >
      <Box
        width="56px"
        height="56px"
        sx={{
          borderRadius: "8px",
          padding: "6px",
          bgcolor: "background.third",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mx: "1rem",
        }}
      >
        <Box
          component="img"
          src={getDetail(item)?.image}
          width="44px"
          height="44px"
          sx={{
            objectFit: "contain",
          }}
        />
      </Box>
      <Typography variant="h4">{item.title}</Typography>
      <Typography
        sx={{
          // opacity: !active ? 0 : 1,
          transition: "opacity 0.6s",
          textAlign: "justify",
        }}
        variant="smallestTextSecondary"
      >
        {getDetail(item)?.description}
      </Typography>
      <Typography sx={{ transition: "opacity 0.6s" }} variant="h3">
        {/* {(item.price / 10).toLocaleString()} تومان */}
      </Typography>
      {/* {carts.some((i) => i.id === item.id) ? (
        <CustomButton
          secondary
          onClick={() => handleAddToCart(item)}
          height="32px"
        >
          {carts.some((i) => i.id === item.id) ? "اضافه شد" : "افزودن"}
        </CustomButton>
      ) : (
        <CustomButton onClick={() => handleAddToCart(item)} height="32px">
          {carts.some((i) => i.id === item.id) ? "اضافه شد" : "افزودن"}
        </CustomButton>
      )} */}
      {/* <DeviceItem setDeviceType={setDeviceType} item={item} /> */}
    </Box>
  );
};

export default DeviceItemMob;
