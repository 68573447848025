import {
  KeyboardArrowDownOutlined,
  LocationOnOutlined,
  MoreHoriz,
} from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material";
import { getDateShamsi, getDateShamsiAirSensors } from "Utils/Utils";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { Accordion } from "components/styledComponents/common";
import { useState } from "react";

const StyledPolygon = styled("polygon")(({ theme }) => ({
  fill: "none",
  stroke: theme.palette.fontColor.main,
  strokeWidth: "16px",
}));
const FarmItem = ({ item }) => {
  const [is_open, setOpen] = useState(false);

  return (
    <Box
      p="0.7rem"
      sx={{
        overflow: "auto",

        "&:not(:last-child)": {
          borderBottom: `1px solid`,
          borderColor: "others.lineChart",
        },
      }}
    >
      <FlexBetween
        alignItems="center"
        justifyContent="space-between"
        mb="0.5rem"
      >
        <Stack direction="row" gap="0.5rem" sx={{ alignItems: "center" }}>
          {/* <Box width="16px" height="16px">
            <svg viewBox="0 0 200 200">
              <StyledPolygon points={item.points} />
            </svg>
          </Box> */}
          <Typography variant="smallestTextPrimary" color={"fontColor.main"}>
            {item.land?.name}
          </Typography>
        </Stack>
        <Stack direction="row" gap="0.5rem" sx={{ alignItems: "center" }}>
          <IconButton>
            <MoreHoriz sx={{ rotate: "90deg" }} />
          </IconButton>
          <IconButton onClick={() => setOpen((prev) => !prev)}>
            <KeyboardArrowDownOutlined
              sx={{
                rotate: is_open ? "180deg" : "0deg",
                transition: "rotate 0.3s",
              }}
            />
          </IconButton>
        </Stack>
      </FlexBetween>
      <FlexBetween>
        <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
          {item.land?.landArea} هکتار
        </Typography>
        <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
          {item.land?.productType}
        </Typography>
      </FlexBetween>
      <Accordion open={is_open}>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            مختصات
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            <LocationOnOutlined />
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            گروه محصول :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.productType}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            زیر گروه محصول :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.productSubGroup}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            نوع محصول خاص :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.specificTypeOfProduct}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            رقم محصول :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.productName}
          </Typography>
        </FlexBetween>

        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            تراکم کشت :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.cultivationDensity}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            فاصله ردیف های کاشت :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.plantingMethod}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            فاصله گیاهان یک ردیف :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.plantingRowSpacing}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            نوع آبیاری :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.irrigationType}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            روش آبیاری :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.irrigationMethod}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            سیستم آبیاری :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.irrigationSystem}
          </Typography>
        </FlexBetween>

        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            در نظر گرفتن نیاز آبشوری :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.saltWaterRequirement ? "بله" : "خیر"}
          </Typography>
        </FlexBetween>

        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            آب آبیاری EC :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.ecIrrigationWater}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            نام سم :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.poisonName}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            نام آفت یا بیماری :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.pestName}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            روش سم پاشی :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.plantingMethod}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            نام کود :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.fertilizerName}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            روش کوددهی :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.fertilizerSpreadingMethod}
          </Typography>
        </FlexBetween>

        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={"fontColor.alt"}>
            مقدار کود :
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            {item.land?.fertilizerQuantity}
          </Typography>
        </FlexBetween>
      </Accordion>
    </Box>
  );
};

export default FarmItem;
