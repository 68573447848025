import {
  AddBoxOutlined,
  AddLocationAltOutlined,
  AddOutlined,
  Cloud,
  CloudOutlined,
  Dashboard,
  DashboardOutlined,
  Forum,
  ForumOutlined,
  Message,
  MessageOutlined,
  PeopleAlt,
  PeopleAltOutlined,
  PersonAdd,
  PersonAddOutlined,
  PlaylistAddOutlined,
  SatelliteAlt,
  SatelliteAltOutlined,
  SettingsRemote,
  SettingsRemoteOutlined,
} from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { setSelectedItemMob } from "States";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EventIcon from "../../assets/icon/event.svg";
import SensorIcon from "../../assets/icon/sensor.svg";
import FarmIcon from "../../assets/icon/farm.svg";
const TabbarMob = () => {
  const theme = useTheme();
  const location = useLocation();
  const alt = theme.palette.fontColor.alt;
  const dispatch = useDispatch();
  const selectedItemMob = useSelector((state) => state.global.selectedItemMob);
  const [open_record, setOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.global.userType);
  console.log("🚀 ~ TabbarMob ~ user:", user);
  return user !== "Staff" ? (
    <FlexBetween
      sx={{
        width: "100%",
        height: "56px",
        position: "fixed",
        bottom: 0,
        borderRadius: "12px 12px 0 0",
        backgroundColor: theme.palette.background.second,
        p: "0 0.5rem",
        zIndex: "1000",
        mt: "0.9rem",
        boxShadow: "0 -4px 15px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          // dispatch(setSelectedItemMob({ selectedItemMob: 1 }));
          navigate("/management");
        }}
      >
        {location?.pathname === "/management" ? (
          <Box
            width="70px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Dashboard sx={{ color: theme.palette.others.first }} />
            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              مدیریت جامع
            </Typography>
          </Box>
        ) : (
          <DashboardOutlined sx={{ color: "fontColor.alt" }} />
        )}
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          // dispatch(setSelectedItemMob({ selectedItemMob: 2 }));
          navigate("/sensormanagement");
        }}
      >
        {location?.pathname === "/sensormanagement" ? (
          <Box
            width="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <SettingsRemoteOutlined
              sx={{ color: theme.palette.others.first }}
            />

            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              مدیریت سنسورها
            </Typography>
          </Box>
        ) : (
          <SettingsRemote sx={{ color: "fontColor.alt" }} />
        )}
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          // dispatch(setSelectedItemMob({ selectedItemMob: 4 }));
          navigate("/remotesensing");
        }}
      >
        {location?.pathname === "/remotesensing" ? (
          <Box
            width="70px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <SatelliteAltOutlined sx={{ color: theme.palette.others.first }} />

            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              سنجش از دور
            </Typography>
          </Box>
        ) : (
          <SatelliteAlt sx={{ color: "fontColor.alt" }} />
        )}
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          // dispatch(setSelectedItemMob({ selectedItemMob: 8 }));
          navigate("/staffmgmt");
        }}
      >
        {location?.pathname === "/staffmgmt" ? (
          <Box
            width="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <PeopleAltOutlined sx={{ color: theme.palette.others.first }} />

            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              مدیریت کارکنان
            </Typography>
          </Box>
        ) : (
          <PeopleAlt sx={{ color: "fontColor.alt" }} />
        )}
      </Stack>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box
          onClick={() => setOpen((prev) => !prev)}
          sx={{
            position: "absolute",
            width: "38px",
            height: "38px",
            top: 5,
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "38px",
              height: "38px",
              backgroundColor: theme.palette.background.second,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:before": {
                content: '""',
                position: "absolute",
                width: "40px",
                height: "40px",
                top: "50%",
                right: "50%",
                transform: "translate(50%, -50%)",
                zIndex: -2,
                borderRadius: "inherit" /* !importanté */,
                background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
              },
            }}
          >
            <AddOutlined
              sx={{
                width: "75%",
                height: "75%",
                color: alt,
                rotate: open_record ? "135deg" : "0deg",
                transition: "rotate 0.3s",
              }}
            />
            <Link to="/recordUser">
              <Box
                sx={{
                  content: '""',
                  position: "absolute",
                  width: "40px",
                  height: "40px",
                  top: open_record ? "-35%" : "50%",
                  right: open_record ? "180%" : "50%",
                  transition: "all 0.3s",
                  transform: "translate(50%, -50%)",
                  zIndex: -2,
                  borderRadius: "50%",
                  background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PersonAddOutlined sx={{ color: "fontColor.main" }} />
              </Box>
            </Link>
            <Link to="/recordfarm">
              <Box
                sx={{
                  content: '""',
                  position: "absolute",
                  width: "40px",
                  height: "40px",
                  top: open_record ? "-80%" : "50%",
                  right: "50%",
                  transition: "all 0.3s",
                  transform: "translate(50%, -50%)",
                  zIndex: -2,
                  borderRadius: "50%",
                  background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AddLocationAltOutlined sx={{ color: "fontColor.main" }} />
              </Box>
            </Link>
            <Link to="/recordEvents">
              <Box
                sx={{
                  content: '""',
                  position: "absolute",
                  width: "40px",
                  height: "40px",
                  top: open_record ? "-35%" : "50%",
                  right: open_record ? "-80%" : "50%",
                  transition: "all 0.3s",
                  transform: "translate(50%, -50%)",
                  zIndex: -2,
                  borderRadius: "50%",
                  background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PlaylistAddOutlined sx={{ color: "fontColor.main" }} />
              </Box>
            </Link>
          </Box>
        </Box>
      </ClickAwayListener>
    </FlexBetween>
  ) : (
    <FlexBetween
      sx={{
        width: "100%",
        height: "56px",
        position: "fixed",
        bottom: 0,
        borderRadius: "12px 12px 0 0",
        backgroundColor: theme.palette.background.second,
        p: "0 0.5rem",
        zIndex: "1000",
        mt: "0.9rem",
        boxShadow: "0 -4px 15px rgba(0, 0, 0, 0.25)",
        justifyContent: "space-around",
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          // dispatch(setSelectedItemMob({ selectedItemMob: 1 }));
          navigate("/management");
        }}
      >
        {location?.pathname === "/management" ? (
          <Box
            width="70px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <PeopleAlt sx={{ color: theme.palette.others.first }} />
            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              وظایف
            </Typography>
          </Box>
        ) : (
          <PeopleAltOutlined sx={{ color: "fontColor.alt" }} />
        )}
      </Stack>
      {/* <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          // dispatch(setSelectedItemMob({ selectedItemMob: 2 }));
          // navigate("/messenger");
        }}
      >
        {location?.pathname === "/messenger" ? (
          <Box
            width="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <ForumOutlined sx={{ color: theme.palette.others.first }} />

            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              مدیریت سنسورها
            </Typography>
          </Box>
        ) : (
          <Forum sx={{ color: "fontColor.alt" }} />
        )}
      </Stack> */}
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          // dispatch(setSelectedItemMob({ selectedItemMob: 4 }));
          navigate("/weather");
        }}
      >
        {location?.pathname === "/weather" ? (
          <Box
            width="70px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CloudOutlined sx={{ color: theme.palette.others.first }} />

            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              هواشناسی
            </Typography>
          </Box>
        ) : (
          <Cloud sx={{ color: "fontColor.alt" }} />
        )}
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          // dispatch(setSelectedItemMob({ selectedItemMob: 8 }));
          navigate("/ticket");
        }}
      >
        {location?.pathname === "/ticket" ? (
          <Box
            width="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <MessageOutlined sx={{ color: theme.palette.others.first }} />

            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              تیکت
            </Typography>
          </Box>
        ) : (
          <Message sx={{ color: "fontColor.alt" }} />
        )}
      </Stack>
      {user !== "Staff" && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box
            onClick={() => setOpen((prev) => !prev)}
            sx={{
              position: "absolute",
              width: "38px",
              height: "38px",
              top: 5,
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "38px",
                height: "38px",
                backgroundColor: theme.palette.background.second,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  width: "40px",
                  height: "40px",
                  top: "50%",
                  right: "50%",
                  transform: "translate(50%, -50%)",
                  zIndex: -2,
                  borderRadius: "inherit" /* !importanté */,
                  background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
                },
              }}
            >
              <AddOutlined
                sx={{
                  width: "75%",
                  height: "75%",
                  color: alt,
                  rotate: open_record ? "135deg" : "0deg",
                  transition: "rotate 0.3s",
                }}
              />
              <Link to="/recordUser">
                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    width: "40px",
                    height: "40px",
                    top: open_record ? "-35%" : "50%",
                    right: open_record ? "180%" : "50%",
                    transition: "all 0.3s",
                    transform: "translate(50%, -50%)",
                    zIndex: -2,
                    borderRadius: "50%",
                    background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PersonAddOutlined sx={{ color: "fontColor.main" }} />
                </Box>
              </Link>
              <Link to="/recordfarm">
                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    width: "40px",
                    height: "40px",
                    top: open_record ? "-80%" : "50%",
                    right: "50%",
                    transition: "all 0.3s",
                    transform: "translate(50%, -50%)",
                    zIndex: -2,
                    borderRadius: "50%",
                    background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AddLocationAltOutlined sx={{ color: "fontColor.main" }} />
                </Box>
              </Link>
              <Link to="/recordEvents">
                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    width: "40px",
                    height: "40px",
                    top: open_record ? "-35%" : "50%",
                    right: open_record ? "-80%" : "50%",
                    transition: "all 0.3s",
                    transform: "translate(50%, -50%)",
                    zIndex: -2,
                    borderRadius: "50%",
                    background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PlaylistAddOutlined sx={{ color: "fontColor.main" }} />
                </Box>
              </Link>
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </FlexBetween>
  );
};

export default TabbarMob;
