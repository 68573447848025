import { createTheme } from "@mui/material";

export const tokenDark = {
  primary: {
    100: "#2A80F4",
    200: "#FF7410",
  },

  font: {
    50: "#F7F7F7",
    100: "#C4C3D9",
  },
  backgroundColor: {
    100: "#141332",
    200: "#1D1D41",
    300: "#38385C",
    400: "#64638F",
  },
  others: {
    100: "#2F80ED", //blue
    200: "#FC4949", //red
    300: "#5FBD15", //green
    400: "#FFEE00", //yellow
    500: "rgba(29, 29, 65, 0.7)", //glassy
    600: "#64638F",
    700: "rgba(56, 56, 92, 0.7)", //glassy-2
  },
};
export const tokenLight = {
  primary: {
    100: "#2A80F4",
    200: "#FF7410",
  },

  font: {
    50: "#2D2D51",
    100: "#76758F",
  },
  backgroundColor: {
    100: "linear-gradient(180deg, #EBEEF2, #CADCE8)",
    200: "#F7F7FC",
    300: "#E1ECFA",
    400: "#F7F7FC",
  },
  others: {
    100: "#2F80ED", //blue
    200: "#F83F26", //red
    300: "#50A90A", //green
    400: "#FF9B23", //yellow
    500: "rgba(250,250,252,0.7)", //glassy
    600: "#D7D6EE",
    700: "rgba(225, 236, 250, 0.7)", //glassy-2
  },
};

//function that reverses the color palette

function reverseTokens(tokenDark) {
  const reversedTokens = {};
  Object.entries(tokenDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj = {};
    for (let i = 0; i < length; i++) {
      reversedObj[key[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}

//mui theme settings

export const themeSettings = (mode, language) => {
  const fontFamily =
    language === "En"
      ? ["sans-serif", "YekanBakhFaNum-Regular"].join(",")
      : ["YekanBakhFaNum-Regular", "sans-serif"].join(",");

  const semiBoldFontFamily =
    language === "En"
      ? ["sans-serif", "YekanBakhFaNum-SemiBold"].join(",")
      : ["YekanBakhFaNum-SemiBold", "sans-serif"].join(",");

  return {
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            userSelect: "none",
            textTransform: "none",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&>textarea": {
              // height: "100% !important",
            },
          },
        },
      },
    },

    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            //palette values for dark mode
            primary: {
              ...tokenDark.primary,

              main: tokenDark.primary[100],
              alt: tokenDark.primary[200],
            },

            background: {
              first: tokenDark.backgroundColor[100],
              second: tokenDark.backgroundColor[200],
              third: tokenDark.backgroundColor[300],
              forth: tokenDark.backgroundColor[400],
            },
            fontColor: {
              main: tokenDark.font[50],
              alt: tokenDark.font[100],
              third: tokenDark.font[300],
            },
            others: {
              first: tokenDark.others[100],
              second: tokenDark.others[200],
              third: tokenDark.others[300],
              forth: tokenDark.others[400],
              fifth: tokenDark.others[500],
              sixth: tokenDark.others[700],
              lineChart: tokenDark.others[600],
            },
            popper: {
              backgroundColor: tokenDark.backgroundColor[300],
              boxShadow: "none",
            },
            menuItem: {
              backgroundColor: tokenDark.backgroundColor[400],
            },
          }
        : {
            //palette values for light mode
            primary: {
              ...tokenLight.primary,
              main: tokenLight.primary[100],
              alt: tokenLight.primary[200],
            },

            background: {
              first: tokenLight.backgroundColor[100],
              second: tokenLight.backgroundColor[200],
              third: tokenLight.backgroundColor[300],
              forth: tokenLight.backgroundColor[400],
            },
            fontColor: {
              main: tokenLight.font[50],
              alt: tokenLight.font[100],
              third: tokenLight.font[300],
            },
            others: {
              first: tokenLight.others[100],
              second: tokenLight.others[200],
              third: tokenLight.others[300],
              forth: tokenLight.others[400],
              fifth: tokenLight.others[500],
              sixth: tokenLight.others[700],
              lineChart: tokenLight.others[600],
            },
            popper: {
              backgroundColor: tokenLight.backgroundColor[200],
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
            },
            menuItem: {
              backgroundColor: tokenLight.backgroundColor[300],
            },
          }),
    },
    typography: {
      fontFamily,
      fontSize: 12,
      h1: { fontFamily, fontSize: 40 },
      h2: { fontFamily, fontSize: 32 },
      h3: { fontFamily: semiBoldFontFamily, fontSize: 24 },
      h4: { fontFamily: semiBoldFontFamily, fontSize: 18 },
      h5: { fontFamily: semiBoldFontFamily, fontSize: 16 },
      h6: { fontFamily: semiBoldFontFamily, fontSize: 12 },
      body: { fontFamily, fontSize: 16 },
      subHeading: { fontFamily, fontSize: 14 },
      smallestTextPrimary: { fontFamily: semiBoldFontFamily, fontSize: 12 },
      smallestTextSecondary: { fontFamily, fontSize: 12 },
      extraSmall: { fontFamily, fontSize: 10 },
      regular: { fontFamily, fontSize: 20 },
    },
  };
};
