import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
  isSidebarOpen: "false",
  token: null,
  userId: "",
  activeFarm: null,
  sensorIndex: 0,
  propertyId: null,
  alarmCount: 0,
  farmName: "",
  selectedItemMob: 1,
  farmNameMob: "",
  userType: "",
  statusLandName: false,
  changedLand: false,
  profileImage: null,
  email: "",
  mobile: "",
  fullName: "",
  birthDate: "",
  polygonAreaLand: 0,
  alarmNotification: false,
  userPermissions: null,
  language: "Fa",
  newTask: false,
  newRecommend: "",
  newDescRecommend: "",
  selectedFarmEdit: "",
  editFarm: false,
  help: true,
  helper: {
    welcome: true,
    alarm: false,
    weather: false,
    growthStages: false,
    cultivationStatus: false,
    equipmentControl: false,
    map: false,
    sensorM: false,
    smartIndex: false,
    recommends: false,
    staff: false,
  },
  staffHelp: true,
  staffHelper: {
    welcome: true,
    weather: false,
    totalIndex: false,
    staff: false,
    task: false,
  },
  recommendHelp: true,
  recommendHelper: {
    welcome: true,
    weather: false,
    lastSensor: false,
    recommends: false,
  },
  weatherHelp: true,
  weatherHelper: {
    welcome: true,
    daily: false,
    hourly: false,
    uv: false,
    sun: false,
    sensors: false,
    details: false,
  },
  growthStagesHelp: true,
  growthStagesHelper: {
    welcome: true,
    product: false,
    task: false,
    map: false,
    smartIndex: false,
  },
  expertStatusHelp: true,
  expertStatusHelper: {
    welcome: true,
    requestStatus: false,
    experts: false,
  },
  drawMapHelp: true,
  drawMapHelper: {
    welcome: true,
    search: false,
    tools: false,
    map: false,
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    openSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setLogin: (state, action) => {
      state.userType = action.payload.userType;
      state.token = action.payload.token.token;
      state.email = action.payload.email;
      state.mobile = action.payload.mobile;
      state.profileImage = action.payload.profileImage;
      state.fullName = action.payload.fullName;
      state.birthDate = action.payload.birthDate;
    },
    setProfileImage: (state, action) => {
      state.profileImage = action.payload.profileImage;
    },
    setMobile: (state, action) => {
      state.mobile = action.payload.mobile;
    },
    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setFullName: (state, action) => {
      state.fullName = action.payload.fullName;
    },
    setBirthDate: (state, action) => {
      state.birthDate = action.payload.birthDate;
    },
    setLogout: (state) => {
      state.userType = "";
      state.token = null;
    },
    setClearLogin: (state, action) => {
      state.token = null;
    },
    setActiveFarm: (state, action) => {
      state.activeFarm = action.payload.activeFarm;
    },
    setSensorIndex: (state, action) => {
      state.sensorIndex = action.payload.sensorIndex;
    },
    setPropertyId: (state, action) => {
      state.propertyId = action.payload.propertyId;
    },
    setAlarmCount: (state, action) => {
      state.alarmCount = action.payload.alarmCount;
    },
    setFarmName: (state, action) => {
      state.farmName = action.payload.farmName;
    },
    setSelectedItemMob: (state, action) => {
      state.selectedItemMob = action.payload.selectedItemMob;
    },
    setFarmNameMob: (state, action) => {
      state.farmNameMob = action.payload.farmNameMob;
    },
    setUserType: (state, action) => {
      state.userType = action.payload.userType;
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    setChangedLand: (state, action) => {
      state.changedLand = action.payload.changedLand;
    },
    setStatusLandName: (state, action) => {
      state.statusLandName = action.payload.statusLandName;
    },
    setPolygonAreaLand: (state, action) => {
      state.polygonAreaLand = action.payload.polygonAreaLand;
    },
    setAlarmNotification: (state, action) => {
      state.alarmNotification = action.payload.alarmNotification;
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload.userPermissions;
    },
    setLanguage: (state, action) => {
      state.language = action.payload.language;
    },
    setHelper: (state, action) => {
      state.helper = action.payload.helper;
    },
    setHelp: (state, action) => {
      state.help = action.payload.help;
    },
    setStaffHelper: (state, action) => {
      state.staffHelper = action.payload.staffHelper;
    },
    setStaffHelp: (state, action) => {
      state.staffHelp = action.payload.staffHelp;
    },
    setRecommendHelp: (state, action) => {
      state.recommendHelp = action.payload.recommendHelp;
    },
    setRecommendHelper: (state, action) => {
      state.recommendHelper = action.payload.recommendHelper;
    },
    setWeatherHelp: (state, action) => {
      state.weatherHelp = action.payload.weatherHelp;
    },
    setWeatherHelper: (state, action) => {
      state.weatherHelper = action.payload.weatherHelper;
    },
    setGrowthStagesHelp: (state, action) => {
      state.growthStagesHelp = action.payload.growthStagesHelp;
    },
    setGrowthStagesHelper: (state, action) => {
      state.growthStagesHelper = action.payload.growthStagesHelper;
    },
    setExpertStatusHelp: (state, action) => {
      state.expertStatusHelp = action.payload.expertStatusHelp;
    },
    setExpertStatusHelper: (state, action) => {
      state.expertStatusHelper = action.payload.expertStatusHelper;
    },
    setDrawMapHelp: (state, action) => {
      state.drawMapHelp = action.payload.drawMapHelp;
    },
    setDrawMapHelper: (state, action) => {
      state.drawMapHelper = action.payload.drawMapHelper;
    },
    setNewTask: (state, action) => {
      state.newTask = action.payload.newTask;
    },
    setNewRecommend: (state, action) => {
      state.newRecommend = action.payload.newRecommend;
    },
    setNewDescRecommend: (state, action) => {
      state.newDescRecommend = action.payload.newDescRecommend;
    },
    setSelectedFarmEdit: (state, action) => {
      state.selectedFarmEdit = action.payload.selectedFarmEdit;
    },
    setEditFarm: (state, action) => {
      state.editFarm = action.payload.editFarm;
    },
  },
});

export const {
  setMode,
  setToken,
  openSidebar,
  setLogin,
  setLogout,
  setClearLogin,
  setActiveFarm,
  setSensorIndex,
  setPropertyId,
  setAlarmCount,
  setFarmName,
  setSelectedItemMob,
  setFarmNameMob,
  setUserType,
  setChangedLand,
  setStatusLandName,
  setProfileImage,
  setMobile,
  setEmail,
  setFullName,
  setBirthDate,
  setPolygonAreaLand,
  setAlarmNotification,
  setUserPermissions,
  setLanguage,
  setHelper,
  setHelp,
  setStaffHelper,
  setStaffHelp,
  setRecommendHelp,
  setRecommendHelper,
  setWeatherHelp,
  setWeatherHelper,
  setGrowthStagesHelp,
  setGrowthStagesHelper,
  setExpertStatusHelp,
  setExpertStatusHelper,
  setDrawMapHelp,
  setDrawMapHelper,
  setNewTask,
  setNewRecommend,
  setNewDescRecommend,
  setSelectedFarmEdit,
  setEditFarm,
} = globalSlice.actions;
export default globalSlice.reducer;
