import {
  Badge,
  Box,
  Divider,
  IconButton,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useContext } from "react";
import ServiceItem from "modules/WeatherModule/ServiceItem";
import { CustomButton } from "components/styledComponents/common";
import ShoppingCartDark from "assets/icon/shopping-cart-dark.svg";
import ShoppingCartLight from "assets/icon/shopping-cart-light.svg";
import { useSelector } from "react-redux";
import { useStatus } from "./Shopping";
import CustomRating from "components/CustomRating/CustomRating";
import SensorDevice from "assets/hardwares/sensorDevice.svg";
const DeviceIndividual = ({ type, setDeviceType }) => {
  const { mode } = useSelector((state) => state.global);
  const shoppingIcon = mode === "dark" ? ShoppingCartDark : ShoppingCartLight;
  const { setShoppingCartOpen } = useStatus();
  return (
    <Box width="100%" height="calc(100% - 110px)" p="1rem">
      <Box display="flex" gap="16px" alignItems="stretch" height="100%">
        <Box
          width="80%"
          // height="100%"
          display="flex"
          flexDirection="column"
          gap="16px"
          sx={{
            overflowY: "auto",
          }}
        >
          <WidgetWrapper width="100%" height="100%">
            <Stack direction="row" alignItems="stretch" width="100%" gap="16px">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  gap: "18px",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "8px",
                    padding: "1.5rem",
                    bgcolor: "background.third",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={SensorDevice}
                    width="98px"
                    height="98px"
                  />
                </Box>
                <CustomButton height="32px">خرید دستگاه</CustomButton>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="90%"
              >
                <FlexBetween>
                  <Stack direction="row" gap="20px" alignItems="center">
                    <Typography variant="h3" color="fontColor.main">
                      دستگاه سنسور ها
                    </Typography>
                    <Stack direction="row" alignItems="center" gap="8px">
                      <CustomRating value={4} />
                      <Typography variant="subHeading" color="fontColor.alt">
                        83 نفر
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap="8px">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.99935 8.00016C9.8403 8.00016 11.3327 6.50778 11.3327 4.66683C11.3327 2.82588 9.8403 1.3335 7.99935 1.3335C6.1584 1.3335 4.66602 2.82588 4.66602 4.66683C4.66602 6.50778 6.1584 8.00016 7.99935 8.00016Z"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <Typography variant="subHeading" color="fontColor.alt">
                        خرید
                      </Typography>
                      <Typography variant="h5" color="fontColor.main">
                        150
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap="8px">
                    <CustomButton
                      height="35px"
                      secondary
                      onClick={() => setDeviceType(null)}
                    >
                      بازگشت به فروشگاه
                    </CustomButton>
                    <IconButton onClick={() => setShoppingCartOpen(true)}>
                      <Badge
                        overlap="circular"
                        badgeContent={4}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        invisible={false}
                        sx={{
                          "& 	.MuiBadge-badge": {
                            backgroundColor: "others.second",
                            minWidth: "16px",
                            width: "16px",
                            height: "16px",
                            color: "white",
                          },
                        }}
                      >
                        <Box component="img" width="24px" src={shoppingIcon} />
                      </Badge>
                    </IconButton>
                  </Stack>
                </FlexBetween>
                <Box
                  sx={{ display: "flex", gap: "8px", alignItems: "baseline" }}
                >
                  <Typography variant="h3">15.000.000</Typography>
                  <Typography
                    variant="smallestTextSecondary"
                    color="fontColor.alt"
                  >
                    تومان
                  </Typography>
                </Box>
                <Typography>
                  دستگاه سنسور هوا برای کشاورزی هوشمند، یک سامانه حیاتی برای
                  نظارت و کنترل شرایط محیطی در مزارع و باغات شماست. این دستگاه
                  می تواند مجهز به مجموعه‌ای از حسگرهای پیشرفته باشد که اطلاعات
                  دقیقی از عوامل مهم هواشناسی مانند دما، رطوبت، فشار هوا،
                  بارندگی و سرعت و جهت باد فراهم می‌کند. با استفاده از این
                  داده‌ها، می‌توانید تصمیمات هوشمندانه‌تری برای مراقبت از
                  محصولات خود بگیرید.
                </Typography>
              </Box>
            </Stack>

            <Box width="100%" mt="3rem">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Typography variant="h4" color="fontColor.main">
                  امکانات
                </Typography>
              </Box>
              <Box m="0.5rem 1.5rem 0 0">
                <ul>
                  <li style={{ margin: "1.5rem 0 0.5rem 0" }}>
                    <Typography variant="body" color="fontColor.main">
                      قابلیت نصب سنسور های دما و رطوبت، شدت تابش، بارندگی، باد،
                      نقطه شبنم و فشار هوا
                    </Typography>
                  </li>
                  <li style={{ marginBottom: "0.5rem" }}>
                    <Typography variant="body" color="fontColor.main">
                      نصب آسان
                    </Typography>
                  </li>
                  <li style={{ marginBottom: "0.5rem" }}>
                    <Typography variant="body" color="fontColor.main">
                      طراحی مقاوم در برابر آب و هوای متغیر و مشکلات محیطی دیگر
                    </Typography>
                  </li>
                  <li style={{ marginBottom: "0.5rem" }}>
                    <Typography variant="body" color="fontColor.main">
                      قابل استفاده برای زمین های باغی وزراعی
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Box>
          </WidgetWrapper>
        </Box>
        <WidgetWrapper width="19.3%" hasScroll overflow="auto">
          <Box pl='1rem'>
            <Typography variant="h4">سرویس های پیشنهادی</Typography>
            <ServiceItem title={"شاخص هوشمند"} />
            <Divider />
            <ServiceItem title={"مدیریت سنسورها"} />

            <Divider />
            <ServiceItem title={"توصیه ها"} />

            <Divider />
            <ServiceItem title={"مدیریت کارکنان"} />
            <Divider />
            <ServiceItem title={"هشدارها"} />
          </Box>
        </WidgetWrapper>
      </Box>
    </Box>
  );
};

export default DeviceIndividual;
