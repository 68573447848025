// api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';

const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.rahbaan.ir' }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/api/v1/auth/login',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userName: credentials.username,
          password: credentials.password,
        }),
      }),
    }),
    fetchLandData: builder.query({
      query: (token) => ({
        url: '/api/v1/land',
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }),
    }),

    fetchSensorsData: builder.query({
      query: (token) => ({
        url: '/api/v1/device/attributes?sortOrder=DES&sortProperty=createdTime&page=0&pageSize=10',
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }),
    }),

    fetchLastSensorsData: (token, id) => {
      return builder.query({
        queryFn: ({ queryKey }) => {
          const [token, id] = queryKey;
          const url = `/api/v1/plugins/telemetry/DEVICE/${id}/values/timeseries`;
    
          return fetch(url, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }).then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          });
        },
      });
    },
    

    
  }),
});

export const { useLoginMutation, useFetchLandDataQuery , useFetchSensorsDataQuery, useFetchLastSensorsData} = api;



export default api;
