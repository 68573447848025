import { Wallet } from "@mui/icons-material";
import {
  Badge,
  Box,
  Divider,
  IconButton,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useContext, useEffect } from "react";
import CopyRight from "widgets/CopyRight";
import FarmIcon from "assets/Modules/weather1.png";
import FarmIcon2 from "assets/Modules/Weather2.png";
import FarmIcon3 from "assets/Modules/Weather3.png";
import FarmIcon4 from "assets/Modules/Weather4.png";
import PackageItem from "modules/WeatherModule/PackageItem";
import ServiceItem from "modules/WeatherModule/ServiceItem";
import { CustomButton } from "components/styledComponents/common";
import ShoppingCartDark from "assets/icon/shopping-cart-dark.svg";
import ShoppingCartLight from "assets/icon/shopping-cart-light.svg";
import { useDispatch, useSelector } from "react-redux";
import { useStatus } from "./Shopping";
import CustomRating from "components/CustomRating/CustomRating";
import { convertToToman, translateToPersian } from "Utils/Utils";
import { setShoppingCartOpen } from "States/shopping";
import { useTranslation } from "react-i18next";

const ServiceIndividual = ({ setServiceType, type }) => {
  const { t } = useTranslation();
  const descriptions = [
    {
      type: t("weather"),
      img1: null,
      img2: null,
      img3: null,
      description: t("weatherDescService"),
    },
    {
      type: t("alarms"),
      img1: null,
      img2: null,
      img3: null,
      description: t("alarmDescService"),
    },
    {
      type: t("remoteSensing"),
      img1: null,
      img2: null,
      img3: null,
      description: t("remoteDescService"),
    },
    {
      type: t("actuators"),
      img1: null,
      img2: null,
      img3: null,
      description: t("actuatorDescService"),
    },
    {
      type: t("messenger"),
      img1: null,
      img2: null,
      img3: null,
      description: t("messengerDescService"),
    },
    {
      type: t("staffManagement"),
      img1: null,
      img2: null,
      img3: null,
      description: t("staffDescService"),
    },
    {
      type: t("sensorManagement"),
      img1: null,
      img2: null,
      img3: null,
      description: t("sensorDescService"),
    },
    {
      type: t("smartIndex"),
      img1: null,
      img2: null,
      img3: null,
      description: t("smartDescService"),
    },
    {
      type: t("growthStages"),
      img1: null,
      img2: null,
      img3: null,
      description: t("growthDescService"),
    },
    {
      type: t("recommend"),
      img1: null,
      img2: null,
      img3: null,
      description: t("recommendDescService"),
    },
  ];
  const allServices = useSelector((state) => state.shopping.allServices);
  console.log("🚀 ~ ServiceIndividual ~ allServices:", allServices);
  const selectedService = useSelector((state) => state.shopping.services);
  console.log("🚀 ~ ServiceIndividual ~ selectedService:", selectedService);

  const { mode } = useSelector((state) => state.global);
  const shoppingIcon = mode === "dark" ? ShoppingCartDark : ShoppingCartLight;
  // const { setShoppingCartOpen } = useStatus();
  const services = useSelector((state) => state.shopping.services);
  console.log("🚀 ~ ServiceIndividual ~ services:", services);
  const des = descriptions?.filter(
    (item) => item.type === translateToPersian(selectedService.type)
  );
  const dispatch = useDispatch();
  const carts = useSelector((state) => state.shopping.cart);

  useEffect(() => {}, [selectedService]);
  const shop = useSelector((state) => state.shopping.shoppingCartOpen);
  console.log("🚀 ~ ServiceIndividual ~ shop:", shop);
  const lang = useSelector((state) => state.global.language);
  return (
    <Box width="100" min="calc(100% - 110px)" p="1rem">
      <Box display="flex" gap="16px" alignItems="stretch" height="100%">
        <Box
          width="80%"
          display="flex"
          flexDirection="column"
          gap="16px"
          sx={{
            overflowY: "auto",
          }}
        >
          <WidgetWrapper width="100%" height="80vh">
            <Stack
              direction="row"
              alignItems="flex-start"
              width="100%"
              height="139px"
              mb="27px"
              gap="16px"
            >
              <Box
                component="img"
                src={FarmIcon}
                width="139px"
                height="139px"
              />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="90%"
                height="60%"
              >
                <FlexBetween>
                  <Stack direction="row" gap="20px" alignItems="center">
                    <Typography variant="h3" color="fontColor.main">
                      {translateToPersian(selectedService.type, lang)}
                    </Typography>
                    {/* <Stack direction="row" alignItems="center" gap="8px">
                      <CustomRating value={4} />
                      <Typography variant="subHeading" color="fontColor.alt">
                        83 نفر
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap="8px">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.99935 8.00016C9.8403 8.00016 11.3327 6.50778 11.3327 4.66683C11.3327 2.82588 9.8403 1.3335 7.99935 1.3335C6.1584 1.3335 4.66602 2.82588 4.66602 4.66683C4.66602 6.50778 6.1584 8.00016 7.99935 8.00016Z"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <Typography variant="subHeading" color="fontColor.alt">
                        خرید
                      </Typography>
                      <Typography variant="h5" color="fontColor.main">
                        150
                      </Typography>
                    </Stack> */}
                  </Stack>
                  <Stack direction="row" alignItems="center" gap="8px">
                    <CustomButton
                      height="35px"
                      secondary
                      onClick={() => setServiceType(null)}
                    >
                      {t("returnShopping")}{" "}
                    </CustomButton>
                  </Stack>
                </FlexBetween>
                {/* <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
               2.0.0
                </Typography> */}
              </Box>
            </Stack>
            <Typography pr="1rem">{des[0]?.description}</Typography>
            <Box
              mt="2rem"
              width="100%"
              //   height="50%"
              display="flex"
              alignItems="center"
              p="0 1rem"
              justifyContent="flex-start"
              gap="20px"
              flexWrap="wrap"
            >
              {services?.services.map((service, index) => (
                <PackageItem
                  title={`${service.timeValue} ${
                    service.timeUnit === "MONTH"
                      ? t("monthly")
                      : service.timeUnit === "YEAR"
                      ? t("yearly")
                      : t("daily")
                  }`}
                  price={
                    convertToToman(service.price) > "1000000"
                      ? `${convertToToman(service.price) / 1000000}`
                      : `${convertToToman(service.price) / 1000}`
                  }
                  unit={
                    lang === "Fa"
                      ? convertToToman(service.price) > "1000000"
                        ? "میلیون تومان"
                        : "هزار تومان"
                      : "USD"
                  }
                  service={service}
                />
              ))}
            </Box>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="3rem"
              gap="16px"
            >
              <Box component="img" src={des[0]?.img1} />
              <Box component="img" src={des[0]?.img2} />
              <Box component="img" src={des[0]?.img3} />
            </Box>
            {/* <Box width="100%" mt="3rem">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Typography variant="h4" color="fontColor.main">
                  امکانات جدید
                </Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
                  ورژن 1.0.0
                </Typography>
              </Box>
              <Box m="0.5rem 1.5rem 0 0">
                <ul>
                  <li style={{ margin: "1.5rem 0 0.5rem 0" }}>
                    <Typography variant="body" color="fontColor.main">
                      مشاهده تغییرات آب و هوای 7 روز آینده
                    </Typography>
                  </li>
                  <li style={{ marginBottom: "0.5rem" }}>
                    <Typography variant="body" color="fontColor.main">
                      زمان غروب و طلوع خورشید
                    </Typography>
                  </li>
                  <li style={{ marginBottom: "0.5rem" }}>
                    <Typography variant="body" color="fontColor.main">
                      مقایسه داده های آب و هوا با داده های سنسورهای مزرعه
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Box> */}
          </WidgetWrapper>
        </Box>
        <WidgetWrapper width="19.3%" hasScroll overflow="auto">
          <Box
            pl="1rem"
            height="75vh"
            sx={{
              overflowY: "auto",
            }}
          >
            <Typography variant="h4">{t("suggestedServices")}</Typography>
            {allServices
              .filter((item) => item.type !== selectedService.type)
              .map((service, index) => (
                <Box key={index}>
                  <ServiceItem
                    title={translateToPersian(service.type, lang)}
                    service={service}
                  />
                  <Divider />
                </Box>
              ))}
          </Box>
        </WidgetWrapper>
      </Box>
    </Box>
  );
};

export default ServiceIndividual;
