import React, { useState } from "react";
import { Backdrop, Box, Modal, Slide, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navbar from "components/navbar/Navbar";
import Sidebar from "components/sidebar/Sidebar";
import NavbarMob from "mob/NavbarMob/NavbarMob";
import TabbarMob from "mob/TabbarMob/TabbarMob";
import { MOBILE_WIDTH } from "constants/constant";
import { setFinalize, setShoppingCartOpen } from "States/shopping";
import ShoppingCard from "scenes/Shopping/ShoppingCard";
import { useDispatch, useSelector } from "react-redux";
const Layout = () => {
  const isMobileScreens = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);
  const modalContext = React.createContext();
  const { Provider } = modalContext;
  const dispatch = useDispatch();
  const shoppingCartOpen = useSelector(
    (state) => state.shopping.shoppingCartOpen
  );
  const language = useSelector((state) => state.global.language);

  return (
    <Box
      display={!isMobileScreens ? "flex" : "block"}
      width="100%"
      height="100%"
      sx={({ palette }) => ({
        // direction: "ltr",
        // background:
        //   mode === "dark" ? palette.background.first : "rgb(235,238,242)",
        background: palette.background.first,

        scrollbarColor: `${palette.background.first} ${palette.background.third}`,
        scrollbarWidth: "thin",
        "& ::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
        },
        "& ::-webkit-scrollbar-track": {
          background: "transparent",
          borderRadius: "5px",
        },

        "& ::-webkit-scrollbar-thumb": {
          background: palette.others.lineChart,
          borderRadius: "5px",
        },
      })}
    >
      {isMobileScreens ? (
        <Provider value={{ shoppingCartOpen, setShoppingCartOpen }}>
          <Box flexGrow={1}>
            <NavbarMob />
            <Modal
              open={shoppingCartOpen}
              onClose={() =>
                dispatch(setShoppingCartOpen({ shoppingCartOpen: false }))
              }
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 1000,
                },
              }}
            >
              <Slide in={shoppingCartOpen} direction="right">
                <Box
                  sx={{
                    height: "100%",
                    width: "370px",
                    position: "absolute",
                    left: 0,
                    backgroundColor: "background.second",
                    borderTopRightRadius: "24px",
                    borderBottomRightRadius: "24px",
                    // position: "relative",
                  }}
                >
                  <ShoppingCard setFinalize={setFinalize} />
                </Box>
              </Slide>
            </Modal>
            <Outlet />
            <TabbarMob />
          </Box>
        </Provider>
      ) : (
        <Provider value={{ shoppingCartOpen, setShoppingCartOpen }}>
          <Sidebar isNonMobile={!isMobileScreens} />
          <Box flexGrow={1} width="calc(100% - 80px)">
            <Navbar />
            <Modal
              open={shoppingCartOpen}
              onClose={() =>
                dispatch(setShoppingCartOpen({ shoppingCartOpen: false }))
              }
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 1000,
                },
              }}
            >
              <Slide
                in={shoppingCartOpen}
                direction={language === "Fa" ? "right" : "left"}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: "370px",
                    position: "absolute",
                    left: language === "Fa" ? 0 : "auto",
                    right: language === "En" ? 0 : "auto",
                    backgroundColor: "background.second",
                    borderTopRightRadius: "24px",
                    borderBottomRightRadius: "24px",
                    // position: "relative",
                  }}
                >
                  <ShoppingCard setFinalize={setFinalize} />
                </Box>
              </Slide>
            </Modal>
            <Outlet />
          </Box>
        </Provider>
      )}
    </Box>
  );
};

export default Layout;
